import { ClassConstructor } from "elements/metadata"
import { FieldNames, FormFields } from "elements/FormFields"
import { Adresse } from "elements/model/dossierClasses"

export function AdresseFields<T>(props: { model: ClassConstructor<T>, names: FieldNames<T>, parentPath?: keyof T }) {

    const parent = { model: props.model, path: props.names }

    return (<>
        <FormFields parent={ parent } model={ Adresse } names={{
            appartement: true,
            batiment: true,
            adresse: { size: "long" },
            complement: { size: "short" },
            codePostal: true,
        }} />
    </>)
}
