import { Yop, ObjectPropertiesSchemas, Message, TestValidationContext, AnySchema } from '@dsid-opcoatlas/yop'

export const OptionalString = Yop.string().defined()
export const RequiredString = OptionalString.required()

export const OptionalBoolean = Yop.boolean().defined()
export const RequiredBoolean = OptionalBoolean.required()

const DATE_MIN = new Date(1900, 0, 1)
const DATE_MAX = new Date(2100, 0, 1)
export const OptionalDate = Yop.date().defined().min(DATE_MIN, 'Date invalide').max(DATE_MAX, 'Date invalide')
export const RequiredDate = OptionalDate.required()
export const RequiredStagiaireDate = RequiredDate.min(DATE_MIN, 'La date de naissance doit être postérieure au 1er janvier 1900')

export const OptionalTime = OptionalString.time()
export const RequiredTime = OptionalTime.required()

export const OptionalNumber = Yop.number().defined()
export const RequiredNumber = OptionalNumber.required()

export const PercentNumber = Yop.number().defined()
    .min(0, 'Doit être positif')
    .max(999, 'Doit être inférieur à 1000%')

export function optionalObject<T extends object | null | undefined>(definition: ObjectPropertiesSchemas<T>, message?: Message) {
    return Yop.object<T>(definition).defined(message)
}

export function requiredObject<T extends object | null | undefined>(definition: ObjectPropertiesSchemas<T>, message?: Message) {
    return Yop.object<T>(definition).required(message)
}

export function requiredArray<T extends any>(elementType: AnySchema<T | null | undefined>, message?: Message) {
    return Yop.array<T>(elementType).required(message)
}

export const OptionalPhone = OptionalString.matches(/^0\d{9}$/, 'Le numéro de téléphone doit commencer par 0 et comporter exactement 10 chiffres')
export const RequiredPhone = OptionalPhone.required()
export const OptionalMobile = OptionalString.matches(/^0[6|7](\d){8}$/, 'Le numéro de mobile doit commencer par 06 ou 07 et comporter exactement 10 chiffres')
export const RequiredMobile = OptionalMobile.required()
export const OptionalEmail = OptionalString.email().max(64)
export const RequiredEmail = OptionalEmail.required()

export const Ignored = Yop.ignored()

export const OptionalFile = Yop.file().defined()
export const RequiredFile = Yop.file().required()

export const OptionalFileAccept = (accept: string[]) => Yop.file().defined().test(function(context: TestValidationContext<File | null>) {
    const f = context.value
    if (f === null)
        return true
    if (f && !accept.includes(f.type))
        return context.createError("Ce type de document n'est pas accepté")
    else if (f && f.size > 15*1024*1024)
        return context.createError('Document trop volumineux (15 Mo maximum)')
    return true
})

export const RequiredFileAccept = (requiredMessage: string, accept: string[]) => OptionalFileAccept(accept).required(requiredMessage)

export type AcceptedFiles = {
    [mimetype: string]: string
}

export const AcceptedFilePDF: AcceptedFiles = {
    'application/pdf': 'pdf',
}

export const AcceptedDocuments: AcceptedFiles = {
    ...AcceptedFilePDF,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'application/vnd.ms-powerpoint': 'ppt',
}

export const AcceptedImages: AcceptedFiles = {
    ...AcceptedFilePDF,
    'image/jpeg': 'jpg',
    'image/png': 'png',
}

export const AdresseValidationFieldsParams = {
    Apt: 38,
    Bat: 38,
    Adr: 43,
    Cpt: 38,
    Cp: 10,
    Com: 50,
    CpRegex: /^[0-9]{5}$/,
    CpMessage: 'Le code postal doit être composé de 5 chiffres',
}

export const CappOFAdresseValidationFieldsParams = {
    ...AdresseValidationFieldsParams,
    Apt: 38,
    Bat: 38,
    Cpt: 38,
}
