import { Reference, References } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import { formatRef, formatRefReduit } from 'components/format/Format'
import { useSelector } from 'react-redux'
import { references } from 'api/references'

interface ExportRefFieldProps {
    label: string
    exportLabel?: string
    cell?: boolean
    row?: boolean
    options: (refs: References, parent?: any, root?: any) => Reference[] | undefined
    value: string
    parent?: any
    root?: any
    code?: boolean
    reduit?: boolean
    libelle?: boolean
}

export default function ExportRefField(props: ExportRefFieldProps) {

    const refs = references()

    const options = refs ? (props.options(refs, props.parent, props.root) ?? []) : []
    const value = formatRef(options, props.value, {
        libelle: props.reduit ? 'reduit' : (props.libelle ? 'complet' : 'reduit')
    })

    return <Value row={ props.row } cell={ props.cell } value={{ 
        label: props.exportLabel ?? props.label,
        value: value,
    }} />
}
