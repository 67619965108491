import { HANDICAP_OUI, SITUATION_SALARIE, SITUATION_SALARIE_INACTIF, SITUATION_SALARIE_RECHERCHE_EMPLOI, TYPE_BRANCHE_BANQUE } from "api/references"
import { AdresseFieldMetadata, adresseMapping } from "components/metadata/AdresseFieldMetadata"
import { PhoneFieldMetadata, TextFieldMetadata } from "components/metadata/TextFieldMetadata"
import CommuneField from "components/reform/inputs/CommuneField"
import DepartementField from "components/reform/inputs/DepartementField"
import { addYears, isBefore } from "date-fns"
import { simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { ElementContext, IgnoredFieldMetadata, ObjectMetadata, override } from "pages/dossier/metadata/FormMetadata"
import { salarieFields } from "pages/dossier/metadata/SalariesMetadata"
import { DossierCPRO, DossierCPROSalarie, createDossierCPROSalarie } from "./CPROModel"
import { SetValueOptions, UseFormReturn } from '@dsid-opcoatlas/reform'
import { BooleanFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { entreprise } from "api/entreprise"
import { NumberFieldMetadata } from "components/metadata/NumberFieldMetadata"


export const isCPROSalarieInactif = (salarie?: DossierCPROSalarie, situationAvantContrat?: string, handicap?: string, typeMinimumSocial?: string | null) => {
    situationAvantContrat = situationAvantContrat ?? salarie?.situationAvantContrat
    handicap = handicap ?? salarie?.handicap
    typeMinimumSocial = typeMinimumSocial ?? salarie?.typeMinimumSocial
    return (situationAvantContrat === SITUATION_SALARIE_INACTIF 
        || situationAvantContrat === SITUATION_SALARIE_RECHERCHE_EMPLOI 
        || situationAvantContrat === SITUATION_SALARIE)
        && !typeMinimumSocial
        && handicap !== HANDICAP_OUI
}


export const CPROSalarieMetadata: ObjectMetadata<DossierCPROSalarie> = {
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: override(salarieFields.prenom, { props: { 
        tooltip: "Renseigner un seul prénom. Celui-ci peut être le prénom d’usage (le second ou troisième de l’état civil si choisi par le salarié)" } 
    }),
    sexe: salarieFields.sexe,
    dateNaissance: salarieFields.dateNaissance,
    naissanceFrance: salarieFields.naissanceFrance,
    communeNaissance: TextFieldMetadata({
        props: {
            label: 'Commune de naissance',
        },
        input: props => <CommuneField { ...props } departementName="departementNaissance" />,
        mapping: simpleMapping('CommuneNaissance__c'),
    }).required().mutate(yop => yop.max(50)),
    departementNaissance: TextFieldMetadata({
        props: {
            label: "Département de naissance",
        },
        input: props => <DepartementField { ...props } communeName="communeNaissance" />,
        mapping: simpleMapping('DepartementNaissance__c'),
        visibleIf: ctx => ctx.parent?.departementNaissance !== '099',
        preserveSpaceWhenHidden: true
    }).required().mutate(yop => yop.matches(/^((\d){2,3}|2A|2B|69D|69M)$/, 'Le département doit comporter 2 ou 3 chiffres')),
    nir: override(salarieFields.nir.required(), { props: { alone: true }}),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    telephone: PhoneFieldMetadata({
        props: {
            label: 'Téléphone',
        },
        mapping: simpleMapping('Telephone__c'),
    }),
    handicap: salarieFields.handicap,
    publicSpecifique: BooleanFieldMetadata({
        props: {
            label: "Public spécifique",
            tooltip: "Joindre lors du dépôt du contrat de professionnalisation une attestation sur l’honneur précisant que l’alternant à bien suivi précédemment une formation dans le cadre de la POE ou AFPR.",
        },
        visibleIf: () => entreprise()?.Branche__c === TYPE_BRANCHE_BANQUE,
        mapping: simpleMapping('PublicSpecifique__c', 'dossier'),
    }).required().mutate(yop => yop.ignoredIf(() => entreprise()?.Branche__c !== TYPE_BRANCHE_BANQUE)),
    adresse: AdresseFieldMetadata({
        mapping: adresseMapping('Adresse1__c', 'Adresse2__c', 'Adresse3__c', 'Adresse4__c', 'CodePostal__c', 'Ville__c')
    }).required(),
    statut: override(salarieFields.statut, {
        props: {
            alone: true
        }
    }),
    situationAvantContrat: salarieFields.situationAvantContrat,
    plusHautDiplome: override(salarieFields.plusHautDiplome, {
        mapping: simpleMapping("DiplomeObtenu__c"),
    }),
    diplomeVise: salarieFields.diplomeVise,
    typeMinimumSocial: salarieFields.typeMinimumSocial,
    poleEmploi: override(salarieFields.poleEmploi, { props: {
        alone: true,
        onChange: (value: boolean | null, form: UseFormReturn<any>, parentPath: string | null) => {
            if (!value) {
                form.setValue(parentPath + '.numeroPoleEmploi', null, SetValueOptions.Untouch)
                form.setValue(parentPath + '.dureePoleEmploi', null, SetValueOptions.Untouch)
            }
        },
    }}).mutate(yop => yop.test<DossierCPROSalarie, DossierCPRO>(ctx => {
        const dateNaissance = ctx.parent?.dateNaissance
        const dateDebutContrat = ctx.root?.contrat?.dateDebut
        if (ctx.value === false && dateNaissance != null && dateDebutContrat != null) {
            const plus26ans = isBefore(addYears(dateNaissance, 26), dateDebutContrat)
            if (plus26ans && isCPROSalarieInactif(ctx.parent))
                return ctx.createError("Public éligible au contrat de professionnalisation : demandeur d'emploi de plus de 26 ans (Article L6325-1 du code du travail). Indiquez Oui dans Pôle emploi et renseignez le numéro et la durée.")
        }
        return true
    })),
    numeroPoleEmploi: TextFieldMetadata({
        props: {
            label: "Numéro d'inscription Pôle Emploi",
        },
        visibleIf: (ctx: ElementContext<DossierCPROSalarie>) => ctx.parent?.poleEmploi === true,
        mapping: simpleMapping('NumeroInscriptionPoleEmploi__c'),
    }).mutate(yop => yop.when<DossierCPROSalarie>(
        ctx => ctx.parent?.poleEmploi === true ?
        yop.required().matches(/^[\da-zA-Z]{1,9}$/, "Format de numéro Pôle Emploi incorrect (9 chiffres ou lettres maximum)") :
        yop.ignored()
    )),
    dureePoleEmploi: NumberFieldMetadata({
        props: {
            label: "Durée d'inscription Pôle Emploi",
            suffix: 'mois',
        },
        visibleIf: (ctx: ElementContext<DossierCPROSalarie>) => ctx.parent?.poleEmploi === true,
        mapping: simpleMapping('DureeMoisPoleEmploi__c'),
    }).mutate(yop => yop.requiredIf<DossierCPROSalarie>(ctx => ctx.parent?.poleEmploi === true)),
    niveauMaximumClasseEntreeFormationAgora: override(salarieFields.niveauMaximumClasseEntreeFormationAgora, {
        props: {
            full: true
        }
    }),
    situationHandicap: IgnoredFieldMetadata(simpleMapping('SituationHandicap__c')),
    nationalite: IgnoredFieldMetadata(),
    statutEntreeEnFormationAgora: IgnoredFieldMetadata(),
}

export function dossierCPROSalarieInitializer<D extends DossierCPRO>(dossier: D) {
    if (dossier.salarie == null)
        dossier.salarie = createDossierCPROSalarie()
    return dossier
}
