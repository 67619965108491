import { AtlasFlex, AtlasLogo, AtlasSpacer } from "atlas-ds"
import { PageHeaderProps } from "components/PageHeader"

interface DiagPageHeaderProps {
  children: React.ReactElement<PageHeaderProps>;
}

export default function DiagPageHeader(props: DiagPageHeaderProps) {
  return <AtlasFlex alignItems="baseline" columnGap="xxl" wrap>
      <AtlasFlex.Grow>
          {props.children}
      </AtlasFlex.Grow>

      <div>
        <AtlasFlex gap="s" alignItems="center">
            <p>avec</p>
            <AtlasLogo logo="diagnostic" width="12rem" />
        </AtlasFlex>
        <AtlasSpacer />
      </div>
    </AtlasFlex>
}