import { MyMenu } from 'atlas-ds'
import { Location } from 'history'
import { Component, MouseEventHandler } from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'

interface MenuItemProps extends RouteComponentProps {
    iconName: string
    children: string
    href: string
    notificationsCount?: number
    isActive?: (location?: Location) => boolean
    onClick?: MouseEventHandler
}

export class MenuItem extends Component<MenuItemProps> {
    override render() {
        return <MyMenu.Item
            {...this.props}
            linkConstructor={(props: any, content: React.ReactNode) => <NavLink
                exact
                isActive={ this.props.isActive ? (_, location) => this.props.isActive!(location) : undefined }
                to={ this.props.href }
            >{content}</NavLink>}
        />
    }
}

export default withRouter(MenuItem)