import { durationToString } from 'components/reform/inputs/DurationFloatingField'
import RecapAtlasValue from './RecapAtlasValue'

interface RecapDurationFieldProps {
    label: string,
    value: number | null | undefined,
    initialValue?: number | null | undefined,
    editMode?: boolean
}

export default function RecapDurationField(props: RecapDurationFieldProps) {
    if (props.value == null && props.initialValue == null)
        return null

    const value = props.value != null ? durationToString(props.value) : ''
    const initialValue = props.initialValue != null ? durationToString(props.initialValue) : ''

    return  <RecapAtlasValue label={props.label} initialValue={initialValue} value={value} editMode={props.editMode || false} />
}
