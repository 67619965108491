import { useFormContext } from '@dsid-opcoatlas/reform'
import { Profil } from 'api/profilAPI'
import { MyForm } from 'atlas-ds'
import { FormFields } from 'elements/FormFields'
import { CompanySummary } from 'components/reform/inputs/CompanySummary'
import SelectOrCreateEntityField from 'components/reform/inputs/SelectOrCreateEntityField'
import { FormContentProps, FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierAF, DossierAFModule, DossierCFA } from 'elements/model/dossierClasses'
import { join } from 'components/reform/inputs/utils/BaseFieldProps'
import { getOrganismesFormation, OFSearchOptions } from 'api/commonAPI'
import { AdresseFields } from './AdresseFields'
import { overrides } from "elements/decorators/overrides"
import { dtoToModel } from 'elements/mapping'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { useElementsContext } from 'elements/ElementsForm'

export function dossierModulesInitializer(dossier: DossierAF, metadata: FormMetadata<any>, _: Profil | null) {
    if (dossier.modules == null || dossier.modules.length === 0)
        dossier.modules = [metadata.api.createModule!(dossier) as unknown as DossierAFModule]
    return dossier
}

@overrides({
    EtablissementOFId__c: { dto: "Id" },
    CodeEtablissementOF__c: { dto: "CodeEtablissement__c" },
    nom: { dto: "Name" },
    siret: { dto: { path: "SIRET__c", fromDTO: siret => typeof siret === "string" ? siret?.replaceAll(' ', '') : null }},
    numeroUAI: { dto: "Uai__c" },
    numeroDA: { dto: "NumeroDA__c" },
    etat: { dto: "EtatEtablissement__c" },
    appartement: { dto: "Adresse1__c" },
    batiment: { dto: "Adresse2__c" },
    adresse: { dto: "Adresse3__c" },
    complement: { dto: "Adresse4__c" },
    codePostal: { dto: "BillingPostalCode" },
    commune: { dto: "BillingCity" },
})
class EtablissementCFA extends DossierCFA {
}

async function searchDossierCFA(recherche: string, options: OFSearchOptions = { actif: true }): Promise<DossierCFA[]> {
    return (await getOrganismesFormation(recherche, options)).map(etablissement =>
        dtoToModel(EtablissementCFA, etablissement, new DossierCFA())
    )
}

export function ModuleFields(props: FormContentProps<any>) {

    const { model } = useElementsContext<DossierAF>()

    const path = props.step.path!.toString()
    const index = props.step.path!.at(-1)?.index ?? 0
    const form = useFormContext<DossierAF>()
    const module = form.getValue(path)

    return ( <> 
        { module && <>
        <FormFields model={ model } names={{ modules: [index, {
            formationInterne: true
        }]}} />

        { module.formationInterne === false && <>
        <SelectOrCreateEntityField
            name={ `${ path }.cfa` }
            label={ "Choix de l'organisme de formation" }
            placeholder="Rechercher par SIRET ou raison sociale (3 caractères minimum)"
            tooltip={ <>
                <p>
                    Vous pouvez choisir un établissement actif dans la liste en entrant les 3 premières lettres du centre de formation,
                    certains champs seront remplis automatiquement.
                </p>
                <p>Si la recherche ne remonte pas de résultat, vous pouvez aussi entrer manuellement le centre de formation de votre choix.</p>
            </> }
            searchValues={ recherche => searchDossierCFA(recherche, { actif: true }) }
            create={{
                newValue: () => new DossierCFA(),
                buttonLabel: "Ajouter un organisme de formation",
                infoTitle: "Ajout d'un nouvel organisme de formation",
                infoContent: <>
                    Vous n'avez pas trouvé l'organisme de formation que vous cherchiez ?<br/>
                    Utilisez les champs ci-dessous pour renseigner votre organisme de formation.
                </>,
            }}
            renderValue={ cfa => <CompanySummary nom={ cfa.nom ?? "" } siret={ cfa.siret } adresse={ join(cfa.adresse, cfa.codePostal, cfa.commune) } /> }
            getValueId={ value => value.EtablissementOFId__c ?? "" }
            onChange={ () => form.renderForm() }
        />

        <FormFields model={ model } names={{ modules: [index, {
            cfa: {
                nom: true,
                siret: true,
                numeroDA: true,
            }
        }]}} />

        <MyForm.Fieldset legend="Adresse">
            <MyForm.Grid>
                <AdresseFields model={ model } names={{ modules: [index, { cfa: {} }] }} />
            </MyForm.Grid>
        </MyForm.Fieldset>
        </> }

        <FormFields model={ model } names={{ modules: [index, {
            subrogation: true
        }]}} />
        
        <MyForm.Grid>
            <FormFields model={ model } names={{ modules: [index, {
                intitulePrecis: true,
                dureeTotale: true,
                dateDebutFormation: true,
                dateFinFormation: true,
                coutPedagogique: {
                    montant: true,
                },
                numeroDossierApprentissage: true,
                typeFormation: true,
            }]}} />
        </MyForm.Grid>

        <MyForm.Fieldset legend="Informations complémentaires AGORA" tooltipContent={ TOOLTIPS.capp.formation.agora }>
            <MyForm.Grid>
                <FormFields model={ model } names={{ modules: [index, {
                    formationCertifianteAgora: true,
                    categorieActionAgora: true,
                    objectifAgora: true,
                }]}} />
            </MyForm.Grid>
        </MyForm.Fieldset>
        </> }
    </> )
}
