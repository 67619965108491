import { AtlasValues, MyContainer } from 'atlas-ds'
import { formDiffElements, FormElements } from 'pages/dossier/metadata/FormElements'
import { DossierCPRO } from './CPROModel'
import { RecapProps } from 'pages/dossier/recap/RecapProps'
import { useDossierNavigation } from 'pages/dossier/useDossierNavigation'
import { RecapSection } from 'pages/dossier/recap/RecapSection'
import { SECTION_MODULE_AFEST, SECTION_MODULE_AFEST_AGORA, SECTION_MODULE_PRINCIPAL, SECTION_MODULE_PRINCIPAL_AGORA } from './CPROFormationMetadata'
import { DISPOSITIF_CONTRAT_PROFESSIONNALISATION } from 'api/references'


export default function RecapCPROFormation(props: RecapProps<DossierCPRO>) {
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)
    
    const metadata = props.metadata
    const modulesEtape = steps.indexOf(props.step) + 1
    const skeleton = props.loading ? [{ cfa: {} }] : []
    const modulePrincipal = props.dossier?.modulePrincipal ?? skeleton
    const moduleAfest = props.dossier?.moduleAfest ?? skeleton
    const dossier = props.dossier ?? { modulePrincipal, moduleAfest } as DossierCPRO
    const initialDossier = props.initialDossier ?? { modulePrincipal: skeleton, moduleAfest: skeleton } as any

    const onModifier = (etape?: number) => navigateTo(props.dossier!.NumeroDossier__c!, etape ?? modulesEtape)

  
    if (props.metadata.api.isEdit) {
        const elements = formDiffElements(metadata, dossier, initialDossier, 'modulePrincipal', undefined, undefined, DISPOSITIF_CONTRAT_PROFESSIONNALISATION)
        const elementsAfest = formDiffElements(metadata, dossier, initialDossier, 'moduleAfest')
        if ((!elementsAfest || elementsAfest.length === 0) && (!elements || elements.length === 0)) return null  
        
        return <>
            { elements.length > 0 && <RecapSection key="modulePrincipal" title="Premier module de formation"
                onModifier={ () => onModifier() }>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            { elements }
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection> }

            { (elementsAfest.length > 1) && <RecapSection key="moduleAfest" title="Second module de formation AFEST"
                onModifier={ () => onModifier() }>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            { elementsAfest }
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection> }
        </>
    }

    return <>
        <RecapSection
            title="Premier module de formation"
            onModifier={ () => onModifier() }>
            <MyContainer>
                <AtlasValues>
                    <AtlasValues.Grid>
                        <FormElements section={ SECTION_MODULE_PRINCIPAL } dossier={ dossier } metadata={ metadata } path="modulePrincipal" type="recap"/>
                    </AtlasValues.Grid>

                    <AtlasValues.Grid label="Organisation de la formation en CFA">
                        <FormElements section={ SECTION_MODULE_PRINCIPAL_AGORA } dossier={ dossier } metadata={ props.metadata } path="modulePrincipal" type="recap" />
                    </AtlasValues.Grid>
                </AtlasValues>
            </MyContainer>
        </RecapSection>

        { moduleAfest &&
        <RecapSection
            title="Second module de formation AFEST"
            onModifier={ () => onModifier() }>
            <MyContainer>
                <AtlasValues>
                    <AtlasValues.Grid>
                        <FormElements section={ SECTION_MODULE_AFEST } dossier={ dossier } metadata={ metadata } path="moduleAfest" type="recap"/>
                    </AtlasValues.Grid>

                    <AtlasValues.Grid label="Organisation de la formation en CFA">
                        <FormElements section={ SECTION_MODULE_AFEST_AGORA } dossier={ dossier } metadata={ props.metadata } path="moduleAfest" type="recap" />
                    </AtlasValues.Grid>
                </AtlasValues>
            </MyContainer>
        </RecapSection>
        }
    </>
}