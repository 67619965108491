
import { BaseTextField, getFieldState, useFormContext, useRender } from '@dsid-opcoatlas/reform'
import { AtlasFieldDuration, AtlasFieldDurationInputProps } from 'atlas-ds'
import { BaseFieldProps } from './utils/BaseFieldProps'

export interface DurationMnFieldProps<T extends object> extends BaseFieldProps<{ hours: number, minutes: number }, T> {}

export default function DurationMnField<T extends object>(props: DurationMnFieldProps<T>) {
    const nameHours = props.name + ".hours"
    const nameMinutes = props.name + ".minutes"

    const render = useRender()
    const context = useFormContext<T>()
    const fieldHoursState = getFieldState<number>(context, nameHours)
    const fieldMinutesState = getFieldState<number>(context, nameMinutes)

    const numberRegex = /^[\d\s]*$/
    const numberFormatter = new Intl.NumberFormat("fr-FR", { minimumFractionDigits: 0, maximumFractionDigits: 0, minimumIntegerDigits: 2 })

    const getInputValue = (value: string) => {
        const parsedValue = parseInt(value.replaceAll(/\s/g, ''))
        return isNaN(parsedValue) ? null : parsedValue
    }

    return <AtlasFieldDuration
        name={ props.name }
        nameHours={ nameHours }
        nameMinutes={ nameMinutes }
        label={ props.label }
        disabled={ props.disabled }
        required={ fieldHoursState.constraints.required }
        tooltipContent={ props.tooltip }
        error={ fieldHoursState.error ?? fieldMinutesState.error }
        inputConstructor={ (inputProps: AtlasFieldDurationInputProps) => <BaseTextField
            { ...inputProps }
            render={ render }
            toModelValue={ getInputValue }
            toTextValue={ value => value ? numberFormatter.format(value).replace(/\s+/g, '\xa0') : ''}
            acceptInputValue={ value => numberRegex.test(value) }
        /> }
    />
}
