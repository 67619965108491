import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { Etablissement } from 'api/commonAPI'
import { useAppContext } from 'app/useAppContext'
import { MyForm } from 'atlas-ds'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import { CompanySummary } from 'components/reform/inputs/CompanySummary'
import IBANField from 'components/reform/inputs/IBANField'
import PhoneField from 'components/reform/inputs/PhoneField'
import TextField from 'components/reform/inputs/TextField'
import { join } from 'components/reform/inputs/utils/BaseFieldProps'
import { RequiredEmail, RequiredMobile, RequiredString, optionalObject, requiredObject } from 'components/yop/constraints'
import { isValidBIC, isValidIBAN } from 'ibantools'
import { nil } from 'slices/dossierCommon'
import { Reglement } from './ReglementBlock'

export function createPrelevement() { return {
    etablissementTitulaire: nil as Partial<Etablissement> & { Id: string },
    iban: nil as string,
    bic: nil as string,
    nom: nil as string,
    prenom: nil as string,
    mobile: nil as string,
    email: nil as string,
}}
export type Prelevement = ReturnType<typeof createPrelevement>


export const OptionalPrelevement = optionalObject<Prelevement>({
    etablissementTitulaire: requiredObject({ Id: RequiredString }),
    iban: RequiredString.test(ctx => {
        return !ctx.value.startsWith('FR') || isValidIBAN(ctx.value) || ctx.createError('Format IBAN incorrect')
    }),
    bic: RequiredString.test(ctx => {
        if (ctx.value.length >= 6 && ctx.value.substring(4, 6).toUpperCase() !== 'FR') {
            return ctx.createError("Le BIC doit être celui d'une banque française")
        }
        return isValidBIC(ctx.value) || ctx.createError('Format BIC incorrect')
    }),
    nom: RequiredString,
    prenom: RequiredString,
    mobile: RequiredMobile,
    email: RequiredEmail,
})


export default function PrelevementBlock() {
    const appContext = useAppContext()
    
    const name = "prelevement.etablissementTitulaire"
    const form = useFormContext<Reglement>()
    const fieldState = getFieldState<Etablissement | null>(form, name)

    return <>
        <MyForm.Fieldset legend="Merci de renseigner vos coordonnées bancaires">
            <AutocompleteBaseField<Etablissement>
                label={ "Etablissement titulaire" }
                defaultValue={ fieldState.value?.Name ?? "" }
                placeholder="Rechercher par SIRET ou raison sociale (3 caractères minimum)"
                required={ fieldState.constraints.required }
                error={ fieldState.error }
                search={ appContext.appInfo.options!.getEtablissements }
                searchDebounceOptions={ { immediate: true, empty: true } }

                optionFor={ etablissement => ({ id: etablissement.Id, content: (
                    <CompanySummary
                        nom={etablissement.Name}
                        siren={etablissement.SIREN__c}
                        adresse={join(etablissement.Adresse3__c ?? etablissement.BillingStreet, etablissement.BillingPostalCode, etablissement.BillingCity)} />
                )})}
                onSelect={ etablissement => form.setValue(name, etablissement, true) }
                hideNoResultsMessage
            />
            {/* <SimpleEtablissementSelectorField name="prelevement.etablissementTitulaire" label="Etablissement titulaire" /> */}

            <MyForm.Grid>
                <MyForm.Field size="long">
                    <IBANField name="prelevement.iban" label="IBAN" />
                </MyForm.Field>

                <MyForm.Field size="short">
                    <TextField name="prelevement.bic" label="BIC" />
                </MyForm.Field>

                <TextField name="prelevement.nom" label="Nom contact" />

                <TextField name="prelevement.prenom" label="Prénom contact" />

                <PhoneField name="prelevement.mobile" label="Mobile" />

                <TextField name="prelevement.email" label="E-mail" />
            </MyForm.Grid>

            <p>En cliquant sur valider, vous accéderez à notre plateforme de paiement pour remplir les informations nécessaires au prélèvement.</p>
        </MyForm.Fieldset>
    </>
}