import { AnySchema, Yop, Message, ValidationContext, StringSchema, NumberSchema, BooleanSchema, DateSchema, FileSchema, ObjectSchema, ArraySchema } from "@dsid-opcoatlas/yop";
import { ClassConstructor, AnyFieldMetadata, MinMaxFieldMetadata, getMetadata, FieldMetadata } from "./metadata";
import { reformContext } from "@dsid-opcoatlas/reform";

export class ValidationFieldContext<ParentClass, T> {

    constructor(
        readonly context: ValidationContext<T>
    ) { }

    get path() {
        return this.context.path
    }

    get reformContext() {
        return reformContext(this.context)
    }

    get value() {
        return this.context.value
    }

    get parent() {
        return this.context.parent as ParentClass | null | undefined
    }

    getRoot<R>() {
        return this.context.root as R | null | undefined
    }
}

export type ValidationContextProperty<ParentClass, ValidatorType, ValueType> =
    ValidatorType |
    ((context: ValidationFieldContext<ParentClass, ValueType>) => ValidatorType | null) |
    [
        ValidatorType | ((context: ValidationFieldContext<ParentClass, ValueType>) => ValidatorType | null),
        string | ((context: ValidationFieldContext<ParentClass, ValueType>) => string)
    ]

const PrimitiveSchemas = new Map<ClassConstructor<object>, Pick<AnySchema<object>, "defined">>([
    [String, Yop.string()],
    [Number, Yop.number()],
    [Boolean, Yop.boolean()],
    [Date, Yop.date()],
    [File, Yop.file()],
])
type YopConstraint = {
    message?: (message?: Message<any>) => any
    primitive?: (value: any, message?: Message<any>) => any
    condition?: (context: (context: ValidationContext<any>) => any, message?: Message<any>) => any
}
function applyConstraint(yop: YopConstraint, elements: ValidationContextProperty<any, any, any>) {

    if (elements === true && yop.message != null)
        return yop.message()

    if (typeof elements === 'function') {
        if (yop.condition != null)
            return yop.condition((context: ValidationContext<any>) => elements(new ValidationFieldContext(context)))
        throw new Error('Invalid constraint')
    }

    if (Array.isArray(elements)) {
        let [constraint, message] = elements
        if (typeof message === 'function')
            message = (context: ValidationContext<any>) => message(new ValidationFieldContext(context))

        if (constraint === true && yop.message != null)
            return yop.message(message)

        if (typeof constraint === 'function') {
            if (yop.condition != null)
                return yop.condition((context: ValidationContext<any>) => constraint(new ValidationFieldContext(context)), message)
            throw new Error('Invalid constraint')
        }
        if (yop.primitive != null)
            return yop.primitive(constraint, message)

        throw new Error('Invalid constraint')
    }

    if (yop.primitive != null)
        return yop.primitive(elements)

    throw new Error('Invalid constraint')
}

export function applyValidationConstraints<T, S extends AnySchema<T>>(schema: S, constraints: AnyFieldMetadata<any, T> & MinMaxFieldMetadata<any, T, any>): S {

    if (constraints.ignored != null)
        schema = applyConstraint({ primitive: schema.ignored.bind(schema), condition: schema.ignoredIf.bind(schema) }, constraints.ignored)

    if (constraints.required != null && constraints.required !== false)
        schema = applyConstraint({ message: schema.required.bind(schema), condition: schema.requiredIf.bind(schema) }, constraints.required)

    const yopMin = (schema as any)['min']
    if (constraints.min != null && (typeof yopMin === 'function'))
        schema = applyConstraint({ primitive: yopMin.bind(schema), condition: yopMin.bind(schema) }, constraints.min)

    const yopMax = (schema as any)['max']
    if (constraints.max != null && (typeof yopMax === 'function'))
        schema = applyConstraint({ primitive: yopMax.bind(schema), condition: yopMax.bind(schema) }, constraints.max)

    return schema
}

export function createValidationSchema(model: ClassConstructor<String>): StringSchema<string | null>
export function createValidationSchema(model: ClassConstructor<Number>): NumberSchema<number | null>
export function createValidationSchema(model: ClassConstructor<Boolean>): BooleanSchema<boolean | null>
export function createValidationSchema(model: ClassConstructor<Date>): DateSchema<Date | null>
export function createValidationSchema(model: ClassConstructor<File>): FileSchema<File | null>
export function createValidationSchema<T extends object>(model: ClassConstructor<T>): ObjectSchema<T | null>
export function createValidationSchema<T extends object>(
    model: ClassConstructor<T>,
    arrayElementValidation: AnyFieldMetadata<any, T>
): ArraySchema<(T | null)[] | null>

export function createValidationSchema<T extends object>(model: ClassConstructor<T>, arrayElementValidation?: AnyFieldMetadata<any, any>) {
    let schema = PrimitiveSchemas.get(model)?.defined()

    if (schema == null) {
        const metadata = getMetadata(model)

        const properties = Object.fromEntries(Object.entries<FieldMetadata<any, any>>(metadata)
            .filter(([, fieldMetadata]) => fieldMetadata?.schema != null)
            .map(([fieldName, fieldMetadata]) => {
                let schema = fieldMetadata.schema!
                if (typeof schema === 'function')
                    schema = schema()
                return [fieldName, applyValidationConstraints(schema, fieldMetadata)]
            }))

        schema = Yop.object<any>(properties).defined()
    }

    if (arrayElementValidation != null) {
        schema = applyValidationConstraints(schema, arrayElementValidation)
        schema = Yop.array(schema).defined()
    }

    return schema
}

