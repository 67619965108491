import { ETAT_VERSEMENT, Versement, VersementsSearchQuery, getVersements } from 'api/versementAPI'
import { RootState } from 'app/rootReducer'
import { AtlasInfo, MyDropdownMenu, MyTableColumn } from 'atlas-ds'
import { formatApiDate, formatCurrency } from 'components/format/Format'
import Link from 'components/Link'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import RechercheBase, { DEFAULT_DATE_DEBUT, DEFAULT_DATE_FIN } from 'components/recherche/RechercheBase'
import { useIdcc } from 'components/reform/inputs/utils/useIdcc'
import State from 'components/State'
import { useSelector } from 'react-redux'
import { hasConventionnel } from './VersementConventionnel'

class VersementsSearchQueryImpl extends BaseSearchQueryImpl implements VersementsSearchQuery {

    recherche = ''
    dateDebut = DEFAULT_DATE_DEBUT
    dateFin = DEFAULT_DATE_FIN
    etatVersement = [] as string[]

    constructor(search: URLSearchParams) {
        super(search, 'DatePaiement__c')
        this.applySearch(search)
    }

    override applySearch(params: URLSearchParams): VersementsSearchQueryImpl {
        super.applySearch(params)
        this.recherche = params.get('recherche') ?? this.recherche
        this.dateDebut = params.get('date-debut') ?? this.dateDebut
        this.dateFin = params.get('date-fin') ?? this.dateFin
        this.etatVersement = params.getAll('etat-versement') ?? this.etatVersement
        return this
    }

    clone() {
        return new VersementsSearchQueryImpl(this.toSearchParams())
    }

    override toSearchParams(forStorage = false): URLSearchParams {
        const params = super.toSearchParams(forStorage)
        params.set('recherche', this.recherche)
        params.set('date-debut', this.dateDebut)
        params.set('date-fin', this.dateFin)
        this.etatVersement.forEach((etat) => params.append('etat-versement', etat))
        return params
    }
}

export default function MesVersements() {

    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const refsIdcc = useIdcc()
    
    const conventionnel = hasConventionnel(refsIdcc, entreprise)

    const colonnes = (_: VersementsSearchQuery): MyTableColumn[] => [
        {
            id: "ExternalID_Sor__c",
            label: "Référence",
            wrap: true,
            value: (row: any) => row.ExternalID_Sor__c ?? '-'
        },
        {
            id: "DatePaiement__c",
            label: "Date",
            sortable: true,
            value: (row: any) => formatApiDate(row.DatePaiement__c)
        },
        {
            id: "MontantTTC__c",
            label: "Montant TTC",
            sortable: true,
            value: (row: any) => formatCurrency(row.MontantTTC__c)
        },
        {
            id: "EtatVersementFORef__c",
            label: "État",
            value: (row: any) => ETAT_VERSEMENT[row?.EtatVersement__c]
                ? <State type="versement" id={ row.EtatVersement__c }>
                    { ETAT_VERSEMENT[row?.EtatVersement__c] as string }
                </State> : <></>
        },
    ]

    const actions = (versement: Versement) => {
        return [
            <Link
                key="details"
                icon="details"
                level={ 3 }
                to={ versement.TypeVersement__c === 'VersementVolontaire' ? `/detail-versementv/${versement.IdHeroku}` : `/detail-versement/${versement.IdHeroku}` }
            >Voir le détail</Link>,
        ]
    }

    const versementTabs = []
    if (conventionnel) {
        versementTabs.push({
            label: (count: number) => count + ' contribution' + (count > 1 ? 's' : '') + ' conventionnelle' + (count > 1 ? 's' : ''),
            executeQuery: (query: VersementsSearchQuery) => getVersements(query, 'ContributionConventionnelle'),
        })
    }
    versementTabs.push({
        label: (count: number) => count + ' versement' + (count > 1 ? 's' : '') + ' volontaire' + (count > 1 ? 's' : ''),
        executeQuery: (query: VersementsSearchQuery) => getVersements(query, 'VersementVolontaire'),
    })

    const headerActions = conventionnel
        ? <MyDropdownMenu
            items={[
                <Link icon="versements" to="/versement-conventionnel">
                    Versement d'une contribution conventionnelle
                </Link>,
                <Link icon="versements" to="/versement-volontaire">
                    Versement volontaire
                </Link>
            ]}
        >Faire un versement</MyDropdownMenu> : <Link to="/versement-volontaire">Faire un versement volontaire</Link>


    const newQuery = (params: URLSearchParams) => new VersementsSearchQueryImpl(params)

    return ( // N'essaie pas de rendre Recherche tant que refsIdcc n'est pas connu (1 ou 2 tabs suivant les cas)
        refsIdcc && <RechercheBase<Versement, VersementsSearchQueryImpl>
            name="MesVersements"
            title="Versements & Contributions"
            emptyText="Votre recherche n'a retourné aucun résultat."
            headerActions={ import.meta.env.REACT_APP_FAIRE_VERSEMENT_DISABLED === 'true' ? undefined : headerActions }
            headerInfo={ import.meta.env.REACT_APP_FAIRE_VERSEMENT_DISABLED === 'true' ?
                <AtlasInfo type="warning" title="Maintenance">
                    <p>Le dépôt de versements est momentanément indisponible. Nous mettons tout en oeuvre pour le rétablir dans les meilleurs délais.</p>
                    <p>Merci de ne pas tenter d'en créer et de revenir ultérieurement.</p>
                    <p>Nous vous prions de nous excuser pour la gêne occasionnée.</p>
                </AtlasInfo> : undefined }
            newQuery={ newQuery }
            filtres={ [] }
            colonnes={ colonnes }
            tabs={ versementTabs }
            tableActions={ actions }
        />
    )
}
