import { RootState } from 'app/rootReducer'
import { AtlasValue, AtlasValues, MyContainer } from 'atlas-ds'
import { formDiffElements, FormElements, mergeCommuneWithCodePostal } from 'pages/dossier/metadata/FormElements'
import { useSelector } from 'react-redux'
import { BaseDossier } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import RecapAdresse from './RecapAdresse'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'

export default function RecapEmployeur<D extends BaseDossier>(props: RecapProps<D>) {
    const { navigateTo } = useDossierNavigation(props.metadata, props.navigationGuard)
    
    const { profil } = useSelector((state: RootState) => state.profilState)
    const skeleton = props.loading ? {} : {}
    const employeur = props.dossier?.employeur ?? skeleton as any
    const dossier = props.dossier ?? { employeur } as any
    const initialDossier = props.initialDossier ?? { employeur } as any

    if (props.metadata.api.isEdit) {
        
        const elements = formDiffElements(props.metadata, dossier, initialDossier, 'employeur')
        mergeCommuneWithCodePostal(elements)

        if (!elements || elements.length === 0) return null
        return <RecapSection title={ props.step.title ?? 'Employeur' } 
                    onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, props.etape) }>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            { elements }
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection>
    }

    return <>
        <RecapSection
            title={ props.step.title ?? 'Employeur' }
            onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, props.etape) }
        >
            <MyContainer>
                <AtlasValues>
                    <AtlasValues.Grid>
                        <AtlasValue label="Raison sociale">{ employeur?.nom }</AtlasValue>
                        <AtlasValue label="SIRET">{ employeur?.numeroSiret }</AtlasValue>
                    </AtlasValues.Grid>
                    <RecapAdresse parent={{adresse: employeur}} />

                    <>{ props.step.sections.map(section =>
                        <AtlasValues.Grid key={section}>
                            <FormElements metadata={ props.metadata } dossier={ props.dossier } path="employeur" type="recap" section={ section } />
                        </AtlasValues.Grid>
                    )}</>

                    <>{ props.step.options?.contact && <AtlasValues.Grid>
                        <AtlasValue label="Contact">
                            {profil?.Contact?.FirstName} {profil?.Contact?.LastName} - {profil?.Contact?.Phone} - {profil?.Contact?.Email}
                        </AtlasValue>
                    </AtlasValues.Grid> }</>
                </AtlasValues>
            </MyContainer>
        </RecapSection>
    </>
}
