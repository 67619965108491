import RecapAtlasValue from './RecapAtlasValue'

interface RecapDurationMnFieldProps {
    label: string,
    value: { hours: number, minutes: number } | null | undefined,
    initialValue?: { hours: number, minutes: number } | undefined,
    editMode?: boolean
}

export const formatDuration = (duration: { hours: number, minutes: number } | null | undefined) => {
    if (!duration) return '';

    const minutes = duration.minutes ?? 0
    const formattedMinutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString()

    return `${duration.hours ? `${duration.hours} h` : ''} ${formattedMinutes} min`
}

export default function RecapDurationMnField(props: RecapDurationMnFieldProps) {

    const value = formatDuration(props.value)
    const initialValue = formatDuration(props.initialValue)

    return  <RecapAtlasValue label={props.label} initialValue={initialValue} value={value} editMode={props.editMode || false} />
}
