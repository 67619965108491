import CurrencyField from "components/reform/inputs/CurrencyField";
import NumberField from "components/reform/inputs/NumberField";
import { OptionalNumber } from "components/yop/constraints";
import { NumberFieldMetadata, fieldMetadataDecorator } from "elements/metadata";


export function numberField<ParentClass>(props?: NumberFieldMetadata<ParentClass>) {
    return fieldMetadataDecorator<ParentClass, number | null>({
        kind: "number",
        input: NumberField,
        schema: OptionalNumber,
        ...props
    })
}

export function currencyField<ParentClass>(props?: NumberFieldMetadata<ParentClass>) {
    return numberField<ParentClass>({
        input: CurrencyField,
        ...props,
    })
}

export function durationField<ParentClass>(props?: NumberFieldMetadata<ParentClass>) {
    return numberField<ParentClass>({
        input: props => <NumberField {...props} fractionDigits={1} minimumFractionDigits={0} />,
        ...props,
    })
}

export function heuresField<ParentClass>(props?: NumberFieldMetadata<ParentClass>) {
    return durationField<ParentClass>({
        suffix: "heure(s)",
        ...props,
    })
}
