import store from 'app/store'
import axios from 'axios'
import { BaseSearchQuery, getRequestConfig, Page } from "./baseAPI"

export interface PieceDTO {
    Id: string
    ContentVersionId: string | null
    nom?: string
    dateAjout: string
    fichier: string
    type: string
    info?: string
    source?: string
    uploadedToGed?: boolean
    typeFichier?: string
    tailleFicher?: number

    // Vient de PieceTypeDTO
    description?: string
    actif?: boolean
    obligatoire?: boolean
    action?: string;
}

export interface PieceTypeDTO {
    id: string
    nom: string
    description: string
    obligatoire: boolean
}


export async function uploadDocument(idDossier: string, nom: string, file: File | Blob, fileName?: string): Promise<PieceDTO> {
    return doUploadDocument(idDossier, nom, false, file, fileName)
}

export async function uploadDocumentToGed(idDossier: string, nom: string, file: File | Blob, fileName?: string): Promise<PieceDTO> {
    return doUploadDocument(idDossier, nom, true, file, fileName)
}

async function doUploadDocument(idDossier: string, nom: string, uploadToGed: boolean, file: File | Blob, fileName?: string): Promise<PieceDTO> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data'

    const name = (fileName ?? (file as any).name ?? nom).replaceAll(/[\\/|:*?"<>]/g, '_')

    const formData = new FormData()
    formData.append('nom', nom)
    formData.append('document', file, name)
    if (uploadToGed)
        formData.append('uploadToGed', 'true')
    const { data } = await axios.post('/dossiers/num/' + idDossier + '/documents', formData, requestConfig)
    return data
}

export async function deleteDocument(iddossier: string, id: string) {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.delete<string[]>('/dossiers/num/' + iddossier + '/documents/' + id, requestConfig)
    return data
}

export interface DownloadDocumentDetail {
    contenu: string
    encodage: string
    mimeType: string
}

export async function getDocumentAlfresco(doc: string): Promise<DownloadDocumentDetail> {
    const requestConfig = await getRequestConfig()
    requestConfig.params = getDownloadDocumentsParams()
    const { data } = await axios.get('/documents/' + doc.replace('workspace://SpacesStore/', ""), requestConfig)
    return data
}

export async function getDocumentSalesforce(iddossier: string, contentVersionId: string): Promise<any> {
    const requestConfig = await getRequestConfig()
    const content = await axios.get<any>('/dossiers/num/' + iddossier + '/documents/download/' + contentVersionId, { ...requestConfig, responseType: 'blob' })
    return content
}

export function openDocumentSalesforce(docName: string, response: any) {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', docName)
    document.body.appendChild(fileLink)

    fileLink.click()
}

export function openDocumentAlfresco(docName: string, response: any) {
    const byteCharacters = atob(response.contenu)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob(
        [byteArray], {
        type: response.mimeType
    })
    const anchor = document.createElement('a')
    anchor.href = window.URL.createObjectURL(blob)
    anchor.download = docName
    anchor.click()
}

export async function downloadDoc(doc: PieceDTO): Promise<void> {
    return getDocumentAlfresco(doc.Id).then(response => {
        openDocumentAlfresco(doc.fichier, response)
    })
}

export interface DocumentsSearchQuery extends BaseSearchQuery {
    // recherche?: string
}

export function getSearchDocumentsParams(query: DocumentsSearchQuery): URLSearchParams {
    const params = new URLSearchParams()

    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 20).toFixed(0))
    if (query.tri) {
        params.append('tri', query.tri)
        params.append('ordre', query.ordre ?? 'desc')
    }
    // if (query.recherche)
    //     params.append('recherche', query.recherche.trim())
    return params
}

function getDownloadDocumentsParams(): URLSearchParams {
    const param = new URLSearchParams()
    param.append("siren", store.getState().contextState.entreprise?.SIREN__c ?? '')
    return param
}

export async function getDocuments(query: DocumentsSearchQuery): Promise<Page<PieceDTO>> {
    const requestConfig = await getRequestConfig()

    requestConfig.params = getSearchDocumentsParams(query)

    const siren = store.getState().contextState.entreprise?.SIREN__c ?? ''
    const { data, headers } = await axios.get<PieceDTO[]>('/entreprises/siren/' + siren + '/documents', requestConfig)

    const totalCount = parseInt(headers['x-total-count'], 10) || data.length

    return {
        totalCount: totalCount,
        items: data,
    }
}
