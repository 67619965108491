import { useState } from 'react'
import { AtlasButton } from 'atlas-ds'

import InformationRuptureModal from 'components/annuler/InformationRuptureModal'
import { RuptureFormMetadata, RuptureInfo } from 'pages/dossier/metadata/RuptureFormMetadata'
import { DossierActionProps } from './DetailDossierContrat'
import { checkDetailAction } from 'pages/detail-common/Detail'

interface DossierInformationRuptureProps<R extends RuptureInfo = RuptureInfo> extends DossierActionProps {
    formMetadata: RuptureFormMetadata<R>
}

export default function DossierInformationRupture<R extends RuptureInfo = RuptureInfo>(props: DossierInformationRuptureProps<R>) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null
    
    const [showInfoRupture, setShowInfoRupture] = useState(false)
    
    return <>
       <AtlasButton
            id="rupture"
            level={ 2 }
            disabled={ props.formMetadata.disabled ? props.formMetadata.disabled(dossier) : false }
            onClick={() => setShowInfoRupture(true)}>Informer la rupture du contrat</AtlasButton>

        { showInfoRupture && <InformationRuptureModal formMetadata={ props.formMetadata } dossier={ dossier }
            close={ () => setShowInfoRupture(false) } />
        }
    </>
}


