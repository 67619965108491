import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { getFormationFC } from 'api/commonAPI'
import { RootState } from 'app/rootReducer'
import { AtlasValue, AtlasValues, MyForm } from 'atlas-ds'
import SelectOrCreateEntityField from 'components/reform/inputs/SelectOrCreateEntityField'
import TextField from 'components/reform/inputs/TextField'
import { DossierDiplome, createDossierDiplome } from 'pages/dossier-capp/CAPPModel'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { useSelector } from 'react-redux'


async function searchDossierDiplome(recherche: string, niveau?: number): Promise<DossierDiplome[]> {
    const formations = await getFormationFC(recherche, niveau)
    return formations.map(f => ({
        IdFormation: f.CodeRncp__c + '-' + f.CodeDiplome__c,
        intitulePrecis: f.Libelle__c,
        codeDiplome: f.CodeDiplome__c,
        codeRNCP: f.CodeRncp__c,
        eligibleRNCP: f.AccesApprentissage__c,
        formationReferentielLBAC: (f?.Id ?? null) !== null,
    }))
}

interface DiplomeFieldsProps {
    name: string
    disabled: boolean
}

const niveauFormation = (diplomeVise?: string): number | null => {
    if ((diplomeVise ?? '').length >= 1) {
        switch (diplomeVise!.charAt(0)) {
            case '3': return 5
            case '4': return 4
            case '5': return 3
            case '6': return 2
            case '7': return 1
        }
    }
    return null
}

export default function DiplomeFields<FormObjectType extends object>(props: DiplomeFieldsProps) {

    const refs = useSelector((state: RootState) => state.referencesState.fulfilled)
    
    const context = useFormContext<FormObjectType>()
    const fieldState = props.name ? getFieldState<DossierDiplome | null>(context, props.name) : null

    const searchDossierDiplomeAvecNiveau = (recherche: string) => {
        const diplomeVise = refs?.REF_DIPLOME?.find(r => r.Id === (context as any)?.stagiaire?.diplomeVise)?.Libelle__c
        const niveau = niveauFormation(diplomeVise) ?? undefined
        return searchDossierDiplome(recherche, niveau)
    }

    const renderDossierDiplome = (diplome: DossierDiplome) => {
        return <AtlasValues label={ diplome.intitulePrecis }>
            <AtlasValues.Grid>
                <AtlasValue label="Code RNCP">{ diplome.codeRNCP}</AtlasValue>
                <AtlasValue label="Code Diplôme">{ diplome.codeDiplome}</AtlasValue>
            </AtlasValues.Grid>
        </AtlasValues>
    }

    const newDossierDiplome = (rechercheRNCP?: string | null) => {
        return { ...createDossierDiplome(), IdFormation: null, codeRNCP: rechercheRNCP ?? '' }
    }

    const onNewDossierDiplome = (rechercheRNCP?: string | null) => {
        if (rechercheRNCP) {
            context.touch(props.name + '.codeRNCP')
            context.validateAt(props.name + '.codeRNCP')
        }
    }

    const diplomeFieldsDisabled = props.disabled || fieldState?.value === null || !!fieldState?.value?.IdFormation 

    return (<>
        { !props.disabled &&
        <SelectOrCreateEntityField
            name={ props.name }
            label="Rechercher votre formation par code RNCP"
            tooltip={ TOOLTIPS.formation.rechercheFormationParRNCP }
            placeholder="Code RNCP (3 caractères minimum)"
            searchValues={ searchDossierDiplomeAvecNiveau }
            renderValue={ renderDossierDiplome }
            getValueId={ value => value.IdFormation ?? "" }
            onChange={ () => context.renderForm() }
            disabled={ props.disabled }
            create={{
                newValue: newDossierDiplome,
                onCreated: onNewDossierDiplome,
                buttonLabel: "Ajouter une formation",
                infoTitle: "Ajout d'une nouvelle formation",
                infoContent: <>Vous n'avez pas trouvé la formation que vous cherchiez ?<br/>Utilisez les champs ci-dessous pour renseigner votre formation.</>,
            }}
        /> }

        <TextField name={ props.name + '.codeRNCP' }
            label="Code RNCP"
            placeholder="Format : RNCP123, RNCP1234 ou RNCP12345"
            tooltip={ TOOLTIPS.formation.codeRNCP }
            disabled={ diplomeFieldsDisabled } />

        <TextField name={ props.name + '.codeDiplome' }
            label="Code du diplôme"
            tooltip={ TOOLTIPS.formation.diplomeCode }
            disabled={ diplomeFieldsDisabled } />

        <MyForm.Field size="full">
            <TextField name={ props.name + '.intitulePrecis' }
                label="Intitulé précis"
                tooltip={ TOOLTIPS.formation.diplomeIntitulePrecis }
                disabled={ diplomeFieldsDisabled } />
        </MyForm.Field>
    </>)
}