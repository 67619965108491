import { Facture, FacturesSearchQuery, getFactures, getFacturesSelfe } from 'api/factureAPI'
import { ETAT_FACTURE_INCOMPLET, TYPE_FACTURE_AVOIR, TYPE_FACTURE_FACTURE } from 'api/references'
import { AtlasText, MyTableColumn } from 'atlas-ds'
import { formatApiDate, formatCurrency } from 'components/format/Format'
import Link from 'components/Link'
import { BaseSearchQuery2Impl } from 'components/recherche/BaseSearchQueryImpl'
import { DEFAULT_DATE_DEBUT, DEFAULT_DATE_FIN } from 'components/recherche/RechercheBase'
import State from 'components/State'


export class FacturesSearchQueryImpl extends BaseSearchQuery2Impl implements FacturesSearchQuery {

    recherche = ''
    dateDebut = DEFAULT_DATE_DEBUT
    dateFin = DEFAULT_DATE_FIN
    etatFactureFo = [] as string[]
    typeFacture = TYPE_FACTURE_FACTURE
    organisme = ''
    alerte = ''
    // demande = ''


    constructor(search: URLSearchParams) {
        super(search, 'DateEmission__c')
        this.applySearch(search)
    }

    override applySearch(params: URLSearchParams): FacturesSearchQueryImpl {
        super.applySearch(params)
        this.recherche = params.get('recherche') ?? this.recherche
        this.dateDebut = params.get('date-debut') ?? this.dateDebut
        this.dateFin = params.get('date-fin') ?? this.dateFin
        this.etatFactureFo = params.getAll('etat-facture-fo') ?? this.etatFactureFo
        this.typeFacture = params.get('type-facture') ?? this.typeFacture
        this.organisme = params.get('organisme') ?? this.organisme
        this.alerte = params.get('alerte') ?? this.alerte
        // this.demande = params.get('demande') ?? this.demande
        return this
    }

    clone() {
        return new FacturesSearchQueryImpl(this.toSearchParams())
    }

    override toSearchParams(forStorage = false): URLSearchParams {
        const params = super.toSearchParams(forStorage)
        params.set('recherche', this.recherche)
        params.set('date-debut', this.dateDebut)
        params.set('date-fin', this.dateFin)
        this.etatFactureFo.forEach((value) => params.append('etat-facture-fo', value))
        params.set('type-facture', this.typeFacture)
        params.set('organisme', this.organisme)
        if (import.meta.env.REACT_APP_RELANCE === 'true') {
            params.set('alerte', this.alerte)
            // params.set('demande', this.demande)
        }
        return params
    }
}

export const factureTabs = [{
        label: (count: number) => count + ' facture' + (count > 1 ? 's' : '') + ' déposée' + (count > 1 ? 's' : ''),
        executeQuery: getFactures,
    }, {
        label: (count: number) => count + ' facture' + (count > 1 ? 's' : '') + ' en cours de dépôt',
        executeQuery: getFacturesSelfe,
    }
]

export const factureActions = (facture: Facture, tabIndex?: number) => {
    if ((tabIndex ?? 0) !== 0)
        return []
    const actions = [
        <Link
            key="detail"
            icon="details"
            level={ 3 }
            to={ '/detail-facture/' + facture.Id }
        >Voir le détail</Link>
    ]
    if (facture.EtatFactureFORef?.Id === ETAT_FACTURE_INCOMPLET) {
        actions.push(<Link
            key="ajouterDocuments"
            icon="add"
            level={ 3 }
            to={{
                pathname: 'detail-facture/' + facture.Id,
                hash: '#documents',
                state: { showIncomplete: true }
            }}
        >Ajouter des documents</Link>)
    }
    return actions
}

const getFactureType = (facture: Facture) => {
    return (facture.Type__c ?? '') === TYPE_FACTURE_AVOIR ? 'Avoir' : 'Facture';
}

export const ColonneRef: MyTableColumn = {
    id: "RefFactureClient__c",
    label: "Référence",
    sortable: true,
    nowrap: true,
    value: (row: any) => [
        <AtlasText bold>{getFactureType(row)}</AtlasText>,
        <AtlasText small>{row.RefFactureClient__c}</AtlasText>
    ]
}

export const ColonneNumeroDossier: MyTableColumn = {
    id: "NumeroDossier__c",
    label: "Numéro dossier",
    nowrap: true,
    value: (row: any) => row.Dossier?.NumeroDossier__c ?? row.NumeroDossier__c
}

export const ColonneDateEmission: MyTableColumn = {
    id: "DateEmission__c",
    label: "Date émission",
    sortable: true,
    value: (row: any) => formatApiDate(row.DateEmission__c)
}

export const ColonneEtat: MyTableColumn = {
    id: "EtatFactureFORef",
    label: "État de la facture",
    value: (row: any) => <State type="bill" id={ row.EtatFactureFO__c }>
        { row.EtatFactureFORef?.Libelle__c }
    </State>
}

export const ColonneMontant: MyTableColumn = {
    id: "MontantFactureHt__c",
    label: "Montant",
    sortable: true,
    value: (row: any) => [
        row.MontantTTC__c ? <AtlasText bold>{formatCurrency(row.MontantTTC__c)} TTC</AtlasText> : '',
        row.MontantFactureHT__c ? <AtlasText small>{formatCurrency(row.MontantFactureHT__c)} HT</AtlasText> : ''
    ]
}
