import { Yop } from '@dsid-opcoatlas/yop'
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import { Ignored, RequiredBoolean, RequiredString } from 'components/yop/constraints'
import { RequiredRNCP } from './CodeRNCPFieldMetadata'
import DiplomeFields from 'components/fields/DiplomeFields'
import { FieldMapping } from 'pages/dossier/metadata/DossierMapping'
import RecapDiplome from 'pages/dossier/recap/RecapDiplome'
import { DossierDiplome, DossierCAPP } from 'pages/dossier-capp/CAPPModel'
import { BlockValue } from 'pages/dossier/cerfa/CerfaDpc'
import { BaseDossier, BaseDossierModule } from 'slices/dossierCommon'


export function DiplomeFieldMetadata(settings: SettingsFieldMetadata<DossierDiplome>) {
    return createMetadata({
        yop: Yop.object<DossierDiplome>({
            IdFormation: Ignored,
            intitulePrecis: RequiredString.max(250),
            codeDiplome: RequiredString
                .test<DossierDiplome, DossierCAPP>(ctx => {
                    if (ctx.value.toUpperCase().includes('RNCP'))
                        return ctx.createError('Code diplôme non conforme : celui communiqué correspond au code RNCP, vérifiez le code auprès de l’organisme de formation en apprentissage')

                    if (ctx.parent?.IdFormation === null && ctx.value?.length !== 8)
                        return ctx.createError('Code diplôme non conforme : il doit contenir 8 caractères, vérifier le code auprès de l’organisme de formation en apprentissage')

                    return true
                }),
            codeRNCP: RequiredRNCP,
            eligibleRNCP: RequiredBoolean,
            formationReferentielLBAC: RequiredBoolean,
        }).defined().required("Veuillez sélectionner une formation"),
        input: DiplomeFields,
        recap: RecapDiplome,
        export: ExportDiplome,
        mapping: diplomeMapping(),
        
    }, settings)
}

export function diplomeMapping(): FieldMapping<DossierDiplome | null> {
    return {
        mapModelToDTO(value, dto) {
            dto.IntituleProgramme__c = value?.intitulePrecis ?? null
            dto.CodeDiplome__c = value?.codeDiplome ?? null
            dto.CodeRNCP__c = value?.codeRNCP ?? null
            dto.RncpEligible__c = value?.eligibleRNCP ?? null
            dto.FormationReferentielLBA__c = value?.formationReferentielLBAC !== null 
        },
        mapDTOToModel(dto, context) {
            if (context.dossierDTO.Intitule__c == null)
                return null
            return {
                IdFormation: dto?.CodeRNCP__c && dto?.CodeDiplome__c ? dto?.CodeRNCP__c + '-' + dto?.CodeDiplome__c : undefined,
                intitulePrecis: context.dossierDTO.Intitule__c ?? null,
                codeDiplome: dto.CodeDiplome__c ?? null,
                codeRNCP: dto.CodeRNCP__c ?? null,
                eligibleRNCP: dto.RncpEligible__c ?? false,
                formationReferentielLBAC: dto.FormationReferentielLBA__c ?? false,
            }
        },
    }
}
interface ExportDiplomeProps {
    label?: string
    cell?: boolean
    name?: string
    value?: DossierDiplome | null
    root?: BaseDossier | null
    parent?: BaseDossierModule | null
}

export default function ExportDiplome(props: ExportDiplomeProps) {
    let values = [
        { label: 'Intitulé précis', value: props.value?.intitulePrecis },
        { label: 'Code diplôme', value: props.value?.codeDiplome },
        { label: 'Code RNCP', value: props.value?.codeRNCP },
    ]
    if (props.cell) {
        values = values.filter(v => v.value)
    }

    return <BlockValue value={{
        cell: props.cell,
        title: props.label,
        values,
    }} />
}