import { Facture, FacturesSearchQuery, exportFactures, getShouldAddRIB } from 'api/factureAPI'
import { TYPE_EMETTEUR_ADHERENT, TYPE_FACTURE_AVOIR, TYPE_FACTURE_FACTURE, getAllStatutFactureFO } from 'api/references'
import { useSimpleApiState } from 'api/useApiState'
import { AtlasInfo, MyTableColumn } from 'atlas-ds'
import { FiltreCheckboxes, FiltrePeriode, FiltreRadioGroup, FiltreRecherche } from 'components/recherche/Filtres'
import RechercheBase, { DEFAULT_DATE_RANGES, FiltreFunctionProps } from 'components/recherche/RechercheBase'
import DossierDeposerFactureSelfe from 'pages/detail-dossier/DossierDeposerFactureSelfe'
import { ColonneDateEmission, ColonneEtat, ColonneMontant, ColonneNumeroDossier, ColonneRef, FacturesSearchQueryImpl, factureActions, factureTabs } from 'pages/mes-factures/MesFactures'
import { Link } from 'react-router-dom'

export default function MesFacturesEntreprise() {

    const { value: shouldAddRIB } = useSimpleApiState(getShouldAddRIB)

    const etatFacturesCheckboxes = (getAllStatutFactureFO() ?? []).map(etatFacture => ({
        label: etatFacture.Libelle__c,
        value: etatFacture.Id
    }))

    const headerActions = <>
        { import.meta.env.REACT_APP_ALLOW_FACTURATION_TUTEUR_BUTTON !== 'false' && <DossierDeposerFactureSelfe mode="tuteur" level={1} /> }
        <DossierDeposerFactureSelfe mode="facture" level={1} label="Déposer une facture" />
    </>

    const getFiltres = (props: FiltreFunctionProps) => {
        const filtres = [
            FiltreRecherche({ label: 'Recherche', placeholder: 'Référence, dossier...' }),
            FiltrePeriode({ label: "Date d'émission de la facture", placeholder: 'Choisir une période', dateRanges: DEFAULT_DATE_RANGES }),
            FiltreCheckboxes({
                name: 'etatFactureIds', label: "État de la facture",
                paramName: 'etat-facture-fo', valueName: 'etatFactureIds', queryName: 'etatFactureFo', checkboxes: etatFacturesCheckboxes
            }),
            FiltreRadioGroup({
                name: 'typeFacture', label: "Type", paramName: 'type-facture', defaultValue: TYPE_FACTURE_FACTURE, options: [
                    { label: "Facture", value: TYPE_FACTURE_FACTURE },
                    { label: "Avoir", value: TYPE_FACTURE_AVOIR }
                ]
            }),
        ]

        if (import.meta.env.REACT_APP_RELANCE === 'true') {
            filtres.push(
                FiltreRadioGroup({
                    name: 'alerte',
                    label: "Facture en alerte",
                    paramName: 'alerte',
                    defaultValue: '',
                    options: [
                        { label: "Oui", value: "oui" },
                        { label: "Non", value: "non" },
                        { label: "Toutes", value: "" },
                    ]
                }),
                /* FiltreRadioGroup({
                    name: 'demande',
                    label: "Demande en cours de traitement par Atlas",
                    paramName: 'demande',
                    defaultValue: '',
                    options: [
                        { label: "Oui", value: "oui" },
                        { label: "Non", value: "non" },
                        { label: "Tous", value: "" },
                    ]
                }), */
            )
        }
        return filtres
    }

    const colonnes = (_: FacturesSearchQuery): MyTableColumn[] => [
        ColonneRef,
        ColonneNumeroDossier,
        ColonneDateEmission,
        ColonneEtat,
        ColonneMontant,
        {
            id: "TypeEmetteur",
            label: "Émetteur",
            sortable: true,
            value: (row: any) => row.TypeEmetteur === TYPE_EMETTEUR_ADHERENT ? 'Adhérent' : 'Prestataire'
        }
    ]

    const newQuery = (params: URLSearchParams) => new FacturesSearchQueryImpl(params)

    return (
        <RechercheBase<Facture, FacturesSearchQueryImpl>
            name="MesFactures"
            title="Factures"
            headerInfo={
                shouldAddRIB ?
                <AtlasInfo
                    type="warning"
                    title="Ajout d'un RIB"
                    link={<Link to="/mes-refs-bancaires">Ajouter un RIB</Link>}
                >Afin de permettre le traitement de vos factures, veuillez renseigner un RIB.</AtlasInfo> : undefined
            }
            headerActions={headerActions}
            newQuery={newQuery}
            filtres={ getFiltres }
            colonnes={colonnes}
            tabs={factureTabs}
            executeExport={exportFactures}
            tableActions={factureActions}
            getErrorRowsIndexes={
                import.meta.env.REACT_APP_RELANCE === 'true' ?
                rows => new Set<number>(rows.map((row, index) => row.TypeAlerteADH__c ? index : -1).filter(index => index != -1)) : undefined
            }
            errorLabel="Cette facture est en alerte"
        />
    )
}
