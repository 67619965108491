import { AtlasValues, MyContainer } from 'atlas-ds'
import { formDiffElements, FormElements } from 'pages/dossier/metadata/FormElements'
import { BaseDossier } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapContrat<D extends BaseDossier>(props: RecapProps<D>) {
    const { navigateTo } = useDossierNavigation(props.metadata, props.navigationGuard)

    const dossier = props.dossier ?? { contrat: {} } as any
    const initialDossier = props.initialDossier ?? { contrat: {} } as any

    if (props.metadata.api.isEdit) {
        const elements = formDiffElements(props.metadata, dossier, initialDossier, 'contrat')
        if (!elements || elements.length === 0) return null
        return <RecapSection title={ props.step.title ?? 'Contrat' } 
                    onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, props.etape) }>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            { elements }
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection>
    }

    return <RecapSection
        title={ props.step.title ?? 'Contrat' } 
        onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, props.etape) }
    >
        <MyContainer>
            <AtlasValues>
            { props.step.sections.map(section => 
                <AtlasValues.Grid key={section}>
                    <FormElements metadata={ props.metadata } dossier={ props.dossier } path="contrat" type="recap" section={ section } />
                </AtlasValues.Grid> )
            }
            </AtlasValues>
        </MyContainer>
    </RecapSection>
}
