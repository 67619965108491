import { AtlasValue, AtlasValues, MyContainer } from "atlas-ds"
import { formatToFullDate } from "components/format/Format"
import { Diagnostic } from "slices/diagatlas/diagnosticSlice"

export function DiagDetails(props: {diagnostic?: Diagnostic}) {
    return <MyContainer>
        <AtlasValues>
            <AtlasValue label="Diagnostic réalisé par">
                { props.diagnostic?.contactRealisation }
            </AtlasValue>

            <AtlasValues.Grid>
                <AtlasValue label="Réalisé le">
                    { formatToFullDate(props.diagnostic?.dateRealisation) }
                </AtlasValue>

                { props.diagnostic?.dateModification && <AtlasValue label="Mis à jour le">
                    { formatToFullDate(props.diagnostic?.dateModification) }
                </AtlasValue> }
            </AtlasValues.Grid>
        </AtlasValues>
    </MyContainer>
}