import DateField from "components/reform/inputs/DateField";
import { OptionalDate } from "components/yop/constraints";
import { DateFieldMetadata, fieldMetadataDecorator } from "elements/metadata";

export const dateConverter = {
    toDTO: (date: Date | null) => {
        if (date == null || isNaN(date.getTime()))
            return null
        const iso = date.toISOString()
        const timeIndex = iso.indexOf("T")
        return timeIndex >= 0 ? iso.substring(0, timeIndex) : iso
    },

    fromDTO: (iso: unknown) => {
        if (iso == null || typeof iso !== "string")
            return null
        const timeIndex = iso.indexOf("T")
        const date = new Date(timeIndex >= 0 ? iso.substring(0, timeIndex) : iso)
        return isNaN(date.getTime()) ? null : date
    }
}

export function dateField<ParentClass>(props?: DateFieldMetadata<ParentClass>) {
    let { dto, ...rest } = props ?? {}
    if (dto != null) {
        if (typeof dto === "string")
            dto = { path: dto, ...dateConverter }

        else
            dto = { ...dateConverter, ...dto }
    }
    return fieldMetadataDecorator<ParentClass, Date | null>({
        kind: "date",
        input: DateField,
        schema: OptionalDate,
        ...rest,
        dto
    })
}
