import { getFinancesStatistiques } from "api/statsAPI"
import useApiState from "api/useApiState"
import { DISPLAY_DATE_FORMAT } from "app/constants"
import { RootState } from "app/rootReducer"
import { AtlasFlex, AtlasHeading, AtlasLoading, MyContainer, MyFeaturedNumber } from "atlas-ds"
import { formatCurrency } from "components/format/Format"
import { addYears, format } from 'date-fns'
import { useEffect } from "react"
import { useSelector } from "react-redux"


export default function FinancialSummary() {

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const [{ pending, value: statistiques }, withGetFinancesStatistiques] = useApiState(getFinancesStatistiques)

    useEffect(() => {
        if (entreprise)
            withGetFinancesStatistiques(entreprise)
    }, [entreprise, withGetFinancesStatistiques]);

    const date = format(addYears(new Date(), -5), DISPLAY_DATE_FORMAT)

    return (<div>
        <AtlasHeading tag="h3" size="s">
            Données depuis le {date} sur mes dates de fin de formation
        </AtlasHeading>
        <MyContainer colored>
            <AtlasLoading loading={ pending }>
                <AtlasFlex gap="l" grow wrap>
                    <MyFeaturedNumber label="Montants engagés">
                        { formatCurrency(statistiques?.MontantTTCEngagementsOF) }
                    </MyFeaturedNumber>
                    <MyFeaturedNumber label="Montants facturés">
                        { formatCurrency(statistiques?.MontantFactures) }
                    </MyFeaturedNumber>
                    <MyFeaturedNumber label="Montants réglés">
                        { formatCurrency(statistiques?.MontantRegles) }
                    </MyFeaturedNumber>
                </AtlasFlex>
            </AtlasLoading>
        </MyContainer>
    </div>)

}
