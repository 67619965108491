import axios from 'axios'
import { SIREN_PRESTATAIRE } from 'app/constants'
import { Identifiable } from './dossierAPI'
import { BaseSearchQuery, getRequestConfig, Page } from 'api/baseAPI'
import store from 'app/store'
import { RootState } from 'app/rootReducer'
import { AdresseEntreprise } from 'api/profilAPI'
import { thematiquesCampusActions } from 'slices/thematiquesCampusSlice'

export type { Page } from 'api/baseAPI'

export interface ThematiqueCampus extends Identifiable {
    IdHeroku: number
    Externalid_Heroku__c: string
    Domaine__c: string
    Libelle__c: string
    Publie__c: boolean
    Presentation__c: string
    Branche__c: string[]
    // CreatedDate: string
    // LastModifiedDate: string
}

export interface FormationCampus extends Identifiable {
    IdHeroku: number
    Libelle__c: string
    NbSessions: string
    Publie__c: boolean
    Statut__c: string
    Thematique: ThematiqueCampus
    // CreatedDate: string
    // LastModifiedDate: string
}

export interface DetailFormationCampusContact extends Identifiable {
    IdHeroku: number
    FirstName: string
    LastName: string
    Email: string
    Phone: string
}

export interface DetailFormationCampusModule extends Identifiable {
    IdHeroku: number
    Thematique: string
    Libelle__c: string
    Publie__c: boolean
    ObjectifModule__c: string
    ObjectifPedagogique__c: string
    PreRequis__c: string
    PublicVise__c: string
    DureeHeures__c: number
    DureeJours__c: number
    ChampSemantiqueFormacode__c: string
    DescripteurFormacode__c: string
    EnregistreRNCP__c: boolean
    SpecialiteNSF__c: string
    SpecialiteDetailNSF__c: string
    Typologie__c: string
    StagiaireMinInter__c: number
    StagiaireMaxInter__c: number
    StagiaireMinIntra__c: number
    StagiaireMaxIntra__c: number
    Certification__c: boolean
    CertificationObligatoire__c: boolean
    NomCertification__c: string
    Modalite__c: string[]
    Statut__c: string
    // CreatedDate: string
    // LastModifiedDate: string
}

export interface DetailFormationCampus extends Identifiable {
    IdHeroku: number
    Libelle__c: string
    ProgrammeDetaille__c: string
    ModaliteCertification__c: string
    ModaliteEvaluation__c: string
    Contact: DetailFormationCampusContact
    CoutInter__c: number
    CoutIntra__c: number
    Certification__c: boolean
    CoutCertification__c: number
    SupportCours__c: boolean
    CoutSupportCours__c: number
    Modalite__c: string
    Type__c: string
    RegionAutorisee__c: string
    DateDebutValidite__c: string
    DateFinValidite__c: string
    Module: DetailFormationCampusModule
    DateSession__c: string
    CoutCertificationRevise__c: number
    CoutSupportDeCoursRevise__c: number
    CoutinterEtrRevise__c: number
    CoutintraEtrRevise__c: number
    // CreatedDate: string
    // LastModifiedDate: string
}

export interface SessionFormation {
    IdHeroku?: number
    Id?: string
    DateDebut__c: string | null
    HeureDebut__c: string | null
    DateFin__c: string | null
    HeureFin__c: string | null
    DateSession__c: string | null
    Adresse: AdresseEntreprise | null
    Modalite__c: string
    Type__c: string,
    NbPlacesTotalAtlas__c: number | null
    NbPlacesDisponibles__c?: number
    NbPlacesOccupees__c?: number
    SessionGarantie__c: boolean | null
    Stagiaires?: StagiaireSession[]
    Stage?: DetailFormationCampus
    // CreatedDate: string
    // LastModifiedDate: string
}

export interface StagiaireSession {
    IdHeroku: number
    Nom__c: string
    Particulier__c: string
    Id: string
    Prenom__c: string
    Sexe__c: string
    DateInscription__c: string
    Dossier: {
        Id: string
        Name: string
        DateReception__c: string
        IdHeroku: string
    }
    Entreprise__c: string
    EmailContact__c: string
    CreatedDate: string
    LastModifiedDate: string
}

export interface FormationsCampusSearchQuery extends BaseSearchQuery {
    recherche?: string
    thematique?: string
    statuts?: string[]
}

function getSearchFormationsParams(query: FormationsCampusSearchQuery): URLSearchParams {

    const params = new URLSearchParams()

    const entreprise = store.getState().contextState.entreprise

    if (query.tri) {
        params.append('tri', query.tri)
        params.append('ordre', query.ordre ?? 'desc')
    }

    if (query.recherche)
        params.append('recherche', query.recherche.trim())

    if (query.thematique)
        params.append('thematique', query.thematique.trim())

    if (query.statuts && query.statuts.length > 0) {
        query.statuts.forEach(value => {
            params.append('statuts', value)
        })
    }

    // const today = startOfToday()
    // params.append('date-debut', query.dateDebut ?? format(subYears(today, 5), DATE_FORMAT))
    // params.append('date-fin', query.dateFin ?? format(today, DATE_FORMAT))
    params.append(SIREN_PRESTATAIRE, entreprise?.SIREN__c ?? '')

    return params
}

async function preloadThematiques() {
    if ((store.getState() as RootState).thematiquesCampusState.fulfilled === null)
        await store.dispatch(thematiquesCampusActions.load())
}

export async function getThematiquesCampus(): Promise<ThematiqueCampus[]> {
    const requestConfig = await getRequestConfig()
    const entreprise = store.getState().contextState.entreprise

    const { data } = await axios.get<ThematiqueCampus[]>(`/campus/entreprises/${entreprise?.IdHeroku}/thematiques`, requestConfig)
    return data
}

export async function getFormationsCampus(query: FormationsCampusSearchQuery): Promise<Page<FormationCampus>> {

    const requestConfig = await getRequestConfig()
    const params = getSearchFormationsParams(query)
    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 20).toFixed(0))
    requestConfig.params = params

    await preloadThematiques()

    const entreprise = store.getState().contextState.entreprise

    const { data, headers } = await axios.get<FormationCampus[]>(`/campus/entreprises/${entreprise?.IdHeroku}/stages`, requestConfig)

    const totalCount = parseInt(headers['x-total-count'], 10) || data.length
    return {
        totalCount: totalCount,
        items: data
    }
}

export async function getFormation(id: number): Promise<DetailFormationCampus> {
    const requestConfig = await getRequestConfig()

    await preloadThematiques()

    const { data } = await axios.get<DetailFormationCampus>('/campus/stages/' + id, requestConfig)

    return data
}

export async function getSessions(id: number): Promise<SessionFormation[]> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    params.append('par-page', "1000")

    requestConfig.params = params

    const { data } = await axios.get<SessionFormation[]>(`/campus/stages/${id}/sessions`, requestConfig)

    return data
}

export async function getSession(idSession: number): Promise<SessionFormation> {
    const requestConfig = await getRequestConfig()

    const { data } = await axios.get<SessionFormation>(`/campus/sessions/${idSession}`, requestConfig)

    return data
}

export async function patchSession(idSession: number, formData: SessionFormation): Promise<SessionFormation> {
    const requestConfig = await getRequestConfig();

    const params = new URLSearchParams();
    params.append("id-entreprise", store.getState().contextState.entreprise!.Id)
    requestConfig.params = params

    requestConfig.headers['Content-type'] = 'application/json'

    const { data } = await axios.patch<SessionFormation>(`/campus/sessions/${idSession}`, formData, requestConfig)

    return data
}

export async function deleteSession(idHeroku: number): Promise<string> {
    const requestConfig = await getRequestConfig();
    requestConfig.headers['Content-type'] = 'application/json'

    const { data } = await axios.delete<string>(`/campus/sessions/${idHeroku}`, requestConfig)

    return data
}

export async function postSession(idStage: number, formData: SessionFormation): Promise<SessionFormation> {
    const requestConfig = await getRequestConfig();

    const params = new URLSearchParams();
    params.append("id-entreprise", store.getState().contextState.entreprise!.Id)
    requestConfig.params = params

    requestConfig.headers['Content-type'] = 'application/json'

    const { data } = await axios.post<SessionFormation>(`/campus/stages/${idStage}/sessions`, formData, requestConfig)

    return data
}

export interface StagiairesSearchQuery extends BaseSearchQuery {
    // recherche?: string
}

export async function getStagiaires(idSession: number, query: StagiairesSearchQuery): Promise<Page<StagiaireSession>> {
    const requestConfig = await getRequestConfig()

    const params = new URLSearchParams()
    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 20).toFixed(0))
    params.append('tri', (query.tri ?? "Dossier.DateReception__c"))
    params.append('ordre', (query.ordre ?? 'desc'))

    requestConfig.params = params

    const { data, headers } = await axios.get<StagiaireSession[]>(`/campus/sessions/${idSession}/stagiaires`, requestConfig)
    
    const totalCount = parseInt(headers['x-total-count'], 10) || data.length

    return {
        totalCount: totalCount,
        items: data
    }
}

export async function patchFormation(idFormation: number, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.patch(`campus/stages/${idFormation}`, formData, requestConfig)
    return data
}

