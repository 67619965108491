import { UseFormReturn, useFormContext } from '@dsid-opcoatlas/reform'
import { unwrapResult } from '@reduxjs/toolkit'
import { Profil } from 'api/profilAPI'
import { Entreprise } from 'api/referencesAPI'
import { AppDispatch } from 'app/store'
import { AtlasFlex, AtlasHeading, AtlasLoading } from 'atlas-ds'
import log from 'loglevel'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { BaseDossier, DossierModification } from 'slices/dossierCommon'
import { dossierSliceActions } from 'slices/dossierSlice'
import { submitAvenant, submitDossier } from '../SubmitDossier'
import RecapSignature from '../recap/RecapSignature'

const now = new Date()

export function dossierRecapitulatifInitializer<D extends BaseDossier = BaseDossier>(dossier: D, metadata: FormMetadata<D>, profil: Profil | null) {
    if (profil && profil.Contact)
        dossier.signature.nom = `${profil.Contact.FirstName} ${profil.Contact.LastName}`
    dossier.signature.date = now  // Attention à réutiliser la même date à chaque fois pendant la session pour la comparaison isDirty
    return dossier
}

export function DossierRecapitulatifPreElements<D extends BaseDossier>(props: { metadata: FormMetadata<D>, navigationGuard: (next: () => void) => boolean, dossier: D | null, initialDossier?: D | null, loading?: boolean }) {
    
    const recapSections = props.metadata.steps.filter(s => s.recap).map((step, index) => {
        const Recap = step.recap!
        
        return (
            <Recap
                key={ `step${ index + 1 }` }
                dossier={ props.dossier }
                initialDossier={ props.initialDossier }
                navigationGuard={ props.navigationGuard }
                loading={ props.loading }
                metadata={ props.metadata }
                etape={ index + 1 }
                step={ step } />
        )
    })

    return (
        <AtlasLoading loading={!!props.loading}>
            <AtlasHeading tag="h2" size="m">{props.metadata.api.isEdit ? 'Récapitulatif des corrections en cours' :  'Récapitulatif'}</AtlasHeading>
            <AtlasFlex column gap="xl">
                { recapSections }
            </AtlasFlex>
        </AtlasLoading>
    )
}

export function onDossierRecapitulatifSubmit<D extends BaseDossier>(context: UseFormReturn<D>, 
    metadata: FormMetadata<D>, dossier: D, dispatch: AppDispatch) {
    return submitDossier(
        metadata, dossier, 
        submitting => context.setSubmitting(submitting),
        uploading => dispatch(dossierSliceActions.transmitting(uploading)),
        () => dispatch(dossierSliceActions.send({ api: metadata.api as any, dossier })).then(unwrapResult)
    )
    .then(() => false)
    .catch(error => {
        log.error("Erreur transmission dossier recap", error)
        throw error
    })
}

export function onDossierModificationRecapitulatifSubmit<D extends BaseDossier & DossierModification>(context: UseFormReturn<D>, 
    metadata: FormMetadata<D>, dossier: D, dispatch: AppDispatch, entreprise?: Entreprise | null) {
    return submitAvenant(
        metadata, dossier, 
        submitting => context.setSubmitting(submitting),
        uploading => dispatch(dossierSliceActions.transmitting(uploading)),
        entreprise?.Id ?? '', 
    )
    .then(() => true)
    .catch(error => {
        log.error("Erreur transmission modification dossier", error)
        throw error
    })
}

export function DossierRecapitulatifElements<D extends BaseDossier>(props: { metadata: FormMetadata<D> }) {
    const form = useFormContext<D>()
    
    return <RecapSignature metadata={ props.metadata } dossier={ form.values } />
} 
