import { AtlasValues, MyContainer } from 'atlas-ds'
import { formDiffElements, FormElements } from 'pages/dossier/metadata/FormElements'
import { Fragment } from 'react'
import { BaseDossierStagiaire, DossierAF } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapSalaries<S extends BaseDossierStagiaire>(props: RecapProps<DossierAF<S, any>>) {
    const { navigateTo, navigateEdit, steps } = useDossierNavigation(props.metadata, props.navigationGuard)

    const metadata = props.metadata
    const salariesEtape = steps.indexOf(props.step) + 1
    const single = props.step.options?.minElements === 1 && props.step.options?.maxElements === 1

    const skeleton = props.loading ? [{ cfa: {} }] : []
    const salaries = props.dossier?.salaries ?? skeleton
    const dossier = props.dossier ?? { salaries } as any
    const initialDossier = props.initialDossier ?? { salaries: skeleton } as any

    if (props.metadata.api.isEdit) {
        // Cas des modifications multi-salaries, pas encore possible
        return <>{ salaries.map((s, index) => {
            const elements = formDiffElements(props.metadata, dossier, initialDossier, 'salaries', index)
            if (!elements || elements.length === 0) return null

            return <Fragment key={ `salarie[${index}]` }>
                { elements && <RecapSection title={ `Salarié n°${index+1}` }
                    onModifier={ () => navigateEdit(props.dossier!.NumeroDossier__c!, salariesEtape, (s as any).IdHeroku) }>
                    <MyContainer>
                        <AtlasValues>
                            <AtlasValues.Grid>
                                { elements }
                            </AtlasValues.Grid>
                        </AtlasValues>
                    </MyContainer>
                </RecapSection> }
            </Fragment>
        }) }</>
    }

    const onModifier = (s?: any) => {
        if (single)
            navigateTo(props.dossier!.NumeroDossier__c!, salariesEtape)
        else
            navigateEdit(props.dossier!.NumeroDossier__c!, salariesEtape, s.IdHeroku)
    }

    return <>{ salaries.map((s, index) => <Fragment key={ `salarie[${index}]` }>
            <RecapSection
                title={ single ? 'Salarié' : `Salarié n°${index+1}` }
                onModifier={ () => onModifier(s) }
            >
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            <FormElements key={ `salaries[${index}]` } dossier={ dossier } metadata={ metadata } path="salaries" type="recap" index={ index } />
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection>
        </Fragment>) 
    }</>
}
