import styles from 'pages/dossier/cerfa/global.styles'
import { Style } from '@react-pdf/types'
import { Text } from '@react-pdf/renderer'
import { durationHours, durationMinutes } from 'components/reform/inputs/DurationFloatingField'

interface CerfaDurationFieldProps {
    value: number | null | undefined
    style?: Style | Style[]
}

export default function CerfaDurationField(props: CerfaDurationFieldProps) {
    if (props.value == null)
        return null

    const style = props.style ?? styles.user
    const hours = durationHours(props.value)
    const minutes = durationMinutes(props.value)

    return (
        <Text>
            <Text style={ style }>
                { hours ?? 0 }
            </Text>
            <Text> heures</Text>{' '}
            <Text style={ style }>
                { (minutes ?? 0).toString().padStart(2, '0') }
            </Text>
            <Text> minutes</Text>
        </Text>
    )
}
