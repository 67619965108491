import { AtlasValue, AtlasValues } from 'atlas-ds'
import { formatAdresse } from 'components/format/Format'
import { Adresse } from 'slices/dossierCommon'
import RecapAtlasValue from './RecapAtlasValue'


interface RecapAdresseProps {
    parent?: { adresse?: Adresse }
    initialValue?: any
    editMode?: boolean
}

function RecapValue(props: { label: string, value: string | null | undefined }) {
    return props.value && (
        <AtlasValue label={ props.label }>
            { props.value }
        </AtlasValue>
    )
}

export default function RecapAdresse(props: RecapAdresseProps) {
    const adresse = props.parent?.adresse
    const initialAdresse = props.initialValue

    const valAdresse = formatAdresse(adresse, adresse?.codePostal, adresse?.commune)
    const valpostalCommune = formatAdresse('', adresse?.codePostal, adresse?.commune)
    const valInitialpostalCommune = formatAdresse('', initialAdresse?.codePostal, initialAdresse?.commune)

    if (adresse == null)
        return null
    if (props.editMode &&  props.initialValue ) {
        return (
            <AtlasValues>
                <AtlasValues.Grid>
                    { ('appartement' in props.initialValue) &&  <RecapAtlasValue label={"Appartement"} initialValue={initialAdresse?.appartement} value={adresse?.appartement} editMode={props.editMode || false} />}
                    { ('batiment' in props.initialValue)  && <RecapAtlasValue label={"Bâtiment"} initialValue={initialAdresse?.batiment} value={adresse?.batiment} editMode={props.editMode || false} />}
                    { ('adresse' in props.initialValue)   && <RecapAtlasValue label={"N° et voie"} initialValue={initialAdresse?.adresse} value={adresse?.adresse} editMode={props.editMode || false} />}
                    {('complement' in props.initialValue)  && <RecapAtlasValue label={"Lieu dit/BP"} initialValue={initialAdresse?.complement} value={adresse?.complement} editMode={props.editMode || false} />}
                    {('codePostal' in props.initialValue)  && <RecapAtlasValue label={"Code postal et commune"} initialValue={valInitialpostalCommune} value={valpostalCommune} editMode={props.editMode || false} />}
                </AtlasValues.Grid>
            </AtlasValues>
        )
    }
    return (
        <AtlasValues>
            <AtlasValues.Grid>
                <RecapValue label="Appartement" value={ adresse?.appartement } />
                <RecapValue label="Bâtiment" value={ adresse?.batiment } />
                <RecapValue label="Adresse" value={ valAdresse } />
                <RecapValue label="Lieu dit/BP" value={ adresse?.complement } />
            </AtlasValues.Grid>
        </AtlasValues>
    )

}