import { BaseTextField, getFieldState, useFormContext, useRender } from '@dsid-opcoatlas/reform'
import { AtlasCheckbox, AtlasFieldText, MyForm, MyModal } from 'atlas-ds'
import Button from 'components/reform/Button'
import { TextFieldProps } from 'components/reform/inputs/TextField'
import { useEffect, useRef, useState } from 'react'

/*
const formatNir = (nir: string) => {
    return nir.match(/^([12])?([0-9]{1,2})?([0-9]{1,2})?([0-9]{1,2}|2A|2B)?([0-9]{1,3})?([0-9]{1,3})?([0-9]{1,2})?$/)?.slice(1).filter(t => t != null).join(' ')
}
*/
interface NIRAsyncFieldProps<T extends object> extends TextFieldProps<T> {
    parent: any
    parentPath: string
    nameNTT?: string
    nameNirVerifError?: string
    nameNaissanceFrance?: string
}

const asNumber = (value: any) => {
    return typeof value === 'number' ? value : undefined
}

const editModeValue = '****************'

export function NIRAsyncField<T extends object>(props: NIRAsyncFieldProps<T>) {

    const nameNTT = `${ props.parentPath }.${ props.nameNTT ?? "ntt" }`
    const nameNirVerifError = `${ props.parentPath }.${ props.nameNirVerifError ?? "nirVerifError" }`
    const nameNaissanceFrance = `${ props.parentPath }.${ props.nameNaissanceFrance ?? "naissanceFrance" }`

    const render = useRender()
    const context = useFormContext<T>()
    const fieldState = getFieldState<string>(context, props.name)
    const fieldNTTState = getFieldState<boolean>(context, nameNTT)
    const fieldNaissanceFranceState = getFieldState<boolean>(context, nameNaissanceFrance)
    const [ engagementNTT, setEngagementNTT ] = useState(false)

    const [ displayNttCheckbox, setDisplayNttCheckbox ] = useState(false)
    const [ showNttModal, setShowNttModal ] = useState(false)

    const inputNTTRef = useRef<HTMLInputElement>(null)

    const toModelValue = (value: string) => {
        if (value === '' || (props.editMode && value === editModeValue))
            return null
        else if (props.uppercase)
            return value.toUpperCase()
        return value
    }

    const internalOnChangeNTT = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.checked
        if (!value)
            context.touch(props.name)
       else {
            context.untouch(props.name)
            setEngagementNTT(false)
        }
        context.setValue(nameNTT, value, true)
    }

    const asyncStatus = context.getAsyncError(props.name)?.status
    useEffect(() => {
        if (context.getValue(props.parentPath) != null) {
            if (asyncStatus === 'unavailable')
                context.setValue(nameNirVerifError, true)
            else if (asyncStatus === 'valid')
                context.setValue(nameNirVerifError, false)
        }

        if (fieldNTTState.value === true || (fieldState.value != null && fieldState.error != null && asyncStatus !== 'pending'))
            setDisplayNttCheckbox(true)

        if (fieldNTTState.value === true && fieldNTTState.touched === false)
            setEngagementNTT(true)

        setShowNttModal(fieldNTTState.value === true && !engagementNTT && fieldNTTState.touched)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ fieldNTTState.value, fieldState.value, fieldState.error, engagementNTT, asyncStatus ])

    return <>
        <AtlasFieldText
            name={ props.name }
            label={ props.label }
            required={ fieldState.constraints.required }
            disabled={ props.disabled || asyncStatus === 'pending' }
            maxLength={ props.maxLength ?? asNumber(fieldState.constraints.max) }
            tooltipContent={ props.tooltip }
            error={ fieldState.error }
            checkbox={ fieldNaissanceFranceState.value !== true && displayNttCheckbox ? <AtlasCheckbox
                name={ props.parentPath + '.' + props.nameNTT! }
                value={ props.parentPath + '.' + props.nameNTT! }
                ref={ inputNTTRef }
                label="Le bénéficiaire a un Numéro Technique Temporaire (NTT)"
                onChange={ internalOnChangeNTT }
                checked={ !!fieldNTTState.value }
                disabled={ props.disabled || asyncStatus === 'pending' }
            /> : undefined }
            inputConstructor={(inputProps) => <BaseTextField
                { ...inputProps }
                render={ render }
                onChange={ props.onChange }
                onBlur={ props.onBlur }
                toModelValue={ toModelValue }
                formatDisplayedValue={(value) => value === '' ? (props.editMode ? editModeValue : '') : value}
            />}
        />

        { showNttModal && <NIRAsyncNTTModal valider={ () => setEngagementNTT(true) } /> }
    </>
}

export function NIRAsyncNTTModal(props: { valider: () => void }) {
    const id = "NIRAsyncNTTModalEngagement"

    const [ engagement, setEngagement ] = useState(false)

    const internalOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.checked
        setEngagement(value)
    }

    return (
        <MyModal label="Utilisation d'un Numéro Technique Temporaire (NTT)">
            <MyForm>
                <p>
                    Le bénéficiaire dispose d'un Numéro technique temporaire (NTT), vous pouvez poursuivre la saisie de votre demande.
                    Dès l'obtention définitive du Numéro d'inscription au Répertoire de la Sécurité Sociale (NIR), ce numéro doit impérativement
                    nous être communiqué dans les meilleurs délais via la fonction Modifier le cerfa accessible dans le détail du dossier.
                    En effet cette information est nécessaire pour le dépôt du dossier auprès des services de l'Etat en charge de la formation
                    professionnelle.
                </p>

                <MyForm.Field size="full">
                    <AtlasCheckbox
                        name={ id }
                        value={ id }
                        checked={ engagement }
                        onChange={ internalOnChange }
                        label="Je m'engage à communiquer le numéro définitif dans les meilleurs délais"
                        required
                    />
                </MyForm.Field>

                <MyForm.Actions>
                    <Button disabled={ !engagement } onClick={ props.valider }>Continuer</Button>
                </MyForm.Actions>
            </MyForm>
        </MyModal>
    )
}
