import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { OFSearchOptions, getOrganismesFormation } from 'api/commonAPI'
import { AdresseFields } from 'components/reform/inputs/AdresseFields'
import { CompanySummary } from 'components/reform/inputs/CompanySummary'
import RadioBooleanField from 'components/reform/inputs/RadioBooleanField'
import SelectOrCreateEntityField from 'components/reform/inputs/SelectOrCreateEntityField'
import TextField from 'components/reform/inputs/TextField'
import { join } from 'components/reform/inputs/utils/BaseFieldProps'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { DossierCFA, createDossierCFA } from 'slices/dossierCommon'

async function searchDossierCFA(recherche: string, options: OFSearchOptions = { actif: true }): Promise<DossierCFA[]> {
    const ofs = await getOrganismesFormation(recherche, options)
    return ofs.map(of => Object.assign(createDossierCFA(), {
        EtablissementOFId__c: of.Id ?? null,
        CodeEtablissementOF__c: of.CodeEtablissement__c ?? null,
        nom: of.Name ?? null,
        siret: of.SIRET__c?.replaceAll(' ', '') ?? null,
        numeroUAI: of.Uai__c ?? null,
        numeroDA: of.NumeroDA__c ?? null,
        etat: of.EtatEtablissement__c ?? '',
        entreprise: false,
        appartement: of.Adresse1__c ?? null,
        batiment: of.Adresse2__c ?? null,
        adresse: of.Adresse3__c ?? null,
        complement: of.Adresse4__c ?? null,
        codePostal: of.BillingPostalCode ?? null,
        commune: of.BillingCity ?? null,
    }))
}

interface OrganismeFormationFieldsProps {
    name: string
    tooltip?: string | JSX.Element
    selectLabel?: string
    noCreate?: boolean
    entreprise?: boolean
    prestataire?: boolean
    nomLabel?: string
    uai?: boolean
    uaiLabel?: string
    siretLabel?: string
    da?: boolean
    disabled?: boolean
    searchOptions?: OFSearchOptions
    editForm?: boolean
    moduleSecondaire?: boolean
    adresse?: boolean
    communeEnabled?: boolean
    forceDisabled?: boolean
    nomTooltip?: string | JSX.Element
}

export default function OrganismeFormationFields<FormObjectType extends object>(props: OrganismeFormationFieldsProps) {
    
    const context = useFormContext<FormObjectType>()
    const fieldState = props.name ? getFieldState<DossierCFA | null>(context, props.name) : null

    const renderDossierCFA = (cfa: DossierCFA) => {
        return <CompanySummary nom={ cfa.nom } siret={ cfa.siret } adresse={ join(cfa.adresse, cfa.codePostal, cfa.commune) } />
    }

    const newDossierCFA = () => {
        return {
            ...createDossierCFA(),
            EtablissementOFId__c: null,
        }
    }

    const cfaFieldsDisabled = props.disabled || fieldState?.value?.EtablissementOFId__c !== null || props.forceDisabled

    const searchOptions = { actif: true, ...props.searchOptions }

    return (<>
        { !props.disabled &&
        <SelectOrCreateEntityField
            name={ props.name }
            noCreate={ props.noCreate }

            // Si on ne peut pas créer, on cherche probablement parmi un nombre limité donc on peut déclencher la recherche
            searchDebounceOptions={ props.noCreate ? { immediate: true, empty: true } : undefined }

            label={ props.selectLabel ?? "Choix de l'organisme de formation" }
            placeholder="Rechercher par SIRET ou raison sociale (3 caractères minimum)"
            tooltip={ props.tooltip ?? <>
                <p>
                    Vous pouvez choisir un établissement actif dans la liste en entrant les 3 premières lettres du centre de formation,
                    certains champs seront remplis automatiquement.
                </p>
                {!props.noCreate && <p>Si la recherche ne remonte pas de résultat, vous pouvez aussi entrer manuellement le centre de formation de votre choix.</p>}
            </>}
            searchValues={ recherche => searchDossierCFA(recherche, searchOptions) as any }
            create={{
                newValue: newDossierCFA,
                buttonLabel: "Ajouter un organisme de formation",
                infoTitle: "Ajout d'un nouvel organisme de formation",
                infoContent: <>Vous n'avez pas trouvé l'organisme de formation que vous cherchiez ?<br/>Utilisez les champs ci-dessous pour renseigner votre organisme de formation.</>,
            }}
            renderValue={ renderDossierCFA }
            getValueId={ value => value.EtablissementOFId__c ?? "" }
            onChange={ () => context.renderForm() }
            disabled={ props.disabled } />
        }

        <TextField
            name={ props.name + '.nom' }
            label={ props.nomLabel ?? (props.moduleSecondaire ? "Dénomination de l’organisme de formation" : "Dénomination de l’organisme de formation principal") }
            tooltip={ props.nomTooltip }
            disabled={ cfaFieldsDisabled } />

        <TextField
            name={ props.name + '.siret' }
            label={ props.siretLabel ?? (props.moduleSecondaire ? "N° de SIRET" : "N° de SIRET de l'établissement de formation responsable") }
            disabled={ cfaFieldsDisabled } />

        { props.da &&
        <TextField name={ props.name + '.numeroDA' }
            label="N° de déclaration d'activité"
            uppercase={true}
            tooltip={ TOOLTIPS.cfa.numeroDA }
            disabled={ cfaFieldsDisabled } />
        }

        { props.entreprise &&
        <RadioBooleanField name={ props.name + '.entreprise' }                   
            label="CFA entreprise"
            disabled={ props.disabled } />
        }

        { props.uai &&
        <TextField name={ props.name + '.numeroUAI' }
            label={ props.uaiLabel ?? "N° UAI de l'organisme" }
            uppercase={true}
            tooltip={ TOOLTIPS.cfa.numeroUAI }
            disabled={ props.disabled } />
        }

        { props.adresse !== false &&
        <AdresseFields
            name={ props.name } 
            adresseEnabled={ fieldState?.value?.EtablissementOFId__c != null && !props.forceDisabled }
            communeEnabled={ props.communeEnabled && !props.forceDisabled }
            disabled={ cfaFieldsDisabled }/>
        }
    </>)
}