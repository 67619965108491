import { TYPES_QUALIFICATIONS_RNCP } from 'api/references'
import TextField from 'components/reform/inputs/TextField'
import { OptionalString } from 'components/yop/constraints'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'
import ExportTextField from 'pages/dossier/export/ExportTextField'
import { createMetadata, ElementContext, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import RecapTextField from 'pages/dossier/recap/RecapTextField'
import { BaseDossier, DossierModuleWithRNCP } from 'slices/dossierCommon'


export const OptionalRNCP = OptionalString.matches(/^RNCP[0-9]{3,5}$/, 'Format de code RNCP incorrect ("RNCP" suivi de 3 à 5 chiffres)')
export const RequiredRNCP = OptionalRNCP.required()

export function CodeRNCPFieldMetadata(settings: SettingsFieldMetadata<string | null> & { typesQualificationsRNCP?: string[] } = {}) {
    const typesQualificationsRNCP = settings.typesQualificationsRNCP ?? TYPES_QUALIFICATIONS_RNCP
    return createMetadata(
        {
            props: {
                placeholder: 'Format : RNCP123, RNCP1234 ou RNCP12345',
                disabled: (ctx: ElementContext<DossierModuleWithRNCP, BaseDossier>) => {
                    return !ctx.parent?.typeQualification || !typesQualificationsRNCP.includes(ctx.parent?.typeQualification)
                },
            },
            yop: OptionalRNCP.requiredIf(ctx => typesQualificationsRNCP.includes(ctx.parent?.typeQualification)),
            input: TextField,
            recap: RecapTextField,
            export: ExportTextField,
            cerfa: CerfaTextField,
        },
        settings,
    )
}
