import { ReformSetValueEvent, SetValueOptions, UseFormReturn } from "@dsid-opcoatlas/reform"
import { ClassConstructor, getElementsMetadataForField, getMetadata } from "./metadata"
import { useEffect } from "react"
import { escapeRegExp } from "lodash-es"
import { Path } from "./Path"

export type ObserverCallbackOptions = {

    untouch?: boolean
    
    propagate?: boolean
}

export type ObserverCallbackContext<T> = {

    path: Path

    observedValue: unknown

    currentValue: T

    setValue: (value: T, options?: ObserverCallbackOptions) => void

    event: ReformSetValueEvent<unknown>
}

export type ObserverMetadata<T> = {
    path: string
    callback: (context: ObserverCallbackContext<T>) => void
}

export function observer<ParentClass, T>(path: ObserverMetadata<T>['path'], callback: ObserverMetadata<T>['callback'] | undefined) {
    return function _(_: any, context: ClassFieldDecoratorContext<ParentClass, T>) {
        const metadata = getElementsMetadataForField<ParentClass, T>(context)
        metadata.observers ??= new Map()
        if (callback != null)
            metadata.observers.set(path, { path, callback })
        else
            metadata.observers.delete(path)
    }
}

type ObserverData<T> = {
    observer: ObserverMetadata<T>
    path: Path
}

const arrayIndexMatcher = "\\[[0-9]+\\]"

function collectObservers<T>(path: Path, model: ClassConstructor, observersMap: Map<string, ObserverData<T>[]>) {
    const metadata = getMetadata(model)

    Object.entries(metadata).forEach(([name, fieldMetadata]) => {
        path.push({ class: model, property: name, index: fieldMetadata.kind === "array" ? Number.NaN : undefined })
        
        fieldMetadata.observers?.forEach(observer => {
            const observerPath: string[] = []
            
            if (observer.path.startsWith("/"))
                observerPath.push(...observer.path.substring(1).split("/"))
            else {
                const currentPath = path.slice(0, -1)
                observerPath.push(...observer.path.split("/"))
                while (observerPath?.[0] === "..") {
                    observerPath.shift()
                    currentPath.pop()
                }
                observerPath.unshift(...currentPath.map(path => path.index !== undefined ? (path.property + arrayIndexMatcher) : path.property))
            }

            observerPath.map((pathElement, index) => {
                observerPath[index] = (
                    pathElement === "**" ? ".*" :
                    pathElement === "*" ? "[^\\/]+" :
                    pathElement.endsWith(arrayIndexMatcher) ? escapeRegExp(pathElement.slice(0, -arrayIndexMatcher.length)) + arrayIndexMatcher :
                    escapeRegExp(pathElement)
                )
            })

            const pathRegExp = `^${ observerPath.join("\\.") }$`
            let observersData = observersMap.get(pathRegExp)
            if (observersData == null) {
                observersData = []
                observersMap.set(pathRegExp, observersData)
            }
            observersData.push({ observer, path: new Path(...path) })
        })
        
        if (fieldMetadata.kind === "object")
            collectObservers(path, fieldMetadata.type!, observersMap)
        else if (fieldMetadata.kind === "array")
            collectObservers(path, fieldMetadata.type!, observersMap)
        
        path.pop()
    })
}

type SetValueCalled = { value: boolean }

function createCallbackContext<T>(path: Path, value: any, event: ReformSetValueEvent, setValueCalled: SetValueCalled): ObserverCallbackContext<T> {
    const fullPath = path.map(pathElement => pathElement.property + (pathElement.index != null ? `[${ pathElement.index }]` : "")).join('.')
    
    return {
        path: new Path(...path),
        observedValue: event.detail.value,
        currentValue: value,
        setValue: (value: any, options?: ObserverCallbackOptions) => {
            let setValueOptions = SetValueOptions.Touch | SetValueOptions.Silent
            if (options != null) {
                if (options.untouch === true) {
                    setValueOptions &= ~SetValueOptions.Touch
                    setValueOptions |= SetValueOptions.Untouch
                }
                if (options.propagate === true)
                    setValueOptions &= ~SetValueOptions.Silent
            }
            event.detail.form.setValue(fullPath, value, setValueOptions)
            setValueCalled.value = true
        },
        event,
    }
}

function callObservers(observerData: ObserverData<any>, value: any, startPath: Path, path: Path, event: ReformSetValueEvent, setValueCalled: SetValueCalled) {
    if (path.length === 0) {
        if (value !== undefined)
            observerData.observer.callback(createCallbackContext(new Path(...startPath, ...path), value, event, setValueCalled))
        return
    }
    
    if (value == null)
        return
    
    const lastIndex = path.length - 1
    for (let index = 0; index < lastIndex; index++) {
        const pathElement = path[index]
        value = value[pathElement.property]
        
        if (value == null)
            return
        
        if (pathElement.index !== undefined) {
            if (!Array.isArray(value))
                return
            
            const itemPath = path.slice(index + 1)
            value.forEach((item, itemIndex) => {
                if (item != null) {
                    const newStartPath = new Path(...startPath, ...path.slice(0, index), { ...pathElement, index: itemIndex })
                    callObservers(observerData, item, newStartPath, itemPath, event, setValueCalled)
                }
            })
        }
    }

    if (value != null) {
        const pathElement = path[lastIndex]
        value = value[pathElement.property]
        if (value !== undefined)
            observerData.observer.callback(createCallbackContext(new Path(...startPath, ...path), value, event, setValueCalled))
    }
}

function createReformEventListener(model: ClassConstructor) {
    const observersMap = new Map<string, ObserverData<any>[]>()
    collectObservers(new Path(), model, observersMap)
    const observers = Array.from(observersMap.entries()).map(([path, observerData]) => [new RegExp(path), observerData]) as [RegExp, ObserverData<any>[]][]

    return ((event: ReformSetValueEvent) => {
        const setValueCalled = { value: false }
        const values = event.detail.form.values
        
        observers.forEach(([pathRegExp, observersData]) => {
            if (pathRegExp.test(event.detail.path))
                observersData.forEach(observerData => callObservers(observerData, values, new Path(), observerData.path, event, setValueCalled))
        })
        
        if (setValueCalled.value) {
            event.detail.form.validate()
            event.detail.form.renderForm()
        }
    }) as EventListener
}

export function useObservers<T extends object>(model: ClassConstructor<T>, form: UseFormReturn<T>) {
    useEffect(() => {
        const reformEventListener = createReformEventListener(model)
        form.addReformEventListener(reformEventListener)
        return () => {
            form.removeReformEventListener(reformEventListener)
        }
    }, [model])
}
