import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { loadDossierAF, saveDossierAF, sendDossierAF } from 'api/dossierAPI'
import { DISPOSITIF_FMA } from 'api/references'
import { createCerfaDpc } from 'pages/dossier/cerfa/CerfaDpc'
import { mapDTOToDossierAF, mapDossierAFToDTO } from 'pages/dossier/metadata/DossierMapping'
import { DOCNAME_DPC, DOCTYPE_DPC, registerMetadata } from 'pages/dossier/metadata/FormMetadata'
import { piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import { DossierRecapitulatifElements, DossierRecapitulatifPreElements, dossierRecapitulatifInitializer, onDossierRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { dossierMultiSalariesInitializer } from 'pages/dossier/elements/DossierSalariesElements'
import { exportDossier } from 'pages/dossier/ExportDossierDPC'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import RecapModules from 'pages/dossier/recap/RecapModules'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import RecapSalaries from 'pages/dossier/recap/RecapSalaries'
import { AppType } from 'slices/contextSlice'
import { DossierAPI } from 'slices/dossierSlice'
import { ElementsDossierForm } from 'elements/dossier/ElementsDossierForm'
import { EmployeurFields } from 'elements/dossier/EmployeurFields'
import { DossierAF, DossierAFModule, DossierAFSalarie, DossierEmployeur } from 'elements/model/dossierClasses'
import { ModuleFields, dossierModulesInitializer } from 'elements/dossier/ModuleFields'
import { Path } from 'elements/Path'
import { modelToDTO, dtoToModel } from 'elements/mapping'
import { SalariesTable } from 'elements/dossier/SalariesTable'
import { SalariesEditFields } from 'elements/dossier/SalariesEditFields'
import { PiecesTable } from 'elements/dossier/PiecesTable'
import { disabled, override } from 'elements/decorators/fragments'
import { array } from 'elements/decorators/arrays'

const FMA_MODULE_INTITULE = "Formation maître d'apprentissage"

export class DossierFMAModule extends DossierAFModule {

    @disabled(true)
    override intitulePrecis: string = FMA_MODULE_INTITULE
}
export class DossierFMASalarie extends DossierAFSalarie {

    constructor(dossier?: DossierFMA) {
        super(dossier)
        this.heures ??= dossier?.modules?.[0]?.dureeTotale ?? null
    }
}

export class DossierFMA extends DossierAF {
    
    override DispositifFO__c: string = DISPOSITIF_FMA

    override intitule: string = FMA_MODULE_INTITULE

    @array({ type: DossierFMAModule })
    override modules: DossierFMAModule[] = []

    @array({ type: DossierFMASalarie })
    override salaries: DossierFMASalarie[] = []
}

const api: DossierAPI<any> = {
    create: () => new DossierFMA(),
    createModule: (dossier?: DossierFMA) => new DossierFMAModule(dossier) as any,
    createSalarie: (dossier?: DossierFMA) => new DossierFMASalarie(dossier) as any,
    load: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        return dtoToModel(DossierFMA, dossierDTO)
    },
    loadWithPieces: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
        const dossier = dtoToModel(DossierFMA, dossierDTO)
        dossier.pieces?.sort((a, b) => a.obligatoire === b.obligatoire ? 0 : a.obligatoire ? -1 : 1)
        return dossier
    },
    save: async (dossier: DossierFMA) => {
        const dossierDTO = modelToDTO(DossierFMA, dossier)
        const savedDossierDTO = await saveDossierAF(dossierDTO)
        return dtoToModel(DossierFMA, savedDossierDTO)
    },
    send: async (dossier: DossierFMA) => {
        const dossierDTO = mapDossierAFToDTO(MetadataFMA, dossier)
        const sentDossierDTO = await sendDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataFMA, sentDossierDTO)
    },
    postSend: async (dossier: DossierFMA) => {
        await postSendDossier(MetadataFMA, dossier)
    },
}

export const MetadataFMA = registerMetadata(AppType.Entreprise, {
    dispositif: DISPOSITIF_FMA,
    model: DossierFMA,
    title: "Dépôt dossier Formation maître d'apprentissage (X)",
    pathname: '/nouveau-dossier-fma',
    steps: [
        { 
            title: 'Employeur',
            sections: [],
            recap: RecapEmployeur,
            path: Path.fromString('employeur', DossierFMA),
            formContent: EmployeurFields,
            form: ElementsDossierForm,
            options: {
                forceLoading: true,
            },
        }, {
            title: 'Formation',
            sections: [],
            recap: RecapModules,
            initializer: dossierModulesInitializer,
            path: Path.fromString('modules[0]', DossierFMA),
            formContent: ModuleFields,
            form: ElementsDossierForm,
        }, {
            title: 'Salariés',
            sections: [],
            recap: RecapSalaries,
            path: Path.fromString('salaries', DossierFMA),
            editForm: SalariesEditFields,
            initializer: dossierMultiSalariesInitializer,
            preFormContent: SalariesTable,
            form: ElementsDossierForm,
            options: {
                noFormHeading: true,
                noSaving: true,
                forceValidate: (dossier) => dossier.salaries?.length >= 1,
            },
        }, {
            title: 'Pièces',
            sections: [],
            recap: RecapPieces,
            path: Path.fromString('pieces', DossierFMA),
            preFormContent: PiecesTable,
            form: ElementsDossierForm,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
            },
        },
    ],
    api,
    fields: null as any,
    exportProps: [{
        exportDossier: async (metadata, dossier) => createCerfaDpc(exportDossier(metadata, dossier)),
        documentType: DOCTYPE_DPC,
        documentName: DOCNAME_DPC,
    }],
})

