import { getParentPath, ValidationContext } from '@dsid-opcoatlas/yop'
import { StagiaireDTO } from 'api/dossierAPI'
import { parseISO, startOfYear, subDays } from 'date-fns'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { BaseDossierStagiaire, DossierAF, DossierSalarie } from 'slices/dossierCommon'
import { BooleanFieldMetadata } from '../../../components/metadata/BooleanFieldMetadata'
import { DateFieldMetadata, DateNaissanceFieldMetadata } from '../../../components/metadata/DateFieldMetadata'
import { DurationFieldMetadata, floatingDurationMapping } from '../../../components/metadata/DurationFieldMetadata'
import { HandicapFieldMetadata } from '../../../components/metadata/HandicapFieldMetadata'
import { NIRAsyncFieldMetadata } from '../../../components/metadata/NIRAsyncFieldMetadata'
import { RadioRefFieldMetadata, SelectRefFieldMetadata } from '../../../components/metadata/RefFieldMetadata'
import { AutocompleteNameFieldMetadata, EmailFieldMetadata, FirstNameFieldMetadata, NameFieldMetadata, PhoneFieldMetadata, TextFieldMetadata } from '../../../components/metadata/TextFieldMetadata'
import { MappingContext, dateMapping, mapDTOToModel, mapModelToDTO, simpleMapping } from './DossierMapping'
import { FormMetadata, IgnoredFieldMetadata, ObjectMetadata } from './FormMetadata'
import { getStagiaires } from 'api/commonAPI'
import { SetValueOptions, UseFormReturn } from '@dsid-opcoatlas/reform'
import RadioBooleanField from 'components/reform/inputs/RadioBooleanField'
import { durationHours, durationMinutes, durationToString } from 'components/reform/inputs/DurationFloatingField'

export const SECTION_SALARIE_AGORA = 'agora'

const END_OF_PREVIOUS_YEAR = subDays(startOfYear(Date.now()), 1)

const searchSalarie = async (form: UseFormReturn<DossierAF>, recherche: string, metadata?: FormMetadata<DossierAF>): Promise<BaseDossierStagiaire[]> => {
    const idEntreprise = form.values?.employeur?.ParentId
    if (idEntreprise == null)
        return []
    
    const items = await getStagiaires(idEntreprise, recherche)
    return items.map(item => Object.assign(metadata!.api.createSalarie(), {
        IdContact: item.Id ?? null,
        nomNaissance: item.LastName ?? null,
        nom: item.LastName ?? null,
        prenom: item.FirstName ?? null,
        sexe: item.Salutation ?? null,
        courriel: item.Email ?? null,
        dateNaissance: item.DateNaissance__c ? parseISO(item.DateNaissance__c as unknown as string) : null,
        nir: null,
        ntt: false,
        nirVerifError: false,
        nirValid: { valid: false, checked: false, pending: false },
    }))
}


export const salarieFields = {
    nom: NameFieldMetadata({
        props: {
            label: 'Nom', 
        },
        mapping: simpleMapping('Nom__c'),
    }).required().mutate(yop => yop.max(50)),
    nomNaissance: AutocompleteNameFieldMetadata({
        search: searchSalarie,
        props: {
            label: 'Nom de naissance',
            disabled: (context, form) => {
                return form?.isAsyncResultPending(`${ getParentPath(context.path) }.nir`) === true
            },
            onChange(value, form, parentPath) {
                const nirPath = `${ parentPath }.nir`
                if (!form.isTouched(nirPath) && form.getValue(nirPath)) {
                    form.touch(nirPath)
                    form.validateAt(nirPath)
                }
            },
        },
        mapping: simpleMapping('NomNaissance__c'),
    }).required().mutate(yop => yop.max(50)),
    prenom: FirstNameFieldMetadata({
        props: {
            label: "Prénom",
        },
        mapping: simpleMapping('Prenom__c'),
    }).required().mutate(yop => yop.max(50)),
    sexe: RadioRefFieldMetadata({
        props: {
            label: 'Sexe',
            options: refs => refs.REF_SEXE.filter(ref => ref.Id !== '0'),
            disabled: (context, form) => {
                return form?.isAsyncResultPending(`${ getParentPath(context.path) }.nir`) === true
            },
            onChange(value, form, parentPath) {
                const nirPath = `${ parentPath }.nir`
                if (!form.isTouched(nirPath) && form.getValue(nirPath)) {
                    form.touch(nirPath)
                    form.validateAt(nirPath)
                }
            },
        },
        mapping: simpleMapping('Sexe__c'),
    }).required(),
    dateNaissance: DateNaissanceFieldMetadata({
        props: {
            label: 'Date de naissance',
        },
        mapping: dateMapping('DateNaissance__c'),
    }).required().mutate(yop => yop.max(END_OF_PREVIOUS_YEAR, "Le stagiaire doit être né avant l'année en cours")),
    naissanceFrance: BooleanFieldMetadata({
        props: {
            label: "Lieu de naissance",
            labelYes: 'France',
            labelNo: 'Etranger',
            disabled: (context: any, form: any) => {
                return form?.isAsyncResultPending(`${ getParentPath(context?.path) }.nir`) === true
            },
        } as any,
        input: props => <RadioBooleanField { ...props }
            onChange={ (value, ctx) => {
                ctx.setValue(props.parentPath + '.communeNaissance', null, SetValueOptions.Untouch)
                if (value) {
                    ctx.setValue(props.parentPath + '.departementNaissance', null, SetValueOptions.Untouch | SetValueOptions.Validate)
                    ctx.setValue(props.parentPath + '.ntt', false, SetValueOptions.Untouch | SetValueOptions.Validate)
                }
                else {
                    ctx.setValue(props.parentPath + '.departementNaissance', '099', SetValueOptions.Untouch | SetValueOptions.Validate)
                }

                const nirPath = `${ props.parentPath }.nir`
                if (!ctx.isTouched(nirPath) && ctx.getValue(nirPath)) {
                    ctx.touch(nirPath)
                    ctx.validateAt(nirPath)
                }
            } } />,
        mapping: {
            mapDTOToModel: (dto: any) => dto.DepartementNaissance__c !== '099',
            mapModelToDTO: (value, dto) => { if (value === false) { dto.DepartementNaissance__c = '099' }},
        },
    }).required(),
    nir: NIRAsyncFieldMetadata({
        props: {
            label: 'Numéro de sécurité sociale (NIR)',
            tooltip: TOOLTIPS.salarie.nir,
        },
        mapping: simpleMapping('Nir__c'),
    }),
    ntt: IgnoredFieldMetadata<boolean>(simpleMapping('NumeroTechniqueTemporaire')),
    nirVerifError: IgnoredFieldMetadata<boolean>(simpleMapping('NirNonVerifieSNGI')),
    courriel: EmailFieldMetadata({
        props: {
            label: 'Email',
        },
        mapping: simpleMapping('Email__c'),
    }).required(),
    telephone: PhoneFieldMetadata({
        props: {
            label: 'Téléphone',
        },
        mapping: simpleMapping('Telephone__c'),
    }).required(),
    handicap: HandicapFieldMetadata({
        props: {
            label: 'Travailleur handicapé',
        },
        mapping: simpleMapping('Handicap__c'),
    }).required(),
    // situationHandicap: Ignored,
    statut: SelectRefFieldMetadata({
        props: {
            label: 'Statut',
            options: refs => refs.REF_TYPE_CSP.filter(ref => ref.IsActive__c && ref.Priorite__c),
            sorted: true,
            alone: true
        },
        mapping: simpleMapping('CSP__c'),
    }).required(),
    contrat: SelectRefFieldMetadata({
        props: {
            label: 'Contrat',
            options: refs => refs.REF_NATURE_CONTRAT.filter(ref => ref.IsActive__c && ref.Categorie__c?.split(',').includes('1') && ref.Priorite__c),
            sorted: true,
        },
        mapping: simpleMapping('NatureContrat__c'),
    }).required(),
    niveauFormation: SelectRefFieldMetadata({
        props: {
            label: 'Niveau de formation',
            options: refs => refs.REF_NIVEAU_FORMATION.filter(ref => ref.IsActive__c && ref.Priorite__c),
            sorted: true,
        },
        mapping: simpleMapping('NiveauFormation__c'),
    }).required(),
    dateEntree: DateFieldMetadata({
        props: {
            label: "Date d'entrée dans l'entreprise",
        },
        mapping: dateMapping('DateEntree__c'),
    }).required().mutate(yop => yop.max<any, DossierAF>(
        context => {
            const dates = context.root!.modules?.map(module => module.dateDebutFormation)?.filter(d => !!d)
            return dates && dates.length > 0 ? dates.reduce((d1, d2) => d1!.getTime() > d2!.getTime() ? d2 : d1) : null
        },
        // eslint-disable-next-line no-template-curly-in-string
        "La date d'entrée dans l'entreprise doit être antérieure à la date de début de formation le ${max}"
    )),
    categorieAction: SelectRefFieldMetadata({
        props: {
            label: "Catégorie d'action",
            options: refs => refs.REF_CATEGORIE_ACTION.filter(ref => ref.IsActive__c && ref.Priorite__c),
            sorted: true,
        },
        mapping: simpleMapping('CategorieAction__c'),
    }).required(),
    heures: DurationFieldMetadata({
        props: {
            label: 'Heures de formation du stagiaire',
            suffix: 'heure(s)',
        },
        mapping: { ...floatingDurationMapping('DureeHeure__c'), mapDTOToModel: (dto, context) => {
            const value = dto?.DureeHeure__c ?? context.dossierDTO.Modules?.[0]?.NombreHeureFormation__c ?? null
            context.currentModelContext!.model[context.currentModelContext!.key + "_duration"] = {
                hours: durationHours(value) ?? null,
                minutes: durationMinutes(value) ?? null,
            }
            return value
        }},
        // TODO: disableHeures
        // TODO: salarie.heures = salarieDTO.DureeHeure__c ?? dossierAF.modules?.[0]?.dureeTotale ?? null
    }).required().mutate(yop => yop.min(5 / 60, "La durée doit être supérieure ou égale à 5 minutes").max<any, DossierAF>(
        context => (context.root!.modules.reduce((heures, module) => heures + (module.dureeTotale ?? 0), 0) ?? 0),
        context => `Le nombre d'heures doit être inférieur ou égal à la durée totale des formations (${durationToString(context.schema.constraints.max?.value ?? 0)})`
    )),
    heuresHTT: IgnoredFieldMetadata(simpleMapping('DureeHttHeure__c')),
    heuresFoad: DurationFieldMetadata({
        props: {
            label: 'Dont heures de formation à distance',
            suffix: 'heure(s)',
            tooltip: TOOLTIPS.salarie.heuresFoad,
        },
        mapping: floatingDurationMapping('DureeFoadHeure__c'),
        // TODO: disableHeures
    }).required().mutate(yop => yop.min(0).max<DossierSalarie>(
        context => context.parent!.heures,
        context => `Le nombre d'heures de formation à distance doit être inférieur ou égal à la durée totale de la formation (${durationToString(context.schema.constraints.max?.value ?? 0)})`
    )),
    heuresAfest: IgnoredFieldMetadata(simpleMapping('DureeAFESTDemandee__c')),
    coefficient: TextFieldMetadata({
        props: {
            label: 'Coefficient',
        },
        mapping: simpleMapping('CoefficientHierarchique__c'),
    }).mutate(yop => yop.max(10)),
    autreMetier: TextFieldMetadata({
        props: {
            label: 'Métier',
        },
        mapping: simpleMapping('Metier__c'),
    }).mutate(yop => yop.max(50)),
    situationAvantContrat: SelectRefFieldMetadata({
        props: {
            label: 'Situation avant contrat',
            options: refs => refs.REF_SITUATION_SALARIE.filter(ref => ref.IsActive__c && ref.Priorite__c && ref.Categorie__c?.split(',').includes('2')),
            sorted: true,
        },
        mapping: simpleMapping('Situation__c'),
    }).required(),
    plusHautDiplome: SelectRefFieldMetadata({
        props: {
            label: 'Diplôme ou titre le plus élevé obtenu',
            options: refs => refs.REF_DIPLOME?.filter(ref => ref.IsActive__c && ref.Categorie__c?.split(',').includes('2')),
            sorted: true,
        },
        mapping: simpleMapping('DiplomePlusEleveObtenu__c'),
    }).required(),
    diplomeVise: SelectRefFieldMetadata({
        props: {
            label: 'Diplôme ou titre visé',
            options: refs => refs.REF_DIPLOME.filter(ref => ref.IsActive__c && ref.Categorie__c?.split(',').includes('2')),
            sorted: true,
        },
        mapping: simpleMapping('DiplomeVise__c'),
    }).required(),
    poleEmploi: BooleanFieldMetadata({
        props: {
            label: 'Inscrit à Pôle Emploi',
        },
        mapping: simpleMapping('InscritPoleEmploi__c'),
    }).required(),
    typeMinimumSocial: SelectRefFieldMetadata({
        props: {
            label: 'Type de minimum social, si bénéficiaire',
            options: refs => refs.REF_TYPE_MINIMUM_SOCIAL.filter(ref => ref.IsActive__c && ref.Priorite__c),
            sorted: true,
        },
        mapping: simpleMapping('TypeMinimumSocial__c'),
    }),

    cspAgora: SelectRefFieldMetadata({
        props: {
            label: 'Catégorie socio-professionnelle',
            options: refs => refs.REF_TYPE_CSP.filter(ref => ref.IsActive__c && ref.Priorite__c),
            sorted: true,
        },
        section: SECTION_SALARIE_AGORA,
        mapping: simpleMapping('CSP__c'),
    }).required(),
    niveauMaximumClasseEntreeFormationAgora: SelectRefFieldMetadata({
        props: {
            label: "Niveau maximum de classe au moment de l'entrée en formation",
            options: refs => refs.REF_NIVEAU_MAX_CLASSE_ENTREE_FORMATION.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
        },
        section: SECTION_SALARIE_AGORA,
        mapping: simpleMapping('NiveauMaximumClasseEntreeFormationAgora__c'),
    }).required(),
}


export function mapDTOToStagiaire<S extends BaseDossierStagiaire, SDTO extends StagiaireDTO>(
    context: MappingContext,
    stagiaireMetadata: ObjectMetadata<S>,
    stagiaire: S,
    sdto: SDTO,
    path?: string
) {
    stagiaire.IdHeroku = sdto.IdHeroku
    // Important: doit être null quand particulier_c est null mais undefined quand aucune valeur reçue pour que le formulaire reste en mode 'Sélectionner'
    stagiaire.IdContact = sdto.Particulier__c === null ? null : (sdto.Particulier__c ?? undefined)
    mapDTOToModel(context, stagiaireMetadata, stagiaire, sdto, path)
}

export function mapStagiaireToDTO<S extends BaseDossierStagiaire, SDTO extends StagiaireDTO>(stagiaireMetadata: ObjectMetadata<S>, dossier: any, stagiaire: S): SDTO | null {
    return mapModelToDTO(stagiaireMetadata, dossier, stagiaire, {
        IdHeroku: stagiaire?.IdHeroku ?? null,
        Particulier__c: stagiaire?.IdContact ?? null,
    })
}
