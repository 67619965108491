import { formatSuffix } from 'components/format/Format'
import RecapAtlasValue from './RecapAtlasValue'

interface RecapNumberFieldProps {
    label: string,
    value: string | number | null | undefined,
    initialValue?: string | number | null | undefined,
    suffix?: string,
    type?: string,
    editMode?: boolean
}

export default function RecapNumberField(props: RecapNumberFieldProps) {
    const suffix = props.suffix ? ' ' + props.suffix : ''

    const value = props.value || props.value === 0 ? props.value + formatSuffix(suffix, (((typeof props.value === 'string' && parseFloat(props.value.toString()) >= 2) || (typeof props.value === 'number' && props.value >= 2)))) : ''
    const initialValue = props.initialValue || props.initialValue === 0 ? props.initialValue + formatSuffix(suffix, (((typeof props.initialValue === 'string' && parseFloat(props.initialValue.toString()) >= 2) || (typeof props.initialValue === 'number' && props.initialValue >= 2)))) : ''

    return (
        value || initialValue ?
        <RecapAtlasValue label={props.label} initialValue={initialValue} value={value} editMode={props.editMode || false} /> :
        null
    )
}
