import { ContactEntreprise, deleteContact } from 'api/profilAPI'
import { dispatchAppEvent } from 'app/events'
import { AtlasButton, MyModal } from 'atlas-ds'
import { useState } from 'react'

interface DeleteModalProps {
    contact: ContactEntreprise
    refreshEvent: string
    close: () => void
}

export default function DeleteContactModal(props: DeleteModalProps) {

    const [deletePending, setDeletePending] = useState<boolean>(false)

    const handleDelete = () => {
        setDeletePending(true)
        deleteContact(props.contact.IdHeroku!)
            .then(() => dispatchAppEvent(props.refreshEvent, "Le contact a été supprimé avec succès"))
            .finally(() => {
                props.close()
            })
    }

    return <MyModal
        label="Confirmation"
        isPending={ deletePending }
        okButton={<AtlasButton onClick={handleDelete}>Confirmer</AtlasButton>}
        cancelButton={<AtlasButton onClick={props.close}>Retour</AtlasButton>}
    >
        <p>Êtes-vous sûr de vouloir supprimer ce contact ?</p>
    </MyModal>
}