import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import cerfaImage from 'pages/dossier/cerfa/img/cerfa.png'
import ministryImage from 'pages/dossier/cerfa/img/ministry.png'
import styles from 'pages/dossier/cerfa/global.styles'
import Employeur from './parts/Employeur'
import Apprenti from './parts/Apprenti'
import Maitres from './parts/Maitres'
import Contrat from './parts/Contrat'
import Formation from './parts/Formation'
import Signature from './parts/Signature'
import Organisme from './parts/Organisme'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'
import { FormMetadata, atField } from 'pages/dossier/metadata/FormMetadata'
import { DossierCAPP } from 'pages/dossier-capp/CAPPModel'


export function createCerfaCAPP<D extends DossierCAPP>(metadata: FormMetadata<D>, dossier: D) {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image source={ministryImage} style={styles.ministryImage}></Image>
          <View style={styles.titles}>
            <Text style={styles.title}>Contrat d'apprentissage</Text>
            <Text style={styles.subtitle}>
              (art. L6211-1 et suivants du code du travail)
            </Text>
            <Text style={styles.notice}>
              (Lire ATTENTIVEMENT la notice Cerfa avant de remplir ce document)
            </Text>
          </View>
          <View style={styles.cerfa}>
            <Image source={cerfaImage} style={styles.cerfaImage}></Image>
            <Text style={styles.cerfaReference}>N° 10103*11</Text>
          </View>
        </View>
        <Text style={styles.mode}>
          Mode contractuel de l'apprentissage{' '}
          <CerfaElement metadata={ atField(metadata, 'contrat', 'modeContractuelApprentissage') } 
            value={ dossier.contrat.modeContractuelApprentissage} />
        </Text>
        <View style={styles.main}>
          <Employeur employeur={dossier.employeur}></Employeur>
          <Apprenti stagiaire={dossier.stagiaire} representant={dossier.representant}></Apprenti>
          <Maitres maitres={ [dossier.maitre1, dossier.maitre2] } eligibilite={dossier.eligibilite}></Maitres>
        </View>
        <View style={styles.main}>
          <Contrat metadata={ metadata } dossier={dossier} contrat={dossier.contrat} employeur={dossier.employeur}></Contrat>
          <Formation formation={dossier.formation} stagiaire={dossier.stagiaire}></Formation>
          <Signature signature={{ ...dossier.signature, piecesJustificatives: dossier.attestationPieces }}></Signature>
          <Organisme organisme={null}></Organisme>
          <View style={styles.footer}>
            <Text style={[styles.boldItalic, styles.xSmall]}>
              Pour remplir le contrat et pour plus d’informations sur le
              traitement des données reportez-vous à la notice FA 14
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}
