import { ClassConstructor, getMetadata } from "./metadata"
import log from "loglevel"

export type PathElement = {
    class: ClassConstructor
    property: string
    index?: number
}

Array.prototype.at ??= function(index: number) {
    if (index < 0)
        return this[this.length + index]
    return this[index]
}

export class Path extends Array<PathElement> {

    override slice(start?: number, end?: number) {
        return new Path(...super.slice(start, end))
    }

    get rootClass(): ClassConstructor | undefined {
        return this[0]?.class
    }

    getValue<T>(rootValue: any) {
        return this.reduce(
            (value, pathElement) => pathElement.index != null ? value?.[pathElement.property]?.[pathElement.index] : value?.[pathElement.property],
            rootValue
        ) as T
    }

    override toString() {
        return this.map(pathElement => pathElement.property + (pathElement.index != null ? `[${ pathElement.index }]` : "")).join('.')
    }

    static fromString(path: string, model: ClassConstructor) {
        const pathElements: PathElement[] = []
        
        for (const pathElement of path.split('.')) {
            if (model === Object) {
                log.error(`Invalid path "${ path }" for model ${ model.name }`)
                break
            }
            
            const metadata = getMetadata(model)
            const bracketIndex = pathElement.indexOf('[')
            const property = bracketIndex !== -1 ? pathElement.slice(0, bracketIndex) : pathElement
            const index = bracketIndex !== -1 ? parseInt(pathElement.slice(bracketIndex + 1, -1)) : undefined
            pathElements.push({ class: model, property, index })

            model = metadata[property]?.type ?? Object
        }

        return new Path(...pathElements)
    }
}
