import { AtlasLoading } from "atlas-ds/atlas";
import { MyActionProps } from "../MyAction";

export interface MyActionListProps {
  /*
   * Les actions recommandées
   */
  children: React.ReactElement<MyActionProps>[];
}

/**
 * Un tableau de recommandations d'actions
 */
export function MyActionList(props: MyActionListProps) {
  const baseColumnLength = Math.floor(props.children.length / 3);
  const rest = props.children.length % 3;
  const columnsLength = [
    baseColumnLength + (rest >= 1 ? 1 : 0),
    baseColumnLength + (rest >= 2 ? 1 : 0),
    baseColumnLength,
  ];
  const columns = [
    props.children.slice(0, columnsLength[0]),
    props.children.slice(columnsLength[0], columnsLength[0] + columnsLength[1]),
    props.children.slice(columnsLength[0] + columnsLength[1]),
  ];

  return (
    <div className="my-actionList">
      <div className="my-actionList__inner">
        {columns.map((column, index) => (
          <div key={`column-${index}`} className="my-actionList__column">
            <AtlasLoading.Loaders>
              <div className="my-actionList__loaders">
                <AtlasLoading.Loader />
                <AtlasLoading.Loader />
                <AtlasLoading.Loader />
                {index < 2 && <AtlasLoading.Loader />}
              </div>
            </AtlasLoading.Loaders>
            {column}
          </div>
        ))}
      </div>
    </div>
  );
}
