import { patchDesactiverRefBancaire, RefBancaireDTO } from 'api/refBancaireAPI'
import { dispatchAppEvent } from 'app/events'
import { useAppDispatch } from 'app/store'
import { AtlasButton, MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import log from 'loglevel'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { refsBancairesActions } from 'slices/refsBancairesSlice'


export default function RefBancaireDesactiverModal(props: { show: boolean, refBancaire: RefBancaireDTO, refreshEvent?: string, close: () => void }) {

    const dispatch = useAppDispatch()
    const [actionPending, setActionPending] = useState(false)
    const history = useHistory()

    const confirmDesactiver = () => {
        setActionPending(true)
        
        patchDesactiverRefBancaire(props.refBancaire).then(_ => {
            dispatch(refsBancairesActions.clear())
            ToastService.showToast({
                label: 'Désactiver un RIB',
                type: 'success',
                content: 'Votre demande de désactivation a bien été prise en compte. Elle sera traitée dans les meilleurs délais',
            })
        }).catch(e => {
            log.error('Erreur désactivation RIB ' + props.refBancaire.Id, e)
            ToastService.showToast({
                label: 'Désactiver un RIB',
                type: 'error',
                content: e?.response?.data?.message ?? 'Une erreur est survenue. Veuillez réessayer ultérieurement',
            })
        }).finally(() => {
            // Attend 5 secondes et rafraîchit le statut et la liste des documents (récupération du récépissé)
            setTimeout(() => {
                setActionPending(false)
                props.close()
                if (props.refreshEvent) {
                    dispatchAppEvent(props.refreshEvent)
                } else {
                    history.push({ pathname: '/mes-refs-bancaires' })
                }
            }, 500)
        })
    }

    return <MyModal show={ props.show }
        label="Désactivation IBAN"
        isPending={actionPending}
        okButton={<AtlasButton onClick={confirmDesactiver} destructive>
            Confirmer (désactivation définitive)
        </AtlasButton>} 
        cancelButton={<AtlasButton onClick={props.close}>Retour</AtlasButton>}
    >
        <p>Confirmer la désactivation de l'IBAN <strong>{ props.refBancaire.iban }</strong></p>
        <p>Si vous confirmez la désactivation de cet IBAN, vous ne pourrez plus recevoir de paiement sur celui-ci.</p>
    </MyModal>
}
