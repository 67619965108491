import React from 'react'

import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { AtlasFieldPeriod, AtlasFieldPeriodPreset, AtlasSelect } from 'atlas-ds'

export declare type ValueType = [Date?, Date?];

export interface DateTypeOption {
    label: string
    value: string
}

interface DropdownDateRangePickerProps {
    name: string
    label: string
    placeholder?: string
    ranges: AtlasFieldPeriodPreset[]
    dateTypeOptions?: DateTypeOption[]
    minDate?: Date
    maxDate?: Date
}

function DateTypeOptions(props: DropdownDateRangePickerProps) {
    const name = props.name + "Type"

    const context = useFormContext()
    const fieldState = getFieldState<string>(context, name)

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value
        context.setValue(name, value, true)
    }

    return <AtlasSelect
        mini
        ariaLabel="Sélectionner le type de période"
        name={ name }
        value={ fieldState.value }
        onChange={ onChange }
        options={ props.dateTypeOptions ?? [] }
    />
}

export default function DropdownDateRangePicker(props: DropdownDateRangePickerProps) {
    const context = useFormContext()
    const fieldState = getFieldState<ValueType>(context, props.name)

    const setValue = (value: ValueType) => {
        context.setValue(props.name, value, true)
    }

    return <AtlasFieldPeriod
        name={ props.name }
        label={ props.label }
        value={ fieldState.value }
        onPeriodChange={ setValue }
        presets={ props.ranges }
        min={ props.minDate }
        max={ props.maxDate }
        placeholder={ props.placeholder }
        select={ props.dateTypeOptions && <DateTypeOptions {...props} /> }
    />
}
