import { AtlasLogo } from "atlas-ds/atlas";

export interface MyPublicTemplateProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
}

/**
 * Le template utilisé pour la partie publique du site, dénué de l'interface
 * habituelle (header, sidebar...)
 */
export function MyPublicTemplate(props: MyPublicTemplateProps) {
  return (
    <div className="my-publicTemplate">
      <div className="my-publicTemplate__inner">
        <a href="/">
          <AtlasLogo />
        </a>
        <main id="main">{props.children}</main>
      </div>
    </div>
  );
}
