import { DossierDetail } from 'api/dossierAPI'
import { AtlasInfo, AtlasSpacer, AtlasValue } from 'atlas-ds'
import { formatCurrency } from 'components/format/Format'

interface DetailAlertesProps<D> {
    detail?: D
    label: string
    messages: (detail: D) => Record<'demandes' | 'alertes', string[]>
    children?: ChildNode
}

export function DetailAlertes<D>(props: DetailAlertesProps<D>) {
    const messages = props.messages(props.detail!)
    const alertes = messages.alertes && messages.alertes.length > 0
    const demandes = messages.demandes && messages.demandes.length > 0

    return <>
    { alertes &&
    <AtlasInfo
        type="error"
        icon="error"
        title={ `${props.label} est en alerte` }>
        <ul>
            { messages.alertes.map((alerte, index) =>
            <li key={index}>{ alerte }</li>)
            }
        </ul>
    </AtlasInfo>
    }
    { alertes && demandes &&
    <AtlasSpacer />
    }
    { demandes &&
    <AtlasInfo
        type="info"
        icon="hourglass"
        title={ `${props.label} est en cours de traitement chez Atlas` }>
        <ul>
            { messages.demandes.map((message, index) =>
            <li key={index}>{ message }</li>)
            }
        </ul>
    </AtlasInfo>
    }
    </>
}

export function DetailDossierExtraInfos({ detail: dossier, showAdh, showOF }: { detail?: DossierDetail, showAdh?: boolean, showOF?: boolean }) {
    if ((showAdh && dossier?.MontantSoldeADH__c == null) || (showOF && dossier?.MontantSoldeOF__c == null))
        return undefined
    
    const montantAdh = showAdh ? (dossier?.MontantSoldeADH__c ?? 0) : 0
    const montantOF = showOF ? (dossier?.MontantSoldeOF__c ?? 0) : 0

    return (
        <AtlasValue
            label="Montant restant à facturer"
            subvalues={<>
                { showAdh && <AtlasValue label="Solde Entreprise">
                    { formatCurrency(montantAdh) }
                </AtlasValue> }
                { showOF && <AtlasValue label="Solde Organisme de formation">
                    { formatCurrency(montantOF) }
                </AtlasValue> }
            </>}
        >
            { formatCurrency(montantAdh + montantOF) }
        </AtlasValue>
    )
}
