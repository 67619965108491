import { DATE_TYPE_CREATION, DATE_TYPE_DEBUT_CONTRAT, DATE_TYPE_DEBUT_FORMATION, DATE_TYPE_FIN_FORMATION, DATE_TYPE_TRANSMISSION, Dossier, DossierDetail, DossiersSearchQuery } from 'api/dossierAPI'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE } from 'api/references'
import { AtlasFlex, AtlasIcon, AtlasText, AtlasTooltip, MyTableColumn } from 'atlas-ds'
import { formatApiDate, formatCurrency } from 'components/format/Format'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import { DEFAULT_DATE_DEBUT, DEFAULT_DATE_FIN } from 'components/recherche/RechercheBase'
import { DateTypeOption } from 'components/reform/inputs/DropdownDateRangePicker'
import State from 'components/State'

interface DateTypeOptionExt extends DateTypeOption {
    // Ajoutés là par commodité
    field: string
    columnLabel: string
}

export const dateTypeOptions: DateTypeOptionExt[] = [
    { label: "création", value: DATE_TYPE_CREATION, field: 'DateCreationFO__c', columnLabel: 'Date de création' },
    { label: "transmission", value: DATE_TYPE_TRANSMISSION, field: 'DateReception__c', columnLabel: 'Date de transmission' },
    { label: "début de formation", value: DATE_TYPE_DEBUT_FORMATION, field: 'DateDebutFormation__c', columnLabel: 'Date de début de formation' },
    { label: "fin de formation", value: DATE_TYPE_FIN_FORMATION, field: 'DateFinFormation__c', columnLabel: 'Date de fin de formation' },
    { label: "début de contrat", value: DATE_TYPE_DEBUT_CONTRAT, field: 'DateDebutContrat__c', columnLabel: 'Date de début de contrat' },
]
export const defaultDateType = dateTypeOptions[0].value

const getDateField = (query: DossiersSearchQuery) => {
    return dateTypeOptions.find(o => o.value === query.dateType)?.field ?? 'DateCreationFO__c'
}

const getDateColumnLabel = (query: DossiersSearchQuery) => {
    return dateTypeOptions.find(o => o.value === query.dateType)?.columnLabel ?? 'Date'
}

const displayDate = (query: DossiersSearchQuery, dossier: Dossier) => {
    const field = dateTypeOptions.find(o => o.value === query.dateType)?.field
    let date: (string | null) = field ? (dossier as any)[field] : null
    if (!date)
        return ''
    return formatApiDate(date);
}

export class DossiersSearchQueryImpl extends BaseSearchQueryImpl implements DossiersSearchQuery {

    withVV: boolean
    withOrganisme: boolean
    withSubrogation: boolean

    recherche = ''
    dateType = defaultDateType
    dateDebut = DEFAULT_DATE_DEBUT
    dateFin = DEFAULT_DATE_FIN
    dispositifFo = [] as string[]
    etatDossierFo = [] as string[]
    utilisateur = 'tous'
    financementVV = 'tous'
    organisme = ''
    subrogation = 'tous'
    alerte = ''
    demande = ''


    constructor(search: URLSearchParams, options: { withVV?: boolean, withOrganisme?: boolean, withSubrogation?: boolean }) {
        super(search, 'DateCreationFO__c')
        this.withVV = options.withVV ?? false
        this.withOrganisme = options.withOrganisme ?? false
        this.withSubrogation = options.withSubrogation ?? false
        this.applySearch(search)
    }

    override applySearch(params: URLSearchParams): DossiersSearchQueryImpl {
        super.applySearch(params)
        this.recherche = params.get('recherche') ?? this.recherche
        this.dateType = params.get('date-type') ?? this.dateType
        this.dateDebut = params.get('date-debut') ?? this.dateDebut
        this.dateFin = params.get('date-fin') ?? this.dateFin
        this.dispositifFo = params.getAll('dispositif-fo') ?? this.dispositifFo
        this.etatDossierFo = params.getAll('etat-dossier-fo') ?? this.etatDossierFo
        this.utilisateur = params.get('utilisateur') ?? this.utilisateur
        this.financementVV = params.get('financement-vv') ?? this.financementVV
        this.organisme = params.get('organisme') ?? this.organisme
        this.subrogation = params.get('subrogation') ?? this.subrogation
        this.alerte = params.get('alerte') ?? this.alerte
        this.demande = params.get('demande') ?? this.demande
        return this
    }

    clone() {
        return new DossiersSearchQueryImpl(this.toSearchParams(), {
            withVV: this.withVV,
            withOrganisme: this.withOrganisme,
            withSubrogation: this.withSubrogation
        })
    }

    override toSearchParams(forStorage = false): URLSearchParams {
        const params = super.toSearchParams(forStorage)
        params.set('recherche', this.recherche)
        params.set('date-type', this.dateType)
        params.set('date-debut', this.dateDebut)
        params.set('date-fin', this.dateFin)
        this.dispositifFo.forEach((value) => params.append('dispositif-fo', value))
        this.etatDossierFo.forEach((value) => params.append('etat-dossier-fo', value))
        params.set('utilisateur', this.utilisateur)
        if (this.withVV)
            params.set('financement-vv', this.financementVV)
        if (this.withOrganisme)
            params.set('organisme', this.organisme)
        if (this.withSubrogation)
            params.set('subrogation', this.subrogation)
        if (import.meta.env.REACT_APP_RELANCE === 'true') {
            params.set('alerte', this.alerte)
            params.set('demande', this.demande)
        }
        return params
    }
}

export const ColonneNumero: MyTableColumn = {
    id: "NumeroDossier__c",
    label: "Numéro",
    sortable: true,
    bold: true,
    nowrap: true
}

export const ColonneStagiaire: MyTableColumn = {
    id: "Stagiaire",
    label: "Stagiaire(s)",
    value: (row: DossierDetail) =>
    (row.Stagiaires?.length ?? 0) > 0 ? <AtlasFlex alignItems="center" gap="s">
        {row.Stagiaires[0].Nom__c ?? ''} {row.Stagiaires[0].Prenom__c ?? ''}
        {row.Stagiaires.length > 1 && <AtlasTooltip opener={<AtlasIcon name="users" size="s" title="Voir la liste des stagiaires" />}>
            <ul>{ row.Stagiaires.map((stagiaire, index) =>
                <li key={ index }>{stagiaire.Nom__c ?? ''} {stagiaire.Prenom__c }</li>
            )}</ul>
        </AtlasTooltip> }
    </AtlasFlex> : <></>
}

export const ColonneIntitule: MyTableColumn = {
    id: "Intitule__c",
    label: "Intitulé",
    sortable: true,
    wrap: true
}

export const ColonneDispositif: MyTableColumn = {
    id: "Dispositif__c",
    label: "Dispositif",
    value: (row: any) => row.DispositifFORef?.Libelle__c,
    wrap: true
}

const source = (row: any, prefix?: string, suffix?: string) => {
    const p = prefix ?? ''
    const s = suffix ?? ''
    return row.SourceFo === 'MAP' ? p + 'CFA' + s : (row.SourceFo === 'MAE' ? p + 'Entreprise' + s : '')
}

export const ColonneDateSource = (query: DossiersSearchQuery): MyTableColumn => ({
    id: getDateField(query),
    label: getDateColumnLabel(query),
    sortable: true,
    value: (row: any) => [
        displayDate(query, row),
        row.DispositifFO__c === DISPOSITIF_CONTRAT_APPRENTISSAGE ? <AtlasText small>(Par : { source(row) })</AtlasText> : undefined
    ]
})

export const ColonneEtat: MyTableColumn = ({
    id: "EtatDossierFORef",
    label: "État du dossier",
    value: (row: any) => <State type="file" id={ row.EtatDossierFO__c }>
        { row.EtatDossierFORef?.Libelle__c }
    </State>
})

// -- RELANCE FF --

export const ColonneNumeroFF: MyTableColumn = {
    id: "NumeroDossier__c",
    label: "Numéro",
    sortable: true,
    nowrap: true,
    value: (row: DossierDetail) => [
        <AtlasText bold>{ row.NumeroDossier__c }</AtlasText>,
        row.DispositifFO__c === DISPOSITIF_CONTRAT_APPRENTISSAGE ? <AtlasText small>{ source(row, '(', ')') }</AtlasText> : undefined,
        <State small type="file" id={ row.EtatDossierFO__c }>
            { row.EtatDossierFORef?.Libelle__c ?? '' }
        </State>
    ]
}

export const ColonneStagiaireFF: MyTableColumn = {
    id: "StagiaireDispositif",
    label: "Stagiaire(s) et dispositif",
    value: (row: DossierDetail) => [
        (row.Stagiaires?.length ?? 0) > 0 ? <AtlasFlex alignItems="center" gap="s">
            {row.Stagiaires[0].Nom__c ?? ''} {row.Stagiaires[0].Prenom__c ?? ''}
            {row.Stagiaires.length > 1 && <AtlasTooltip opener={<AtlasIcon name="users" size="s" title="Voir la liste des stagiaires" />}>
                <ul>{ row.Stagiaires.map((stagiaire, index) =>
                    <li key={ index }>{stagiaire.Nom__c ?? ''} {stagiaire.Prenom__c }</li>
                )}</ul>
            </AtlasTooltip> }
        </AtlasFlex> : <></>,
        <AtlasText small>{ row.DispositifFORef?.Libelle__c }</AtlasText>
    ]
}

export const ColonneFormationOrganismeFF: MyTableColumn = {
    id: "FormationOrganisme",
    label: "Formation et organisme",
    wrap: true,
    truncate: true,
    value: (row: DossierDetail) => [
        <span title={ row.Intitule__c }>{ row.Intitule__c }</span>,
        <AtlasText small>{ row.etablissementOf?.Name }</AtlasText>
    ]
}

export const ColonneFormationEntrepriseFF: MyTableColumn = {
    id: "FormationEntreprise",
    label: "Formation et entreprise",
    wrap: true,
    truncate: true,
    value: (row: DossierDetail) => [
        <span title={ row.Intitule__c }>{ row.Intitule__c }</span>,
        <AtlasText small>{ row.EtablissementAdherent?.Name }</AtlasText>
    ]
}

export const ColonneDateSourceFF = (query: DossiersSearchQuery): MyTableColumn => ({
    id: getDateField(query),
    label: getDateColumnLabel(query),
    sortable: true,
    value: (row: any) => displayDate(query, row)
})

export const ColonneMontantAccordeFF: MyTableColumn = {
    id: "MontantTotalEngage__c",
    label: "Montant accordé",
    sortable: true,
    wrap: true,
    value: (row: DossierDetail) => formatCurrency(row.MontantTotalEngage__c)
}
