import { formatRef } from 'components/format/Format'
import { useIdcc } from 'components/reform/inputs/utils/useIdcc'
import RecapAtlasValue from './RecapAtlasValue'

interface RecapCodeIdccFieldProps {
    label: string,
    value: string,
    initialValue?: string | null | undefined,
    editMode?: boolean
}

export default function RecapCodeIdccField(props: RecapCodeIdccFieldProps) {
    const refsIdcc = useIdcc()

     const value = props.value + '-' + formatRef(refsIdcc, props.value) 
    const initialValue = props.initialValue + '-' + formatRef(refsIdcc, props.initialValue)

    return  <RecapAtlasValue label={props.label} initialValue={initialValue} value={value} editMode={props.editMode || false} />

}
