import axios from 'axios'
import { getRequestConfig } from './baseAPI'

interface ClimateSchoolLink {
    siren: string
    link: string
}

export async function getClimateSchoolLink(siren: string): Promise<ClimateSchoolLink> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.post<ClimateSchoolLink>(`/entreprises/${siren}/recuperer-lien-climate-school-atlas`, null, 
        Object.assign(requestConfig, { ignoreErrors: true }))
    return { ...data, siren }
}
