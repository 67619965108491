import { SetValueOptions, UseFormReturn } from "@dsid-opcoatlas/reform"
import { Image, Text } from '@react-pdf/renderer'
import { config } from "api/config"
import { entreprise } from "api/entreprise"
import { CSP_ETAM, NATURE_CONTRAT_CDD, NATURE_CONTRAT_CDI } from "api/references"
import { References } from "api/referencesAPI"
import { CheckboxFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { DateFieldMetadata } from "components/metadata/DateFieldMetadata"
import { RequiredDurationMnFieldMetadata, durationMnMapping, durationMnSchema } from "components/metadata/DurationMnFieldMetadata"
import { NumberFieldMetadata } from "components/metadata/NumberFieldMetadata"
import { SelectRefFieldMetadata } from "components/metadata/RefFieldMetadata"
import { TextFieldMetadata } from "components/metadata/TextFieldMetadata"
import CurrencyField from "components/reform/inputs/CurrencyField"
import { addMonths, differenceInMonths, differenceInWeeks, isAfter } from "date-fns"
import { TOOLTIPS } from "pages/dossier/Tooltips"
import checkboxOffImage from 'pages/dossier/cerfa/img/checkbox-off.png'
import checkboxOnImage from 'pages/dossier/cerfa/img/checkbox-on.png'
import { contratFields } from "pages/dossier/metadata/ContratMetadata"
import { dateMapping, nonNullBooleanMapping, simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { ElementContext, IgnoredFieldMetadata, ObjectMetadata, override } from "pages/dossier/metadata/FormMetadata"
import { FinancementPDCFieldMetadata } from "./FinancementPDC"
import { StrategiePlusFieldMetadata } from "./StrategiePlus"
import { VAEIModuleAFESTMetadata, VAEIModulePrincipalMetadata } from "./VAEIFormationMetadata"
import { DossierVAEI, DossierVAEIContrat, createDossierVAEIContrat } from "./VAEIModel"
import { VAEISalarieMetadata } from "./VAEISalarieMetadata"

export const SECTION_VAEI_CONTRAT = "contrat"

function hasFinancementPdc() {
    const branche = entreprise()?.Branche__c
    return branche != null && (config()?.CproFinancementResteAChargePdc?.BranchesAutorisees?.includes(branche) ?? false)
}

const today = new Date()
const date2020 = new Date(2020, 0, 1)

export default function CerfaNatureContratField(props: { value?: string }) {

    return <>
        <Text>
            <Image source={ ["5", "1"].includes(props.value ?? "") ? checkboxOnImage : checkboxOffImage } />{' '}
            <Text>CDI</Text>
            {' '}
            <Image source={ ["6", "2"].includes(props.value ?? "") ? checkboxOnImage : checkboxOffImage } />{' '}
            <Text>CDD</Text>
            {' '}
            <Image source={ ["3"].includes(props.value ?? "") ? checkboxOnImage : checkboxOffImage } />{' '}
            <Text>travail temporaire</Text>
        </Text>
    </>
}

export const VAEIContratMetadata: ObjectMetadata<DossierVAEIContrat> = {
    avenantContratInitial: CheckboxFieldMetadata({
        props: {
            label: "Si le présent contrat de professionnalisation vaut avenant au contrat de travail initial cochez la case ci-contre",
            tooltip: TOOLTIPS.cpro.contrat.avenantContratInitial,
            full: true,
            onChange: (value: boolean | null, form: UseFormReturn<any>) => {
                if (value === true)
                    form.setValue('contrat.natureContrat', null, SetValueOptions.Untouch | SetValueOptions.Validate)
            }
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: nonNullBooleanMapping("AvenantContratTravailInitial__c"),
    }),
    natureContrat: SelectRefFieldMetadata({
        props: {
            label: "Nature du contrat",
            options: (refs: References) => refs?.REF_NATURE_CONTRAT.filter(ref => ref.IsActive__c && ref.Priorite__c && ref.Categorie__c?.split(',').includes('1')),
            disabled: (context: ElementContext<DossierVAEIContrat, DossierVAEI>) => context.parent?.avenantContratInitial === true,
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: simpleMapping("NatureContrat__c"),
        cerfa: CerfaNatureContratField,
    }).required().mutate(yop => yop.ignoredIf<DossierVAEIContrat>(ctx => ctx.parent?.avenantContratInitial === true)),
    typeContrat: SelectRefFieldMetadata({
        props: {
            label: "Type de contrat",
            options: (refs: References) => refs?.REF_TYPE_CONTRAT.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('2')),
            tooltip: TOOLTIPS.cpro.contrat.typeContrat,
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: simpleMapping("TypeContrat__c"),
    }).required(),
    emploiOccupe: override(contratFields.emploiOccupe, {
        props: {
            label: "Emploi occupé pendant le contrat (intitulé précis)",
        },
        section: SECTION_VAEI_CONTRAT,
    }).required().mutate(yop => yop.max(80)),
    dateDebut: DateFieldMetadata({
        props: {
            label: "Date de début d'exécution du contrat",
            tooltip: TOOLTIPS.cpro.contrat.dateDebut,
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: dateMapping('DateDebutContrat__c'),
    }).required(),
    dateFin: DateFieldMetadata({
        props: {
            label: "Date de fin du CDD ou de l'action de professionnalisation si CDI",
            tooltip: TOOLTIPS.cpro.contrat.dateFin,
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: dateMapping("DateFinContrat__c"),
    }).required().mutate(yop => yop
        .min<DossierVAEIContrat>(ctx => ctx.parent?.dateDebut, "La date de fin doit être postérieure ou égale à la date de début de contrat")
        .test<DossierVAEIContrat>(ctx => {
            const dateDebut = ctx.parent?.dateDebut
            if (dateDebut != null) {
                if (isAfter(ctx.value, addMonths(dateDebut, 36)))
                    return ctx.createError("La durée du contrat ne peut pas dépasser 36 mois")
            }
            return true
        })
    ),
    dateConclusion: DateFieldMetadata({
        props: {
            label: "Date de conclusion (date de signature du contrat)",
            tooltip: TOOLTIPS.cpro.contrat.dateConclusion,
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: dateMapping("DateConclusionContrat__c"),
        dependencies: [
            { stepName: "Formation (module principal)", path: "modulePrincipal.dateDebutFormation", field: VAEIModulePrincipalMetadata.dateDebutFormation, visibleWith: "modulePrincipal.dateFinFormation" },
            { stepName: "Formation (module principal)", path: "modulePrincipal.dateFinFormation", field: VAEIModulePrincipalMetadata.dateFinFormation, visibleWith: "modulePrincipal.dateDebutFormation" },
            { stepName: "Formation (module AFEST)", path: "moduleAfest.dateDebutFormation", field: VAEIModuleAFESTMetadata.dateDebutFormation, visibleWith: "moduleAfest.dateFinFormation" },
            { stepName: "Formation (module AFEST)", path: "moduleAfest.dateFinFormation", field: VAEIModuleAFESTMetadata.dateFinFormation, visibleWith: "moduleAfest.dateDebutFormation" },
            { stepName: "Salarié", path: "salarie.poleEmploi", field: VAEISalarieMetadata.poleEmploi },
            { stepName: "Salarié", path: "salarie.numeroPoleEmploi", field: VAEISalarieMetadata.numeroPoleEmploi, visibleWith: "salarie.poleEmploi" },
            { stepName: "Salarié", path: "salarie.dureePoleEmploi", field: VAEISalarieMetadata.dureePoleEmploi, visibleWith: "salarie.poleEmploi" },
            { stepName: "Salarié", path: "salarie.dateNaissance", field: VAEISalarieMetadata.dateNaissance, visibleWith: "salarie.poleEmploi" },
            { stepName: "Salarié", path: "salarie.situationAvantContrat", field: VAEISalarieMetadata.situationAvantContrat, visibleWith: "salarie.poleEmploi" },
            { stepName: "Salarié", path: "salarie.handicap", field: VAEISalarieMetadata.handicap, visibleWith: "salarie.poleEmploi" },
            { stepName: "Salarié", path: "salarie.typeMinimumSocial", field: VAEISalarieMetadata.typeMinimumSocial, visibleWith: "salarie.poleEmploi" },
        ]
    }).required().mutate(yop => yop
        .min<DossierVAEIContrat>(date2020, "Date de conclusion antérieure au 01/01/2020 : seuls les contrats conclus en 2020 peuvent être saisis sur myAtlas, veuillez nous retourner le Cerfa signé par voie postale")
        .max<DossierVAEIContrat>(today, "Le contrat pourra être transmis à Atlas lorsque le contrat aura effectivement été conclu. La date de conclusion s'entend comme étant celle du jour où les deux signatures des parties au contrat (employeur et apprenti) sont recueillies, en principe un contrat doit être signé avant de débuter.")
        .test<DossierVAEIContrat>(ctx => {
            const dateDebut = ctx.parent?.dateDebut
            if (dateDebut != null && isAfter(ctx.value, dateDebut))
                return ctx.createError("La date de conclusion (signature) est postérieure à la date de début de contrat")
            return true
        })
    ),
    dureePeriodeEssai: NumberFieldMetadata({
        props: {
            label: "Durée de la période d'essai",
            suffix: "jour(s)",
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: simpleMapping("DureePeriodeEssai__c"),
    }).required().mutate(yop => yop
        .max(180, "La période d'essai ne peut pas dépasser 180 jours")
        .test<DossierVAEIContrat, DossierVAEI>(ctx => {
            const dateDebut = ctx.parent?.dateDebut
            const dateFin = ctx.parent?.dateFin
            const natureContrat = ctx.parent?.natureContrat

            if (dateDebut == null || dateFin == null || natureContrat == null)
                return true

            let dureeMax = 180
            if (NATURE_CONTRAT_CDD.includes(natureContrat)) {
                const dureeContrat = differenceInMonths(dateFin, dateDebut)
                if (dureeContrat >= 6)
                    dureeMax = 30
                else
                    dureeMax = Math.min(14, differenceInWeeks(dateFin, dateDebut))
            }
            else if (natureContrat === NATURE_CONTRAT_CDI) {
                if (ctx.root?.salarie?.statut === CSP_ETAM)
                    dureeMax = 3 * 30
                else // CSP_CADRE
                    dureeMax = 4 * 30
            }

            if (ctx.value > dureeMax)
                return ctx.createError(`La période d'essai ne peut pas dépasser ${ dureeMax } jour${ dureeMax > 1 ? "s" : "" }`)

            return true
        })
    ),
    dureeHebdomadaireTravail: RequiredDurationMnFieldMetadata({
        props: {
            label: "Durée hebdomadaire du travail",
            tooltip: TOOLTIPS.cpro.contrat.dureeHebdomadaireTravail,
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: durationMnMapping("DureeHebdomadaireTravailHeures__c", "DureeHebdomadaireTravailMinutes__c"),
    }).mutate(_ => durationMnSchema(48, 'Maximum 48 heures par semaine')),
    salaireEmbauche: NumberFieldMetadata({
        props: {
            label: "Salaire mensuel brut à l'embauche",
            tooltip: TOOLTIPS.cpro.contrat.salaireEmbauche,
            fractionDigits: 2,
            suffix: "€ / mois",
        },
        section: SECTION_VAEI_CONTRAT,
        input: CurrencyField,
        mapping: simpleMapping("MontantSalaire__c"),
    }).required().mutate(yop => yop.min(0.01).max(9999.99)),
    classificationEmploi: TextFieldMetadata({
        props: {
            label: "Classification de l'emploi dans la convention collective",
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: simpleMapping("ClassificationEmploi__c"),
    }),
    niveau: TextFieldMetadata({
        props: {
            label: "Niveau ou position",
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: simpleMapping("NiveauCCN__c"),
    }).required().mutate(yop => yop.max(15)),
    coefficient: TextFieldMetadata({
        props: {
            label: "Coefficient hiérarchique",
        },
        section: SECTION_VAEI_CONTRAT,
        mapping: simpleMapping("CoefficientCCN__c"),
    }).mutate(yop => yop.max(3).requiredIf(_ => entreprise()?.Branche__c === '22')),
    financementPdc: FinancementPDCFieldMetadata({
        section: SECTION_VAEI_CONTRAT,
        mapping: simpleMapping("FinancementRacPDC__c"),
        visibleIf: _ => hasFinancementPdc(),
    }).mutate(yop => yop.ignoredIf(_ => !hasFinancementPdc())),
    strategiePlus: StrategiePlusFieldMetadata({
        section: SECTION_VAEI_CONTRAT,
        mapping: simpleMapping("FinancementRacVV__c"),
    }),
    dateEffet: IgnoredFieldMetadata(),
    dateConclusionAvenant: IgnoredFieldMetadata(),
}

export function dossierVAEIContratInitializer<D extends DossierVAEI>(dossier: D) {
    if (dossier.contrat == null)
        dossier.contrat = createDossierVAEIContrat()
    return dossier
}
