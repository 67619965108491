import { get, set } from "lodash-es"
import { ClassConstructor, FieldMetadata, getMetadata } from "./metadata"

export type DTOProperty<ModelType = any> = string | {
    path?: string
    type?: "array" | "object"
    select?: (value: any) => boolean
    ignore?: (value: any) => boolean
    init?: (value: any) => ModelType
    toDTO?: (value: ModelType) => unknown
    fromDTO?: (value: unknown) => ModelType
}

function dtoContext(fieldMetadata: FieldMetadata<any, any>, dtoValue: any, dtoRoot: any): [
    any,
    string | undefined,
    string | undefined,
    ((value: any) => boolean) | undefined,
    { toDTO?: (value: any) => any, fromDTO?: (value: any) => any } | undefined,
    ((value: any) => boolean) | undefined,
    ((value: any) => any) | undefined,
] {
    
    if (typeof fieldMetadata.dto === "string") {
        const dtoPath = fieldMetadata.dto
        if (dtoPath.startsWith("/"))
            return [dtoRoot, dtoPath.substring(1), undefined, undefined, undefined, undefined, undefined]
        return [dtoValue, dtoPath, undefined, undefined, undefined, undefined, undefined]
    }

    const dtoPath = fieldMetadata.dto!.path
    const dtoType = fieldMetadata.dto!.type
    const dtoSelect = fieldMetadata.dto!.select
    const dtoConvert = { fromDTO: fieldMetadata.dto!.fromDTO, toDTO: fieldMetadata.dto!.toDTO }
    const dtoIgnore = fieldMetadata.dto!.ignore
    const dtoInit = fieldMetadata.dto!.init
    if (dtoPath?.startsWith("/"))
        return [dtoRoot, dtoPath.substring(1), dtoType, dtoSelect, dtoConvert, dtoIgnore, dtoInit]
    return [dtoValue, dtoPath, dtoType, dtoSelect, dtoConvert, dtoIgnore, dtoInit]
}

export function dtoToModel<T, I extends T>(model: ClassConstructor<T>, dtoRoot: any, modelInstance?: I): I {
    return _dtoToModel(model, modelInstance ?? new model(), dtoRoot, dtoRoot)
}

function _dtoToModel(model: ClassConstructor, value: any, dtoValue: any, dtoRoot: any) {
    const metadata = getMetadata(model)

    for (const [name, fieldMetadata] of Object.entries(metadata)) {
        if (fieldMetadata.dto == null)
            continue

        const [dto, path, type, select, convert, ignore, init] = dtoContext(fieldMetadata, dtoValue, dtoRoot)

        if (path == null) {
            if (init != null)
                value[name] = init(dto)
            continue
        }

        let fieldValue = (path === "." ? dto : get(dto, path)) ?? null

        if (fieldValue == null)
            continue

        if (fieldMetadata.kind === "object") {
            const classConstructor = fieldMetadata.type!
            // object -> array
            if (type === "array") {
                if (!Array.isArray(fieldValue))
                    throw new Error("Value is not an array")
                if (select == null)
                    throw new Error("Array object must have a select function")
                
                fieldValue = fieldValue?.find(select)
                if (fieldValue != null) {
                    const object = new classConstructor()
                    value[name] = object
                    _dtoToModel(classConstructor, object, fieldValue, dtoRoot)
                }
            }
            // object -> object (type == null || type === "object")
            else if (ignore?.(fieldValue) !== true) {
                const object = new classConstructor()
                value[name] = object
                _dtoToModel(classConstructor, object, dtoValue, dtoRoot)
            }
        }
        else if (fieldMetadata.kind === "array") {
            const classConstructor = fieldMetadata.type!
            // array -> object
            if (type === "object") {
                // TODO
            }
            // array -> array (type == null || type === "array")
            else {
                if (!Array.isArray(fieldValue))
                    throw new Error("Value is not an array")
                fieldValue = fieldValue.filter((item: any) => item != null)
                if (fieldValue.length === 0)
                    continue
                const array = fieldValue.map((item: any) => {
                    const object = new classConstructor()
                    _dtoToModel(classConstructor, object, item, dtoRoot)
                    return object
                })
                value[name] = array
            }
        }
        else {
            if (convert?.fromDTO != null)
                fieldValue = convert.fromDTO(fieldValue)
            value[name] = fieldValue ?? null
        }
    }

    return value
}

export function modelToDTO(model: ClassConstructor, value: any ): any {
    const dto = {}
    _modelToDTO(model, value, dto, dto)
    return dto
}

function _modelToDTO(model: ClassConstructor, value: any, dtoValue: any, dtoRoot: any) {
    const metadata = getMetadata(model)

    for (const [name, fieldMetadata] of Object.entries(metadata)) {
        if (fieldMetadata.dto == null)
            continue

        let fieldValue = value?.[name] ?? null
        const [dto, path, type, select, convert] = dtoContext(fieldMetadata, dtoValue, dtoRoot)
        
        if (path == null)
            continue

        if (fieldMetadata.kind === "object") {
            const classConstructor = fieldMetadata.type!
            // object -> array
            if (type === "array") {
                let array = path === "." ? dto : get(dto, path)
                if (!Array.isArray(array)) {
                    if (array != null)
                        throw new Error(`Value at path '${ path }' is not an array`)
                    array = []
                    set(dto, path, array)
                }
                if (fieldValue != null) {
                    const object = {}
                    array.push(object)
                    _modelToDTO(classConstructor, fieldValue, object, dtoRoot)
                }
            }
            // object -> object (type == null || type === "object")
            else {
                let object = path === "." ? dto : get(dto, path)
                if (object?.constructor?.name !== "Object") {
                    if (object != null)
                        throw new Error(`Value at path '${ path }' is not an object`)
                    object = fieldValue != null ? {} : null
                    set(dto, path, object)
                }
                _modelToDTO(classConstructor, fieldValue, object, dtoRoot)
            }
        }
        else if (fieldMetadata.kind === "array") {
            const classConstructor = fieldMetadata.type!
            // array -> object
            if (type === "object") {
                // TODO
            }
            // array -> array (type == null || type === "array")
            else {
                let array = path === "." ? dto : get(dto, path)
                if (!Array.isArray(array)) {
                    if (array != null)
                        throw new Error(`Value at path '${ path }' is not an array`)
                    array = []
                    set(dto, path, array)
                }

                fieldValue?.forEach((item: any) => {
                    if (item != null) {
                        const object = {}
                        array.push(object)
                        _modelToDTO(classConstructor, item, object, dtoRoot)
                    }
                })
            }
        }
        else {
            if (convert?.toDTO != null)
                fieldValue = convert.toDTO(fieldValue)
            set(dto, path, fieldValue)
        }
    }

    return dtoRoot
}
