import { unwrapResult } from '@reduxjs/toolkit'
import { DiagDiagnosticActionDTO } from 'api/diagatlasAPI'
import { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasFlex, AtlasGrid, AtlasHeading, AtlasIcon, AtlasLogo, AtlasSpacer, MyAction, MyContainer, MyKanban } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import { formatToFullDate } from 'components/format/Format'
import Link from 'components/Link'
import { useHistory } from 'react-router-dom'
import { STATUTS_ACTIONS_PLAN, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import DiagExportsButton from './DiagExportsButton'
import { DiagnosticActionsPreconisees, getActionBanner, getActionError, getActionTags } from './MonDiagnosticActions'

export default function AccueilDiagAtlas() {
    const dispatch = useAppDispatch()
    const { loading, diagnostic, error } = useDiagnostic(null, 'plan-action', { statuts: 'accueil' })
    const history = useHistory()

    const goToDiagnostic = () => {
        history.push("/mon-diagnostic")
    }

    const goToDiagnosticActions = () => {
        history.push("/mon-diagnostic-actions")
    }

    const goToDiagnosticPlan = () => {
        history.push("/mon-diagnostic-plan")
    }

    const ajouterAction = (action: DiagDiagnosticActionDTO) => {
        return dispatch(diagnosticSliceActions.addAction({ action: action.Action.Id, preconisee: false }))
            .then(unwrapResult)
            .then(da => {
                ToastService.showToast({
                    type: "success",
                    label: "Action ajoutée",
                    content: `L'action « ${action.Action.Libelle} » a bien été ajoutée à votre plan d'action`,
                    icon: "diagnostic",
                    link: <Link to="/mon-diagnostic-plan">Voir mon plan d'action</Link>,
                })
                return da
            })
    }

    if (loading || !diagnostic || error) {
        return null
    }

    if (diagnostic?.statut === "Diagnostic réalisé") {
        return <AtlasFlex column gap="s">
            <AtlasLogo logo="diagnostic" width="14rem" />
            <p>Sur la base du diagnostic réalisé{ diagnostic?.dateRealisation ? ' le ' + formatToFullDate(diagnostic?.dateRealisation) : '' }{ diagnostic?.contactRealisation ? ' par ' + diagnostic?.contactRealisation : '' }{ diagnostic?.dateModification ? ' et modifié le ' + formatToFullDate(diagnostic?.dateModification) : '' }{ diagnostic?.contactModification ? ' par ' + diagnostic?.contactModification : '' }</p>

            <AtlasSpacer size="s" />

            <DiagnosticActionsPreconisees
                diagnostic={diagnostic}
                maxElements={6}
                ajouterLabel="Ajouter à mon plan d'action"
                ajouterAction={ajouterAction}
                resultsHeader={<AtlasHeading size="m" tag="h2">Actions préconisées</AtlasHeading>}
            />

            <AtlasSpacer size="s" />

            <AtlasFlex gap="xl">
                <AtlasButton onClick={ goToDiagnosticActions }>Voir toutes les actions préconisées</AtlasButton>
                <DiagExportsButton level={3} label="Voir les diagnostics exportés" />
            </AtlasFlex>
        </AtlasFlex>
    } else if (diagnostic?.statut === "Plan d'action en cours d'exécution") {
        return <AtlasFlex column gap="s">
            <AtlasLogo logo="diagnostic" width="14rem" />
            <p>Sur la base du diagnostic réalisé{ diagnostic?.dateRealisation ? ' le ' + formatToFullDate(diagnostic?.dateRealisation) : '' }{ diagnostic?.contactRealisation ? ' par ' + diagnostic?.contactRealisation : '' }{ diagnostic?.dateModification ? ' et modifié le ' + formatToFullDate(diagnostic?.dateModification) : '' }{ diagnostic?.contactModification ? ' par ' + diagnostic?.contactModification : '' }</p>

            <AtlasSpacer size="s" />

            <AtlasHeading tag="h1" size="m">Plan d'action</AtlasHeading>

            <MyKanban
                previewLink={ <AtlasButton icon="diagnostic" onClick={ goToDiagnosticPlan }>Voir le plan d'action</AtlasButton> }
                onCardMove={ () => {} }
                onCardRemove={ () => {} }
                addCta={ <AtlasButton>Ajouter une action</AtlasButton> }
                columns={ STATUTS_ACTIONS_PLAN.map(s => ({
                    id: s.id,
                    label: s.label,
                    moveLabel: s.shortLabel,
                    emoji: s.emoji,
                    cards: diagnostic?.planAction?.filter(a => a.Statut === s.label)?.slice(0, 3).map(a => {
                        return { id: a.Id, card: <MyAction
                            key={ a.Id } 
                            infoHref={ a.Action.URL }
                            tags={ getActionTags(a) }
                            banner={ getActionBanner(a) }
                            error={ getActionError(a) }
                        >{ a.Action.Libelle }</MyAction> }
                    }) ?? [],
                })) } />

            <DiagExportsButton level={3} label="Voir les diagnostics exportés" />
        </AtlasFlex>
    }

    return <AtlasGrid columnsCount={2} gap="xl">
        <div>
            <AtlasLogo logo="diagnostic" width="14rem" />
            <AtlasHeading tag="h2" size="m">
                Anticipez les mutations des emplois et compétences au sein de votre entreprise&nbsp;!
            </AtlasHeading>
            <p>Découvrez quels seront les métiers et compétences en transition et anticipez leurs mutations grâce à un plan d'action personnalisé.</p>
            <AtlasSpacer size="s" />
            <DiagExportsButton level={3} label="Voir les diagnostics exportés" />
        </div>
        <MyContainer colored>
            { diagnostic?.statut === "Diagnostic en cours" ? <>
                <p>Terminez votre diagnostic afin de découvrir les actions préconisées par l'Opco Atlas et commencer votre plan d'action.</p>
                <AtlasSpacer size="xl" />
                <AtlasFlex justifyContent="center">
                    <AtlasButton type="button" onClick={ goToDiagnostic }>
                        Terminer le diagnostic
                    </AtlasButton>
                </AtlasFlex>
            </> : <>
                <p>Grâce à ce diagnostic, vous pourrez facilement repérer les actions disponibles pour accompagner la mutation de vos emplois et compétences.</p>
                <AtlasSpacer />
                <AtlasFlex gap="s">
                    <AtlasIcon name="clock" size="s" />Temps moyen : 10 minutes
                </AtlasFlex>
                <AtlasSpacer size="xl" />
                <AtlasFlex column alignItems="center">
                    <AtlasButton type="button" onClick={ goToDiagnostic }>
                        Commencer le diagnostic
                    </AtlasButton>
                </AtlasFlex>
            </> }
        </MyContainer>
    </AtlasGrid>
}
