import { findModeReglement, getAllModeReglement } from 'api/references'
import { exportReglements, getReglements, ReglementDetail, ReglementsSearchQuery } from 'api/reglementAPI'
import { FiltreOrganisme } from 'app/prestataire/FiltresPrestataire'
import { AtlasText, MyTableColumn } from 'atlas-ds'
import { formatApiDate, formatCurrency } from 'components/format/Format'
import Link from 'components/Link'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import { FiltreCheckboxes, FiltrePeriode, FiltreRecherche } from 'components/recherche/Filtres'
import RechercheBase, { DEFAULT_DATE_DEBUT, DEFAULT_DATE_FIN, DEFAULT_DATE_RANGES, singleTab } from 'components/recherche/RechercheBase'
import { capitalize } from 'lodash'

class ReglementsSearchQueryImpl extends BaseSearchQueryImpl implements ReglementsSearchQuery {

    withOrganisme: boolean

    recherche = ''
    dateDebut = DEFAULT_DATE_DEBUT
    dateFin = DEFAULT_DATE_FIN
    modeReglement = [] as string[]
    organisme = ''

    constructor(search: URLSearchParams, withOrganisme: boolean) {
        super(search, 'DateReglement__c')
        this.withOrganisme = withOrganisme
        this.applySearch(search)
    }

    override applySearch(params: URLSearchParams): ReglementsSearchQueryImpl {
        super.applySearch(params)
        this.recherche = params.get('recherche') ?? this.recherche
        this.dateDebut = params.get('date-debut') ?? this.dateDebut
        this.dateFin = params.get('date-fin') ?? this.dateFin
        this.modeReglement = params.getAll('mode-reglement') ?? this.modeReglement
        this.organisme = params.get('organisme') ?? this.organisme
        return this
    }

    clone() {
        return new ReglementsSearchQueryImpl(this.toSearchParams(), this.withOrganisme)
    }

    override toSearchParams(forStorage = false): URLSearchParams {
        const params = super.toSearchParams(forStorage)
        params.set('recherche', this.recherche)
        params.set('date-debut', this.dateDebut)
        params.set('date-fin', this.dateFin)
        this.modeReglement.forEach((mode) => params.append('mode-reglement', mode))
        if (this.withOrganisme)
            params.set('organisme', this.organisme)
        return params
    }
}

export default function MesReglements(props: { organisme: boolean }) {
    
    const filtres = [
        FiltreRecherche({ label: 'Recherche', placeholder: 'Référence, dossier...' }),
        FiltrePeriode({ label: "Date d'émission du règlement", placeholder: 'Choisir une période', dateRanges: DEFAULT_DATE_RANGES }),
        FiltreCheckboxes({
            name: 'modeReglementIds', label: "Mode règlement",
            paramName: 'mode-reglement', valueName: 'modeReglementIds',
            queryName: 'modeReglement', checkboxes: (getAllModeReglement() ?? []).map(modeReglement => ({
                label: modeReglement.Libelle__c,
                value: modeReglement.Id
            }))
        }),
        ...(props.organisme ? [FiltreOrganisme({})] : []),
    ]

    const colonnes = (_: ReglementsSearchQuery): MyTableColumn[] => [
        {
            id: "ReferencePaiement__c",
            label: "N° de règlement",
            wrap: true
        },
        {
            id: "RefFactureClient__c",
            label: "N° facture",
            sortable: true,
            nowrap: true,
            value: (row: any) => row.Facture?.RefFactureClient__c
        },
        {
            id: "NumeroDossier__c",
            label: "N° dossier",
            nowrap: true,
            value: (row: any) => row.Dossier?.NumeroDossier__c
        },
        {
            id: "DateReglement__c",
            label: "Date de règlement",
            sortable: true,
            value: (row: any) => [
                <AtlasText bold>{formatApiDate(row.DateReglement__c)}</AtlasText>,
                <AtlasText small>{capitalize(findModeReglement(row.ModeReglement__c)?.LibelleReduit__c ?? '')}</AtlasText>
            ]
        },
        {
            id: "MontantHT__c",
            label: "Montant",
            sortable: true,
            value: (row: any) => [
                <AtlasText bold>{formatCurrency(row.MontantTCC__c, '\xa0TTC')}</AtlasText>,
                <AtlasText small>{formatCurrency(row.MontantHT__c, '\xa0HT')}</AtlasText>
            ]
        },
        {
            id: "TypeEmetteur",
            label: "Bénéficiaire",
            value: (row: any) => row.Facture?.EtablissementEmetteur?.Name + ' - ' + row.Facture?.EtablissementEmetteur?.BillingCity,
        }
    ]

    const actions = (reglement: ReglementDetail) => {
        return [
            <Link
                key="dossier"
                icon="dossiers"
                level={ 3 }
                to={ '/detail-dossier-contrat/' + reglement.Dossier__c }
            >Détail dossier</Link>,
            <Link
                key="facture"
                icon="factures"
                level={ 3 }
                to={ '/detail-facture/' + reglement.Facture__c }
            >Détail facture</Link>,
        ]
    }

    const newQuery = (params: URLSearchParams) => new ReglementsSearchQueryImpl(params, props.organisme)

    return (
        <RechercheBase<ReglementDetail, ReglementsSearchQueryImpl>
            name="MesReglements"
            title="Règlements"
            newQuery={ newQuery }
            filtres={ filtres }
            colonnes={ colonnes }
            tabs={ singleTab(getReglements) }
            executeExport={ exportReglements }
            tableActions={ actions }
        />
    )
}
