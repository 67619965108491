

export function filterProperties<T extends object>(value: T, filter: (key: string, value: any) => boolean): { [P in keyof T]: T[P] } {
    return Object.fromEntries(Object.entries(value).filter(([key, value]) => filter(key, value))) as any
}

export function nonNullProperties<T extends object>(value: T): { [P in keyof T]: NonNullable<T[P]> } {
    return filterProperties(value, (_, value) => value != null) as any
}

export function propertiesOfNames<T extends object>(value: T, ...names: (keyof T)[]) {
    return filterProperties(value, (name, _) => names.includes(name as keyof T))
}
