import { getEtablissements } from 'api/commonAPI'
import { MyForm } from 'atlas-ds'
import EtablissementSelectorField from 'components/reform/inputs/EtablissementSelectorField'
import { FormContentProps } from 'pages/dossier/metadata/FormMetadata'
import { useParams } from 'react-router-dom'
import { FormFields } from 'elements/FormFields'
import { AdresseFields } from './AdresseFields'
import { DossierAF } from 'elements/model/dossierClasses'
import { DossierIdParams } from 'pages/dossier/useDossier'
import { useElementsContext } from 'elements/ElementsForm'

export function EmployeurFields(props: FormContentProps<any>) {
    
    const { dossierId } = useParams<DossierIdParams>()
    const { model } = useElementsContext<DossierAF>()
    
    return (<>
        <MyForm.Grid>
            <EtablissementSelectorField getEtablissements={ getEtablissements } />

            <FormFields model={ model } names={{
                employeur: {
                    nom: true,
                    numeroSiret: true,
                },
            }} />

            <MyForm.Fieldset legend="Adresse d'exécution du contrat">
                <MyForm.Grid>
                    <AdresseFields model={ model } names={{ employeur: {} }} />
                </MyForm.Grid>
            </MyForm.Fieldset>
        </MyForm.Grid>
    
        { !dossierId &&
        <p>La validation de cette étape déclenche la création d'un dossier brouillon, qui pourra être complété ultérieurement.</p>
        }
    </>)
}
