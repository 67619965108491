import { Document, pdf } from '@react-pdf/renderer'
import log from 'loglevel'
import { uploadDocument, uploadDocumentToGed } from 'api/documentAPI'
import { Provider } from 'react-redux'
import store from 'app/store'
import { BaseDossier, DossierModification } from 'slices/dossierCommon'
import { ExportProps, FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { exportDossierChanges } from './ExportDossierChanges'
import { convert } from 'pages/dossier/metadata/DossierMapping'
import Infos from 'pages/dossier/cerfa/Infos'
import { sendAvenantCorrection, sendDossierDocuments } from 'api/dossierAPI'


export async function submitDossier<D extends BaseDossier>(
    metadata: FormMetadata<D>,
    dossier: D,
    setSubmitting: (submitting: boolean) => void,
    setTransmitting: (state: { transmitting: boolean, error?: any }) => void,
    sendDossier: () => Promise<any>) {
    
    if (dossier.traitementEnCours)
        return

    setSubmitting(true)
    setTransmitting({ transmitting: true })

    for (let exportProp of metadata.exportProps) {
        if (exportProp.afterSubmit || (exportProp.ignoredIf && exportProp.ignoredIf(dossier)))
            continue

        try {
            await generateAndUploadDossierDocument(metadata, exportProp, dossier)
            log.debug(`Upload ${exportProp.documentType} ok`)
        }
        catch (e) {
            log.error(`Erreur upload ${exportProp.documentType} pour ${dossier?.NumeroDossier__c}`, e)
            setTransmitting({ transmitting: false, error: (e as any).message ?? "Une erreur inattendue s'est produite" })
            setSubmitting(false)
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
            throw e
        }
    }

    // Transmet le dossier au backend
    return sendDossier()
        .then(dto => {
            return dto
        })
        .catch(e => {
            log.error('Erreur transmission dossier ' + dossier?.NumeroDossier__c, e)
            setSubmitting(false)
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        })
}


export async function postSendDossier<D extends BaseDossier>(metadata: FormMetadata<D>, dossier: D) {
    // Genère les pdfs additionnels
    for (let exportProp of metadata.exportProps) {
        if (exportProp.afterSubmit !== true || (exportProp.ignoredIf && exportProp.ignoredIf(dossier)))
            continue
        
        try {
            await generateAndUploadDossierDocument(metadata, exportProp, dossier)
            log.debug(`Upload ${exportProp.documentType} ok`)

            await sendDossierDocuments(dossier.NumeroDossier__c!)
    
        } catch (e) {
            log.error(`Erreur upload ${exportProp.documentType} pour ${dossier?.NumeroDossier__c}`, e)
            throw new Error(`Erreur de transmission du document ${exportProp.documentType}`)
        }
    }
}

export async function generateAndUploadDossierDocument<D extends BaseDossier>(metadata: FormMetadata<D>, exportProp: ExportProps<D>, dossier: D, ged?: boolean) {
    const document = await exportProp.exportDossier(metadata, dossier)
    const documentType = exportProp.documentType
    const documentName = exportProp.documentName

    const instance = pdf(<Document />)
    instance.updateContainer(<Provider store={store}>{ document }</Provider>)
    const blob = await instance.toBlob()
    if (ged)
        await uploadDocumentToGed(dossier.NumeroDossier__c!, documentType, blob, documentName)
    else
        await uploadDocument(dossier.NumeroDossier__c!, documentType, blob, documentName)
}


export async function submitAvenant<D extends BaseDossier & DossierModification>(
    metadata: FormMetadata<D>,
    dossier: D, 
    setSubmitting: (submitting: boolean) => void, 
    setTransmitting: (state: { transmitting: boolean, error?: any }) => void,
    entrepriseId: string): Promise<any> {

    setSubmitting(true)
    setTransmitting({ transmitting: true })

    const numModificationContrat = (dossier?.numModificationContrat ?? 0)

    let index = 1
    for (let exportProp of metadata.exportProps) {
        if (exportProp.afterSubmit || (exportProp.ignoredIf && exportProp.ignoredIf(dossier)))
            continue

        try {
            await generateAndUploadDossierDocument(metadata, exportProp, dossier, true)
            log.debug(`Upload ${exportProp.documentType} to GED ok`)
        } catch (e) {
            log.error(`Erreur upload ${exportProp.documentType} pour ${dossier?.NumeroDossier__c}`, e)
            setTransmitting({ transmitting: false, error: (e as any).message ?? "Une erreur inattendue s'est produite" })
            setSubmitting(false)
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
            throw e
        }
    }

    try {
        const blobEdit = await generateDossierModificationDocument(metadata, dossier, numModificationContrat)
        await sendAvenantCorrection(dossier.NumeroDossier__c ?? '', dossier.typeModificationContrat ?? '', entrepriseId, numModificationContrat.toString(), 
            `${dossier.typeModificationContrat} ${numModificationContrat}`, blobEdit, 
            `Etat des modifications ${dossier.typeModificationContrat} ${numModificationContrat}.pdf`)

        log.debug('Upload PDF Etat ok')
        setTransmitting({ transmitting: false })
        return dossier?.NumeroDossier__c ?? ''

    } catch (e) {
        log.error(`Erreur ${metadata.title} ${dossier?.NumeroDossier__c}`, e)
        setTransmitting({ transmitting: false, error: (e as any).message ?? "Une erreur inattendue s'est produite" })
        setSubmitting(false)
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
        throw e
    }
}


export async function generateDossierModificationDocument<D extends BaseDossier>(metadata: FormMetadata<D>, dossier: D, numModifContrat : number) {
    const convertedDossier = convert(dossier, metadata)
    const document = await exportDossierChanges(Infos, metadata, convertedDossier, dossier.signature.nom, numModifContrat)

    const instance = pdf(<Document />)
    instance.updateContainer(<Provider store={store}>{ document }</Provider>)
    return await instance.toBlob()
}
