import { SchemaForType } from "@dsid-opcoatlas/yop";
import { ObjectFieldMetadata, fieldMetadataDecorator, ClassOverride, ClassConstructor } from "elements/metadata";
import { DTOProperty } from "elements/mapping";
import { createValidationSchema, applyValidationConstraints } from "elements/validation";


export function object<ParentClass, FieldType extends object>(props: ObjectFieldMetadata<ParentClass, FieldType>) {
    let schema = createValidationSchema(props.type)
    schema = applyValidationConstraints(schema as any, props)

    return fieldMetadataDecorator<ParentClass, FieldType | null>({
        kind: "object",
        type: props.type,
        schema: schema as SchemaForType<FieldType | null>,
        dto: props.dto as DTOProperty<FieldType | null>,
        overrides: props.overrides as ClassOverride<ClassConstructor<FieldType | null>>
    })
}
