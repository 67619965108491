import { config } from 'api/config'
import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { entreprise } from 'api/entreprise'
import { loadDossierAF, saveDossierAF, sendDossierAF } from 'api/dossierAPI'
import { CONTEXTE_SPECIFIQUE_CPF, CONTEXTE_SPECIFIQUE_FNE, CONTEXTE_SPECIFIQUE_AIRBUS_FNE, DISPOSITIF_PDC, PARCOURS_STRATEGIQUES_TPE_PME, CONTEXTE_SPECIFIQUE_AIRBUS, CONTEXTE_SPECIFIQUE_AIRBUS_CPF, CONTEXTE_SPECIFIQUE_FORMATION_COURT, CONTEXTE_SPECIFIQUE_SECTEUR_AUTOMOBILE } from 'api/references'
import { mapDTOToDossierAF, mapDossierAFToDTO, simpleMapping } from 'pages/dossier/metadata/DossierMapping'
import { employeurMetadata } from 'pages/dossier/metadata/EmployeurMetadata'
import { DOCNAME_DPC, DOCTYPE_DPC, ElementContext, IgnoredFieldMetadata, ObjectMetadata, override, registerMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_MODULE_AGORA, SECTION_MODULE_OF, moduleAgoraFields, moduleFields } from 'pages/dossier/metadata/ModulesMetadata'
import { salarieFields } from 'pages/dossier/metadata/SalariesMetadata'
import { signatureMetadata } from 'pages/dossier/metadata/SignatureMetadata'
import { DurationFieldMetadata, floatingDurationMapping } from 'components/metadata/DurationFieldMetadata'
import { ArrayFieldMetadata, ObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { SelectRefFieldMetadata } from 'components/metadata/RefFieldMetadata'
import { DossierEmployeurElements } from 'pages/dossier/elements/DossierEmployeurElements'
import { DossierModulesEditForm } from 'pages/dossier/DossierModulesEditForm'
import { dossierMultiModulesInitializer } from 'pages/dossier/elements/DossierModulesElements'
import { DossierModulesTable } from 'pages/dossier/DossierModulesTable'
import { DossierPiecesElements, piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import { DossierRecapitulatifElements, DossierRecapitulatifPreElements, dossierRecapitulatifInitializer, onDossierRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { DossierSalariesEditForm } from 'pages/dossier/DossierSalariesEditForm'
import { dossierMultiSalariesInitializer } from 'pages/dossier/elements/DossierSalariesElements'
import { DossierSalariesTable } from 'pages/dossier/DossierSalariesTable'
import { exportDossier } from 'pages/dossier/ExportDossierDPC'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import RecapModules from 'pages/dossier/recap/RecapModules'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import RecapSalaries from 'pages/dossier/recap/RecapSalaries'
import { AppType } from 'slices/contextSlice'
import { DossierAF, DossierSalarie, createDossierAF, createDossierModuleAgora, createDossierSalarie, nil } from 'slices/dossierCommon'
import { DossierAPI } from 'slices/dossierSlice'
import ModelePiece from '../../components/ModelePiece'
import { createCerfaDpc } from 'pages/dossier/cerfa/CerfaDpc'
import { durationToString } from 'components/reform/inputs/DurationFloatingField'


function createDossierPDCModule() { return { ...createDossierModuleAgora(),
    niveauVise: nil as string,
    contexteSpecifique: nil as string | null,
}}
type DossierPDCModule = ReturnType<typeof createDossierPDCModule>


export type DossierPDC= DossierAF<DossierSalarie, DossierPDCModule>
function createDossierPDC(): DossierPDC { return {
    ...createDossierAF(DISPOSITIF_PDC),
}}


const api: DossierAPI<DossierPDC> = {
    create: createDossierPDC,
    createModule: createDossierPDCModule,
    createSalarie: createDossierSalarie,
    load: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        return mapDTOToDossierAF(MetadataPDC, dossierDTO)
    },
    loadWithPieces: async (dossierId: string) => {
        const dossierDTO = await loadDossierAF(dossierId)
        if (dossierDTO) {
            dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
        }
        return mapDTOToDossierAF(MetadataPDC, dossierDTO)
    },
    save: async (dossier: DossierPDC) => {
        const dossierDTO = mapDossierAFToDTO(MetadataPDC, dossier)
        const savedDossierDTO = await saveDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataPDC, savedDossierDTO)
    },
    send: async (dossier: DossierPDC) => {
        const dossierDTO = mapDossierAFToDTO(MetadataPDC, dossier)
        const sentDossierDTO = await sendDossierAF(dossierDTO)
        return mapDTOToDossierAF(MetadataPDC, sentDossierDTO)
    },
    postSend: async (dossier: DossierPDC) => {
        await postSendDossier(MetadataPDC, dossier)
    },
}

const PDCModuleMetadata: ObjectMetadata<DossierPDCModule> = {
    formationInterne: override(moduleFields.formationInterne, { section: SECTION_MODULE_OF }),
    cfa: override(moduleFields.cfa, { props: { da: true }, section: SECTION_MODULE_OF }),
    subrogation: override(moduleFields.subrogation, { section: SECTION_MODULE_OF }),
    intitulePrecis: moduleFields.intitulePrecis,
    niveauVise: moduleFields.niveauVise,
    dureeTotale: moduleFields.dureeTotale,
    dateDebutFormation: moduleFields.dateDebutFormation,
    dateFinFormation: moduleFields.dateFinFormation,
    coutPedagogique: moduleFields.coutPedagogique,
    typeFormation: moduleFields.typeFormation,
    contexteSpecifique: SelectRefFieldMetadata({
        props: {
            label: 'Contexte spécifique',
            tooltip: TOOLTIPS.formation.contexteSpecifique,
            options: refs => refs.REF_CONTEXTE_SPECIFIQUE.filter(
                r => r.IsActive__c 
                    && !((r.Id === PARCOURS_STRATEGIQUES_TPE_PME || r.Id === CONTEXTE_SPECIFIQUE_CPF) && (entreprise()?.NumberOfEmployees ?? 0) >= 50) 
                    && !((r.Id === CONTEXTE_SPECIFIQUE_AIRBUS || r.Id === CONTEXTE_SPECIFIQUE_AIRBUS_FNE || r.Id === CONTEXTE_SPECIFIQUE_AIRBUS_CPF) && (entreprise()?.NumberOfEmployees ?? 0) < 50)
                    && !((r.Id === CONTEXTE_SPECIFIQUE_FORMATION_COURT ) && (entreprise()?.NumberOfEmployees ?? 0) >= 300)  
                    && config()?.ContexteSpecifiquePdc.find(c => c.ContexteSpecifiqueId === Number(r.Id))
                        ?.BranchesAutorisees?.includes(entreprise()?.Branche__c ?? '')
            ),
            sorted: true,
        },
        mapping: simpleMapping('ContexteSpecifique__c'),
        visibleIf: (context: ElementContext<DossierPDCModule, DossierPDC>) => context.parent?.formationInterne !== null,
    }),
    commentaire: moduleFields.commentaire,
    formationCertifianteAgora: moduleAgoraFields.formationCertifianteAgora,
    categorieActionAgora: moduleAgoraFields.categorieActionAgora,
    objectifAgora: moduleAgoraFields.objectifAgora,
}


const PDCSalarieMetadata: ObjectMetadata<DossierSalarie> = { 
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: salarieFields.prenom,
    sexe: salarieFields.sexe,
    dateNaissance: salarieFields.dateNaissance,
    nir: salarieFields.nir.required(),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    handicap: salarieFields.handicap,
    statut: salarieFields.statut,
    contrat: salarieFields.contrat,
    niveauFormation: salarieFields.niveauFormation,
    dateEntree: salarieFields.dateEntree,
    categorieAction: IgnoredFieldMetadata(),
    heures: salarieFields.heures,
    heuresFoad: salarieFields.heuresFoad,
    heuresAfest: DurationFieldMetadata({
        props: {
            label: 'Dont heures Afest',
            suffix: 'heure(s)',
            tooltip: TOOLTIPS.salarie.heuresAfest,
        },
        mapping: floatingDurationMapping('DureeAFESTDemandee__c'),
    }).mutate(yop => yop.min(0).max<DossierSalarie>(
        context => context.parent!.heures,
        context => `Le nombre d'heures Afest doit être inférieur ou égal à la durée totale de la formation (${durationToString(context.schema.constraints.max?.value ?? 0)})`
    )),
    coefficient: salarieFields.coefficient,
    autreMetier: salarieFields.autreMetier,
    situationHandicap: IgnoredFieldMetadata(),
    heuresHTT: IgnoredFieldMetadata(),
}

type DossierPDCLight = Omit<DossierPDC, 'pieces'>
const PDCMetadataLight: ObjectMetadata<DossierPDCLight> = {
    employeur: ObjectFieldMetadata(employeurMetadata),
    modules: ArrayFieldMetadata(PDCModuleMetadata),
    salaries: ArrayFieldMetadata(PDCSalarieMetadata),
    signature: ObjectFieldMetadata(signatureMetadata),
}
const PDCMetadata = PDCMetadataLight as ObjectMetadata<DossierPDC>

const dossierPDCSubmitDisabled = (dossier: DossierPDC | null, pending: boolean) => {
    if (piecesSubmitDisabled(dossier, pending)) {
        return { title: 'Pièces', message: 'Il manque une ou plusieurs pièces obligatoires' }
    }
    return false
}

export const MetadataPDC = registerMetadata(AppType.Entreprise, {
    dispositif: DISPOSITIF_PDC,
    title: "Dépôt dossier Plan de développement des compétences",
    pathname: '/nouveau-dossier-pdc',
    steps: [
        { 
            title: 'Employeur',
            sections: [],
            recap: RecapEmployeur,
            yopPath: 'employeur',
            formContent: DossierEmployeurElements,
            options: {
                forceLoading: true,
            },
        },  {
            title: 'Formation',
            sections: [ SECTION_MODULE_AGORA ],
            preFormContent: DossierModulesTable,
            editForm: DossierModulesEditForm,
            recap: RecapModules,
            initializer: dossierMultiModulesInitializer,
            yopPath: 'modules',
            options: {
                noFormHeading: true,
                minElements: { value: 1, message: 'Vous devez ajouter au moins un module de formation' },
                noSaving: true,
                forceValidate: (dossier) => dossier.modules?.length >= 1,
            },
        }, {
            title: 'Salariés',
            sections: [],
            preFormContent: DossierSalariesTable,
            editForm: DossierSalariesEditForm,
            recap: RecapSalaries,
            initializer: dossierMultiSalariesInitializer,
            yopPath: 'salaries',
            options: {
                noFormHeading: true,
                minElements: { value: 1, message: 'Vous devez ajouter au moins un(e) salarié(e)' },
                noSaving: true,
                forceValidate: (dossier) => dossier.salaries?.length >= 1,
            },
        }, {
            title: 'Pièces',
            sections: [],
            recap: RecapPieces,
            formContent: props => <DossierPiecesElements { ...props } piecesObligatoiresMessage={ <PDCPiecesObligatoiresMessage /> }/>,
            submitDisabled: piecesSubmitDisabled,
            options: {
                forceLoading: true,
                withPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: dossierPDCSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
            },
        },
    ],
    api,
    fields: PDCMetadata,
    exportProps: [{
        exportDossier: async (metadata, dossier) => createCerfaDpc(exportDossier(metadata, dossier)),
        documentType: DOCTYPE_DPC,
        documentName: DOCNAME_DPC,
    }],
})

function PDCPiecesObligatoiresMessage() {
    return <ModelePiece modeles={ [{
        label: "Subvention FNE",
        href: "https://www.opco-atlas.fr/sites/default/files/medias/files/2024/07/Demande%20de%20subvention%20FNE%20entreprise%202024.docx",
        when: (dossier?: DossierPDC) => dossier?.modules?.some(module => module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_FNE || module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_AIRBUS_FNE) ?? false,
    }, {
        label: "Attestation sur l'honneur",
        href: "https://www.opco-atlas.fr/sites/default/files/medias/files/2023/11/Attestation%20intercontrat%20Airbus.pdf",
        when: (dossier?: DossierPDC) => dossier?.modules?.some(module => module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_AIRBUS || module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_AIRBUS_FNE || module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_AIRBUS_CPF) ?? false,
    }, {
        label: "Attestation sur l'honneur" ,
        href: "https://www.opco-atlas.fr/sites/default/files/medias/files/2024/10/Atlas_Attestation_Secteur_Automobil.docx",
        when: (dossier?: DossierPDC) => dossier?.modules?.some(module => module?.contexteSpecifique === CONTEXTE_SPECIFIQUE_SECTEUR_AUTOMOBILE) ?? false
    }, {
        label: "Séquence pédagogique",
        href: "https://www.opco-atlas.fr/sites/default/files/medias/files/2023/11/Se%CC%81quence%20pe%CC%81dagogique_PDC.pdf",
        when: (dossier?: DossierPDC) => dossier?.salaries?.some(salarie => !!salarie?.heuresAfest) ?? false,
    }] } />
}
