import { FORMATION_CERTIFIANTE_NON, FORMATION_CERTIFIANTE_OUI, OBJECTIF_GENERAL_FORMATION_QUALIFIANTES_CERTIFIANTES, OBJECTIF_GENERAL_OF_CERTIFICATION, TYPE_COUT_PEDAGOGIQUE, TYPE_FORMATION_INTERNE } from 'api/references'
import { disabled, dto, max, override, required, schema } from "elements/decorators/fragments"
import { overrides, propsOf } from "elements/decorators/overrides"
import { object } from "elements/decorators/objects"
import { array } from "elements/decorators/arrays"
import { radioBooleanField } from "elements/decorators/booleans"
import { dateConverter, dateField } from "elements/decorators/dates"
import { heuresField, currencyField } from "elements/decorators/numbers"
import { textField, codePostalCommuneField, numeroDossierApprentissageField, radioRefField, selectRefField, nameField, firstNameField, emailField, autocompleteNameField, asyncNirField } from "elements/decorators/strings"
import { ClassConstructor } from 'elements/metadata'
import { observer, ObserverCallbackContext } from 'elements/observers'
import { OptionalDA } from 'components/metadata/CFAFieldMetadata'
import { OptionalSiret } from "components/metadata/SiretFieldMetadata"
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { startOfYear, subDays } from 'date-fns'
import { dtoToModel } from 'elements/mapping'
import { UseFormReturn } from '@dsid-opcoatlas/reform'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { getStagiaires } from 'api/commonAPI'
import { OptionalString } from 'components/yop/constraints'
import { FormFieldContext } from 'elements/FormFields'

export class Adresse {
    
    @textField({ label: 'Appartement', max: 38 })
    appartement: string | null = null
    
    @textField({ label: 'Bâtiment', max: 38 })
    batiment: string | null = null
    
    @textField({ label: 'N° et voie', max: 43 })
    adresse: string | null = null
    
    @textField({ label: 'Lieu dit / BP', max: 38 })
    complement: string | null = null
    
    @codePostalCommuneField({ required: true })
    codePostal: string | null = null
    
    @schema(OptionalString)
    @required()
    @max(50)
    commune: string | null = null
}

export class DossierEmployeur extends Adresse {

    @dto("EtablissementAdherentId__c")
    Id: string | null = null

    @dto("EntrepriseAdherentId__c")
    ParentId: string | null = null

    @textField({ label: "Raison sociale", disabled: true, required: true, dto: "NomADH__c" }) 
    nom: string | null = null
    
    @textField({ label: "SIRET", disabled: true, schema: OptionalSiret, required: true, dto: "SiretADH__c" })
    numeroSiret: string | null = null

    @dto("Adresse1ADH__c")
    @disabled(true)
    override appartement: string | null = null

    // @dto("Adresse2ADH__c")
    // @disabled(true)
    @override(textField, { dto: "Adresse2ADH__c", disabled: true })
    override batiment: string | null = null

    @dto("Adresse3ADH__c")
    @disabled(true)
    override adresse: string | null = null
    
    @dto("Adresse4ADH__c")
    @disabled(true)
    override complement: string | null = null

    @dto("CodePostalADH__c")
    @disabled(true)
    override codePostal: string | null = null

    @dto("CommuneADH__c")
    @disabled(true)
    override commune: string | null = null
}

export class DossierSignature {
    
    // exportLabel: 'Par',
    @textField({ label: "Je soussigné", required: true, dto: "NomPrenomSignature__c" })
    nom: string | null = null
    
    @textField({ label: "Fait à", required: true, dto: "LieuSignature__c" })
    lieu: string | null = null
    
    @dateField({ label: "Le", required: true, visible: false, dto: "DateSignature__c" })
    date: Date | null = null
}

export class DossierPiece {

    @dto("Id")
    Id: string | null = null

    @dto("ContentVersionId")
    ContentVersionId: string | null = null

    @dto("type")
    type: string | null = null

    @dto("source")
    source: string | null = null

    @dto("description")
    description: string | null = null

    @dto("info")
    info: string | null = null

    @dto("obligatoire")
    obligatoire: boolean | null = null

    @dto("actif")
    actif: boolean | null = null

    @dto({ path: "dateAjout", ...dateConverter })
    dateAjout: Date | null = null

    @dto("fichier")
    @schema(OptionalString)
    @required(context => context.parent?.obligatoire === true)
    fichier: string | null = null

    @dto("typeFichier")
    typeFichier: string | null = null

    @dto("tailleFicher")
    tailleFicher: number | null = null

    @dto("nom")
    nom: string | null = null

    @dto("uploadedToGed")
    uploadedToGed: boolean | null = null
}

export class BaseDossier {
    
    @dto("DispositifFO__c")
    DispositifFO__c: string | null = null
    
    @dto("Id")
    Id: string | null = null
    
    @dto("IdHeroku")
    IdHeroku: number | null = null
    
    @dto("NumeroDossier__c")
    NumeroDossier__c: string | null = null
    
    @dto("EtatDossierFO__c")
    EtatDossierFO__c: string | null = null
    
    @dto("EnCoursTransmissionSOR__c")
    traitementEnCours: boolean | null = null

    @object({ type: DossierEmployeur, required: true, dto: "." })
    employeur: DossierEmployeur = new DossierEmployeur()

    @array({
        required: true,
        type: DossierPiece,
        elements: { required: true },
        dto: "Pieces"
    })
    pieces: DossierPiece[] = []

    @object({ type: DossierSignature, required: true, dto: "." })
    signature: DossierSignature | null = null
}
    
function cfaFieldDisabled(context: FormFieldContext<DossierCFA, any>) {
    return context.parent?.EtablissementOFId__c !== null
}

function cfaAdresseFieldDisabled(context: FormFieldContext<DossierCFA, any>) {
    return context.parent === null
}

@overrides({
    appartement: { dto: "Adresse1OF__c", disabled: cfaAdresseFieldDisabled },
    batiment: { dto: "Adresse2OF__c", disabled: cfaAdresseFieldDisabled },
    adresse: { dto: "Adresse3OF__c", disabled: cfaAdresseFieldDisabled },
    complement: { dto: "Adresse4OF__c", disabled: cfaAdresseFieldDisabled },
    codePostal: { dto: "CodePostalOF__c", disabled: cfaFieldDisabled },
    commune: { dto: "CommuneOF__c", visible: false },
})
export class DossierCFA extends Adresse {
    
    @dto("EtablissementOFId__c")
    EtablissementOFId__c: string | null = null
    
    @dto("CodeEtablissementOF__c")
    CodeEtablissementOF__c: string | null = null

    @dto("EtatEtablissementOF__c")
    etat: string | null = null
    
    @textField({ label: "Dénomination de l’organisme de formation", disabled: cfaFieldDisabled, required: true, max: 200, dto: "NomOF__c" })
    nom: string | null = null

    @textField({ label: "N° de SIRET de l'établissement de formation responsable", disabled: cfaFieldDisabled, schema: OptionalSiret, dto: "SiretOF__c" })
    siret: string | null = null

    @textField({ label: "N° de déclaration d'activité", tooltip: TOOLTIPS.cfa.numeroDA, disabled: cfaFieldDisabled, schema: OptionalDA, dto: "NdaOF__c" })
    numeroDA: string | null = null
    
    @textField({ label: "N° UAI de l'organisme", disabled: cfaFieldDisabled, schema: OptionalDA, dto: "UaiOF__c" })
    numeroUAI: string | null = null
}

export class TypeCout {
    
    @dto("IdHeroku")
    IdHeroku: number | null = null

    @dto("TypeCout__c")
    TypeCout__c: string | null
    
    @currencyField({ suffix: "€ HT", required: true, max: 300_000, dto: "MontantDemande__c" })
    montant: number | null = null

    constructor(typeCout?: string | null) {
        this.TypeCout__c = typeCout ?? null
    }
}

/*
    if (value) {
        context.setValue(`${props.parentPath}.typeFormation`, TYPE_FORMATION_INTERNE)
        context.setValue(`${props.parentPath}.cfa`, null, SetValueOptions.Untouch)
        context.setValue(`${props.parentPath}.subrogation`, false, SetValueOptions.Untouch)
        context.setValue(`${props.parentPath}.attestationFormationInterne`, null, SetValueOptions.Untouch)
        context.setValue(`${props.parentPath}.adresseRealisationIdentiqueAgora`, true, true)
    }
    else {
        context.untouch(`${props.parentPath}.cfa`)
        context.setValue(`${props.parentPath}.typeFormation`, null, SetValueOptions.Untouch)
        context.setValue(`${props.parentPath}.attestationFormationInterne`, false, SetValueOptions.Untouch)
        context.setValue(`${props.parentPath}.adresseRealisationIdentiqueAgora`, null, SetValueOptions.Untouch | SetValueOptions.Validate)
    }

*/
export class BaseDossierModule {
    
    @dto("IdHeroku")
    IdHeroku: number | null = null

    @radioBooleanField({
        label: "Il s'agit d'un service de formation interne",
        labelYes: "Oui, formation réalisée dans mon entreprise",
        labelNo: "Non, formation réalisée par un organisme tiers",
        required: true,
        dto: { init: (dto: any) => dto.EtablissementOFId__c === null },
    })
    formationInterne = false
    
    @object({
        type: DossierCFA,
        required: context => context.parent?.formationInterne !== true,
        dto: { path: ".", ignore: (dto: any) => dto.EtablissementOFId__c === null },
    })
    @observer("formationInterne", context => context.setValue(context.observedValue === true ? null : context.currentValue, { untouch: true }))
    cfa: DossierCFA | null = null

    @radioBooleanField({
        label: "Paiement direct par l'OPCO à l'organisme de formation (subrogation)",
        required: true,
        disabled: context => context.parent?.formationInterne === true,
        dto: "SubrogationPaiement__c",
    })
    @observer("formationInterne", context => context.setValue(context.observedValue === true ? false : null, { untouch: true }))
    subrogation: boolean | null = null

    @textField({ label: "Intitulé du stage", required: true, max: 255, dto: "IntituleProgramme__c" })
    intitulePrecis: string | null = null

    @heuresField({ label: "Durée", required: true, dto: "NombreHeureFormation__c" })
    dureeTotale: number | null = null

    @dateField({ label: "Date de début de la formation", required: true, dto: "DateDebutFormation__c" })
    dateDebutFormation: Date | null = null

    @dateField({ label: "Date de fin de la formation", required: true, dto: "DateFinFormation__c" })
    dateFinFormation: Date | null = null
    
    @object({
        type: TypeCout,
        overrides: { montant: { label: "Coût pédagogique total" } },
        required:  true,
        dto: { path: "TypesCout", type: "array", select: item => item.TypeCout__c === TYPE_COUT_PEDAGOGIQUE },
    })
    coutPedagogique: TypeCout | null = new TypeCout(TYPE_COUT_PEDAGOGIQUE)

    @radioRefField({
        label: "Type de formation",
        tooltip: TOOLTIPS.formation.typeFormation,
        options: refs => refs.REF_TYPE_FORMATION.filter(ref => ref.IsActive__c && ref.Priorite__c && ref.Categorie__c === 'EXT'),
        visible: context => context.parent?.formationInterne === false,
        required: true,
        dto: "TypeFormation__c",
    })
    @observer("formationInterne", context => context.setValue(context.observedValue === true ? TYPE_FORMATION_INTERNE : null, { untouch: true }))
    typeFormation: string | null = null

    constructor(dossier?: DossierAF) {
    }
}

function AgoraModuleCertification<Module extends ClassConstructor<BaseDossierModule>>(ModuleClass: Module) {
    return class extends ModuleClass {

        @selectRefField({
            label: "Formation certifiante",
            tooltip: TOOLTIPS.formation.formationCertifianteAgora,
            options: refs => refs.REF_FORMATION_CERTIFIANTE.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('1')),
            sorted: true,
            required: true,
            dto: "FormationCertifianteAgora__c",
        })
        formationCertifianteAgora: string | null = null
    
        @selectRefField({
            label: "Catégorie d'action",
            options: (refs, context) => {
                const certification = context.parent?.formationCertifianteAgora
                return refs.REF_OBJECTIF_GENERAL_OF.filter(ref =>
                    ref.IsActiveFO__c &&
                    ref.Categorie__c?.split(',').includes('1') &&
                    ref.Id === OBJECTIF_GENERAL_OF_CERTIFICATION ? certification === FORMATION_CERTIFIANTE_OUI : certification === FORMATION_CERTIFIANTE_NON
                )
            },
            disabled: context => context.parent?.formationCertifianteAgora !== FORMATION_CERTIFIANTE_NON,
            sorted: true,
            required: true,
            dto: "ObjectifGeneralOfAgora__c",
        })
        @observer("formationCertifianteAgora", context => context.setValue(
            context.observedValue === FORMATION_CERTIFIANTE_OUI ? OBJECTIF_GENERAL_OF_CERTIFICATION : null,
            { untouch: true }
        ))
        categorieActionAgora: string | null = null
    
        @selectRefField({
            label: "Objectif",
            options: refs => refs.REF_OBJECTIF_GENERAL_FORMATION.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('1')),
            disabled: context => context.parent?.formationCertifianteAgora !== FORMATION_CERTIFIANTE_NON,
            sorted: true,
            required: true,
            dto: "ObjectifGeneralFormationAgora__c",
        })
        @observer("formationCertifianteAgora", context => context.setValue(
            context.observedValue === FORMATION_CERTIFIANTE_OUI ? OBJECTIF_GENERAL_FORMATION_QUALIFIANTES_CERTIFIANTES : null,
            { untouch: true }
        ))
        objectifAgora: string | null = null
    }
}
  

export class DossierAFModule extends AgoraModuleCertification(BaseDossierModule) {

    @numeroDossierApprentissageField({
        visible: context => context.parent?.formationInterne !== null,
        dto: "NumeroDossierApprentissage__c",
    })
    numeroDossierApprentissage: string | null = null

    constructor(dossier?: DossierAF) {
        super(dossier)
    }
}

export class BasePerson {

    @dto("Particulier__c")
    IdContact: string | null = null
    
    @autocompleteNameField({
        label: "Nom de naissance",
        required: true,
        max: 50,
        disabled: context => context.form?.isAsyncResultPending(`${ context.path.slice(0, -1).toString() }.nir`) === true,
        search: async () => [],
        dto: "NomNaissance__c"
    })
    nomNaissance: string | null = null

    @nameField({ label: "Nom", required: true, max: 50, dto: "Nom__c" })
    nom: string | null = null

    @firstNameField({ label: "Prénom", required: true, max: 50, dto: "Prenom__c" })
    prenom: string | null = null

    @radioRefField({
        label: "Sexe",
        options: refs => refs.REF_SEXE.filter(ref => ref.Id !== '0'),
        disabled: context => context.form?.isAsyncResultPending(`${ context.path.slice(0, -1).toString() }.nir`) === true,
        required: true,
        dto: "Sexe__c"
    })
    sexe: string | null = null

    @dateField({
        label: "Date de naissance",
        required: true,
        min: [new Date(1900, 0, 1), "La date de naissance doit être postérieure au 1er janvier 1900"],
        max: [subDays(startOfYear(Date.now()), 1), "Le stagiaire doit être né avant l'année en cours"],
        dto: "DateNaissance__c"
    })
    dateNaissance: Date | null = null

    @emailField({ label: "Email", required: true, dto: "Email__c" })
    courriel: string | null = null
}

export class SearchedPerson extends BasePerson {

    @dto("Id")
    override IdContact: string | null = null
    
    @dto("LastName")
    override nomNaissance: string | null = null
    
    @dto("LastName")
    override nom: string | null = null
    
    @dto("FirstName")
    override prenom: string | null = null
    
    @dto("Salutation")
    override sexe: string | null = null
    
    @dto("DateNaissance__c")
    override dateNaissance: Date | null = null
    
    @dto("Email")
    override courriel: string | null = null
}

function onNirDependenciesChange(context: ObserverCallbackContext<string | null>) {
    if (context.event.detail.options.validate) {
        const nirPath = context.path.toString()
        const form = context.event.detail.form
        if (!form.isTouched(nirPath) && form.getValue(nirPath)) {
            form.touch(nirPath)
            form.validateAt(nirPath)
        }
    }
}

@overrides({
    nomNaissance: propsOf(autocompleteNameField, {
        search: async (form: UseFormReturn<BaseDossier>, recherche: string, metadata?: FormMetadata<any>) => {
            const idEntreprise = form.values?.employeur?.ParentId
            if (idEntreprise == null)
                return []
            return (await getStagiaires(idEntreprise, recherche)).map(stagiaireDTO =>
                dtoToModel(SearchedPerson, stagiaireDTO, metadata!.api.createSalarie(form.values) as unknown as BaseDossierSalarie)
            )
        }
    })
})
export class BaseDossierSalarie extends BasePerson {
    
    @dto("IdHeroku")
    IdHeroku: number | null = null

    @asyncNirField({ required: true, dto: "Nir__c" })
    @observer("sexe", onNirDependenciesChange)
    @observer("nomNaissance", onNirDependenciesChange)
    @observer("ntt", context => context.observedValue === true && context.event.detail.form.clearErrorsAt(context.path.toString()))
    nir: string | null = null

    @dto("NumeroTechniqueTemporaire")
    ntt: boolean | null = false

    @dto("NirNonVerifieSNGI")
    nirVerifError: boolean | null = false

    @radioBooleanField({
        label: "Travailleur handicapé",
        required: true,
        dto: {
            path: "Handicap__c",
            fromDTO: value => value != null ? value === "1" : null,
            toDTO: value => value != null ? value ? "1" : "2" : null
        },
        message: context => context.value !== true ? undefined : {
            type: "info",
            content: <>
                Pour la formation d’une personne en situation de handicap, l’Agefiph peut vous accompagner. Consultez les {" "}
                <a href="https://www.agefiph.fr/sites/default/files/medias/fichiers/2023-02/Agefiph_Metodia_Janvier-2023.pdf" target="blank" rel="noopener, noreferrer">
                    aides mobilisables
                </a>.
            </>
        }
    })
    handicap: boolean | null = null

    situationHandicap: string | null = null

    constructor(dossier?: DossierAF) {
        super()
    }
}

export class DossierAFSalarie extends BaseDossierSalarie {

    @selectRefField({
        label: "Statut",
        options: refs => refs.REF_TYPE_CSP.filter(ref => ref.IsActive__c && ref.Priorite__c),
        required: true,
        sorted: true,
        dto: "CSP__c",
    })
    statut: string | null = null


    @selectRefField({
        label: "Contrat",
        options: refs => refs.REF_NATURE_CONTRAT.filter(ref => ref.IsActive__c && ref.Categorie__c?.split(',').includes('1') && ref.Priorite__c),
        required: true,
        sorted: true,
        dto: "NatureContrat__c",
    })
    contrat: string | null = null

    @selectRefField({
        label: "Niveau de formation",
        options: refs => refs.REF_NIVEAU_FORMATION.filter(ref => ref.IsActive__c && ref.Priorite__c),
        required: true,
        sorted: true,
        dto: "NiveauFormation__c",
    })
    niveauFormation: string | null = null

    @dateField({
        label: "Date d'entrée dans l'entreprise",
        required: true,
        max: [
            context => {
                const dates = context.getRoot<DossierAF>()?.modules?.map(module => module.dateDebutFormation)?.filter(d => d != null)
                return dates && dates.length > 0 ? dates.reduce((d1, d2) => d1!.getTime() > d2!.getTime() ? d2 : d1) : null
            },
            "La date d'entrée dans l'entreprise doit être antérieure à la date de début de formation le ${max}"
        ],
        dto: "DateEntree__c"
    })
    dateEntree: Date | null = null

    @selectRefField({
        label: "Catégorie d'action",
        options: refs => refs.REF_CATEGORIE_ACTION.filter(ref => ref.IsActive__c && ref.Priorite__c),
        required: true,
        sorted: true,
        dto: "CategorieAction__c",
    })
    categorieAction: string | null = null

    @heuresField({
        label: "Heures de formation du stagiaire",
        required: true,
        min: 0,
        max: [
            context => context.getRoot<DossierAF>()?.modules?.reduce((heures, module) => heures + (module.dureeTotale ?? 0), 0),
            "Le nombre d'heures doit être inférieur ou égal à la durée totale des formations (${max}h)"
        ],
        dto: "DureeHeure__c"
    })
    heures: number | null = null

    @heuresField({
        label: "Dont heures de formation à distance",
        tooltip: TOOLTIPS.salarie.heuresFoad,
        required: true,
        min: 0,
        max: [
            context => context.parent?.heures,
            "Le nombre d'heures de formation à distance doit être inférieur ou égal à la durée totale de la formation (${max}h)"
        ],
        dto: "DureeFoadHeure__c"
    })
    heuresFoad: number | null = null

    @textField({ label: "Coefficient", max: 10, dto: "CoefficientHierarchique__c" })
    coefficient: string | null = null

    @textField({ label: "Métier", max: 50, dto: "Metier__c" })
    autreMetier: string | null = null

    constructor(dossier?: DossierAF) {
        super(dossier)
    }
}

export class DossierAF extends BaseDossier {

    @dto("Intitule__c")
    @observer("modules[0].intitulePrecis", context => context.setValue(context.observedValue as string | null))
    intitule: string | null = null

    @array({
        required: true,
        min: 1,
        max: 1,
        type: DossierAFModule,
        elements: { required: true },
        dto: "Modules"
    })
    modules: DossierAFModule[] = []

    @array({
        required: true,
        min: [1, "Vous devez ajouter au moins un salarié"],
        max: [50, "Vous ne pouvez pas ajouter plus de 50 salariés"],
        type: DossierAFSalarie,
        elements: { required: true },
        dto: "Stagiaires"
    })
    salaries: DossierAFSalarie[] = []
}
