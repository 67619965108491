import { ArrayFieldMetadata, fieldMetadataDecorator, ClassOverride, ClassConstructor } from "elements/metadata";
import { DTOProperty } from "elements/mapping";
import { createValidationSchema, applyValidationConstraints } from "elements/validation";

export function array<ParentClass, ElementType extends object>(props: ArrayFieldMetadata<ParentClass, ElementType>) {
    const { elements, type, ...arrayValidation } = props

    let schema = createValidationSchema(type, elements ?? {})
    schema = applyValidationConstraints(schema as any, arrayValidation)

    return fieldMetadataDecorator<ParentClass, (ElementType | null)[] | null>({
        kind: "array",
        type,
        schema,
        dto: props.dto as DTOProperty<(ElementType | null)[] | null>,
        overrides: props.overrides as ClassOverride<ClassConstructor<ElementType | null>>
    })
}
