import RadioBooleanField from "components/reform/inputs/RadioBooleanField";
import { OptionalBoolean } from "components/yop/constraints";
import { BooleanFieldMetadata, fieldMetadataDecorator } from "elements/metadata";
import { FormContextProperty } from "elements/FormFields";


export function radioBooleanField<ParentClass>(props?: BooleanFieldMetadata<ParentClass> & {
    labelYes?: FormContextProperty<ParentClass, boolean | null, string | JSX.Element>
    labelNo?: FormContextProperty<ParentClass, boolean | null, string | JSX.Element>
}) {
    return fieldMetadataDecorator<ParentClass, boolean | null>({
        kind: "boolean",
        input: RadioBooleanField,
        schema: OptionalBoolean,
        ...props
    })
}
