import { isEqual, cloneDeep } from 'lodash-es'
import log from 'loglevel'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Form, UseFormReturn, useForm } from '@dsid-opcoatlas/reform'
import { unwrapResult } from '@reduxjs/toolkit'
import { ETAT_DOSSIER_BROUILLON, TYPE_MODIFICATION_AVENANT, TYPE_MODIFICATION_CORRECTION } from 'api/references'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasColumns, AtlasFlex, AtlasHeading, AtlasInfo, AtlasLink, MyForm } from 'atlas-ds'
import { useLastLocation } from 'components/lastlocation'
import PageHeader from "components/PageHeader"
import { PointsReserve } from 'components/PointsReserve'
import Button from 'components/reform/Button'
import { useScrollToTop } from 'components/scroll/useScrollToTop'
import { convert, revert } from 'pages/dossier/metadata/DossierMapping'
import { FormMetadata, useYopSchema } from 'pages/dossier/metadata/FormMetadata'
import { BaseDossierComponentProps, DossierIdParams, useDossier } from 'pages/dossier/useDossier'
import { BaseDossier, DossierModification, DossierPointReserve } from 'slices/dossierCommon'
import { dossierSliceActions } from 'slices/dossierSlice'
import AskCancelModal from './AskCancelModal'
import AskSaveModal from './AskSaveModal'
import DossierSide from './DossierSide'
import SendModal from './SendModal'
import { useDossierNavigation } from './useDossierNavigation'


export function DossierForm<D extends BaseDossier | DossierModification>(props: BaseDossierComponentProps<D>) {

    const history = useHistory()
    const lastLocation = useLastLocation()
    const dispatch = useAppDispatch()
    const entreprise = useSelector((state: RootState) => state.contextState.entreprise)
    const { dossierId } = useParams<DossierIdParams>()
    const [navModal, setNavModal] = useState({ show: false, next: () => { } })
    const [cancelModel, setCancelModal] = useState(false)
    const [forceSpinning, setForceSpinning] = useState(false)
    const [checking, setChecking] = useState(false)
    const [saved, setSaved] = useState(0)

    const [dossierInitialConverted, setDossierInitialConverted] = useState<D | null>(null)
    const [pointsReserve, setPointsReserve] = useState<DossierPointReserve[] | false | null>(null)
    
    const { profil } = useSelector((state: RootState) => state.profilState)

    const navigationGuard = (next: () => void) => {
        const dirty = (props.step?.isFormDirty ? props.step?.isFormDirty(form.values!, dossierInitialConverted!) : form.isDirty()) ?? false
        if (dirty)
            setNavModal({ show: true, next })
        return dirty
    }

    const { etape, step, steps, navigatePrev, navigateNext, navigateTo } = useDossierNavigation(props.metadata, navigationGuard)

    const { loading, saving, transmitting, dossier: initialValues, dossierInitial, error, errorLink } = useDossier<D>(
        props.metadata.api,
        dossierId,
        step.options?.forceLoading,
        step.options?.withPieces,
    )
    useEffect(() => {
        setDossierInitialConverted(dossierInitial ? convert(cloneDeep(dossierInitial), props.metadata) : null)
    }, [dossierInitial, props.metadata])
    
    const validationSchema = useYopSchema(props.metadata, step, initialValues)

    const initialValuesConverter = (dossier: D) => {
        if (step?.initializer)
            dossier = step.initializer(dossier, props.metadata, profil, step)
        return convert(dossier, props.metadata)
    }

    const checkPointsReserve = (dossier: D, navigate: boolean) => {
        const previousPoints = (pointsReserve !== false ? pointsReserve?.map(r => r.id) ?? [] : [])
        const savedPointsReserve = (dossier as any)?.pointsReserve ?? null
        if (pointsReserve && savedPointsReserve === null)
            setPointsReserve(false)     // Points de réserve résolus
        else
            setPointsReserve(savedPointsReserve)

        const newPoints = (savedPointsReserve?.map((r: any) => r.id) ?? [])
        const hasNewPoints = !newPoints.every((r: any) => previousPoints.includes(r))
        const hasPointsResolved = !previousPoints.every((r: any) => newPoints.includes(r))

        if (navigate && (etape === 1 || (!hasNewPoints && !hasPointsResolved))) {
            navigateNext(dossier.NumeroDossier__c!)
            return true
        }
        else {
            global.window.document.getElementsByName('pointsReserve')?.[0]?.scrollIntoView({ block: 'center' })
            return false
        }
    }

    const doSubmit = (context: UseFormReturn<D>, dossier: D) => {
        if (step.options?.noSaving === true) {
            setForceSpinning(true)
            setTimeout(() => {
                navigateNext(dossier.NumeroDossier__c!)
            }, 200)
            return
        }

        dispatch(dossierSliceActions.save({ api: props.metadata.api as any, dossier, local: step.options?.localSave })).then(unwrapResult)
            .then(savedDossier => {
                if (!checkPointsReserve(savedDossier as D, true))
                    context.setSubmitting(false)
                setSaved(saved + 1)
            })
            .catch((error: any) => {
                log.error('Erreur submit dossier', error)
                context.setSubmitting(false)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            })
    }

    const onPointsReserveClicked = () => {
        if (Array.isArray(step.yopPath)) {
            let render = false
            step.yopPath.forEach(path => render ||= form.validateAt(path as string, false))
            if (render)
                form.renderForm()
        } else {
            form.validateAt(step.yopPath as string ?? '', false) && form.renderForm()
        }
        if (form.getErrorCount() > 0) {
            const firstErrorKey = form.getErrorPaths()?.[0]
            const element = global.window.document.getElementById(firstErrorKey)
            if (element) {
                setTimeout(() => {
                    element.focus();
                    element.scrollIntoView({ block: 'center' });
                }, 250)
            }
            return
        }

        const dossier = revert(form.values!, props.metadata!)
        form.setSubmitting(true)
        setChecking(true)

        dispatch(dossierSliceActions.save({ api: props.metadata.api as any, dossier })).then(unwrapResult)
            .then(savedDossier => {
                if (!checkPointsReserve(savedDossier as D, false)) {
                    form.setSubmitting(false)
                    setChecking(false)
                }
                setSaved(saved + 1)
            })
            .catch(error => {
                log.error("Erreur save dossier points de réserve", error)
                form.setSubmitting(false)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                setChecking(false)
            })
    }

    useScrollToTop(undefined, () => !checking)

    const form = useForm<D>({
        initialValues,
        initialValuesConverter,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<D>) => {
            const dossier = revert(context.values!, props.metadata!)
            if (step.submitDisabled && step.submitDisabled(dossier, false, dossierInitialConverted, entreprise)) {
                // Force block submit
                return
            }
            if (step.onSubmit) {
                step.onSubmit(context, props.metadata, dossier, dispatch, entreprise).then((next: boolean) => {
                    if (next)
                        doSubmit(context, dossier)
                })
            } else {
                doSubmit(context, dossier)
            }
        },
        resetConfiguration: {
            deps: [initialValues],
        },
    })

    const submitDisabledMessage = step.submitDisabled 
        ? step.submitDisabled(form.values!, loading || saving, dossierInitialConverted, entreprise) : false
    
    const redirectToDetailDossierContrat = (metadata: FormMetadata<D>, dossier: D | null | undefined) => {
        // Modification + pas de type => redirige vers detail dossier
        if (metadata.api.isEdit && dossier?.NumeroDossier__c && !(dossier as DossierModification)?.typeModificationContrat)
            return true
        // Création + pas un brouillon => redirige vers detail dossier
        if (!metadata.api.isEdit && dossier?.EtatDossierFO__c && dossier.EtatDossierFO__c !== ETAT_DOSSIER_BROUILLON)
            return true
        return false
    }

    useEffect(() => {
        if (redirectToDetailDossierContrat(props.metadata, form.values))
            history.push(`/detail-dossier-contrat/${form.values?.Id}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectToDetailDossierContrat(props.metadata, form.values)])

    useEffect(() => {
        if (dossierId && initialValues?.traitementEnCours === true) {
            // Execute le polling du statut pendant la transmission du dossier
            dispatch(dossierSliceActions.checkStatus({ api: props.metadata.api as any, dossier: form.values! }))
                .then(unwrapResult)
                .then(numDossierSaved => {
                    // Terminé, le dossier est déjà envoyé
                    navigateTo(numDossierSaved, steps.length + 1, true)
                })
                .catch(e => {
                    log.error('Erreur transmission dossier ' + dossierId, e)
                    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
                    form.setSubmitting(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dossierId, initialValues?.traitementEnCours])

    useEffect(() => {
        // const readPointsReserveIds = pointsReserve?.map(p => p.id)
        const pointsReserve = (form.values as any)?.pointsReserve
        if (pointsReserve && (pointsReserve?.length ?? 0) > 0) {
            setPointsReserve(pointsReserve ?? null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(form.values as any)?.pointsReserve])

    useEffect(() => {
        if (form.values?.NumeroDossier__c && step.options?.forceValidate?.(form.values)) {
            if (Array.isArray(step.yopPath)) {
                let render = false
                step.yopPath.forEach(path => render ||= form.validateAt(path as string, false))
                if (render)
                    form.renderForm()
            } else {
                form.validateAt(step.yopPath as string ?? '', false) && form.renderForm()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values?.NumeroDossier__c])

    function onAnnuler() {
        if (lastLocation && lastLocation?.pathname !== '/authentication')
            history.goBack()
        else
            history.push('/mes-dossiers-contrats')
    }

    const PreFormContent = step.preFormContent ?? (() => null)
    const FormContent = step.formContent ?? (() => null)

    const step1 = props.metadata.steps.findIndex(s => !s.options?.step0) + 1

    const buttons = step.formButtons ? step.formButtons() : (() => {
        const spinning = () => (forceSpinning || (saving && step.options?.noSaving !== true && !checking))
        if (etape === step1) {
            if (dossierId) {
                return <>
                    {props.metadata.api.isEdit && <Button level={2} onClick={() => setCancelModal(true)}>Retour au dossier</Button>}
                    <Button submit={true} spinner={{ spinning: saving }} disabled={form.values?.employeur?.Id == null}>Suivant</Button>
                </>
            }

            return <>
                <Button level={2} onClick={onAnnuler}>Annuler</Button>
                <Button submit
                    spinner={{ spinning: spinning() }}
                    disabled={!form.values?.employeur?.Id}
                >Initier un dossier</Button>
            </>
        }
        if (etape === steps.length) {

            const submitButtonLabel = (() => {
                if (props.metadata.api.isEdit) {

                  if ((form.values as any)?.typeModificationContrat === TYPE_MODIFICATION_CORRECTION) {
                    return 'Confirmer la correction';
                  }
                  if ((form.values as any)?.typeModificationContrat === TYPE_MODIFICATION_AVENANT) {
                    return 'Confirmer l\'avenant';
                  }
                } 
                return (form.values as any)?.pointsReserve
                  ? 'Confirmer et terminer malgré les informations à vérifier'
                  : 'Confirmer et terminer';
              })()

            return <>
                <Button level={2} onClick={() => navigatePrev(form.values!.NumeroDossier__c!)}>Précédent</Button>
                <Button submit
                    spinner={{ spinning: spinning() }}
                    disabled={!!submitDisabledMessage}
                >{submitButtonLabel}</Button>
            </>
        }
        const nextButtonLabel = (form.values as any)?.pointsReserve ? 'Poursuivre' : 'Suivant'
        return <>
            {!step.options?.step0 && <Button level={2} onClick={() => navigatePrev(dossierId!)}>Précédent</Button>}
            <Button submit
                spinner={{ spinning: spinning() }}
                disabled={!!submitDisabledMessage}
            >{nextButtonLabel}</Button>
        </>
    })()

    const title = typeof props.metadata.title === 'function' ? props.metadata.title(form.values) : props.metadata.title

    // N'affiche pas les données du dossier dans le formulaire si on redirige vers le détail du dossier
    if (redirectToDetailDossierContrat(props.metadata, form.values))
        return null

    return (
        <>
            <PageHeader>
                {`${title} ${dossierId ? `<br />${dossierId}` : ''}`}
            </PageHeader>

            <AtlasFlex column gap="l">
                {!loading && props.errorMessage && <AtlasInfo type="error" title="Erreur">
                    {props.errorMessage}
                </AtlasInfo>}

                {!loading && error && <AtlasInfo
                    type="error"
                    title="Erreur sur ce dossier"
                    link={errorLink ? <AtlasLink icon="arrow-right" level={3} href={errorLink.link}>{errorLink.label}</AtlasLink> : undefined}
                >{error}</AtlasInfo>}

                <AtlasColumns>
                    <AtlasFlex gap="xxl" column>
                        <PreFormContent metadata={props.metadata} navigationGuard={navigationGuard} 
                            dossier={form.values} initialDossier={dossierInitial} 
                            form={form} step={step} loading={loading} saving={saving} />

                        <Form context={form} autoComplete="off" noValidate disabled={loading}>

                            {(!step.options?.noFormHeading && step?.title) &&
                                <AtlasHeading tag="h2" size="m">{step.title}</AtlasHeading>
                            }

                            <MyForm pending={loading}>
                                <FormContent metadata={props.metadata} step={step} loading={loading} />

                                {!step.options?.excludePointsReserve && !props.metadata.api.isEdit &&
                                    <PointsReserve pointsReserve={pointsReserve}
                                        dirty={form.isDirty ? form.isDirty() : false} rubrique={step.rubrique}
                                        spinning={checking} disabled={loading || saving}
                                        onClick={onPointsReserveClicked}
                                    />}

                                { submitDisabledMessage && submitDisabledMessage !== true && 
                                <AtlasInfo title={ submitDisabledMessage.title } type="error">
                                    { submitDisabledMessage.message }
                                </AtlasInfo> }

                                {!loading && <MyForm.Actions>
                                    {buttons}
                                </MyForm.Actions>}
                            </MyForm>
                        </Form>
                    </AtlasFlex>

                    <DossierSide etapeCourante={etape} dossier={form.values} navigationGuard={navigationGuard} metadata={props.metadata} />
                </AtlasColumns>
            </AtlasFlex>

            {transmitting &&
                <SendModal pending={true} />
            }

            {navModal?.show &&
                <AskSaveModal close={() => setNavModal({ show: false, next: () => { } })} next={navModal.next} />
            }

            {cancelModel && <AskCancelModal dossierId={initialValues?.Id} close={() => setCancelModal(false)} />}
        </>
    )
} 
