import { UseFormReturn, useFormContext } from '@dsid-opcoatlas/reform'
import { MyForm } from 'atlas-ds'
import { get, toPath, isEqual, cloneDeep } from 'lodash'
import React from 'react'
import { BaseDossier } from 'slices/dossierCommon'
import { HolderFieldMetadata } from '../../../components/metadata/HolderFieldMetadata'
import { BaseFormMetadata, ElementContext, FieldMetadata, FieldMetadataDependency, FieldMetadataElements, FormMetadata } from './FormMetadata'
import { PieceDTO } from 'api/documentAPI'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE, DISPOSITIF_CONTRAT_PROFESSIONNALISATION } from 'api/references'

type ELEMENT_TYPE = keyof FieldMetadataElements

interface FormElementsProps<D extends BaseDossier = BaseDossier> {
    dossier?: D
    metadata: FormMetadata<D>
    path?: string
    index?: number
    section?: string
    type?: ELEMENT_TYPE
    disabled?: boolean
}

interface SimpleFormElementsProps<T extends object> {
    rootObject?: T
    metadata: BaseFormMetadata<T>
    path?: string
    index?: number
    section?: string
    type?: ELEMENT_TYPE
    disabled?: boolean
}

export function FormElements<D extends BaseDossier>(props: FormElementsProps<D>) {
    const context = useFormContext<D>()
    const dossier = props.dossier ?? context.values
    const editMode = !!props.metadata.api.isEdit

    return <>{formElements(props.metadata, editMode, dossier!, props.path, props.index, props.section, props.type, props.disabled, context)}</>
}

export function SimpleFormElements<T extends object>(props: SimpleFormElementsProps<T>) {
    const context = useFormContext<T>()
    const rootObject = props.rootObject ?? context.values

    return <>{formElements<T>(props.metadata, false, rootObject!, props.path, props.index, props.section, props.type, props.disabled, context)}</>
}

function addDependentElement<T extends object>(
    dependentElements: Map<string, React.ReactElement>,
    dependency: FieldMetadataDependency,
    dossier: T,
    form: UseFormReturn<T> | undefined,
    condition?: (context: ElementContext<T, any>) => boolean
) {
    const dependencyComponent = (dependency.field as any)["input"]
    if (dependencyComponent != null) {
        const dependencyPathElements = toPath(dependency.path)
        if (dependencyPathElements.length === 2) {
            const name = dependency.path
            const parentPath = dependencyPathElements[0]
            const context: ElementContext<T, any> = {
                name: name,
                key: name,
                root: dossier,
                parentPath: parentPath,
                parent: get(dossier, parentPath),
                index: undefined,
                path: name,
                form,
            }

            if (context.parent != null && (condition?.(context) ?? true)) {
                let dependentLabel = (
                    typeof dependency.field.props?.label === 'function' ?
                        dependency.field.props.label(context) :
                        dependency.field.props?.label
                ) ?? ''
                if (typeof dependentLabel === 'string')
                    dependentLabel = `${dependency.stepName} - ${dependentLabel}`
                else
                    dependentLabel = <>{`${dependency.stepName} - `}{dependentLabel}</>

                const elementProps = {
                    ...context,
                    ...dependency.field.props,
                    label: dependentLabel,
                }

                if (typeof dependency.field.props?.disabled === 'function')
                    elementProps.disabled = dependency.field.props!.disabled(context, form)

                if (typeof dependency.field.props?.infoMessage === 'function')
                    elementProps.infoMessage = dependency.field.props!.infoMessage(context, form)
                if (typeof dependency.field.props?.warningMessage === 'function')
                    elementProps.warningMessage = dependency.field.props!.warningMessage(context, form)
                if (typeof dependency.field.props?.successMessage === 'function')
                    elementProps.successMessage = dependency.field.props!.successMessage(context, form)

                dependentElements.set(name, React.createElement(dependencyComponent, elementProps))
            }
        }
    }
}

function getDependentElements<T extends object>(field: FieldMetadata<any>, dossier: T, form: UseFormReturn<T> | undefined) {
    const visibleElements = new Map<string, React.ReactElement>()

    // visibleIf
    field.dependencies?.forEach(dependency => {
        addDependentElement(visibleElements, dependency, dossier, form, context => {
            if (dependency.field.visibleIf?.(context) === false)
                return false

            const touched = form?.isTouched(context.name) ?? false
            if (!touched)
                form?.validateAt(context.name, false)
            return touched || form?.getError(context.name) != null
        })
    })

    // visibleWith
    field.dependencies?.forEach(dependency => {
        if (dependency.visibleWith != null && !visibleElements.has(dependency.path)) {
            const visibleWith = Array.isArray(dependency.visibleWith) ? dependency.visibleWith : [dependency.visibleWith]
            if (visibleWith.some(name => visibleElements.has(name))) {
                addDependentElement(visibleElements, dependency, dossier, form, context => {
                    return dependency.field.visibleIf?.(context) !== false
                })
            }
        }
    })

    return field.dependencies?.map(dependency => visibleElements.get(dependency.path)).filter(dependency => dependency != null) ?? []
}

export function formElements<T extends object>(
    metadata: BaseFormMetadata<T>,
    editMode: boolean,
    rootObject: T,
    path: string | undefined,
    index?: number,
    section?: string,
    type: ELEMENT_TYPE = 'input',
    disabled?: boolean,
    form?: UseFormReturn<T>,
    props?: any) {

    const fields = path
        ? (get(metadata.fields, path) as HolderFieldMetadata<T>).fields as { [x: string]: FieldMetadata<any> }
        : metadata.fields as { [x: string]: FieldMetadata<any> }
    const parentPath = path ? ((index ?? -1) >= 0 ? `${path}[${index}]` : path) : undefined
    const parent = parentPath ? get(rootObject, parentPath) : rootObject

    const elements = []
    for (const [fieldName, field] of Object.entries(fields)) {
        if (!field || (section !== undefined && (field.section ?? '') !== (section ?? ''))) {
            continue
        }

        const component = (field as any)[type]
        if (component) {
            const name = (parentPath ? `${parentPath}.${fieldName}` : fieldName)
            const elementContext: ElementContext<T, any> = {
                name: name,
                key: name,
                root: rootObject,
                parentPath,
                parent,
                index,
                path: name,
                metadata,
                editMode,
                form,
            }
            if (!field.visibleIf || field.visibleIf(elementContext)) {
                const elementProps = {
                    ...elementContext,
                    ...field.props,
                    ...props,
                }

                if (typeof field.props?.label === 'function')
                    elementProps.label = field.props.label(elementContext)

                if (disabled)
                    elementProps.disabled = true
                else if (typeof field.props?.disabled === 'function')
                    elementProps.disabled = field.props!.disabled(elementContext, form)

                if (typeof field.props?.infoMessage === 'function')
                    elementProps.infoMessage = field.props!.infoMessage(elementContext, form)
                if (typeof field.props?.warningMessage === 'function')
                    elementProps.warningMessage = field.props!.warningMessage(elementContext, form)
                if (typeof field.props?.successMessage === 'function')
                    elementProps.successMessage = field.props!.successMessage(elementContext, form)

                if (type !== "input")
                    elementProps.value = parent?.[fieldName]

                const element = React.createElement(component, elementProps)

                if (elementProps.full || elementProps.alone) {
                    elements.push(<MyForm.Field key={name} size={elementProps.full ? "full" : undefined} alone={elementProps.alone}>
                        {element}
                    </MyForm.Field>)
                } else {
                    elements.push(element)
                }

                if (type === "input") {
                    const dependentElements = getDependentElements(field, rootObject, form)
                    if (dependentElements.length > 0) {
                        elements.push(
                            <MyForm.Dependencies autofocus key={name + ".dependencies"}>
                                <MyForm.Grid>{dependentElements}</MyForm.Grid>
                            </MyForm.Dependencies>
                        )
                    }
                }
                else if (field.preserveSpaceWhenHidden) {
                    elements.push(<MyForm.FieldPlaceholder key={name + ".placeholder"} />)
                }
            }
        }
    }
    return elements
}

function getObjectDiff(obj1: any, obj2: any) {
    if (!obj1 || !obj2 || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return [obj1, obj2]
    }
    const diffKeys = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key)
        } else if (isEqual2(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key)
            result.splice(resultKeyIndex, 1)
        }
        return result
    }, Object.keys(obj2))

    const diffObj1 = {} as any
    const diffObj2 = {} as any
    diffKeys.forEach((key: string) => {
        diffObj1[key] = obj1[key]
        diffObj2[key] = obj2[key]
    })
    return [diffObj1, diffObj2]
}

const isEqual2 = (val1: any, val2: any) => {
    return isEqual(val1, val2) || (val1 === false && val2 == null) || (val2 === false && val1 == null)
}



export function formDiffElements<T extends object>(
    metadata: BaseFormMetadata<T>,
    currentRootObject: T,
    initialRootObject: T,
    path: string | undefined,
    index?: number,
    section?: string | string[],
    dispositifFO?: string,
    type: ELEMENT_TYPE = 'recap',
    form?: UseFormReturn<T>,
    props?: any) {

    const fields = path
        ? (get(metadata.fields, path) as HolderFieldMetadata<T>).fields as { [x: string]: FieldMetadata<any> }
        : metadata.fields as { [x: string]: FieldMetadata<any> }
    const parentPath = path ? ((index ?? -1) >= 0 ? `${path}[${index}]` : path) : undefined
    const currentParent = parentPath ? get(currentRootObject, parentPath) : currentRootObject
    const initialParent = parentPath ? get(initialRootObject, parentPath) : initialRootObject
    const sections = section ? (Array.isArray(section) ? section : [section]) : undefined

    const elements = [] as JSX.Element[]
    for (const [fieldName, field] of Object.entries(fields)) {
        if (!field || (sections != null && !sections.includes(field.section ?? ''))) {
            continue
        }
        const name = (parentPath ? `${parentPath}.${fieldName}` : fieldName)
        const elementContext: ElementContext<T, any> = {
            name: name,
            key: name,
            root: currentRootObject,
            parentPath,
            parent: currentParent,
            index,
            path: name,
            metadata,
            editMode: true,
            form,
        }
        if (field.visibleIf && !field.visibleIf(elementContext) && name !== 'modulePrincipal.cfa' && name !== 'formation.organismePrincipalFormationAgora') {
            continue
        }

        let initialValue = (initialParent as any)?.[fieldName] ?? null
        if (field.convert)
            initialValue = field.convert(cloneDeep(initialValue))
        const currentValue = (currentParent as any)?.[fieldName] ?? null
        if ((field as any)[type] && !isEqual2(currentValue, initialValue)) {
            let [initialDiffValue, currentDiffValue] = field.diff
                ? field.diff(initialValue, currentValue)
                : getObjectDiff(initialValue, currentValue)
            const Component = (field as any)[type]

            let label = field.props?.exportLabel ?? field.props?.label ?? name
            if (typeof label === 'function') {
                label = label(elementContext)
            }

            const rule = fieldRules.find(
                r => r.path === path && r.dispositifFO === dispositifFO && r.fieldName === name
            )
            if (rule && !initialDiffValue) {
                initialDiffValue = rule.apply(currentDiffValue)
            }

            elements.push(
                <Component {...elementContext} key={elementContext.key}  {...field.props} value={currentDiffValue} initialValue={initialDiffValue} />
            )
        }
    }

    return elements


}

const fieldRules = [
    {
        path: 'modulePrincipal',
        dispositifFO: DISPOSITIF_CONTRAT_PROFESSIONNALISATION,
        fieldName: 'modulePrincipal.cfa',
        apply: (currentDiffValue: any) => cloneWithAllKeys(currentDiffValue)
    },
    {
        path: 'formation',
        dispositifFO: DISPOSITIF_CONTRAT_APPRENTISSAGE,
        fieldName: 'formation.organismePrincipalFormationAgora', 
        apply: (currentDiffValue: any) => cloneWithAllKeys(currentDiffValue)
    },
]

function cloneWithAllKeys(object: any) {

    const newObject = {} as any

    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            newObject[key] = null
        }
    }

    return newObject
}

export function mergeCommuneWithCodePostal(elements: JSX.Element[]): JSX.Element[] {

    const codePostalIndex = elements.findIndex(el => el.key === "employeur.codePostal")
    const communeIndex = elements.findIndex(el => el.key === "employeur.commune")

    if (codePostalIndex !== -1 && communeIndex !== -1 &&
        elements[codePostalIndex]?.props?.initialValue &&
        elements[codePostalIndex]?.props?.value &&
        elements[communeIndex]?.props?.initialValue &&
        elements[communeIndex]?.props?.value ) {

        const codePostal = elements[codePostalIndex]
        const commune = elements[communeIndex]

      
        const updatedCodePostal = {
            ...codePostal,
            props: {
                ...codePostal.props,
                initialValue: `${codePostal.props.initialValue} ${commune.props.initialValue}`,
                value: `${codePostal.props.value} ${commune.props.value}`,
            },
        };

        elements[codePostalIndex] = updatedCodePostal

        elements.splice(communeIndex, 1)
    }

    return elements;
}



export function piecesDiffElements(currentPieces: PieceDTO[], initialPieces: PieceDTO[]) {

    const added: PieceDTO[] = [];
    const removed: PieceDTO[] = [];

    // Trouver les éléments qui sont dans currentPieces mais pas dans initialPieces
    currentPieces.forEach(currentPiece => {
        const initialPiece = initialPieces.find(piece => piece.Id === currentPiece.Id)
        if (!initialPiece) {
            added.push({ ...currentPiece, action: "Ajout" })
        }
    })

    // Trouver les éléments qui sont dans initialPieces mais pas dans currentPieces
    initialPieces.forEach(initialPiece => {
        const currentPiece = currentPieces.find(piece => piece.Id === initialPiece.Id);
        if (!currentPiece) {
            removed.push({ ...initialPiece, action: "Suppression" })
        }
    })

    const allPieces = added.concat(removed);

    return allPieces

}

