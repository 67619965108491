import { Form, UseFormReturn, useForm } from '@dsid-opcoatlas/reform'
import { Yop } from '@dsid-opcoatlas/yop'
import { unwrapResult } from '@reduxjs/toolkit'
import { DossierDetail } from "api/dossierAPI"
import { ETAT_DOSSIER_ANNULE, ETAT_DOSSIER_EN_COURS, ETAT_DOSSIER_INCOMPLET } from "api/references"
import useDossiersModuleContext from 'app/entreprise/mes-dossiers/useDossiersModuleContext'
import { useAppDispatch } from 'app/store'
import { AtlasHtml, MyForm, MyModal } from "atlas-ds"
import Button from 'components/reform/Button'
import RadioBooleanField from 'components/reform/inputs/RadioBooleanField'
import { OptionalBoolean, RequiredBoolean } from 'components/yop/constraints'
import { getDossierEditPath } from 'pages/dossier/useDossierNavigation'
import { useHistory } from "react-router-dom"
import { nil } from 'slices/dossierCommon'
import { dossierSliceActions } from 'slices/dossierSlice'


interface DepotAvenantProps {
    show: boolean
    close: () => void
    dossier: DossierDetail
    title?: string
}

interface AvenantForm {
    dateAvenant: boolean | null
}

export default function DepotAvenantModal(props: DepotAvenantProps) {

    const history = useHistory()
    const dispatch = useAppDispatch()
    const ctx = useDossiersModuleContext()
 
    const initialValues = { dateAvenant: nil as (boolean | null) }
    let typeModifParEtat = (props.dossier?.EtatDossierFO__c === ETAT_DOSSIER_ANNULE || props.dossier?.EtatDossierFO__c === ETAT_DOSSIER_EN_COURS || props.dossier?.EtatDossierFO__c === ETAT_DOSSIER_INCOMPLET) ? true : false

    const validationSchema = Yop.object<AvenantForm>({
        dateAvenant: typeModifParEtat ? OptionalBoolean : RequiredBoolean,
    })

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<AvenantForm>) => {
            const metadata = ctx?.creationMetadata?.find(m => m.module.dispositifId === props.dossier.DispositifFO__c)!.edit!
            dispatch(dossierSliceActions.edit({ api: metadata.api, dossierDetail: props.dossier, dateAvenant: context.values?.dateAvenant ?? false }))
                .then(unwrapResult)
                .then(() => {
                    onClose()
                    const path = getDossierEditPath(props.dossier, ctx!)
                    history.push(path)
                })
                .finally(() => form.setSubmitting(false))
        },
    })

    const onClose = () => {
        props.close()
        form.reset()
    }

    return (
        <MyModal show={props.show} onClose={onClose} label={props.title ?? ''}>
            <Form context={ form } noValidate autoComplete="off">
                <MyForm>
                    { !typeModifParEtat && <RadioBooleanField name="dateAvenant"
                        label={ `Souhaitez vous : ` } labelNo={ `Apporter une correction au contrat, avec une date d'effet effective depuis le début du contrat` } labelYes={ `Etablir un avenant au contrat, avec une date d'effet postérieure à la date de début de contrat` }
                    /> }

                    <AtlasHtml>
                        <strong>Important :</strong>
                        <p>La modification est transmise à Atlas après confirmation à l'étape récapitulatif, en cas d'interruption de la saisie, celle-ci ne sera pas sauvegardée.</p>
                        <p>La prise en compte de la modification dans votre espace sera visible après l'instruction de la demande par les équipes Atlas, le délai de traitement peut prendre quelques jours.</p>
                    </AtlasHtml>

                    <MyForm.Actions>
                        <Button
                            submit={ true }
                            disabled={ form.getErrorCount() > 0 }
                            spinner={{ spinning: form.submitting }}
                        >Continuer</Button>

                        <Button level={ 2 } onClick={ onClose }>Retour</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}
