import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from '@dsid-opcoatlas/yop'
import { postCreationSollicitation } from 'api/sollicitationAPI'
import { useAppContext } from 'app/useAppContext'
import { AtlasColumns, AtlasFlex, AtlasInfo, AtlasSpacer, MyForm } from "atlas-ds"
import { ToastService } from "components/BandeauAlert"
import PageAlert from 'components/PageAlert'
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import ErrorBanner from "components/reform/ErrorBanner"
import EtablissementSelectorField from 'components/reform/inputs/EtablissementSelectorField'
import FileField from "components/reform/inputs/FileField"
import { AcceptedImages, Ignored, RequiredFile, RequiredString } from "components/yop/constraints"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { nil } from "slices/dossierCommon"

interface RefBancaireEtablissement {
    Id: string
    ParentId: string
    nom: string
    numeroSiren: string
    numeroSiret: string
}

interface RefBancaireFactor {
    etablissement: RefBancaireEtablissement,
    ibanpdf: File,
}

const interruption = import.meta.env.REACT_APP_INTERRUPTION_REFS_BANCAIRES === 'true'

export default function AjoutRefBancaireFactor() {

    const history = useHistory()
    const appContext = useAppContext()
    const [error, setError] = useState<string>()

    const initialValues: RefBancaireFactor = {
        etablissement: nil as RefBancaireEtablissement,
        ibanpdf: nil as File,
    }

    const validationSchema = Yop.object<RefBancaireFactor>({
        etablissement: Yop.object<RefBancaireEtablissement>({
            Id: RequiredString,
            ParentId: Ignored,
            nom: Ignored,
            numeroSiren: Ignored,
            numeroSiret: RequiredString,
        }).required('Vous devez sélectionner un établissement'),
        ibanpdf: RequiredFile.max(15 * 1024 * 1024, 'Fichier trop volumineux (15 Mo maximum)'),
    })

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<RefBancaireFactor>) => {        
            const sollicitation = {
                Subject: "Demande de création de RIB Factor",
                Priority: "Haute",
                Description: `Siret : ${ context.values?.etablissement?.numeroSiret } (${ context.values?.etablissement?.nom })`,
                // ContactId: profil!.Contact.Id,
                // AccountId: profil!.Contact.AccountId,
                Origin: appContext.appInfo.options!.typeOrigine,
                Type: "demandes-assistance",
                SousType__c: "Gestion-des-references-bancaires",
            }

            postCreationSollicitation(sollicitation, context.values!.ibanpdf.name, context.values!.ibanpdf)
                .then(_ => {
                    ToastService.showToast({
                        label: "Ajout d'un RIB factor",
                        type: "success",
                        content: "Votre demande d'ajout de RIB a bien été transmise"
                    })
                    goBack()
                })
                .catch(e => {
                    setError("Une erreur est survenue durant l'envoi de votre demande")
                })
                .finally(() => {
                    form.setSubmitting(false)
                })
        },
    })

    const goBack = () => history.push("/mes-refs-bancaires")

    return (
        <>
            <PageHeader>Ajouter un RIB factor</PageHeader>

            { interruption &&
            <>
            <AtlasInfo type="warning" title="Interruption de service">
                Pour des raisons indépendantes d’Atlas, les services de dépôt de références bancaires sont temporairement interrompus.
                Veuillez nous excuser pour la gêne occasionnée.
            </AtlasInfo>
            <AtlasSpacer size="xl" />
            </>
            }
            
            <AtlasColumns>
                <AtlasFlex column gap="xl">
                    <PageAlert config="BandeauxRefBancaires" />
                            
                    <Form context={ form } noValidate>
                        <MyForm>
                            <EtablissementSelectorField
                                getEtablissements={ appContext.appInfo.options!.getEtablissements }
                                label="Établissement titulaire"
                                employeurPath="etablissement"
                                adresse={ false }
                                code={ false }
                                onChange={ () => form.renderForm() }
                                showSelectedInfo
                                disabled={ interruption }
                            />

                            <FileField
                                name="ibanpdf"
                                label="Sélectionner un RIB"
                                accept={ Object.keys(AcceptedImages) }
                                tooltip={ `Formats acceptés : ${ Object.values(AcceptedImages).join(', ') } — 15 Mo maximum` }
                                disabled={ interruption }
                            />

                            <ErrorBanner title="Erreur lors de l'ajout du RIB" message={ error } />

                            <MyForm.Actions>
                                <Button submit={ true } spinner={{ spinning: form.submitting }} disabled={ interruption }>Valider</Button>
                                <Button level={ 2 } onClick={ () => goBack() }>Annuler</Button>
                            </MyForm.Actions>
                        </MyForm>
                    </Form>
                </AtlasFlex>
            </AtlasColumns>
        </>
    )
}
