import { CFAKey, getCFAKey } from "api/entrepriseAPI"
import { AtlasColumns, AtlasFlex, AtlasInfo, AtlasLoading, AtlasSpacer, AtlasValue, MyContainer } from "atlas-ds"
import { ToastService } from "components/BandeauAlert"
import PageHeader from "components/PageHeader"
import log from "loglevel"
import { useEffect, useState } from "react"

export default function MaCleCfa() {
    const [serverError, setServerError] = useState("")
    const [cfaKey, setCfaKey] = useState<CFAKey | null>(null)
    const [pending, setPending] = useState(true)
    const [copiedMsg, setCopiedMsg] = useState("");

    useEffect(() => {
        getCFAKey().then(data => {
            setCfaKey(data)
            setPending(false)
        }).catch(e => {
            log.error("Impossible de récupérer la clé CFA", e)
            setServerError("Une erreur survenue durant la récupération de la clé CFA, veuillez réessayer dans quelques instants.")
        })
    }, []);

    const onCopy = () => {
        ToastService.showToast({
            type: "success",
            label: "Copie effectuée",
            content: "La clé CFA a été copiée dans votre presse-papier"
        })
    }

    return <>
        <PageHeader>Clé CFA</PageHeader>

        <p>La dernière version de votre clé sera automatiquement générée ici.</p>

        <AtlasSpacer size="l" />

        <AtlasColumns>
            <MyContainer>
                {serverError ? <AtlasInfo type="error" title="Erreur">Erreur sur la clé CFA</AtlasInfo> : <></> }

                <AtlasFlex column gap="m">
                    <AtlasLoading loading={pending && !serverError}>
                        <AtlasValue label="Ma clé CFA" copyable={{ text: cfaKey?.cfaKey }} onCopy={onCopy}>
                            { cfaKey?.cfaKey }
                        </AtlasValue>
                    </AtlasLoading>

                    {copiedMsg && <span>{copiedMsg}</span>}
                </AtlasFlex>
            </MyContainer>
        </AtlasColumns>
    </>
}
