import { DossierDetail, postRelanceModifierDateFormationDossier } from 'api/dossierAPI'
import { DEMANDE_EN_COURS_MODIFICATION_DATES } from 'api/references'
import { MyForm } from 'atlas-ds'
import { date2APIDate, parseDate } from 'components/format/Format'
import DateField from 'components/reform/inputs/DateField'
import { RequiredDate, optionalObject } from 'components/yop/constraints'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { DossierRelanceActionProps } from '.'
import DossierRelanceAction from './DossierRelanceAction'


export default function DossierRelanceModifierDates(props: DossierRelanceActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null

    const initialValues = {
        dateDebut: parseDate(dossier.Modules[0]!.DateDebutFormation__c)!,
        dateFin: parseDate(dossier.Modules[0]!.DateFinFormation__c)!
    }
    
    type Periode = typeof initialValues

    const validationSchema = optionalObject<Periode>({
        dateDebut: RequiredDate
            .test<Periode>(context => {
                if (context.value!.getFullYear() !== initialValues.dateDebut.getFullYear())
                    return context.createError("Vous ne pouvez pas modifier l’année de la date de début de la formation. Merci de nous contacter.")
                return true
            }),
        dateFin: RequiredDate
            .min<Periode>(context => context.parent!.dateDebut, "La date de fin de formation ne doit pas être antérieure à la date de début")
    })

    return <DossierRelanceAction
        dossier={ dossier }
        id="modifierDatesFormation"
        label="Demander une modification des dates de formation"
        of={props.of}
        typeDemande={DEMANDE_EN_COURS_MODIFICATION_DATES}
        successMessage="Votre demande de modification des dates de formation a bien été prise en compte. Elle sera traitée dans les meilleurs délais"
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        params={ values => ({ 
            DateDebut: date2APIDate(values.dateDebut!),
            DateFin: date2APIDate(values.dateFin!),
        }) }
        formContent={<>
            <MyForm>
                <MyForm.Grid>
                    <DateField name="dateDebut" label="Date de début de formation" />
                    <DateField name="dateFin" label="Date de fin de formation" />
                </MyForm.Grid>
            </MyForm>
        </>}
        doAction={ (dossier: DossierDetail, params: any) => postRelanceModifierDateFormationDossier(dossier.NumeroDossier__c, 
            JSON.stringify({ ...params, 'Motif': '' })) }
    />
}
