import { getFieldState, SetValueOptions, useFormContext } from "@dsid-opcoatlas/reform"
import { Commune, getReferencesCommune } from "api/referencesAPI"
import { MyForm } from "atlas-ds"
import { unaccentLowerCase } from "components/fields/FieldUtil"
import { BaseFieldProps } from "components/reform/inputs/utils/BaseFieldProps"
import { PartialNullable } from "utilityTypes"
import AutocompleteBaseField from "./AutocompleteBaseField"

interface CodePostalCommuneFieldProps<T extends object> extends BaseFieldProps<Commune, T> {
    codePostalName?: string
    communeName?: string
    departementName?: string
    regionName?: string
}

function path(name: string, property: string) {
    return name ? `${ name }.${ property }` : property
}

export default function CodePostalCommuneField<T extends object>(props: CodePostalCommuneFieldProps<T>) {

    const form = useFormContext<T>()

    const codePostalPath = path(props.name, props.codePostalName ?? "codePostal")
    const communePath = path(props.name, props.communeName ?? "commune")
    const departementPath = props.departementName ? path(props.name, props.departementName) : undefined
    const regionPath = props.regionName ? path(props.name, props.regionName) : undefined

    const codePostalFieldState = getFieldState<string | null>(form, codePostalPath)
    const communeFieldState = getFieldState<string | null>(form, communePath)

    const renderCommune = (commune: PartialNullable<Commune>) => {
        return commune.CdCodex__c && commune.Nom__c ? commune.CdCodex__c + ' - ' + commune.Nom__c : ""
    }

    const search = async (text: string) => {
        text = text.trim()
        const codePostal = /^(\d{3,5})/.exec(text)?.[1]
        if (codePostal != null) {
            text = unaccentLowerCase(text)
            return (await getReferencesCommune(codePostal)).filter(commune => unaccentLowerCase(renderCommune(commune)).startsWith(text))
        }
        return await getReferencesCommune(text)
    }

    const setValue = (searchOrCommune: string | Commune) => {
        if (typeof searchOrCommune === "string") {
            form.setValue(codePostalPath, searchOrCommune === "" ? null : form.getValue(codePostalPath), SetValueOptions.Touch)
            form.setValue(communePath, searchOrCommune === "" ? null : form.getValue(communePath), SetValueOptions.Touch)
            if (departementPath != null)
                form.setValue(departementPath, searchOrCommune === "" ? null : form.getValue(departementPath))
            if (regionPath != null)
                form.setValue(regionPath, searchOrCommune === "" ? null : form.getValue(regionPath))
        }
        else {
            form.setValue(codePostalPath, searchOrCommune.CdCodex__c, SetValueOptions.Touch)
            form.setValue(communePath, searchOrCommune.Nom__c, SetValueOptions.Touch)
            if (departementPath != null)
                form.setValue(departementPath, searchOrCommune.Departement__c)
            if (regionPath != null)
                form.setValue(regionPath, searchOrCommune.Region__c)
        }
        form.validate()
        form.renderForm()
    }

    return (
        <MyForm.Field size="full">
            <AutocompleteBaseField<Commune>
                label={ props.label ?? "Code postal et commune" }
                defaultValue={ renderCommune({ CdCodex__c: codePostalFieldState.value, Nom__c: communeFieldState.value }) }
                error={ codePostalFieldState.error ?? communeFieldState.error }
                required={ codePostalFieldState.constraints.required || communeFieldState.constraints.required }
                disabled={ props.disabled }
                placeholder="Rechercher par code postal ou commune (3 caractères minimum)"
                onBlur={ setValue }
                
                search={ search }
                
                optionFor={ commune => ({
                    id: commune.Id,
                    content: renderCommune(commune)
                })}
                onSelect={ setValue }
            />
        </MyForm.Field>
    )
}
