import { DossierDetail } from "api/dossierAPI"
import Infos from "pages/dossier/cerfa/Infos"
import { formatDate } from "components/format/Format"
import { formElements } from 'pages/dossier/metadata/FormElements'
import { RuptureFormMetadata, RuptureInfo } from 'pages/dossier/metadata/RuptureFormMetadata'

export function buildCerfaRupture<R extends RuptureInfo = RuptureInfo>(formMetadata: RuptureFormMetadata<R>, ruptureInfo: R, numDossier: string, authName: string) {
    const subtitle = `${formMetadata.nomDispositif.substring(0, 1).toUpperCase()}${formMetadata.nomDispositif.substring(1)} n°${numDossier}`
    const dataPdfRupture = {
        title: "Informations de rupture",
        subtitle,
        auth: authName,
        tables: [
            {
                title: 'Informations',
                values: formElements(formMetadata, false, ruptureInfo, '', 0, undefined, 'export', undefined, undefined, { row: true })
                    .map(x => [x]),
            }
        ],
    }

    return <Infos data={dataPdfRupture} />
}

// A supprimer
export const buildPDFRuptureCPRO = (motif: string, dateRupture: Date, numDossier: string) => {
    const dataPdfRupture = {
        title: "Informations de rupture",
        subtitle: "Contrat d'apprentissage n° " + numDossier,
        tables: [
            {
                title: 'Informations',
                values: [
                    ['Motif de la rupture', motif],
                    ['Date de la rupture', formatDate(dateRupture)]
                ]
            }
        ]
    }

    return <Infos data={dataPdfRupture} />
}

export const buildPdfAnnuler = (dossier: DossierDetail, authName: string, motif: string) => {
    const dataPdfRupture = {
        title: "Informations d'annulation",
        subtitle: "Contrat d'apprentissage n° " + dossier.NumeroDossier__c,
        auth: authName,
        tables: [
            {
                title: 'Informations',
                values: [
                    ["Nom et prénom de l'apprenti", dossier.Stagiaires && dossier.Stagiaires.length > 0 ? (dossier.Stagiaires[0].Nom__c + ' ' + dossier.Stagiaires[0].Prenom__c) : ''],
                    ["Date du début du contrat", formatDate(dossier.DateDebutContrat__c)],
                    ["Date de la fin du contrat", formatDate(dossier.DateFinContrat__c)],
                    ["Nom de l'entreprise", dossier.EtablissementAdherent.Name],
                    ["Motif de la demande", motif],
                ]
            }
        ]
    }

    return <Infos data={dataPdfRupture} />
}
