import { formatCurrency } from 'components/format/Format'
import RecapAtlasValue from './RecapAtlasValue'

interface RecapCurrencyFieldProps {
    label: string,
    value: string | number | undefined | null,
    initialValue?: string | number | null | undefined,
    editMode?: boolean
    suffix?: string,
}

export default function RecapCurrencyField(props: RecapCurrencyFieldProps) {
    const suffix = props.suffix && props.suffix.startsWith("€") ? props.suffix.substring(1) : props.suffix

    const value = formatCurrency(props.value, suffix)
    const initialValue = formatCurrency(props.initialValue, suffix)

    return (
        value || initialValue ?
        <RecapAtlasValue label={props.label} initialValue={initialValue} value={value} editMode={props.editMode || false} /> :
        null
    )
}
