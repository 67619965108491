import { AtlasValue } from "atlas-ds";


interface RecapAtlasValueProps {
    label: string;
    value: string | null | undefined;
    editMode: boolean;
    initialValue?: string | null | undefined;
}

export default function RecapAtlasValue(props: RecapAtlasValueProps) {

    return <AtlasValue label={props.label}>
        {props.editMode
            ? <AtlasValue.Update before={props.initialValue ? props.initialValue : undefined}>{props.value ? props.value : undefined}</AtlasValue.Update>
            : props.value}
    </AtlasValue>
}