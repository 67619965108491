import { UseFormReturn } from "@dsid-opcoatlas/reform";
import { References, Reference } from "api/referencesAPI";
import { NIRAsyncField } from "components/fields/NIRAsyncField";
import { AsyncOptionalNIR } from "components/fields/NIRValidator";
import NumeroDossierApprentissageField from "components/fields/NumeroDossierApprentissageField";
import CodePostalCommuneField from "components/reform/inputs/CodePostalCommuneField";
import NameField from "components/reform/inputs/NameField";
import NomAutocompleteField from "components/reform/inputs/NomAutocompleteField";
import PhoneField from "components/reform/inputs/PhoneField";
import RadioRefField from "components/reform/inputs/RadioRefField";
import SelectRefField from "components/reform/inputs/SelectRefField";
import TextField from "components/reform/inputs/TextField";
import { OptionalString, OptionalEmail, OptionalPhone, RequiredString } from "components/yop/constraints";
import { StringFieldMetadata, fieldMetadataDecorator } from "elements/metadata";
import { FormFieldContext } from "elements/FormFields";
import { FormMetadata } from "pages/dossier/metadata/FormMetadata";
import { TOOLTIPS } from "pages/dossier/Tooltips";


export function textField<ParentClass>(props?: StringFieldMetadata<ParentClass>) {
    return fieldMetadataDecorator<ParentClass, string | null>({
        kind: "string",
        input: TextField,
        schema: OptionalString,
        ...props
    })
}

export function nameField<ParentClass>(props?: StringFieldMetadata<ParentClass>) {
    return textField<ParentClass>({
        input: NameField,
        ...props,
    })
}

export function firstNameField<ParentClass>(props?: StringFieldMetadata<ParentClass>) {
    return textField<ParentClass>({
        input: NameField,
        ...props,
    })
}

export function emailField<ParentClass>(props?: StringFieldMetadata<ParentClass>) {
    return textField<ParentClass>({
        schema: OptionalEmail,
        ...props,
    })
}

export function phoneField<ParentClass>(props?: StringFieldMetadata<ParentClass>) {
    return textField<ParentClass>({
        label: "Téléphone",
        input: PhoneField,
        schema: OptionalPhone,
        ...props,
    })
}

export function numeroDossierApprentissageField<ParentClass>(props?: StringFieldMetadata<ParentClass>) {
    return fieldMetadataDecorator<ParentClass, string | null>({
        kind: "string",
        label: "Numéro dossier apprentissage",
        input: NumeroDossierApprentissageField,
        schema: OptionalString,
        ...props
    })
}

export function autocompleteNameField<ParentClass>(props?: StringFieldMetadata<ParentClass> & {
    search: (form: UseFormReturn<any>, recherche: string, metadata?: FormMetadata<any>) => Promise<any[]>
}) {
    return textField<ParentClass>({
        input: NomAutocompleteField,
        ...props,
    })
}

export function codePostalCommuneField<ParentClass>(props?: StringFieldMetadata<ParentClass>) {

    function removeLastSegment(path: string) {
        const lastDot = path.lastIndexOf('.')
        return lastDot >= 0 ? path.substring(0, lastDot) : path
    }

    return fieldMetadataDecorator<ParentClass, string | null>({
        kind: "string",
        label: "Code postal et commune",
        input: (props?: any) => CodePostalCommuneField({ ...props, name: removeLastSegment(props.name) }),
        schema: RequiredString
            .max(10)
            .matches(/^[0-9]{5}$/, "Le code postal doit être composé de 5 chiffres"),
        ...props
    })
}

export function asyncNirField<ParentClass>(props: StringFieldMetadata<ParentClass> & {
    sexePath?: string
    nameNTT?: string
}) {
    props.sexePath ??= "sexe"
    props.nameNTT ??= "ntt"
    
    return fieldMetadataDecorator<ParentClass, string | null>({
        kind: "string",
        label: "Numéro de sécurité sociale (NIR)",
        tooltip: TOOLTIPS.salarie.nir,
        input: NIRAsyncField,
        schema: AsyncOptionalNIR(props.sexePath),
        ...props
    })
}

export function radioRefField<ParentClass>(props?: StringFieldMetadata<ParentClass> & {
    options: (refs: References, context: FormFieldContext<ParentClass, string | null>) => Reference[]
}) {
    return fieldMetadataDecorator<ParentClass, string | null>({
        kind: "string",
        input: props => {
            const { context, options, ...rest } = props
            return <RadioRefField {...rest} options={refs => options(refs, context)} />
        },
        schema: OptionalString,
        ...props
    })
}


export function selectRefField<ParentClass>(props?: StringFieldMetadata<ParentClass> & {
    sorted?: boolean
    options: (refs: References, context: FormFieldContext<ParentClass, string | null>) => Reference[]
}) {
    return fieldMetadataDecorator<ParentClass, string | null>({
        kind: "string",
        input: props => {
            const { context, options, ...rest } = props
            return <SelectRefField {...rest} options={refs => options(refs, context)} />
        },
        schema: OptionalString,
        ...props
    })
}
