import { PreFormContentProps } from 'pages/dossier/metadata/FormMetadata'
import { BaseDossier, DossierPiece } from 'elements/model/dossierClasses'
import { AtlasButton, AtlasFlex, AtlasHeading, AtlasInfo, AtlasLoading, AtlasTooltip, MyFileCta, MyForm, MyTable } from 'atlas-ds'
import { useDossierNavigation } from 'pages/dossier/useDossierNavigation'
import { formatDate } from 'components/format/Format'
import { useState } from 'react'
import DepotFichierModal from 'components/docs/DepotFichierModal'
import { downloadDossierDoc, getDossierDocumentTypes } from 'api/dossierAPI'
import { deleteDocument, PieceTypeDTO, uploadDocument } from 'api/documentAPI'
import { useAppDispatch } from 'app/store'
import { clearSteps } from 'slices/onboardingSlice'
import { dtoToModel, modelToDTO } from 'elements/mapping'
import useApiState from 'api/useApiState'
import TelechargerDoc from 'components/docs/TelechargerDoc'
import { nonNullProperties } from 'elements/utilities'

function Pieces(props: PreFormContentProps<any> & {
    legend: string,
    pieces: DossierPiece[],
    addFile: (type: string | undefined) => void,
    actions: (piece: DossierPiece) => JSX.Element[]
    errors?: Set<number>
 }) {

    const tooltips: Record<string, string> = {
        "JUSTIFICATIF DEROGATION AGE":
            "Justificatif apprenti(e) de plus de 29 ans (Copie notification rupture, attestation sur l'honneur de l'apprenti(e), ...)",
        "CONVENTION DE REDUCTION DE PARCOURS":
            "Convention réduction de durée de parcours",
        "PRECEDENT CONTRAT D APPRENTISSAGE":
            "Copie du précédent contrat d'apprentissage de l'apprenti(e)",
        "CONVENTION DE MISE A DISPOSITION GIE":
            "Convention de mise à disposition entre le jeune, le groupement d’employeurs et l'entreprise utilisatrice"
    }
    
    const tooltipFormat = (
        <AtlasTooltip title="Formats acceptés">
            Formats acceptés : pdf, docx, xlsx, pptx, doc, xls, ppt — 15 Mo maximum
        </AtlasTooltip>
    )
    
    const typePiece = (piece: DossierPiece) => {
        const tooltip = tooltips[piece.type ?? ""]
        
        return (
            <AtlasFlex gap="xs" alignItems='center'>
                { piece.type + (piece.info ? ' ' + piece.info : '') }
                { tooltip != null ? <AtlasTooltip>{ tooltip }</AtlasTooltip> : undefined }
            </AtlasFlex>
        )
    }

    return (
        <div>
            <AtlasHeading tag="h3" size="s">{ props.legend }</AtlasHeading>

            <AtlasLoading loading={ props.loading || props.dossier == null }>
                <MyTable
                    caption={ props.legend }
                    emptyText="Aucune pièce"
                    rowActions={ props.actions }
                    rowError={ (_, index) => props.errors?.has(index) ? "Cette pièce doit être ajoutée" : undefined }
                    rows={ props.pieces }
                    rowKey={ (piece: DossierPiece, index) => piece.Id ? piece.Id : piece.type ?? `doc-${ index }` }
                    columns={[
                        {
                            id: "nom",
                            label: "Type document",
                            value: (piece: DossierPiece) => typePiece(piece)
                        }, {
                            id: "dateAjout",
                            label: "Date d'ajout",
                            value: (piece: DossierPiece) => piece.dateAjout ? formatDate(piece.dateAjout) : 'En attente'
                        }, {
                            id: "fichier",
                            label: "Fichier",
                            wrap: true,
                            value: (piece: DossierPiece) => piece.fichier ?
                                <p>{ piece.fichier }</p> :
                                <MyFileCta label="Ajouter un document" onClick={ () => props.addFile(piece.type!)  } tooltip={ tooltipFormat } />
                        }
                    ]}
                />
            </AtlasLoading>
        </div>
    )
}

export function PiecesTable(props: PreFormContentProps<any> & {
    documentTypeFilter?: (docType: PieceTypeDTO) => boolean
    piecesObligatoiresMessage?: JSX.Element
    warningMessage?: JSX.Element
}) {
    const dispatch = useAppDispatch()
    const [ { pending: deleting }, withDeleteDocument ] = useApiState(deleteDocument, { pending: false })
    
    const { step } = useDossierNavigation(props.metadata)
    const [ addFileType, setAddFileType ] = useState<string>()
    const dossier = props.dossier as BaseDossier

    const piecesObligatoires = dossier?.pieces?.filter(piece => piece.obligatoire) ?? []
    const piecesComplementaires = dossier?.pieces?.filter(piece => !piece.obligatoire && !piece.uploadedToGed) ?? []
    const piecesComplementairesAjoutees = piecesComplementaires.filter(piece => piece.fichier)

    const removePiece = (doc: DossierPiece) => {
        withDeleteDocument(dossier!.NumeroDossier__c!, doc.Id!).then(_ => {
            const index = dossier!.pieces.findIndex(piece => piece.Id === doc.Id)
            if (index >= 0) {
                const piecesHelper = props.form?.array<DossierPiece>(props.step.path?.toString() ?? "pieces")!
                const piece = props.form?.values!.pieces[index]
                if (piece.actif === true)
                    piecesHelper.replace(index, Object.assign(piece, { Id: '', dateAjout: '', fichier: '' }))
                else
                    piecesHelper.remove(index)
            }
        })
    }

    const onPieceUploaded = (dto: any) => {
        const uploadedPiece = dtoToModel(DossierPiece, dto)
        const index = dossier!.pieces.findIndex(piece => piece.type === uploadedPiece.type)
        const piece = dossier!.pieces[index]

        const piecesHelper = props.form?.array<DossierPiece>(props.step.path?.toString() ?? "pieces")!
        if (piece?.obligatoire === true)
            piecesHelper.replace(index, Object.assign(piece, nonNullProperties(uploadedPiece)))
        else {
            piecesHelper.insert(
                index === -1 ? dossier!.pieces.length : index,
                Object.assign(dtoToModel(DossierPiece, dto), { obligatoire: false })
            )
        }

        // Always skip the onboarding once a file is uploaded
        // This is to prevent an error on the "add file" cta which disappears
        // after the upload
        dispatch(clearSteps())
    }

    const pieceActions = (piece: DossierPiece) => {
        if (!piece.fichier || dossier == null)
            return []

        const actions = [
            <TelechargerDoc key="telechargerDoc" downloadDoc={ () => downloadDossierDoc(modelToDTO(DossierPiece, piece), dossier as any) } />
        ]
        if (!piece.uploadedToGed) {
            actions.push(
                <AtlasButton
                    key="supprimer"
                    level={ 3 }
                    icon="trash"
                    spinner={{ spinning: deleting }}
                    onClick={ () => deleting || removePiece(piece) }>
                    Supprimer
                </AtlasButton>
            )
        }
        return actions
    }

    const validationErrorIndexes = new Set<number>()
    props.form?.getErrorPaths().forEach(errorPath => {
        const errorIndex = errorPath.match(/^pieces\[(\d+)\]/)?.[1]
        if (errorIndex)
            validationErrorIndexes.add(parseInt(errorIndex))
    })

    return (
        <div>
            <AtlasHeading tag="h2" size="m">{ step.title }</AtlasHeading>
            
            <AtlasFlex column gap="xl">
                { piecesObligatoires.length > 0 && <>
                <Pieces { ...props }
                    legend="Ajout des pièces obligatoires"
                    pieces={ piecesObligatoires }
                    addFile={ setAddFileType }
                    actions={ pieceActions }
                    errors={ validationErrorIndexes } />
                
                { props.piecesObligatoiresMessage }
                
                { validationErrorIndexes.size > 0 &&
                <AtlasInfo type="error" title="Erreur">Vous devez ajouter toutes les pièces obligatoires.</AtlasInfo>
                }
                </> }

                <Pieces { ...props }
                    legend="Ajout de pièces complémentaires"
                    pieces={ piecesComplementairesAjoutees }
                    addFile={ setAddFileType }
                    actions={ pieceActions } />
                
                <div>
                    <AtlasButton level={ 2 } icon="plus" onClick={ () => setAddFileType('') }>Ajouter une pièce complémentaire</AtlasButton>
                </div>

                {/* { step.options?.attestationPieces &&
                    <CheckboxField
                        name="attestationPieces"
                        label="L'employeur atteste disposer de l'ensemble des pièces justificatives nécessaires à l'enregistrement du contrat"
                    />
                } */}
            </AtlasFlex>

            { addFileType != null &&
            <DepotFichierModal
                idParent={ dossier?.NumeroDossier__c ?? '' }
                nom={ addFileType }
                withType={ !addFileType && dossier?.DispositifFO__c != null }
                getDocumentTypes={ getDossierDocumentTypes }
                withObligatoires={ false }
                documentTypeFilter={ props.documentTypeFilter }
                warningMessage={ props.warningMessage }
                upload={ uploadDocument }
                uploaded={ onPieceUploaded }
                close={ () => setAddFileType(undefined) }
                hideWarningFactures={ true }
            />
            }
        </div>
    )
}
