import { useFormContext, UseFormReturn } from "@dsid-opcoatlas/reform"
import { MyForm } from "atlas-ds"
import { cloneDeep } from "lodash-es"
import { useElementsContext } from "./ElementsForm"
import { ArrayItemType, BooleanFieldMetadata, ClassConstructor, DateFieldMetadata, FieldMetadata, FileFieldMetadata, getMetadata, NumberFieldMetadata, StringFieldMetadata } from "./metadata"
import { Path } from "./Path"

export class FormFieldContext<ParentClass, FieldType> {
    
    private _path: Path | undefined = undefined

    constructor(
        readonly rootClass: ClassConstructor,
        readonly rawPath: string,
        readonly form: UseFormReturn<any>,
        readonly fieldContext?: any
    ) {}

    get path() {
        if (this._path == null)
            this._path = Path.fromString(this.rawPath, this.rootClass)
        return this._path
    }

    getAt<T>(index?: number) {
        const path = index == null ? this.path : this.path.slice(0, index)
        return path.getValue<T | null | undefined>(this.form.values)
    }

    get value() {
        return this.getAt<FieldType>()
    }

    get parent() {
        return this.getAt<ParentClass>(-1)
    }

    getRoot<T>() {
        return this.getAt<T>(0)
    }
}

export type FormContextProperty<ParentClass, FieldType, ValueType> = (
    ValueType |
    ((context: FormFieldContext<ParentClass, FieldType>) => ValueType)
)

export type FormContextFunctionProperty<ParentClass, FieldType, FunctionType> = (
    FunctionType |
    { resolve: (context: FormFieldContext<ParentClass, FieldType>) => FunctionType }
)

function FormField(props: {
    model: ClassConstructor,
    name: string,
    parentPath: string | undefined,
    overrides: FieldMetadata<any, any> | undefined
}) {

    const elements = useElementsContext()
    const form = useFormContext()
    const name = props.parentPath ? `${ props.parentPath }.${ props.name }` : props.name
    const context = new FormFieldContext(elements!.model!, name, form)
    const metadata = getMetadata(props.model) as Record<string, FieldMetadata<any, any>>
    
    const overrides = Object.fromEntries(Object.entries(props.overrides ?? {}).filter(([, value]) => value !== undefined))
    const ancestor = Path.fromString(name, elements!.model!)?.at(-2)
    const fieldOverridesClass = ancestor?.class
    if (fieldOverridesClass != null) {
        const fieldOverrides = getMetadata(fieldOverridesClass)[ancestor!.property]?.overrides?.[props.name]
        if (fieldOverrides != null)
            Object.assign(overrides, fieldOverrides)
    }

    const fieldMetadata = { ...metadata[props.name], ...overrides }
    let { required, ignored, clear, disabled, visible, message, size, alone, contextConsumer, input: Input, ...rest } = fieldMetadata

    if (typeof Input === "object")
        Input = Input.resolve(context)
    if (Input == null)
        return null

    if (visible != null && (typeof visible === 'boolean' ? visible : visible(context)) === false)
        return null

    const computedProps: Record<string, any> = {}
    if (disabled != null)
        computedProps.disabled = (typeof disabled === 'boolean' ? disabled : disabled(context))

    if (typeof message === "function")
        message = message(context)
    if (message != null)
        computedProps[`${message.type}Message`] = message.content

    const configuredInput =
        <Input
            name={ name }
            parentPath={ props.parentPath }
            context={ context }
            metadata={ elements.metadata }
            { ...rest }
            { ...overrides }
            { ...computedProps }
        />

    if (typeof size === "function")
        size = size(context)
    
    if (typeof alone === "function")
        alone = alone(context)
            
    if (size == null && alone !== true)
        return configuredInput

    return (    
        <MyForm.Field alone={ alone } size={ size }>
            { configuredInput }
        </MyForm.Field>
    )
}

export type FieldNames<T> = Partial<{
    [P in keyof T]: (
        [T[P]] extends [string | null | undefined] ? boolean | StringFieldMetadata<any> :
        [T[P]] extends [number | null | undefined] ? boolean | NumberFieldMetadata<any> :
        [T[P]] extends [boolean | null | undefined] ? boolean | BooleanFieldMetadata<any> :
        [T[P]] extends [Date | null | undefined] ? boolean | DateFieldMetadata<any> :
        [T[P]] extends [File | null | undefined] ? boolean | FileFieldMetadata<any> :
        [T[P]] extends [any[] | null | undefined] ? [number, FieldNames<ArrayItemType<T[P]>>] :
        [T[P]] extends [object | null | undefined] ? FieldNames<T[P]> :
        never
    )
}>

function appendFormFields<T>(formFields: JSX.Element[], model: ClassConstructor<T>, names: FieldNames<T>, parentPath?: string) {
    for (const [name, fieldMetadata] of Object.entries(names)) {
        if (fieldMetadata === false)
            continue
        
        let overrides: FieldMetadata<any, any> | undefined = undefined
        if (typeof fieldMetadata === 'object') {
            const metadata = getMetadata(model)[name]
            const objectClass = metadata.type
            if (objectClass == null)
                overrides = fieldMetadata as FieldMetadata<any, any>
            else {
                if (Array.isArray(fieldMetadata)) {
                    const [index, itemNames] = fieldMetadata as [number, FieldNames<any>]
                    const path = parentPath ? `${ parentPath }.${ name }[${ index }]` : `${ name }[${ index }]`
                    appendFormFields(formFields, objectClass, itemNames, path)
                }
                else {
                    const path = parentPath ? `${ parentPath }.${ name }` : name
                    appendFormFields(formFields, objectClass, names[name as keyof T] as FieldNames<any>, path)
                }
                continue
            }
        }
        
        formFields.push(<FormField key={ name as string } model={ model } name={ name } parentPath={ parentPath } overrides={ overrides } />)
    }
    
    return formFields
}

export function FormFields<T, P = any>(props: { model: ClassConstructor<T>, names: FieldNames<T>, parent?: { model: ClassConstructor<P>, path: FieldNames<P> }, parentPath?: string }) {
    let model = props.model as ClassConstructor
    let names = props.names as FieldNames<any>
    if (props.parent != null) {
        const parent = cloneDeep(props.parent)
        let leaf = Object.values(parent.path)?.[0];
        while (typeof leaf === "object") {
            const newLeaf = Object.values(Array.isArray(leaf) ? leaf[1] : leaf)?.[0]
            if (newLeaf == null)
                break
            leaf = newLeaf
        }
        if (leaf != null) {
            Object.assign(leaf, props.names)
            names = parent.path as FieldNames<any>
            model = parent.model
        }
    }
    const formFields = appendFormFields([], model, names, props.parentPath)
    return <> { formFields } </>
}
