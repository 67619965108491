import { useFormContext } from '@dsid-opcoatlas/reform'
import { MyForm } from 'atlas-ds'
import { FormElements } from 'pages/dossier/metadata/FormElements'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_CAPP_CONTRAT, SECTION_CAPP_CONTRAT_AVANTAGES, SECTION_CAPP_CONTRAT_MODIFICATION, SECTION_CAPP_CONTRAT_REPRESENTANT, SECTION_CAPP_CONTRAT_SALAIRE, isCAPPMineur } from 'pages/dossier-capp/CAPPContratMetadata'
import { DossierCAPP } from 'pages/dossier-capp/CAPPModel'
import { useDossierNavigation } from 'pages/dossier/useDossierNavigation'
import { TYPE_MODIFICATION_AVENANT } from 'api/references'


export function DossierCAPPContratElements(props: { metadata: FormMetadata<DossierCAPP> }) {
    
    const { step } = useDossierNavigation(props.metadata)
    const context = useFormContext<DossierCAPP>()

    return (
        <>
            { step.sections?.includes(SECTION_CAPP_CONTRAT) &&
            <MyForm.Grid>
                <FormElements section={ SECTION_CAPP_CONTRAT } metadata={ props.metadata } path="contrat" type="input" />
            </MyForm.Grid>
            }

            { step.sections?.includes(SECTION_CAPP_CONTRAT_SALAIRE) &&
            <MyForm.Grid>
                <FormElements section={ SECTION_CAPP_CONTRAT_SALAIRE } metadata={ props.metadata } path="contrat" type="input" />
            </MyForm.Grid>
            }

            { step.sections?.includes(SECTION_CAPP_CONTRAT_AVANTAGES) &&
            <MyForm.Fieldset legend="Avantages en nature">
                <MyForm.Grid>
                    <FormElements section={ SECTION_CAPP_CONTRAT_AVANTAGES } metadata={ props.metadata } path="contrat" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>
            }

            { step.sections?.includes(SECTION_CAPP_CONTRAT_REPRESENTANT) && context.values && isCAPPMineur(context.values as DossierCAPP) && 
            <MyForm.Fieldset legend="Représentant légal">
                <MyForm.Grid>
                    <FormElements section={ SECTION_CAPP_CONTRAT_REPRESENTANT } metadata={ props.metadata } path="representant" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>
            }

            { step.sections?.includes(SECTION_CAPP_CONTRAT_MODIFICATION) && (context.values as any).typeModificationContrat === TYPE_MODIFICATION_AVENANT && 
            <MyForm.Fieldset legend="Modification du contrat">
                <MyForm.Grid>
                    <FormElements section={ SECTION_CAPP_CONTRAT_MODIFICATION } metadata={ props.metadata } path="contrat" type="input" />
                </MyForm.Grid>
            </MyForm.Fieldset>
            }
        </>
    )
}
