import { AtlasValues, MyContainer } from 'atlas-ds'
import { formDiffElements, FormElements } from 'pages/dossier/metadata/FormElements'
import { BaseDossier } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapApprenti(props: RecapProps<BaseDossier>) {
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)

    const metadata = props.metadata
    const stagiaireEtape = steps.indexOf(props.step) + 1

    const skeleton = props.loading ? {} : {}
    const stagiaire = (props.dossier as any)?.stagiaire ?? skeleton
    const dossier = props.dossier ?? { stagiaire } as any
    const initialDossier = props.initialDossier ?? { stagiaire } as any

    if (props.metadata.api.isEdit) {
        const elements = formDiffElements(metadata, dossier, initialDossier, 'stagiaire')
        if (!elements || elements.length === 0) return null
        return <RecapSection title="Apprenti" onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, stagiaireEtape) }>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            { elements}
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection>
    }

    return <RecapSection title="Apprenti" onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, stagiaireEtape) }>
        <MyContainer>
            <AtlasValues>
                <AtlasValues.Grid>
                    <FormElements key="stagiaire" dossier={ dossier } metadata={ metadata } path="stagiaire" type="recap" />
                </AtlasValues.Grid>
            </AtlasValues>
        </MyContainer>
    </RecapSection>
}
