import { useAppDispatch } from 'app/store'
import { AtlasBreadcrumb, AtlasButton, AtlasColumns, AtlasInfo, AtlasLoading, AtlasSpacer, MyAction, MyKanban, MyKanbanCardMoveEvent, MyModal } from 'atlas-ds'
import PageHeader from 'components/PageHeader'
import Button from 'components/reform/Button'
import { useScrollToTop } from 'components/scroll/useScrollToTop'
import { Link } from 'react-router-dom'
import { STATUTS_ACTIONS_PLAN, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import { DiagDetails } from './DiagDetails'
import DiagPageHeader from './DiagPageHeader'
import DiagPlanActionOperations from './DiagPlanActionOperations'
import { getActionBanner, getActionError, getActionTags } from './MonDiagnosticActions'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'


const STATUTS_ACTIONS_PLAN_IDS = STATUTS_ACTIONS_PLAN.map(s => s.id)

interface MonDiagnosticPlanActionProps {
}

export default function MonDiagnosticPlanAction(props: MonDiagnosticPlanActionProps) {
    const dispatch = useAppDispatch()
    const { diagnostic, loading } = useDiagnostic(null, 'plan-action', { statuts: STATUTS_ACTIONS_PLAN_IDS })
    const navigation = useDiagnosticNavigation()
    useScrollToTop()

    const supprimerAction = (cardId: string) => {
        const action = diagnostic?.planAction?.find(da => da.ExternalIdHeroku === cardId)
        if (action) {
            dispatch(diagnosticSliceActions.deleteAction({ action: action.Action.Id }))
        }
    }

    const deplacerAction = ({ cardId, toColumnId, beforeCardId }: MyKanbanCardMoveEvent) => {
        const action = diagnostic?.planAction?.find(da => da.ExternalIdHeroku === cardId)
        if (!action) 
            return

        const statut = toColumnId
        const labelStatut = STATUTS_ACTIONS_PLAN.find(s => s.id === toColumnId)?.label
        const beforeAction = diagnostic?.planAction?.find(da => da.ExternalIdHeroku === beforeCardId)
        if (beforeAction) {
            const ordre = beforeAction.Statut === action.Statut && beforeAction.Ordre > action.Ordre ? beforeAction.Ordre - 1 : beforeAction.Ordre
            dispatch(diagnosticSliceActions.moveAction({ action: action.Action.Id, statut, ordre }))
        } else {
            const actionsReverse = diagnostic?.planAction?.concat()?.reverse()
            const lastAction = actionsReverse?.find(a => a.Statut === labelStatut)
            const ordre = lastAction ? (lastAction.Statut === action.Statut && lastAction.Ordre > action.Ordre ? lastAction.Ordre : lastAction.Ordre + 1) : 0
            dispatch(diagnosticSliceActions.moveAction({ action: action.Action.Id, statut, ordre }))
        }
    }

    const ajouterAction = () => {
        navigation.navigateToPath('actions')
    }

    return <>
        <DiagPageHeader>
            <PageHeader
                breadcrumb={<AtlasBreadcrumb currentPageLabel="Plan d'action"
                    linkConstructor={ (props, content) => <Link to={ props.href }>{ content }</Link> }
                    parts={[{
                        label: "Actions préconisées",
                        href: "/mon-diagnostic-actions",
                    }]}
                />}
                actions={[
                    <DiagPlanActionOperations diagnostic={diagnostic} key="operations" />,
                    <AtlasButton key="plan" onClick={ () => navigation.navigateToPath('actions') } icon="search">
                        Actions préconisées
                    </AtlasButton>
                ]}
            >Plan d'action</PageHeader>
        </DiagPageHeader>

        <AtlasColumns alignItems='end'>
            <AtlasInfo title="Retrouvez ici votre sélection d'actions !">
                <p>Vous pouvez les classer en fonction de l'horizon de temps auquel vous envisagez de les mettre en œuvre.</p>
            </AtlasInfo>

            <AtlasLoading loading={loading}>
                <DiagDetails diagnostic={diagnostic ?? undefined} />
            </AtlasLoading>
        </AtlasColumns>

        <AtlasSpacer size="xl" />

        <AtlasLoading loading={loading}>
            <MyKanban
                onCardMove={ deplacerAction }
                onCardRemove={ supprimerAction }
                addCta={ <AtlasButton onClick={ ajouterAction }>Ajouter une action préconisée</AtlasButton> }
                columns={ STATUTS_ACTIONS_PLAN.map(s => ({
                    id: s.id,
                    label: s.label,
                    moveLabel: s.shortLabel,
                    emoji: s.emoji,
                    cards: diagnostic?.planAction?.filter(da => da.Statut === s.label)?.map(da => {
                        return { id: da.ExternalIdHeroku, card: <MyAction
                            key={ `action-${da.ExternalIdHeroku}` }
                            infoHref={ da.Action.URL }
                            tags={ getActionTags(da) } 
                            banner={ getActionBanner(da) }
                            error={ getActionError(da) }
                        >{ da.Action.Libelle }</MyAction> }
                    }) ?? [],
                })) }
            />
        </AtlasLoading>
    </>
}

export function AskCreerPlanActionModal(props: { close: () => void, next: () => void }) {
    return (
        <MyModal
            label="Validation du diagnostic"
            okButton={ <Button level={ 2 } onClick={ props.next }>Continuer</Button> }
            cancelButton={ <Button level={ 3 } onClick={ props.close }>Retour</Button> }
        >
            <p>La validation du diagnostic créera une liste d'actions préconisées par Atlas en fonction de vos choix.</p>
        </MyModal>
    )
}