import { Simulation, SimulationDetail, SimulationsSearchQuery, getSimulations } from 'api/simulationAPI'
import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasLink } from 'atlas-ds'
import { formatApiDateTime } from 'components/format/Format'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import RechercheBase, { singleTab } from 'components/recherche/RechercheBase'
import State from 'components/State'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { setEnterprise } from 'slices/contextSlice'
import DeleteSimulationModal from './DeleteSimulationModal'
import PriseEnChargeSimulationModal from './PriseEnChargeSimulationModal'

class SimulationsSearchQueryImpl extends BaseSearchQueryImpl implements SimulationsSearchQuery {

    constructor(search: URLSearchParams) {
        super(search, 'dateCreation')
        this.applySearch(search)
    }

    override applySearch(params: URLSearchParams): SimulationsSearchQueryImpl {
        super.applySearch(params)
        return this
    }

    clone() {
        return new SimulationsSearchQueryImpl(this.toSearchParams())
    }

    override toSearchParams(forStorage = false): URLSearchParams {
        const params = super.toSearchParams(forStorage)
        return params
    }
}

export default function MesSimulations() {
    const dispatch = useAppDispatch()
    const { profil } = useSelector((state: RootState) => state.profilState)

    const location = useLocation()
    const history = useHistory()

    const [deleteSimulation, setDeleteSimulation] = useState<Simulation | null>(null)
    const [priseEnChargeSimulation, setPriseEnChargeSimulation] = useState<Simulation | null>(null)

    useEffect(() => {
        if (location && location.search && location.search) {
            const params = new URLSearchParams(location.search)
            // Redirection provenant du simulateur: l'id SF de l'adhérent est passé dans l'url
            // => remplace par le siren
            const idAdherent = params.get('id-adh')
            if (idAdherent) {
                const entreprise = profil?.Entreprises?.find(e => e.Id === idAdherent)
                if (entreprise) {
                    params.delete('siren-adh')
                    params.set('siren-adh', entreprise?.SIREN__c)
                    params.set('contexte', 'entreprise')
                    params.delete('id-adh')
                    dispatch(setEnterprise(entreprise))
                    history.replace({
                        pathname: location.pathname,
                        search: params.toString(),
                        state: location.state,
                    })
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const headerActions = <AtlasLink href={ import.meta.env.REACT_APP_LIEN_SIMULATEUR ?? "#" }>
        Faire une nouvelle simulation
    </AtlasLink>

    const colonnes = (_: SimulationsSearchQuery) => [
        {
            id: "dateCreation",
            label: "Date",
            value: (row: any) => formatApiDateTime(row.dateCreation),
        },
        {
            id: "libelle",
            name: "libelle",
            label: "Nom",
            wrap: true,
            value: (row: any) => <div dangerouslySetInnerHTML={{ __html: row.libelle }}></div>
        },
        {
            id: "typeContrat",
            name: "type",
            label: "Type de contrat",
            value: (row: any) => row.type === 'CAPP' ? "Contrat d'apprentissage" : "Contrat de professionalisation",
        },
        {
            id: "status",
            name: "status",
            label: "Statut",
            value: (row: any) => <State type="simulation" id={ row.status }>
                {`${ row.status.charAt(0).toUpperCase() }${ row.status.slice(1)} `}
            </State>
        }
    ]

    const actions = (simulation: SimulationDetail) => {
        return [
            <AtlasLink
                key="detail"
                icon="simulations"
                level={ 3 }
                href={ import.meta.env.REACT_APP_LIEN_SIMULATEUR + simulation.id }
            >Voir le détail</AtlasLink>,
            ...simulation.status === 'convertie' ? [] : [<AtlasButton
                key="priseencharge"
                icon="dossiers"
                level={ 3 }
                onClick={ () => setPriseEnChargeSimulation(simulation) }
            >Demander une prise en charge</AtlasButton>],
            <AtlasButton
                key="supprimer"
                icon="trash"
                level={ 3 }
                onClick={ () => setDeleteSimulation(simulation) }
            >Supprimer</AtlasButton>,
        ]
    }

    const newQuery = (params: URLSearchParams) => new SimulationsSearchQueryImpl(params)

    return (
        <RechercheBase<SimulationDetail, SimulationsSearchQueryImpl>
            name="MesSimulations"
            title="Simulations"
            newQuery={ newQuery }
            filtres={ [] }
            colonnes={ colonnes }
            tabs={ singleTab(getSimulations) }
            headerActions={ headerActions }
            tableActions={ actions }>
            <>

            { deleteSimulation &&
            <DeleteSimulationModal simulation={ deleteSimulation } close={ () => setDeleteSimulation(null) }/> 
            }

            { priseEnChargeSimulation &&
            <PriseEnChargeSimulationModal simulation={ priseEnChargeSimulation } close={ () => setPriseEnChargeSimulation(null) } />
            }
            </>
        </RechercheBase>
    )
}
