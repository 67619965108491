import { Form, useForm, useRender } from '@dsid-opcoatlas/reform'
import { Page, emptyPage } from 'api/baseAPI'
import { Identifiable } from 'api/dossierAPI'
import { DATE_FORMAT } from 'app/constants'
import store, { useAppDispatch } from 'app/store'
import { AtlasBreadcrumbProps, AtlasColumns, AtlasFieldPeriodPreset, AtlasFlex, AtlasGrid, AtlasLoading, AtlasResultsHeader, MyContainer, MyInfiniteTable, MyScrollToTop, MyTable, MyTags } from 'atlas-ds'
import PageHeader from "components/PageHeader"
import { ExportAction } from 'components/blocs/ExportAction'
import getTabs from 'components/getTabs'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import { Filtre } from 'components/recherche/Filtres'
import { format, startOfToday, startOfYear, subYears } from 'date-fns'
import { cloneDeep, debounce, isEqual } from 'lodash'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { historyStackActions } from 'slices/historySlice'

const now = startOfToday()

export const DEFAULT_DATE_RANGES: AtlasFieldPeriodPreset[] = [
    {
        label: "5 ans glissants",
        values: [subYears(now, 5), now]
    },
    {
        label: 'Année glissante',
        values: [subYears(now, 1), now]
    },
    {
        label: 'Année courante',
        values: [startOfYear(now), now]
    }
]

export const DEFAULT_DATE_DEBUT = format((DEFAULT_DATE_RANGES[0].values as Date[])[0], DATE_FORMAT)
export const DEFAULT_DATE_FIN = format((DEFAULT_DATE_RANGES[0].values as Date[])[1], DATE_FORMAT)

type Tab<T, Q> = {
    label: (count: number) => string,
    executeQuery: (query: Q) => Promise<Page<T>>
}

export function singleTab<T, Q>(executeQuery: (query: Q) => Promise<Page<T>>): Tab<T, Q>[] {
    return [{
        label: (_: number) => '',
        executeQuery,
    }]
}

export type FiltreFunctionProps = {
    totalCount: number
    alertCount: number
}

const filtresToSearchParams = (query: BaseSearchQueryImpl, filtres: Filtre[], values: any) => {
    const params = query.toSearchParams()
    filtres.forEach(filtre => filtre.valueToParam(values, params))
    params.sort()
    return params.toString()
}

export default function RechercheBase<
    T extends Identifiable,
    Q extends BaseSearchQueryImpl,
>(props: {
    name: string,
    title: string,
    noHeader?: boolean;
    headerActions?: JSX.Element,
    headerInfo?: JSX.Element,
    rightInfo?: JSX.Element,
    emptyText?: string,
    resultsLabel?: string,
    filtres: Filtre[] | ((props: FiltreFunctionProps) => Filtre[]),
    colonnes: (query: Q) => any,
    tableActions: (row: T, tabIndex?: number) => JSX.Element[],
    newQuery: (params: URLSearchParams) => Q,
    tabs: Tab<T, Q>[],
    executeExport?: (query: Q, email: string) => Promise<string>,
    breadcrumb?: React.ReactElement<AtlasBreadcrumbProps>,
    children?: ReactNode
    getErrorRowsIndexes?: (items: T[]) => Set<number>
    errorLabel?: string
    rowIcon?: (row: T, index: number) => { name: string, label: string, tooltipContent: string } | undefined
}) {

    const dispatch = useAppDispatch()
    const location = useLocation()
    const history = useHistory()

    const pendings = useRef(props.tabs.map(() => true))
    const totalCounts = useRef(props.tabs.map(() => 0))
    const alertCounts = useRef(props.tabs.map(() => 0))

    const render = useRender()
    const [tabIndex, setTabIndex] = useState(0)
    const [error, setError] = useState<string | null>(null)
    const [resultsPage, setResultPage] = useState(emptyPage<T>())

    useEffect(() => {
        dispatch(historyStackActions.clear())
    }, [dispatch])

    const totalCount = totalCounts.current.reduce((sum, count) => sum + count, 0)
    const alertCount = alertCounts.current.reduce((sum, count) => sum + count, 0)

    const filtres = (typeof props.filtres === 'function') ? props.filtres({ totalCount, alertCount }) : props.filtres

    const locationSearchParams = new URLSearchParams(location.search)
    const query = props.newQuery(locationSearchParams)
    const defaultValues = filtres.reduce((prev: any, filtre: Filtre) => { return Object.assign(prev, filtre.defaultValue) }, {})
    const initialValues = filtres.reduce((prev: any, filtre: Filtre) => { return Object.assign(prev, filtre.initialValue(query)) }, {})

    const form = useForm({ initialValues, dispatchEvent: false })

    const filtreSet = filtres && filtres.find(f => !isEqual(form.getValue(f.name), f.defaultValue[f.name]))

    const filtresSearchParams = filtresToSearchParams(query, filtres, form.values)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(debounce((values: any) => {
        const params = filtresToSearchParams(query.rewind(), filtres, values)
        history.push({ 
            pathname: location.pathname, 
            search: params.toString(),
            state: location.state,
        })
    }, 1000), []);

    useEffect(() => {
        if (query.fromEmptySearch) {
            const storedQuery = store.getState().recherchesState.queries[props.name]
            history.push({
                pathname: location.pathname,
                search: query.applySearch(new URLSearchParams(storedQuery)).toSearchParams().toString(),
                state: location.state
            })
        }
        else {
            locationSearchParams.sort()
            if (filtresSearchParams !== locationSearchParams.toString()) {
                debouncedOnChange(form.values)
                // form.reset(false)
            }
        }
    }, [query.fromEmptySearch, filtresSearchParams])

    if (query.fromEmptySearch)
        return null

    const fetchMoreResults = () => {
        history.push({
            pathname: location.pathname,
            search: query.nextPage(tabIndex).toSearchParams().toString(),
            state: { tabIndex: tabIndex }
        })
    }

    const onSortChange = (property: string) => {
        if (tabIndex === 0) {
            history.push({
                pathname: location.pathname,
                search: query.setTri(property).toSearchParams().toString(),
                state: { tabIndex: tabIndex }
            })
        }
    }

    const setIndexedPending = (index: number, value: boolean) => {
        pendings.current[index] = value
        render()
    }

    const setIndexedResultsPage = (index: number, resultsPage: Page<T>) => {
        totalCounts.current[index] = resultsPage.totalCount
        alertCounts.current[index] = resultsPage.alertCount ?? 0
        if (index === tabIndex)
            setResultPage(resultsPage)
    }

    const setIndexedError = (index: number, error: string | null) => {
        if (index === tabIndex)
            setError(error)
    }

    const selectIndexedTab = (index: number, resultsPage: Page<T>, error: string | null) => {
        setResultPage(resultsPage)
        setError(error)
        setTabIndex(index)
    }

    const filters = props.filtres.length > 0 && <MyContainer>
        <AtlasGrid columnsCount={3} gap="xl">
            { filtres.map(filtre => filtre.input) }
        </AtlasGrid>
    </MyContainer>

    const preventEnterKey = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter')
            event.preventDefault()
    }

    return (
        <>
            { !props.noHeader && <PageHeader
                breadcrumb={ props.breadcrumb }
                actions={<>
                    { props.executeExport &&
                    <ExportAction disabled={ resultsPage.totalCount === 0 } query={ query } 
                        export={ props.executeExport } 
                        initialValues={ cloneDeep(form.values) }>
                        { filtres.map(filtre => filtre.filtre(form.values, true)) }
                    </ExportAction> }

                    { props.headerActions }
                </>}
            >{ props.title }</PageHeader> }

            <AtlasFlex column gap="xl">
                { props.headerInfo }
                
                <Form context={form} noValidate onKeyDown={ preventEnterKey }>
                    <AtlasFlex column gap="m">
                        { props.rightInfo
                            ? <AtlasColumns>
                                { filters }
                                { props.rightInfo }
                            </AtlasColumns>
                            : filters
                        }

                        <MyScrollToTop label="Remonter en haut de la liste">
                            <AtlasLoading loading={ pendings.current[tabIndex] }>
                                <MyInfiniteTable
                                    onScrollEnd={ fetchMoreResults }
                                    loadedCount={ resultsPage.items.length }
                                    totalCount={ totalCount }
                                    tabs={getTabs({
                                        tabs: props.tabs,
                                        name: props.name,
                                        query: query,
                                        tabIndex: tabIndex,
                                        pendings: pendings,
                                        setIndexedPending: setIndexedPending,
                                        setIndexedResultsPage: setIndexedResultsPage,
                                        setIndexedError: setIndexedError,
                                        selectIndexedTab: selectIndexedTab
                                    })}
                                    header={<>
                                        <AtlasResultsHeader
                                            count={totalCount}
                                            filters={props.filtres.length ? <MyTags>
                                                { filtres.map(filtre => filtre.filtre(form.values)) }
                                            </MyTags> : undefined}
                                            onResetFilters={filtreSet ? (() => form.setValues(defaultValues)) : undefined}
                                            resultsLabel={props.resultsLabel}
                                        />
                                    </>}
                                >
                                    <MyTable
                                        caption={ props.title }
                                        rows={ resultsPage.items }
                                        rowActions={ row => props.tableActions(row, tabIndex) }
                                        onSortChange={ onSortChange }
                                        sortBy={ query.tri }
                                        sortOrder={ query.ordre }
                                        emptyText={ props.emptyText }
                                        error={ error ?? undefined }
                                        rowError={ (row, index) => props.getErrorRowsIndexes?.(resultsPage.items).has(index)
                                            ? (props.errorLabel ?? "Cet élément doit être mis à jour")
                                            : undefined
                                        }
                                        rowIcon={ props.rowIcon }
                                        columns={
                                            tabIndex === 0
                                                ? props.colonnes(query)
                                                : props.colonnes(query).map((col: any) => ({...col, sortable: false}))
                                        }
                                    />
                                </MyInfiniteTable>
                            </AtlasLoading>
                        </MyScrollToTop>
                    </AtlasFlex>
                </Form>
            </AtlasFlex>

            { props.children }

        </>
    )
}
