import { SessionFormation, deleteSession } from 'api/formationAPI'
import { AtlasButton, MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import ErrorBanner from 'components/reform/ErrorBanner'
import log from 'loglevel'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface DeleteModalProps {
    show: boolean
    session: SessionFormation | undefined
    idStage: number | undefined
    close: () => void
}

export default function DeleteSessionModal(props: DeleteModalProps) {

    const [deletePending, setDeletePending] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const history = useHistory();

    const handleDelete = () => {
        if(props.session?.IdHeroku && props.idStage) {
            setDeletePending(true)
            deleteSession(props.session.IdHeroku)
                .then(_ => {
                    // Utilisation history au lieu d'eventListener afin que la modal puisse fonctionner sur la page détailSession
                    ToastService.showToast({
                        label: 'Action de suppression d\'une session',
                        type: 'success',
                        content: 'La session a été supprimée avec succès.'
                    })
                    history.push({
                        pathname: `/detail-formation/${props.idStage}`
                    })
                    props.close()
                })
                .catch(e => {
                    log.error(`Erreur de suppression de la session ${props?.session?.IdHeroku}`, e)
                    const message = e.response?.data?.message ?? "Une erreur s'est produite lors de la suppression de cette session"
                    setErrorMessage(message)
                                        
                }).finally(() => {
                    setDeletePending(false)
                })
        }
    }

    return <MyModal show={props.show}
        label="Confirmation"
        isPending={deletePending}
        okButton={<AtlasButton onClick={handleDelete} disabled={!!errorMessage}>Confirmer</AtlasButton>}
        cancelButton={<AtlasButton onClick={props.close}>Retour</AtlasButton>}
    >
        { errorMessage
            ? <ErrorBanner title="Erreur lors de la soumission" message={ errorMessage } />
            : <p>Êtes-vous sûr de vouloir supprimer cette session ?</p>
        }
    </MyModal>
}
