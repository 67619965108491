import { AtlasButton, AtlasButtonProps, AtlasHeading } from "atlas-ds";
import classNames from "classnames";
import { useEffect, useRef } from "react";

export interface MyFormProps {
  /**
   * Le contenu du formulaire
   */
  children: React.ReactNode;
  /**
   * Le formulaire est-il en cours de chargement ou d'envoi ?
   * Attention : tous les contrôles doivent alors être désactivés
   * (à gérer dans le contexte appelant).
   */
  pending?: boolean;
}

/**
 * Un formulaire.
 * La gestion de l'élément `<form>` est à la charge du contexte appelant.
 * Ce composant constitue uniquement l'intérieur du formulaire.
 */
export function MyForm(props: MyFormProps) {
  return (
    <div className="my-form" aria-busy={props.pending}>
      {props.children}
    </div>
  );
}

export interface MyFormGridProps {
  /**
   * Les champs
   */
  children: React.ReactNode;
}

/**
 * Une série de champs, présentés par défaut sous la forme d'une grille à deux
 * colonnes. L'utilisation de ce conteneur n'est pas obligatoire au sein d'un
 * formulaire. S'il n'est pas utilisé, les champs seront simplement les uns
 * à la suite des autres.
 */
MyForm.Grid = (props: MyFormGridProps) => {
  return <div className="my-form__grid">{props.children}</div>;
};

export interface MyFormFieldProps {
  /**
   * Le champ
   */
  children: React.ReactNode;
  /**
   * La taille du champ (pleine largeur, réduit ou élargi)
   */
  size?: "full" | "short" | "long";
  /**
   * Ne pas permettre à un autre champ de s'installer sur la même ligne
   */
  alone?: boolean;
}

/**
 * Conteneur permettant de modifier le comportement par défaut d'un champ
 * au sein d'un conteneur `MyForm.Grid`
 */
MyForm.Field = (props: MyFormFieldProps) => {
  return (
    <div
      className={classNames("my-form__field", {
        [`my-form__field--${props.size}`]: props.size,
        "my-form__field--alone": props.alone,
      })}
    >
      {props.children}
    </div>
  );
};

/**
 * Composant vide prenant la place d'un champ.
 * Permet de remplacer un champ non-affiché sans que la mise en page du
 * formulaire ne change.
 */
MyForm.FieldPlaceholder = () => (
  <div className="my-form__fieldPlaceholder"></div>
);

export interface MyFormFieldsetProps {
  /**
   * Le titre du groupe de champ
   */
  legend: string;
  /**
   * Les champs
   */
  children: React.ReactNode;
  /**
   * Une tooltip d'aide
   */
  tooltipContent?: React.ReactNode;
  /**
   * Un bouton d'action
   */
  button?: React.ReactElement<AtlasButtonProps>;
  /**
   * S'il s'agit d'un groupe de champ structurant (pouvant en contenir d'autres,
   * par exemple), une importance visuelle spécifique lui sera donnée
   */
  primary?: boolean;
}

/**
 * Un ensemble de champs formant un sous-ensemble au sein du formulaire
 */
MyForm.Fieldset = (props: MyFormFieldsetProps) => {
  return (
    <fieldset
      className={classNames("my-form__fieldset", {
        "my-form__fieldset--primary": props.primary,
      })}
    >
      <legend>
        <AtlasHeading
          tag="h3"
          size="s"
          tooltipContent={props.tooltipContent}
          button={
            props.button ? (
              <AtlasButton {...props.button.props} level={3} />
            ) : undefined
          }
        >
          {props.legend}
        </AtlasHeading>
      </legend>
      <div className="my-form__fieldsetContent">{props.children}</div>
    </fieldset>
  );
};

export interface MyFormDependenciesProps {
  /**
   * Le formulaire des dépendances
   */
  children: React.ReactNode;
  /**
   * Si cet élément apparaît au cours de l'édition du formulaire, il est
   * préférable d'utiliser cette propriété pour forcer le focus sur l'élément
   * (accessibilité)
   */
  autofocus?: boolean;
}

MyForm.Dependencies = (props: MyFormDependenciesProps) => {
  const rootElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.autofocus && rootElement.current) {
      rootElement.current.focus();
    }
  }, []);

  return (
    <div className="my-form__dependencies" ref={rootElement} tabIndex={-1}>
      <p className="my-form__dependenciesInstructions">
        Certaines informations sont incohérentes avec celles provenant d'autres
        étapes.
        <br />
        Pour faciliter votre saisie, nous réaffichons ci-dessous les champs
        concernés.
      </p>
      <div className="my-form__dependenciesContent">{props.children}</div>
    </div>
  );
};

export interface MyFormActionsProps {
  /**
   * Les actions
   */
  children: React.ReactNode;
  /**
   * Aligner les actions à gauche plutôt qu'à droite (aligement par défaut)
   */
  toLeft?: boolean;
}

/**
 * Les actions associées au formulaire
 */
MyForm.Actions = (props: MyFormActionsProps) => {
  return (
    <div
      className={classNames("my-form__actions", {
        "my-form__actions--toLeft": props.toLeft,
      })}
    >
      {props.children}
    </div>
  );
};
