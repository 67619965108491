import { AtlasColumns, AtlasHtml } from "atlas-ds"
import PageHeader from "components/PageHeader"

export function CguContent() {
  return <AtlasHtml>
    <h2>1. Présentation du Service</h2>
    <h3>1.1 À propos de Nous</h3>
    <p>
      ATLAS, soutenir les compétences (ci-après, « Atlas », « Nous »,
      « Notre » ou « Nos ») est une association loi de 1901, enregistrée sous
      le numéro SIREN 851 296 632. L’établissement situé au 25 quai Panhard et
      Levassor, 75013 Paris, est notre principale adresse de correspondance.
    </p>
    <p>
      Atlas exerce les missions d’opérateur de compétences (ci-après, « OPCO
      »), définies aux{" "}
      <a href="https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000006072050/LEGISCTA000006189905/#LEGISCTA000006189905">
        articles L6332-1 et suivants du code du travail
      </a>
      , pour les branches professionnelles visées par l’arrêté ministériel du
      29 mars 2019 modifié « portant agrément d'un opérateur de compétences
      (ATLAS) ».
    </p>
    <h3>1.2 Champ d’application des CGU</h3>
    <p>
      Les présentes Conditions générales d’utilisation (ci-après, les
      « Conditions » ou les « CGU ») s’appliquent au portail de services en
      ligne édité par Atlas (ci-après, « myAtlas », le(s) « Service(s) » ou
      « le Site »).
    </p>
    <p>
      Ces CGU définissent les termes et les conditions régissant les relations
      entre Atlas et l’Utilisateur des Services en ligne myAtlas (ci-après,
      « l’Utilisateur », « Vous », « Votre » ou « Vos »).
    </p>
    <p>
      À compter de l’acceptation des présentes CGU par l’Utilisateur, en
      cochant la case « J'accepte les conditions d'utilisation des Services en
      ligne afin de valider ma demande » pour créer son « Compte », celles-ci
      constituent un accord contractuel (ci-après, le « Contrat ») entre Atlas
      et l’Utilisateur pour une durée illimitée, sauf résiliation selon les
      dispositions prévues à l’article 2.2.5 ci-après.
    </p>
    <p>
      Elles complètent les{" "}
      <a href="https://www.opco-atlas.fr/conditions-generales.html">
        Conditions générales
      </a>{" "}
      de fonctionnement, téléchargeables sur le site Internet Atlas,
      définissant les conditions d’adhésion à Atlas, de gestion des versements
      des Entreprises (ci-après, les « Contributions ») ainsi que celles
      applicables à la prise en charge et au paiement des Dossiers financés
      par Atlas ou aux autres services proposés par Atlas.
    </p>
    <p>
      Par ailleurs, le portail de Services en ligne myAtlas comporte des liens
      hypertextes vers d’autres services en ligne ou sites Internet proposés
      par Atlas qui peuvent être soumis à l’application de Conditions
      générales d’utilisation complémentaires, dont Nous Vous invitons à
      prendre connaissance. Lorsque vous utilisez Nos autres services en ligne
      ou sites Internet, vous acceptez des Conditions générales d’utilisation
      supplémentaires qui prévalent sur les présentes Conditions générales
      d’utilisation en cas de conflit.
    </p>
    <h3>1.3 Éligibilité d’accès au Service</h3>
    <p>
      La création et l’utilisation d’un Compte myAtlas sont réservés aux
      personnes physiques disposant de leur pleine capacité juridique et
      représentants légaux ou judiciaires d’une des Organisations suivantes
      (ci-après, les « Organisations ») ainsi que le personnel dûment habilité
      par ces Organisations à agir en leur nom et pour leur compte :
    </p>
    <ul>
      <li>
        Entreprises adhérentes d’Atlas, conformément au champ défini par
        l’agrément ministériel susvisé ou aux cas particuliers d’adhésion
        acceptés par Atlas (ci-après, le(s) « Entreprise(s) » ou les
        « Adhérent(s) ») ;
      </li>
      <li>
        Organismes de formation réalisant des actions de formation ou autres
        prestataires d’actions d'accompagnement dans l'analyse et la
        définition des besoins en formation professionnelle, financés par
        Atlas (ci-après, le(s) « Prestataire(s) ») ;
      </li>
      <li>
        Centres de formation d’apprentis (ci-après, le(s) « CFA »), dispensant
        des formations dans le cadre de contrats d'apprentissage financés par
        Atlas.
      </li>
    </ul>
    <p>
      Les personnels, dûment habilités, de sous-traitants mandatés par les
      Organisations concernées à les représenter et à agir en leur nom et pour
      leur compte auprès d’Atlas sont autorisés à accéder au Service,
      sous-réserve expresse du respect des dispositions spécifiques les
      concernant définies à l’article 2.1.1 « Conditions préalables à la
      création du Compte » et à l’article 4.6 « Obligations des Organisations
      transmettant et consultant des Données personnelles via myAtlas »
      ci-après.
    </p>
    <p>
      Les sociétés d’affacturage ou Factors ne sont pas autorisés à accéder au
      Service.
    </p>
    <h3>1.4 Les Services du Compte myAtlas</h3>
    <p>
      L’ensemble des données, documents et autres éléments que vous
      enregistrez, conservez ou mis à votre disposition par Atlas sur ces
      Services est ci-après désigné Votre « Contenu ».
    </p>
    <p>
      Les Services en ligne myAtlas permettent de réaliser les actions
      (ci-après, les « Opérations ») suivantes :
    </p>
    <ul>
      <li>
        Déclarer les Contributions supplémentaires des Entreprises et accéder
        aux reçus émis par Atlas pour les versements correspondants.
      </li>
      <li>
        Déposer des Dossiers de demande de financement ou de facturation
        (ci-après, les « Dossiers »).
      </li>
      <li>
        Télétransmettre des informations ou des pièces complémentaires aux
        Dossiers et demander certaines modifications.
      </li>
      <li>
        Consulter des tableaux de bord de suivi de la gestion des Dossiers et
        des financements reçus.
      </li>
      <li>
        Prendre connaissance des dernières notifications d’Atlas concernant la
        gestion des Contributions ou des Dossiers.
      </li>
      <li>
        Suivre l’état d’avancement détaillé du traitement des Dossiers par
        Atlas et accéder aux données, documents joints et courriers les
        concernant.
      </li>
      <li>Demander l’envoi d’états de suivi des Dossiers.</li>
      <li>
        Utiliser l’aide en ligne ou des tutoriels présentant les
        fonctionnalités de myAtlas.
      </li>
      <li>Interroger la base de connaissance sur les Services d’Atlas.</li>
      <li>
        Solliciter Atlas, pour une question relative à ses Services, en
        utilisant le formulaire de contact. Les sollicitations ou documents
        transmis par ce formulaire de contact pour des Opérations qui peuvent
        être traitées par d’autres fonctionnalités transactionnelles du
        Service, ne sont pas recevables et en conséquence, Atlas n’y donnera
        pas suite.
      </li>
      <li>
        Gérer Vos préférences d’abonnement à la réception par mél d’alertes de
        réception de notifications de gestion et de newsletter Atlas.
      </li>
      <li>
        Recevoir un courriel mensuel compilant les dossiers requérant une
        intervention de votre part.
      </li>
      <li>
        Personnaliser la présentation de vos espaces de Services myAtlas.
      </li>
      <li>
        Accéder à diagnosticAtlas, l’outil de diagnostic de vos emplois et
        compétences en mutation.
      </li>
      <li>
        Accéder à des outils d’aide à la construction et à la mise en œuvre de
        Votre plan de développement des compétences.
      </li>
      <li>
        Retrouver les simulations effectuées sur simulateurAtlas et les
        transformer en demande de prise en charge.
      </li>
      <li>
        Suivre les actualités concernant l’activité d’Atlas et, pour
        lesRetrouver les simulations effectuées sur simulateurAtlas et les
        transformer en demande de prise en charge prestataires ou CFA,
        consulter les appels d’offre en cours.
      </li>
      <li>
        Se connecter à d’autres services en ligne proposés par Atlas, soumis à
        l’acceptation de conditions générales d’utilisation particulières,
        publiées sur chacun de ces services.
      </li>
      <li>
        Accéder aux services personnalisés, pour les Entreprises ayant
        souscrit à l’offre « Stratégie + ».
      </li>
    </ul>
    <h3>
      1.5 Présentation et conditions d’utilisation des outils intégrés au
      Compte myAtlas
    </h3>
    <h4>diagnosticAtlas</h4>
    <p>
      L’outil diagnosticAtlas interroge les entreprises sur leurs besoins RH,
      l’impact des facteurs de mutation, les métiers et compétences à enjeux
      et préconise un plan d’action personnalisé.
    </p>
    <p>
      DiagnosticAtlas est accessible via l’espace myAtlas entreprise, rubrique
      « Diagnostic ». Il est par conséquent réservé exclusivement aux
      entreprises. En participant à diagnosticAtlas, l’Utilisateur accepte de
      Nous partager ses résultats. Les données renseignées dans ce diagnostic
      pourront être exploitées par Atlas.
    </p>
    <h4>simulateurAtlas</h4>
    <p>
      Le service simulateurAtlas permet de simuler le reste à charge d’un
      contrat en alternance.
    </p>{" "}
    <p>
      Le service est accessible sur le site institutionnel Atlas à l’adresse{" "}
      <a href="https://www.opco-atlas.fr/simulateur-cout-contrat-alternance">
        https://www.opco-atlas.fr/simulateur-cout-contrat-alternance
      </a>
      . En se connectant avec son Compte myAtlas au service simulateurAtlas,
      l’Utilisateur accepte de Nous partager les résultats de sa simulation.
      Les données renseignées dans la simulation pourront être exploitées par
      Atlas.
    </p>
    <p>
      L’Utilisateur retrouve ses simulations dans l’espace myAtlas entreprise,
      rubrique « Simulations ». L’accès aux simulations enregistrées est par
      conséquent réservé exclusivement aux entreprises. L’Utilisateur retrouve
      sa simulation dans son espace et peut la transformer directement en
      demande de prise en charge.
    </p>
    <p>
      Les autres services en lignes proposés par Atlas sont soumis à
      l’acceptation de conditions générales d’utilisation particulières,
      publiées sur chacun de ces services.
    </p>
    <h2>2. Le Compte myAtlas</h2>
    <p>
      La création d’un Compte Utilisateur myAtlas (ci-après, le « Compte »)
      est nécessaire pour accéder aux Services.
    </p>
    <h3>2.1 Création du Compte</h3>
    <h4>2.1.1 Conditions préalables à la création du Compte</h4>
    <p>
      La création d’un Compte est exclusivement réservée aux personnes
      physiques disposant de leur pleine capacité juridique et habilitées à
      représenter une Organisation enregistrée par Atlas, ce qui implique au
      préalable :
    </p>
    <ul>
      <li>
        Pour une Entreprise, que l’enregistrement de son adhésion soit validé
        par Atlas. Si l’adhésion de l’Entreprise n’est pas encore validée par
        Atlas, la personne habilitée doit effectuer cette demande sur son
        compte myAtlas, en cliquant sur « Attacher une entreprise » et en
        renseignant son SIREN, puis le formulaire proposé.
      </li>
      <li>
        Pour un Prestataire ou un CFA, le règlement par Atlas d’au moins une
        facture sur un Dossier. Cette première facture devra être adressée à
        Atlas, par courrier postal, à l’adresse indiquée sur le courrier
        d’accord d’Atlas ou à l’aide du formulaire suivant disponible sur le
        site Internet d’Atlas :
        <a href="https://www.opco-atlas.fr/prestataire/espace-organisme-formation.html">
          https://www.opco-atlas.fr/prestataire/espace-organisme-formation.html
        </a>
        .
      </li>
    </ul>
    <p>
      Si l’Organisation représentée a mandaté (ci-après, le « Mandant ») un
      sous-traitant pour la représenter (ci-après, le « Mandataire »), la
      demande de création d’un Compte doit, à l’égard d’Atlas, s’inscrire et
      attester :
    </p>
    <ul>
      <li>
        de la conclusion préalable d’un mandat de gestion valide, entre le
        Mandant et le Mandataire, pour l’ensemble des Opérations autorisées
        depuis le Service ;
      </li>
      <li>
        de la régularité de la délégation de pouvoir du Mandataire à l’égard
        de son personnel habilité à ouvrir et à utiliser un Compte ;
      </li>
      <li>
        du respect, par le Mandataire au même titre que par le Mandant, des
        obligations légales relatives à la protection des Données à caractère
        personnel, notamment celles rappelées à l’article 4.6 « Obligations
        des Organisations transmettant et consultant des Données personnelles
        via myAtlas » ci-après.
      </li>
    </ul>
    <p>
      Ces mandats de gestion et délégations de pouvoir doivent être conservés
      par le Mandant et le Mandataire durant toute la durée d’activation du
      Compte et les 6 (six) ans suivant la clôture des droits d’accès du
      Compte de l’Utilisateur habilité. Atlas pourra, sur simple demande,
      exiger à tout moment la présentation d’une copie de ces documents.
    </p>
    <h4>2.1.2 Demande de création du Compte</h4>
    <p>
      La création d’un Compte s’effectue depuis le lien « Se connecter via
      myAtlas » de la page d’accueil du site Internet d’Atlas{" "}
      <a href="https://www.opco-atlas.fr/">https://www.opco-atlas.fr/</a> ou
      bien directement depuis le lien suivant :{" "}
      <a href="https://myatlas.opco-atlas.fr/creer-mon-compte">
        https://myatlas.opco-atlas.fr/creer-mon-compte
      </a>
      .
    </p>
    <p>
      Pour procéder à la création de votre Compte, Vous devez renseigner les
      informations requises, (civilité, nom, prénom, email professionnel,
      téléphone professionnel et le mot de passe).
    </p>
    <p>
      Ces informations vont créer le compte. Après la création du compte, il
      faut se connecter sur{" "}
      <a href="https://myatlas.opco-atlas.fr/?contexte=compte">
        https://myatlas.opco-atlas.fr/?contexte=compte
      </a>{" "}
      afin d’attacher à son compte une/plusieurs Entreprise(s) Adhérente(s) ou
      un/plusieurs Organisme(s) de formation / CFA.
    </p>
    <p>
      Il sera demandé des informations de vérification de sécurité (masse
      salariale de l’année N-1 de l’Entreprise représentée ou bien pour un
      Prestataire ou un CFA, le montant d’une facture réglée par Atlas).
    </p>
    <p>
      En validant, lors de Votre demande de création d’un Compte,
      l’acceptation de la mention « Je certifie que le titulaire du Compte est
      autorisé à effectuer les opérations sélectionnées », Vous attestez
      disposer de votre pleine capacité juridique ainsi qu’être dûment
      habilité à représenter l’Organisation concernée, en vertu d’une
      délégation de pouvoir ou d’un mandat de gestion valide, Vous autorisant
      à transmettre à Atlas toutes les données et documents requis pour la
      gestion des Dossiers et à consulter les informations dont l’accès est
      autorisé depuis myAtlas.
    </p>
    <p>
      Le Compte utilisateur est personnel. La création et l’utilisation d’un
      Compte utilisateur anonyme, avec une identité fictive ou fausse, pour le
      compte d’un autre Utilisateur ou en partage avec d’autres Utilisateurs
      et le transfert d’un Compte vers un autre Utilisateur ne sont pas
      autorisés.
    </p>
    <p>
      Seules des données Vous concernant personnellement peuvent être
      rattachées à Votre Compte ; elles ne doivent en aucun cas induire en
      erreur quant à Votre identité et à Votre habilitation à représenter
      l’Organisation, concerner une tierce personne ou porter atteinte aux
      droits d’autrui.
    </p>
    <p>
      En validant votre demande de création d’un Compte, Vous attestez de
      l’exactitude et vous Vous engagez à tenir régulièrement à jour les
      informations Vous concernant, déclarées dans le formulaire
      d’inscription.
    </p>
    <p>
      Avant de finaliser votre inscription, vous Vous engagez à avoir pris
      connaissance des présentes Conditions et en cochant la case « j'accepte
      les conditions d'utilisation des Services en Ligne afin de valider ma
      demande », Vous en acceptez les termes.
    </p>
    <h4>2.1.3 Activation du Compte</h4>
    <p>
      Une fois Votre demande d’inscription au Service validée par Atlas, Vous
      recevrez à l’adresse mél que Vous avez renseignée dans le formulaire
      d’inscription, un lien pour activer Votre Compte. En cas de
      non-réception de ce courriel d’activation, veuillez vérifier Votre
      dossier de courriers indésirables ou spams, avant de Nous contacter.
    </p>
    <p>
      L’adresse mél que Vous avez renseignée dans le formulaire d’inscription
      au Service et le mot de passe que Vous avez défini (ci-après, vos «
      Identifiants ») Vous permettent de Vous connecter à Votre Compte
      myAtlas.
    </p>
    <p>
      Si par la suite Vous oubliez Votre mot de passe ou que Vous souhaitez le
      modifier, Vous pouvez en définir un nouveau en utilisant la
      fonctionnalité « mot de passe oublié », accessible depuis la page de
      connexion à myAtlas :
      <a href="https://myatlas.opco-atlas.fr/">
        https://myatlas.opco-atlas.fr/
      </a>
      .
    </p>
    <p>
      Vous recevrez un mél de bienvenue après l’attachement de Votre première
      Entreprise Adhérente ou Organisme de formation/ CFA.
    </p>
    <h4>2.1.4 Aide à l’utilisation du Service</h4>
    <p>
      Atlas met à Votre disposition des fonctionnalités ou moyens pour Vous
      aider dans l’utilisation du Service.
    </p>
    <p>
      Lors de Votre première connexion ou lorsque Vous êtes connecté à Votre
      Compte et que Nous faisons évoluer l’ergonomie ou les fonctionnalités du
      Service, une aide apparaît sous la forme d’un point info qui Vous guide
      successivement, avec un bref descriptif, vers les fonctionnalités que
      Nous Vous invitons à utiliser.
    </p>
    <p>
      Au cours de la réalisation des Opérations autorisées depuis le Service,
      des info-bulles Vous apportent des précisions quant aux données,
      documents ou actions attendues de Votre part.
    </p>
    <p>
      Le Service « Aide & Contacts », vous permet d’accéder en recherche
      guidée ou libre à des informations, documents, articles ou autres
      ressources publiés sur Nos sites Internet.
    </p>
    <p>
      Si Vous rencontrez des difficultés pour créer Votre Compte, si Vous
      n’arrivez pas à accéder au Service une fois connecté à Votre Compte, si
      Vous rencontrez un blocage dans la réalisation des Opérations autorisées
      depuis le Service ou si Vous constatez une erreur technique dans
      l’utilisation du Service, nous Vous invitons à contacter notre service
      d’assistance Utilisateur (ci-après, « Webassistance ») en utilisant le{" "}
      <a href="https://www.opco-atlas.fr/contact/formulaire-webassistance.html">
        formulaire de contact
      </a>{" "}
      disponible sur le site Internet d’Atlas ou bien l’adresse mél suivante :
      <a href="mailto:webassistance@opco-atlas.fr">
        webassistance@opco-atlas.fr
      </a>
      .
    </p>
    <p>
      Pour toute question relative à l’utilisation du Service myAtlas, Vous
      pouvez également contacter la Webassistance ou, pour toute question sur
      un autre sujet, contacter les autres services d’Atlas par téléphone aux
      numéros communiqués sur la{" "}
      <a href="https://www.opco-atlas.fr/contact.html">
        page contact du site Internet d'Atlas
      </a>{" "}
      ou bien encore utiliser les numéros de téléphone et le formulaire de
      Contact accessible par l’intermédiaire de la fonctionnalité « Aide &
      Contacts » de Votre Compte.
    </p>
    <h4>2.1.5 Mesures de sécurité et confidentialité</h4>
    <p>
      Nous Vous conseillons de choisir un mot de passe robuste et de le
      modifier régulièrement. Nous Vous recommandons également de consulter et
      de suivre les conseils publiés sur le site Internet de l’ANSSI (Agence
      nationale de la sécurité des systèmes d'information),
      <a href="https://cyber.gouv.fr/publications/recommandations-relatives-lauthentification-multifacteur-et-aux-mots-de-passe">
        https://www.ssi.gouv.fr/guide/recommandations-relatives-a-lauthentification-multifacteur-et-aux-mots-de-passe/
      </a>
      .
    </p>
    <p>
      Afin de protéger les données de Votre compte et Votre Contenu, veillez à
      ne pas révéler vos Identifiants et les informations de Votre Compte à
      des tiers. Vos Identifiants Vous sont personnels et ne doivent en aucun
      cas être communiqués à autrui. Ils garantissent la confidentialité des
      informations mises à Votre disposition et sont à saisir obligatoirement
      pour accéder au Service.
    </p>
    <p>
      Vous êtes seul responsable de la conservation du caractère confidentiel
      de vos Identifiants et il est précisé que leur divulgation volontaire ou
      par Votre négligence constitue une violation des présentes Conditions.
    </p>
    <p>
      Il est expressément rappelé qu’Atlas ou ses sous-traitants ne demandent
      jamais et pour quelque raison que ce soit aux Utilisateurs de
      communiquer le mot de passe de leur Compte et que toute demande en ce
      sens devra être considérée par l’Utilisateur comme frauduleuse.
    </p>
    <p>
      Atlas met en œuvre ses meilleurs moyens pour assurer la confidentialité
      de Vos données, documents ou Opérations afin qu’il ne soit pas porté
      atteinte au secret professionnel. Sauf exception légale ou Votre accord
      exprès, ces informations ne sont pas communiquées à des tiers, autres
      que les destinataires nécessaires à la réalisation des finalités de
      gestion des Dossiers et les sous-traitants habilités par Atlas à
      intervenir pour le bon fonctionnement du Service, et ne pourront pas
      être exploitées à des fins de prospection commerciale.
    </p>
    <p>
      Vous Vous engagez à informer ATLAS de toute utilisation non autorisée de
      vos Identifiants ou de toute atteinte à la sécurité de vos informations,
      et à Vous assurer qu’à l’issue de chaque session Vous Vous déconnectez
      explicitement du Service.
    </p>
    <p>
      En cas de détection par Atlas d’un risque avéré concernant la sécurité
      du Service, l’accès au Compte pourra être temporairement bloqué ou la
      modification du mot de passe requise, sans notification préalable. Il en
      est de même en cas d’utilisation frauduleuse du Service, selon
      l’appréciation d’Atlas, ou non conforme aux présentes Conditions ainsi
      que dans le cas où l’Utilisateur ne se conforme pas aux actions ou
      pièces justificatives requises par Atlas pour la mise à jour des
      informations concernant son identité ou son habilitation à représenter
      l’Organisation concernée. Atlas informera l’Utilisateur le plus
      rapidement possible de cette action, par tout moyen approprié (courrier
      ou courriel, SMS, appel téléphonique ou autre).
    </p>
    <h3>2.2 Fonctionnement du Service</h3>
    <h4>2.2.1 Connexion au Compte</h4>
    <p>
      Les équipements ou abonnements à des services (ordinateurs, smartphones,
      tablettes, logiciels, tout autre moyen de télécommunication, etc. …) qui
      permettent l'accès à myAtlas sont à la charge exclusive de
      l’Utilisateur, de même que l’ensemble des frais résultants de leur
      utilisation.
    </p>
    <p>
      La connexion à Votre Compte s’effectue depuis l’onglet « Se connecter
      via myAtlas » de la page d’accueil du site Internet d’Atlas{" "}
      <a href="https://www.opco-atlas.fr/">https://www.opco-atlas.fr/</a> ou
      bien directement depuis le lien suivant :{" "}
      <a href="https://myatlas.opco-atlas.fr/">
        https://myatlas.opco-atlas.fr/
      </a>
      .
    </p>
    <p>
      Vous utiliserez Vos Identifiants pour Vous connecter à Votre Compte et
      accéder à Votre espace de Services, personnalisé selon l’Organisation
      que Vous représentez et le choix des paramètres que vous avez définis.
    </p>
    <p>
      Toute connexion, utilisation des Services, consultation ou transmission
      de données réalisée via Votre Compte avec vos Identifiants est présumée
      avoir été effectuée personnellement par Vous et sous Votre
      responsabilité exclusive, sauf dénonciation écrite et dûment motivée
      transmise sous trente (30) jours à compter de la connexion à ATLAS par
      lettre recommandée avec accusé de réception à l’adresse suivante :
      Atlas, à l’attention de Webassistance, 25 quai Panhard et Levassor,
      75013 PARIS ou par courriel, adressé à{" "}
      <a href="mailto:webassistance@opco-atlas.fr">
        webassistance@opco-atlas.fr
      </a>{" "}
      en activant les fonctionnalités d’accusé de réception et de confirmation
      de lecture de ce message afin de vous assurer qu’il Nous soit bien
      remis.
    </p>
    <p>
      À aucun moment ATLAS ne saurait être tenu pour responsable de la perte
      de vos Identifiants ou de leur divulgation volontaire ou par Votre
      négligence à un tiers et des conséquences dommageables qui pourraient en
      résulter, sauf opposition préalable régulièrement notifiée par écrit à
      ATLAS, selon les modalités susvisées.
    </p>
    <h4>2.2.2 Utilisation du Service</h4>
    <p>
      La connexion à Votre Compte vous permet d’accéder au portail de Services
      en ligne myAtlas et à l’historique des états de suivi, Dossiers, données
      et documents s’y rapportant pour les 3 (trois) dernières années, soit
      l’année civile en cours et les 2 (deux) années précédentes. Pour la
      recherche d’une information sur une période antérieure, nous Vous
      invitons à contacter, nos services par l’intermédiaire de la
      fonctionnalité « Aide & Contacts » de Votre Compte.
    </p>
    <p>
      Si une notification de gestion ou une information importante doit Vous
      être communiquée concernant un Service myAtlas, nous Vous l’enverrons à
      l’adresse mél associée à Votre Compte.
    </p>
    <p>
      À cette fin, nous Vous recommandons de consulter régulièrement et de
      garder active l’adresse mél que Vous avez renseignée au moment de la
      création de Votre Compte.
    </p>
    <p>
      En utilisant myAtlas, vous Vous engagez à n’utiliser aucune information
      erronée, inexacte ou trompeuse ainsi qu’à respecter les présentes
      Conditions générales d’utilisation de ce Service.
    </p>
    <p>
      Atlas ne saurait être tenu pour responsable de la transmission de
      fausses informations, d’une usurpation d’identité ou du non-respect de
      l’habilitation requise pour représenter une Organisation.
    </p>
    <p>
      L’adresse mél de connexion à Votre Compte et l’identité de l’Utilisateur
      ne peuvent pas être modifiés.
    </p>
    <p>
      Si Vous souhaitez utiliser une autre adresse de connexion, ou si un
      autre Utilisateur souhaite disposer d’un Compte, il est possible de
      demander l’ouverture d’un autre Compte en complétant de nouveau le
      formulaire d’inscription au Service. Ce nouveau Compte permettra à son
      Utilisateur d’accéder, selon les mêmes Conditions, aux données et aux
      documents de l’Organisation représentée.
    </p>
    <p>
      Si Vous n’utilisez plus cette adresse mél ou que Vous n’êtes plus
      habilité à représenter l’Organisation, Vous devez demander auprès
      d’Atlas, en contactant notre service Webassistance, la fermeture des
      droits d’accès du Compte associé à cette adresse mél.
    </p>
    <p>
      Il est également de la responsabilité de l’Organisation représentée,
      d’informer ATLAS par ce même moyen, de tout changement affectant
      l’habilitation de l’Utilisateur à représenter l’Organisation concernée,
      quel qu’en soit le motif (perte de la qualité de représentant légal ou
      judiciaire, départ de l’Organisation ou changement de fonction, décès,
      révocation d’un mandat de représentation, …), nécessitant la
      modification ou la fermeture des droits d’accès du Compte.
    </p>
    <p>
      Nous Nous réservons le droit de refuser Votre Contenu s’il ne respecte
      pas les conditions de format, les limites de taille de fichier ou de
      stockage autorisées par le Service ainsi que les catégories de données
      requises par les traitements réalisés par Atlas.
    </p>
    <p>
      Ne sont en particulier pas autorisés sur le Service à l’exception des
      informations expressément requises, les données ou documents portant des
      informations relatives à la santé, d’ordre génétique ou biométrique,
      concernant l’origine raciale ou ethnique, la vie sexuelle ou
      l'orientation sexuelle, les opinions politiques, les convictions
      religieuses ou philosophiques et l'appartenance syndicale.
    </p>
    <h4>
      2.2.3 Validation des Opérations par l’authentification de l’Utilisateur
    </h4>
    <p>
      Il est expressément convenu entre Atlas et l’Utilisateur que la seule
      connexion de l’Utilisateur au Service, authentifiée par la saisie de ses
      Identifiants, vaudra certification et signature par l’Utilisateur des
      Opérations effectuées : déclaration des Contributions, dépôt ou demande
      de modification de Dossiers, télétransmission d’informations ou de
      pièces complémentaires, demande d’envoi d’état de suivi, utilisation du
      formulaire de contact d’Atlas ou toute autre Opération autorisée depuis
      le Service.
    </p>
    <p>
      Vous Vous engagez, pour les Opérations que vous réalisez par
      l’intermédiaire du Service, à fournir des informations et documents
      véridiques, exacts, actuels, complets et à tenir Atlas informé de toute
      erreur ou modification des éléments transmis.
    </p>
    <p>
      Afin de respecter les obligations légales auxquelles Atlas est tenu et
      de préserver ses intérêts, Nous menons des actions de détection et de
      lutte contre la fraude, selon les modalités de « Contrôle » prévues dans
      nos Conditions générales de fonctionnement.
    </p>
    <p>
      Le constat d’une fraude peut conduire Atlas, selon son appréciation, à
      appliquer la « Procédure de sanction aux obligations, anomalies ou
      irrégularités constatées » prévue par ces mêmes Conditions générales de
      fonctionnement, ainsi que les modalités prévues ci-après à l’article
      2.2.5 « résiliation du Contrat et fermeture des droits d’accès du Compte
      au Service ».
    </p>
    <p>
      Atlas peut également, selon les faits et dommages constatés, être amené
      à en effectuer le signalement aux autorités administratives et
      organismes de Contrôle compétents sur le champ de la formation
      professionnelle, ainsi qu’à faire valoir ses droits devant les
      juridictions compétentes ou à déclarer aux autorités compétentes les
      faits potentiellement constitutifs d’une infraction pénale, notamment
      celles relevant des articles 226-4-1, 313-1 à 313-3, 323-1 et 441-1 du
      Code pénal.
    </p>
    <p>
      La connexion au Service vaudra imputation de ces mêmes Opérations et
      responsabilités à l’Utilisateur déclaré représentant habilité par
      l’Organisation concernée comme à l’Utilisateur de son Mandant, lorsque
      l’Organisation concernée aura mandaté un tiers pour la représenter.
    </p>
    <h4>
      2.2.4 Conditions particulières applicables aux Dossiers de facturation
    </h4>
    <p>
      Le Compte attaché à un Prestataire ou à un CFA permet d’accéder aux
      Dossiers le concernant déposés par des Entreprises auprès d’Atlas.
    </p>
    <p>
      Les données relatives à ces Dossiers sont intégralement consultables et
      peuvent faire l’objet d’Opérations de facturation à partir du moment où
      leur financement a été accepté par Atlas, avec une subrogation de
      paiement au bénéfice du Prestataire ou du CFA concerné.
    </p>
    <p>
      Pour ces Dossiers, les factures doivent être déposées en ligne,
      uniquement sous format PDF pour lequel le texte a fait l’objet
      reconnaissance optique de caractères (« OCR » Optical character
      recognition) ; le format PDF, image seulement, ou d’autres formats
      électroniques de documents ne sont pas acceptés par Atlas.
    </p>
    <p>
      Il est nécessaire que l’ensemble des informations requises par les
      dispositions légales figurent sur les factures ainsi que le numéro de
      dossier Atlas (9 chiffres mentionnés en haut à gauche sur le courrier
      d’accord de prise en charge d’Atlas).
    </p>
    <p>
      La facture doit être émise au nom et à l’adresse de l’établissement
      indiqué sur l’accord de prise en charge d’Atlas.
    </p>
    <p>
      Les conditions détaillées et modalités de règlement des factures sont
      définies dans les Conditions générales de fonctionnement consultables
      sur le site Internet d’Atlas.
    </p>
    <h4>
      2.2.5 Résiliation du Contrat et fermeture des droits d’accès du Compte
      au Service
    </h4>
    <p>
      La résiliation du Contrat conclu entre Atlas et l’Utilisateur pour
      l’accès au Service et la modification ou la fermeture des droits d’accès
      de son Compte pourront intervenir à tout moment, sans motivation, à la
      demande de l’Utilisateur ou bien à la demande d’un représentant habilité
      par l’Organisation concernée quel que soit le motif de la perte de
      l’habilitation de l’Utilisateur.
    </p>
    <p>
      Si Vous souhaitez demander la fermeture des accès de Votre Compte ou du
      Compte d’un Utilisateur qui n’est plus habilité à représenter Votre
      Organisation, veuillez contacter Notre service Webassistance. Des
      informations ou des documents de Contrôle de Votre identité et de Votre
      habilitation à émettre cette demande pourront alors être requis par
      Atlas afin de valider cette action.
    </p>
    <p>
      La résiliation de ce Contrat et la fermeture des droits d’accès du
      Compte pourront également intervenir à l’initiative d’Atlas en cas de
      non-respect par l’Utilisateur des présentes Conditions, d’atteinte à la
      confidentialité ou à la sécurité du Service en raison des agissements de
      l’Utilisateur et à l’appréciation d’Atlas ou de la décision par Atlas de
      la fermeture définitive du Service, sans qu’il soit nécessaire de
      notifier préalablement et de motiver cette décision. Atlas pourra alors,
      de plein droit, sans aucune indemnisation et sans préjudice d’autres
      recours à sa disposition, suspendre la fourniture de tout ou partie des
      Services, et notamment l’accès au Compte, clôturer le Compte de
      l’Utilisateur et refuser toute nouvelle demande d’inscription.
    </p>
    <p>
      La fermeture des droits d’accès d’un Compte, à l’initiative de
      l’Utilisateur ou d’Atlas, a pour conséquence de mettre un terme au
      présent Contrat entre Atlas et l’Utilisateur et au droit d’utilisation
      du Service par l’Utilisateur.
    </p>
    <p>
      La résiliation du Contrat n’aura d’effet que pour l’avenir et ne
      remettra pas en cause la force probante ou la validité des Opérations
      effectuées, des données transmises ou des documents déposés par
      l’intermédiaire du Service, avant la date de résiliation.
    </p>
    <h2>3. Propriété intellectuelle et licence d’utilisation</h2>
    <h3>3.1 Droits détenus par Atlas</h3>
    <p>
      ATLAS demeure titulaire de tous les droits de propriété intellectuelle
      qu’il détient sur le Site et ses Services, notamment sur ses brevets,
      marques, droits d’auteur, dessins et modèles, savoir-faire, droits sur
      les bases de données, etc.
    </p>
    <p>
      Les droits de propriété intellectuelle d’ATLAS nécessaires à
      l’utilisation du Site et des Services Vous sont concédés en qualité
      d’Utilisateur, et non vendus, sous-licence limitée et révocable.
    </p>
    <p>
      Cette licence Vous est accordée pour utiliser le Service tel que défini
      par les présentes Conditions, à titre personnel et ne peut pas faire
      l’objet de sous-licences.
    </p>
    <p>
      ATLAS se réserve tous les droits qui ne sont pas expressément concédés
      dans le cadre des présentes Conditions.
    </p>
    <p>La présente licence ne vous concède notamment aucun droit de :</p>
    <ul>
      <li>
        copier, céder, louer, vendre, prêter, modifier, reproduire,
        représenter, télécharger, diffuser, exploiter commercialement ou
        distribuer, de quelque façon que ce soit et à quelque titre que ce
        soit, tout ou partie du contenu du Site, des Services et des logiciels
        permettant leur fonctionnement ;
      </li>
      <li>
        contourner ou passer outre les mesures de protection relatives au
        Site, aux Services et aux logiciels permettant leur fonctionnement ;
      </li>
      <li>
        désassembler, décompiler, déchiffrer, pénétrer illégalement, émuler,
        exploiter ou reconstituer tout aspect desdits logiciels ;
      </li>
      <li>
        utiliser le Site et les Services d’une manière non autorisée ou
        détournée.
      </li>
    </ul>
    <p>
      Plus généralement, le client s’interdit d’utiliser les Droits de
      Propriété Intellectuelle d’Atlas d’une manière qui ne serait pas
      expressément autorisée par les termes des présentes Conditions.
    </p>
    <p>
      Toute utilisation de ces droits de propriété intellectuelle, qu’elle
      soit partielle ou intégrale, sans autorisation expresse d’Atlas,
      exposera le contrevenant à des poursuites civiles et le cas échéant
      pénales.
    </p>
    <h3>3.2 Droits sur Votre Contenu</h3>
    <p>
      Afin de vous fournir les Services, ou de les fournir aux autres
      Utilisateurs (ce qui peut comprendre la modification de la taille, de la
      forme ou du format de Votre Contenu afin de vous offrir un meilleur
      stockage ou affichage), de vous protéger ainsi que de protéger les
      Services, et d’améliorer les produits et Services myAtlas, vous accordez
      à Atlas une licence sans restriction, irrévocable, perpétuelle et à
      titre gratuit pour utiliser votre Contenu, avec droit d’octroi illimité
      de sous-licences.
    </p>
    <p>
      Cette licence inclus le droit pour Atlas, au regard des finalités du
      Service, notamment de copier votre Contenu, de le conserver, de le
      transmettre, de modifier son format, de le diffuser via des outils de
      communications et de l'afficher sur les Services à destination des
      Utilisateurs ou des destinataires dûment habilités.
    </p>
    <p>
      Cette licence est accordée à titre non-exclusif à Atlas, pour le monde
      entier et pour toute la durée de protection du Contenu des Utilisateurs
      par le droit d’auteur qui s’y appliquerait.
    </p>
    <h2>4. Données à caractère personnel</h2>
    <h3>4.1 Principes généraux</h3>
    <p>
      Une donnée à caractère personnel (ci-après, « Donnée(s)
      personnelle(s) ») est constituée par toute information se rapportant à
      une personne physique (ci-après, « Personne(s) concernée(s ») identifiée
      ou identifiable que ce soit directement (par l’intermédiaire notamment
      de ses nom et prénom, adresse mél, …) ou indirectement (notamment par
      recoupement de données telles que la date de naissance, la nationalité,
      l’adresse de domiciliation, …).
    </p>
    <p>
      Le Règlement 2016/679 (UE) sur la protection des données, dit règlement
      général sur la protection des données (ci-après, « RGPD »), applicable
      dans l’Union Européenne depuis le 25 Mai 2018, définit les conditions
      dans lesquelles les traitements de données à caractère personnel peuvent
      être opérés pour toute personne physique ou réalisés par tout organisme
      se trouvant sur le territoire européen.
    </p>
    <h3>4.2 Données personnelles que Nous utilisons</h3>
    <p>
      Les services en ligne myAtlas requièrent l’utilisation de Données
      personnelles, en premier lieu, pour la création d’un Compte, le
      formulaire d’inscription mentionné ci-dessus collecte les catégories de
      données relatives à l’identité de l’Utilisateur, des informations liées
      à son activité ainsi que ses coordonnées professionnelles.
    </p>
    <p>
      D’autre part, l’utilisation d’un Compte implique la conservation par
      Atlas des Identifiants nécessaires à l’authentification de Votre
      connexion, l’utilisation de cookies strictement nécessaires au
      fonctionnement technique du Service ou à la mesure anonyme de
      l’audience. Par ailleurs, afin de garantir la sécurité du Service et la
      traçabilité des activités sur les Comptes, nous procédons à la
      journalisation des accès Utilisateur et des principales Opérations
      réalisées depuis le Service.
    </p>
    <p>
      Les Utilisateurs des services en ligne myAtlas transmettent et
      consultent également, pour le compte des Organisations qui les ont
      dûment habilités à les représenter, des Données personnelles relatives
      aux stagiaires ou tout autre bénéficiaire d’actions financées par Atlas.
    </p>
    <h3>4.3 Finalités et bases légales de Nos traitements</h3>
    <p>
      Les Données personnelles transmises via les services en ligne MyAtlas
      sont utilisées principalement pour le fonctionnement des Comptes
      utilisateurs myAtlas, la collecte de la Contribution unique au titre de
      la formation professionnelle et de l’alternance (ci-après, la
      « Cufpa »), des Contributions conventionnelles ou versements volontaires
      complémentaires, la gestion des Dossiers financés par Atlas ainsi que la
      promotion des Services déployés par Atlas en sa qualité d’OPCO.
    </p>
    <p>
      Vos informations de contact et plus particulièrement Votre adresse mél
      sont utilisées par Atlas pour Vous communiquer des alertes et
      notifications de mise à disposition dans myAtlas, de documents tels que
      notamment des reçus de versement des Contributions, des courriers de
      demande de pièces ou d’informations complémentaires, d’accord ou de
      refus de financement et de paiement, des états de suivi des engagements
      de financement et autres documents administratifs concernant les
      Organisations représentées. Nous utilisons également Votre adresse mél
      pour répondre aux sollicitations que Vous Nous adressez par
      l’intermédiaire du formulaire « Aide & Contacts » ou de toute adresse
      mél d’Atlas.
    </p>
    <p>
      Les Données personnelles sont également utilisées notamment dans le
      cadre de Contrôles prévus dans les Conditions générales de
      fonctionnement d’Atlas, d’analyses statistiques anonymisées, la
      réalisation d’études et d’enquêtes de suivi ou d’évaluation de la
      satisfaction s’inscrivant dans les missions de l’OPCO.
    </p>
    <p>
      Les bases légales des traitements mis en place par Atlas relèvent
      principalement de l’exercice de Nos missions d’intérêt public et du
      respect des obligations légales et réglementaires qui y sont attachées,
      de l'exécution des mesures contractuelles conclues avec nos Adhérents,
      Prestataires ou CFA et partenaires, ainsi que de la poursuite de Nos
      intérêts légitimes.
    </p>
    <h3>
      4.4 Destinataires des données, mesures de sécurité et délais de
      conservation
    </h3>
    <p>
      Atlas porte une attention particulière aux mesures techniques et
      organisationnelles de sécurité appliquées aux traitements de Données
      personnelles et met en place une gestion des habilitations ainsi qu’une
      traçabilité des accès afin de garantir que chaque destinataire du
      personnel d’Atlas ou de ses sous-traitants est bien habilité à traiter
      les informations en fonction de ses rôle et activité.
    </p>
    <p>
      Atlas est également susceptible de transférer des données à des
      Partenaires institutionnels, conformément aux obligations légales et
      réglementaires ou contractuelles applicables, ainsi qu'à ses
      sous-traitants qui s'engagent à respecter un degré de protection a
      minima équivalent aux mesures nécessaires au respect du RGPD appliquées
      par Atlas.
    </p>
    <p>
      En outre, dans le cadre d'échanges de données personnelles, pour
      certaines sensibles au sens du RGPD, Atlas contribue avec ses Adhérents,
      Prestataires ou CFA, sous-traitants et Partenaires à déterminer des
      procédés de transferts et de traitement sécurisés appropriés.
    </p>
    <p>
      Des mesures de sécurité renforcées sont appliquées pour les traitements
      (actions financées par Atlas) nécessitant l’utilisation du Numéro
      d'inscription au répertoire national d'identification des personnes
      physiques (communément appelé « NIR » ou « NIA » et composante du numéro
      de sécurité sociale pour un bénéficiaire de l’Assurance-Maladie en
      France). Cette donnée est notamment utilisée pour répondre à
      l’obligation légale définie par l’
      <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000037461056">
        article L6353-10 alinéa 2 du code du travail
      </a>{" "}
      imposant le partage entre organismes financeurs de données relatives aux
      actions financées sous forme dématérialisée par l’intermédiaire du
      système d’information du compte personnel de formation (« SI-CPF »),
      dans le cadre de la plateforme « AGORA », mis en œuvre par la Caisse des
      dépôts et consignation (CDC) pour le compte des services de l’État par
      l’intermédiaire de la « DGEFP » (Délégation Générale de l’Emploi et de
      la Formation Professionnelle).
    </p>
    <p>
      Les Données personnelles traitées par Atlas sont conservées, de façon
      générale, le temps nécessaire pour la réalisation des finalités de
      chaque traitement et le respect des délais légaux de conservation avant
      de faire si nécessaire l'objet d'un archivage en respect de délais
      légaux de prescription d’éventuels contentieux ou infractions pénales.
    </p>
    <p>
      Atlas tient à jour la documentation requise au titre de l'obligation
      « d'accountability », et coopère aux demandes d’informations relatives
      aux traitements de Données personnelles qu'il effectue.
    </p>
    <p>
      Pour plus de précisions, nous Vous invitons à consulter les
      informations, régulièrement mises à jour sur Notre site Internet, sur
      Nos{" "}
      <a href="https://www.opco-atlas.fr/rgpd.html">
        traitements de données à caractère personnel
      </a>{" "}
      ainsi que concernant l’utilisation de{" "}
      <a href="https://www.opco-atlas.fr/cookies.html">cookies et traceurs</a>{" "}
      lors de Votre navigation sur ce Service.
    </p>
    <h3>4.5 Droits des personnes</h3>
    <p>
      Toute personne identifiée dans le cadre du traitement de Données
      personnelles, dispose d’un droit d’accès, de rectification,
      d’effacement, de limitation, d'opposition pour motifs légitimes et à la
      portabilité des informations la concernant, ainsi que du droit d’établir
      des directives relatives au sort de ses données après décès.
    </p>
    <p>
      Ces droits peuvent être exercés par les Personnes concernées en
      contactant Notre délégué à la protection des données par courriel
      adressé à <a href="mailto:dpo@opco-atlas.fr">dpo@opco-atlas.fr</a> ou en
      écrivant à l’adresse suivante : Atlas, à l’attention personnelle du DPO,
      25 quai Panhard et Levassor, 75013 Paris. Cette demande doit être
      accompagnée de la copie d’un titre d’identité en cours de validité.
    </p>
    <p>
      Atlas rappelle enfin que chaque personne physique estimant, après Nous
      avoir contacté, que les droits sur ses données ne sont pas respectés,
      peut adresser une réclamation à la CNIL.
    </p>
    <h3>
      4.6 Obligations des Organisations transmettant et consultant des Données
      personnelles via myAtlas
    </h3>
    <p>
      Compte tenu du caractère indirect de la collecte de certaines Données
      personnelles par Atlas, il est rappelé aux Entreprises, Prestataires ou
      CFA qu'ils conservent, au sens du RGPD, la responsabilité de mentionner
      dans leur registre des activités de traitement les données transmises à
      Atlas, de délivrer l’information appropriée aux Personnes concernées
      ainsi que de respecter l’exercice de leurs droits.
    </p>
    <p>
      Si une Organisation a mandaté (le « Mandant ») un sous-traitant pour la
      représenter (le « Mandataire ») et a habilité son personnel à utiliser
      un Compte myAtlas, tous deux s’engagent à respecter l’ensemble des
      exigences définies par l’article 28 du RGPD et notamment à veiller à la
      mise en œuvre des mesures de sécurité appropriées pour préserver la
      confidentialité, l’exactitude et l’intégrité des Données personnelles
      saisies, consultées ou téléchargées depuis le Compte, à assurer
      l’information des Personnes concernées et le respect de leurs droits.
      Atlas ne saurait être tenu pour responsable du non-respect de ces
      exigences par le Mandant ou par son Mandataire.
    </p>
    <p>
      Si Vous téléchargez ou copiez Votre Contenu depuis le Service, la
      responsabilité de la sécurité de ces éléments et la licéité de leur
      conservation, notamment à l’égard des obligations légales relatives à la
      protection des Données personnelles, relève de Votre seule
      responsabilité.
    </p>
    <h2>5. Disponibilité, maintenance et mise à jour du Service</h2>
    <p>
      Le Service est en principe disponible 24 (vingt-quatre) heures sur 24
      (vingt-quatre) et 7 (sept) jours sur 7 (sept) à l’exception de la
      survenance d’un évènement de force majeure, d’un incident technique, de
      l’identification d’un risque de sécurité pour le Service nécessitant de
      déployer une mesure de sauvegarde et des périodes de maintenance ou de
      mise à jour.
    </p>
    <p>
      En cas d’incident technique, Atlas fera ses meilleurs efforts pour
      corriger toute anomalie, erreur ou dysfonctionnement et rétablir la
      disponibilité du Service dans les meilleurs délais.
    </p>
    <p>
      Nous améliorons en permanence le Service et nous pouvons à tout moment
      modifier ou supprimer des fonctionnalités, faire évoluer les Conditions
      d’utilisation du Service, prendre en compte des contraintes légales ou
      cesser de Vous fournir un accès.
    </p>
    <p>
      ATLAS pourra rendre le Service momentanément indisponible afin de
      réaliser toute opération de maintenance, de sécurité, de mise à jour ou
      d’amélioration.
    </p>
    <p>
      Sauf urgence pour la disponibilité ou la sécurité du Service, ces
      interruptions auront lieu sur des périodes de moindre utilisation du
      Service et seront les plus courtes possibles. Nous Vous avertirons, dans
      la mesure du possible, de toute évolution du Service susceptible
      d'interrompre partiellement ou totalement l’accès à Votre Contenu ou
      d’en modifier significativement les fonctionnalités.
    </p>
    <p>
      ATLAS ne peut en aucun cas être tenu responsable pour l’indisponibilité
      qu’elle soit temporaire ou permanente du Service, résultant notamment
      d’un non-fonctionnement, d'une impossibilité d'accès, d’une suspension
      ou interruption du Service, ou de mauvaises conditions d'utilisation du
      Service, pour quelle que cause que ce soit.
    </p>
    <p>
      Bien que nous mettions en œuvre tous les moyens afin d’assurer en
      permanence la disponibilité du Service, il se peut que celui-ci soit
      interrompu à tout moment. Aussi Nous Vous recommandons de sauvegarder
      régulièrement Votre Contenu stocké sur le Service.
    </p>
    <p>
      ATLAS décline également toute responsabilité pour les conséquences liées
      au fonctionnement dégradé du Service, résultant du refus de cookies par
      l'Utilisateur ou de l'impossibilité pour le gestionnaire du Site
      d'enregistrer ou de consulter les cookies strictement nécessaires au
      fonctionnement du Service, du fait du choix de l'Utilisateur.
    </p>
    <h2>6. Responsabilités</h2>
    <p>
      Les Utilisateurs qui accèdent au Service garantissent à ATLAS qu’ils
      détiennent l’intégralité des droits, autorisations et pouvoirs
      nécessaires pour accepter les présentes Conditions et utiliser le
      Service.
    </p>
    <p>
      Les éléments ou les actions qui enfreignent ces Conditions ne sont pas
      autorisés.
    </p>
    <p>
      En acceptant ces Conditions, Vous avez pour obligation de Vous conformer
      aux règles qui suivent :
    </p>
    <ul>
      <li>
        N’enfreignez pas la Loi ou toute autre convention internationale,
        disposition réglementaire, circulaire, directive ou ligne directrice
        administrative en vigueur.
      </li>
      <li>
        Ne transgressez pas les termes de nos accords, au titre des présentes
        CGU.
      </li>
      <li>
        Ne détournez pas volontairement les restrictions d’accès,
        d’utilisation, de sécurité ou de disponibilité du Service.
      </li>
      <li>
        N’envoyez pas de spams, de phishing, de virus ou autre élément de
        cyber-attaque malveillante qui menacerait la sécurité du Service,
        d’Atlas ou de tiers.
      </li>
      <li>
        Ne Vous engagez pas dans l’usage d’un faux nom, d’une fausse qualité,
        l’abus d’une qualité vraie ou l'emploi de manœuvres frauduleuses dans
        le but de tromper Atlas ou un tiers.
      </li>
      <li>
        Ne menez pas des activités qui vous seraient nuisibles, seraient
        nuisibles au Service ou porteraient atteinte aux droits de tiers,
        selon l’appréciation d’Atlas (ex. : exercice d’une activité illicite
        ou qui pourra raisonnablement être considérée comme diffamatoire,
        calomnieuse ou harcelante à l’égard d’une personne physique, d’une
        personne morale ou d’une institution, haineuse, tendancieuse ou
        offensante envers des races, des ethnies, des religions ou autres,
        insultante, vulgaire, pornographique ou obscène, portant atteinte aux
        obligations de confidentialité, droits d'auteur, brevets, marques,
        secrets commerciaux ou tout autre droit de propriété intellectuelle
        exclusif de tiers).
      </li>
      <li>
        N’exercez pas d’activités qui contreviennent à la protection des
        Données personnelles.
      </li>
      <li>
        Sauf si Nous l’acceptons expressément par écrit, n’utilisez pas le
        Service pour des activités publicitaires, commerciales ou
        promotionnelles ou pour impliquer un quelconque soutien ou partenariat
        d’Atlas au bénéfice de Votre propre activité.
      </li>
      <li>N’aidez personne à enfreindre les présentes règles.</li>
    </ul>
    <p>
      Si Vous enfreignez l’une quelconque de ces règles ou de toute autre
      manière les présentes Conditions, nous pourrons prendre des mesures à
      Votre encontre, y compris (sans limitation) cesser de Vous fournir le
      Service, fermer les droits d’accès de Votre Compte sans délai ou bloquer
      l’envoi d’une communication (ex. un courriel, un message instantané, un
      document, …) vers ou depuis le Service.
    </p>
    <p>
      Nous pourrons également, le cas échéant, faire valoir nos droits par
      voie amiable ou juridictionnelle ainsi que déclarer auprès de l’autorité
      compétente toute action dont nous pourrions penser, selon notre
      appréciation, qu’elle est susceptible de constituer une infraction
      pénale ou un manquement à un obligation légale.
    </p>
    <p>
      Le Service peut inclure des liens hypertextes vers d'autres sites
      Internet édités par des tiers. Dans la mesure où ATLAS ne peut pas
      contrôler ces sites tiers, il ne peut être tenu pour responsable de leur
      mise à disposition, et sa responsabilité ne saurait engagée quant aux
      contenus, publicités, produits, services ou tout autre élément
      disponible à partir de ces sites tiers ni de tous dommages ou pertes
      avérés ou allégués en relation avec l'utilisation des informations, des
      biens ou des services disponibles sur ces sites tiers.
    </p>
    <h2>7. Droit applicable, langue d’usage et juridiction compétente</h2>
    <p>
      Le droit applicable à la relation pré-contractuelle et contractuelle
      dans le cadre de l’application des présentes Conditions et de
      l’utilisation du Service est le droit français.
    </p>
    <p>
      La langue utilisée durant la relation pré-contractuelle, contractuelle
      ou post-contractuelle est le français.
    </p>
    <p>
      Tout différend ou litige relatif à la validité, à l’interprétation et à
      l’exécution des présentes Conditions, ne pouvant être résolu à
      l’amiable, relèvera de la compétence exclusive des tribunaux judiciaires
      de Paris.
    </p>
    <p>
      Dans l'hypothèse où des stipulations du Contrat seraient considérées
      nulles, inapplicables ou inopposables par toute juridiction compétente,
      les autres stipulations resteront valables, applicables et opposables.
    </p>
    <h2>8. Modification des présentes Conditions</h2>
    <p>
      Les présentes Conditions révisées remplacent les Conditions précédemment
      appliquées et entrent en vigueur à la date du 15/10/2024, pour une durée
      illimitée jusqu’à l’entrée en vigueur de nouvelles Conditions.
    </p>
    <p>
      Toute mesure législative ou réglementaire, comme toute interprétation
      des dispositions légales ou réglementaires en vigueur par une autorité
      judiciaire ou administrative compétente, qui aurait pour effet de
      modifier tout ou partie du présent Contrat, sera applicable dès son
      entrée en vigueur.
    </p>
    <p>
      Nous pouvons modifier ces Conditions à tout moment en publiant une
      nouvelle version sur ce Site afin de les mettre en conformité avec des
      évolutions techniques ou fonctionnelles, la modification des conditions
      d’utilisation du Service ou la prise en compte d’exigences légales ou
      réglementaires.
    </p>
    <p>
      Toutes Conditions ainsi révisées seront immédiatement applicables, dès
      la date de leur publication, en remplacement des Conditions précédemment
      en vigueur, à l’égard de tous les Utilisateurs précédemment ou
      nouvellement inscrits pour accéder au Service.
    </p>
    <p>
      Votre utilisation continue de myAtlas est soumise à la version la plus
      récente des termes des présentes Conditions. À ce titre, Vous
      reconnaissez et acceptez que Votre utilisation ou simplement Votre accès
      au Service à partir de la date d’entrée en vigueur des modifications
      vaudra acceptation des nouvelles Conditions.
    </p>
    <p>
      Par conséquent, Nous Vous encourageons à consulter régulièrement cette
      page pour prendre connaissance des dernières Conditions applicables.
    </p>
    <p>
      Si Vous refusez la modification de ces Conditions, Vous devez cesser
      d'utiliser le Service et demander la fermeture des droits d’accès de
      Votre Compte.
    </p>
    <p>
      En cas de modification majeure, la continuité de l’accès au Service
      pourra, à la discrétion d’Atlas, être subordonnée à l’acceptation
      expresse d’une nouvelle version des Conditions.
    </p>
  </AtlasHtml>
}

export default function Cgu() {
  return (
    <>
      <PageHeader>Conditions générales d'utilisation des services en ligne myAtlas</PageHeader>

      <AtlasColumns>
        <CguContent />
      </AtlasColumns>
    </>
  );
}
