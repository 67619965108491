import DurationFloatingField, { durationHours, durationMinutes } from 'components/reform/inputs/DurationFloatingField'
import { OptionalNumber } from 'components/yop/constraints'
import { get, set } from 'lodash-es'
import CerfaDurationField from 'pages/dossier/cerfa/CertaDurationField'
import ExportDurationField from 'pages/dossier/export/ExportDurationField'
import { FieldMapping } from 'pages/dossier/metadata/DossierMapping'
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import RecapDurationField from 'pages/dossier/recap/RecapDurationField'

export function floatingDurationMapping<T extends number | null>(path: string, source?: string): FieldMapping<T> {
    return {
        source,
        mapModelToDTO: (value: T, dto: any) => set(dto, path, value),
        mapDTOToModel: (dto: any, context) => {
            const value = (get(dto, path) ?? null) as T
            context.currentModelContext!.model[context.currentModelContext!.key + "_duration"] = {
                hours: durationHours(value) ?? null,
                minutes: durationMinutes(value) ?? null,
            }
            return value
        }
    }
}

export function DurationFieldMetadata(settings: SettingsFieldMetadata<number | null> = {}) {
    return createMetadata(
        {
            yop: OptionalNumber,
            input: DurationFloatingField,
            recap: RecapDurationField,
            export: ExportDurationField,
            cerfa: CerfaDurationField,
        },
        settings,
    )
}
