import styles from 'pages/dossier/cerfa/global.styles'
import { Style } from '@react-pdf/types'
import { Text } from '@react-pdf/renderer'

interface CerfaDurationMnFieldProps {
    value: { hours: number, minutes: number } | null | undefined
    style?: Style | Style[]
}

export default function CerfaDurationMnField(props: CerfaDurationMnFieldProps) {
    if (props.value === null || props.value === undefined) {
        return null
    }

    const style = props.style ?? styles.user

    return (
        <Text>
            <Text style={ style }>
                { props.value.hours ?? '0'}
            </Text>
            <Text> heures</Text>{' '}
            <Text style={ style }>
                { props.value.minutes ?? '00'}
            </Text>
            <Text> minutes</Text>
        </Text>
    )
}
