import { RootState } from "app/rootReducer"
import { useSelector } from "react-redux"
import { BaseDossierComponentProps } from "pages/dossier/useDossier"
import { Form, UseFormReturn } from "@dsid-opcoatlas/reform"
import { ClassConstructor } from "./metadata"
import { FormMetadata } from "pages/dossier/metadata/FormMetadata"
import { Entreprise, References } from "api/referencesAPI"
import React from "react"

export type ElementsContextValue<T = any> = {
    model: ClassConstructor<T>,
    metadata: FormMetadata<any>,
    entreprise: Entreprise | null,
    references: References | null,
    map: { [key: string]: any }
}

export const ElementsContext = React.createContext<ElementsContextValue | null>(null)

export function useElementsContext<T>() {
    return React.useContext(ElementsContext) as ElementsContextValue<T>
}

export function ElementsForm(props:
    BaseDossierComponentProps/* TODO: <BaseDossier> */ &
    {
        children: React.ReactNode 
        form: UseFormReturn<any> // TODO: <BaseDossier>
        disabled?: boolean
    }) {

    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const { fulfilled: references } = useSelector((state: RootState) => state.referencesState)

    const elementsContext: ElementsContextValue = {
        model: props.metadata.model!,
        metadata: props.metadata,
        entreprise,
        references,
        map: {}
    }

    return (
        <ElementsContext.Provider value={ elementsContext }>
            <Form context={ props.form } autoComplete="off" noValidate disabled={ props.disabled }>
                { props.children }
            </Form>
        </ElementsContext.Provider>
    )
}
