import { Text, View } from '@react-pdf/renderer'
import styles from 'pages/dossier/cerfa/global.styles'
import Checkbox from 'pages/dossier/cerfa/components/Checkbox'
import { DossierProA, ProAModuleMetadata } from 'pages/dossier-proa/ProAFormMetadata'
import CerfaElement from 'pages/dossier/cerfa/CerfaElement'
import CerfaTextField from 'pages/dossier/cerfa/CerfaTextField'

export default function Formation({ dossier }: { dossier: DossierProA }) {
  const module = dossier.modules[0]

  return (
    <View style={styles.section}>
      <Text style={styles.sectionHeader}>LA FORMATION</Text>
      <View style={styles.sectionContent}>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>
              Dénomination de l'organisme de formation responsable&nbsp;:
            </Text>
            <Text style={styles.user}>{ module?.cfa?.nom ?? ' '}</Text>
            <View style={styles.bordered}>
              <Text>S'agit-il d'un service de formation interne :</Text>
              <Text>
                <CerfaElement metadata={ ProAModuleMetadata!.formationInterne } value={ module.formationInterne }/>
              </Text>
              <Text style={[styles.small, styles.bold, styles.italic]}>
                <Checkbox checked={ module.attestationFormationInterne }></Checkbox>{' '}
                L'employeur atteste que le service de formation interne dispose
                des moyens nécessaires à la réalisation de la formation du
                salarié.
              </Text>
            </View>
          </View>
          <View style={styles.colRight}>
            <Text>N° SIRET de l'organisme de formation responsable :</Text>
            <CerfaTextField value={ module?.cfa?.siret } />
            <Text>N° de déclaration d'activité de cet organisme :</Text>
            <CerfaTextField value={ module?.cfa?.numeroDA } />
            <Text>
              Nombre d'organismes de formation intervenant :{' '}
              <Text style={styles.user}>1</Text>
            </Text>
          </View>
        </View>
        <View style={styles.cols}>
          <View style={styles.tripleCol}>
            <Text>
              Type qualitification visée :{' '}
              <CerfaElement metadata={ ProAModuleMetadata!.typeQualification } value={ module.typeQualification }/>
            </Text>
          </View>
          <View style={styles.tripleCol}>
            <Text>
              Certification visée :{' '}
              <Text style={styles.user}>
                <CerfaElement metadata={ ProAModuleMetadata!.certificationVisee } value={ module.certificationVisee }/>
              </Text>
            </Text>
          </View>
          <View style={styles.tripleColLast}>
            <Text>
              Code RNCP :{' '}
              <CerfaElement metadata={ ProAModuleMetadata!.codeRNCP } value={ module.codeRNCP }/>
            </Text>
          </View>
        </View>
        <Text>
          Intitulé précis :{' '}
          <CerfaElement metadata={ ProAModuleMetadata!.intitulePrecis } value={ module.intitulePrecis }/>
        </Text>
        <Text>
          Spécialité de formation :{' '}
          <CerfaElement metadata={ ProAModuleMetadata!.specialiteFormation } value={ module.specialiteFormation }/>
        </Text>
        <Text style={styles.bold}>Organisation de la formation :</Text>
        <Text>
          Durée totale des actions d'évaluation, d'accompagnement et des
          enseignements :{' '}
          <CerfaElement metadata={ ProAModuleMetadata!.dureeEvaluation } value={ module.dureeEvaluation } />
        </Text>
        <Text>
          <CerfaElement metadata={ ProAModuleMetadata!.dureeTotale } value={ module.dureeTotale } />
          <Text>dont durée des enseignements généraux, professionnels et technologiques :{' '}</Text>
          <CerfaElement metadata={ ProAModuleMetadata!.dureeEnseignement } value={ module.dureeEnseignement } />
        </Text>
        <View style={styles.cols}>
          <View style={styles.colLeft}>
            <Text>Date de début du cycle de formation</Text>
            <CerfaElement metadata={ ProAModuleMetadata!.dateDebutFormation } value={ module.dateDebutFormation } />
          </View>
          <View style={styles.colRight}>
            <Text>Date prévue de fin des épreuves ou examens :</Text>
            <CerfaElement metadata={ ProAModuleMetadata!.dateExamen } value={ module.dateExamen } />
          </View>
        </View>

        <Text> </Text>

        <View style={styles.signatureSection}>
          <Text>
            Fait le :{' '}
            <CerfaTextField type="date" value={dossier.signature.date}></CerfaTextField>{' '}
            à{' '}
            <CerfaTextField value={dossier.signature.lieu}></CerfaTextField>{' '}
          </Text>
          <View style={styles.cols}>
            <View style={styles.colLeft}>
              <Text style={[styles.boldItalic, styles.center]}>
                Signature de l'employeur
              </Text>
            </View>
            <View style={styles.colRight}>
              <Text style={[styles.boldItalic, styles.center]}>
                Signature du salarié
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
