import { useFormContext } from '@dsid-opcoatlas/reform'
import { AtlasInfo } from 'atlas-ds'
import { AtlasHtml } from 'atlas-ds/atlas/components/value/AtlasHtml/AtlasHtml'
import { BaseDossier } from 'slices/dossierCommon'

interface ModeleInfo<D extends BaseDossier> {
    when: (dossier?: D) => boolean
    label: string
    href: string
}

interface ModelePieceProps<D extends BaseDossier> {
    modeles: ModeleInfo<D>[]
}

export default function ModelePiece<D extends BaseDossier>(props: ModelePieceProps<D>) {
    const context = useFormContext<D>()

    if (!context.values)
        return null

    const modeles = props.modeles.filter(m => m.when(context.values!))
    const s = modeles.length > 1 ? 's' : ''
    return modeles.length > 0 ? <AtlasInfo title={`Document${s} à transmettre`}>
        <AtlasHtml>
            <p>Remplissez et transmettez-nous le{s} document{s} suivant{s} :</p>
            <ul>
                { modeles.map(modele => <li key={ modele.label }><a href={ modele.href } rel="noopener noreferrer" target="_blank">{ modele.label }</a></li>)}
            </ul>
        </AtlasHtml>
    </AtlasInfo> : null
}
