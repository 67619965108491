import { findDispositifIds } from 'api/config'
import { getAllDispositifFO } from 'api/references'
import useDossiersModuleContext from 'app/entreprise/mes-dossiers/useDossiersModuleContext'
import { AtlasButton, AtlasTooltip, MyDropdownMenu } from 'atlas-ds'
import Link from 'components/Link'
import { useMemo } from 'react'

export interface newRequestProps {
    menu: string
}

export default function NewRequest(props: newRequestProps) {
    const ctx = useDossiersModuleContext()
 
    // Filtre les dispositifs listés dans la config du menu et qui ont des metadata de création
    // Note: les dispositifs dans ctx.dossierModules sont déjà filtrés par branche
    const creationMetadata = useMemo(() => {
        const dispositifs = getAllDispositifFO()
        const menuIds = findDispositifIds(props.menu)
        return ctx?.creationMetadata
            ?.filter(m => m?.create != null && menuIds?.includes(m.create.dispositif))
            ?.map(m => ({ 
                pathname: m.create!.pathname, 
                Libelle__c: dispositifs?.find(d => d.Id === m.create!.dispositif)?.Libelle__c ?? '',
            }))
            ?.sort((m1: any, m2: any) => m1.Libelle__c.localeCompare(m2.Libelle__c ?? ''))
    }, [ctx, props.menu])
    
    // Aucun dispositif disponible => probablement compte en cours de création
    if ((creationMetadata?.length ?? 0) === 0) {
        return <AtlasTooltip opener={<AtlasButton icon="hourglass" disabled>
            Nouvelle demande de prise en charge
        </AtlasButton>}>
            Vous pourrez bientôt effectuer une demande de prise en charge, mais nos données sont actuellement en cours de synchronisation. Merci de réessayer ultérieurement.
        </AtlasTooltip>
    }

    return (<>
        <MyDropdownMenu
            items={ creationMetadata!.map(md => <Link icon="alternance" to={ md.pathname }>{ md.Libelle__c }</Link>) }
        >Nouvelle demande de prise en charge</MyDropdownMenu>
    </>)
}
