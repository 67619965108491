import { formatDate, formatSuffix } from 'components/format/Format'
import RecapAtlasValue from './RecapAtlasValue'
import { NIR_HIDE } from 'api/references'

interface RecapTextFieldProps {
    label: string,
    value: string | Date | null | undefined,
    initialValue?: string | Date | null | undefined,
    suffix?: string,
    type?: string,
    editMode?: boolean
    name?: string
}


export default function RecapTextField(props: RecapTextFieldProps) {
    const suffix = props.suffix ? (' ' + props.suffix) : ''

    let value = ''
    if (props.value) {
        if (props.type === 'date')
            value = formatDate(props.value) + suffix
        else if (props.type === 'number' && typeof props.value === 'string' && parseFloat(props.value))
            value = props.value + formatSuffix(suffix, parseFloat(props.value) > 2)
        else
            value = props.value + suffix
    }

    let initialValue = ''
    if (props.initialValue) {
        if (props.type === 'date')
            initialValue = formatDate(props.initialValue) + suffix
        else if (props.type === 'number' && typeof props.initialValue === 'string' && parseFloat(props.initialValue))
            initialValue = props.initialValue + formatSuffix(suffix, parseFloat(props.initialValue) > 2)
        else
            initialValue = props.initialValue + suffix    
    }
   
    if (props.name === 'stagiaire.nir' || props.name === 'salarie.nir')
        initialValue = NIR_HIDE

    if (!initialValue && !value)
        return null

    return <RecapAtlasValue label={props.label} initialValue={initialValue} value={value} editMode={props.editMode || false} />
}
