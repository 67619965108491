import { Reference, References } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { formatRef } from 'components/format/Format'
import { useSelector } from 'react-redux'
import RecapAtlasValue from './RecapAtlasValue'

interface RecapRefFieldProps {
    label: string
    options: (refs: References, parent?: any, root?: any) => Reference[] | undefined
    value: string | null | undefined
    initialValue?: string | null | undefined,
    parent?: any
    root?: any
    code?: boolean
    reduit?: boolean
    libelle?: boolean
    editMode?: boolean
}

export default function RecapRefField(props: RecapRefFieldProps) {

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)

    const options = refs ? (props.options(refs, props.parent, props.root) ?? []) : []

    const value = formatRef(options, props.value, {
        code: props.code,
        libelle: props.libelle ? 'complet' : (props.reduit ? 'reduit' : 'complet')
    })
    const initialValue = formatRef(options, props.initialValue, {
        code: props.code,
        libelle: props.libelle ? 'complet' : (props.reduit ? 'reduit' : 'complet')
    })

    if (!initialValue && !value)
        return null

    return <RecapAtlasValue label={props.label} initialValue={initialValue} value={value} editMode={props.editMode || false} />
}

