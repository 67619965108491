import { Form, useForm } from '@dsid-opcoatlas/reform'
import { BaseSearchQuery } from 'api/baseAPI'
import { RootState } from 'app/rootReducer'
import { AtlasHeading, AtlasSpacer, MyForm, MyModal, MyTags } from 'atlas-ds'
import { RequiredEmail, optionalObject } from 'components/yop/constraints'
import log from "loglevel"
import { useSelector } from 'react-redux'
import { ToastService } from './BandeauAlert'
import Button from './reform/Button'
import TextField from './reform/inputs/TextField'

interface ExportModalProps<Q> {
    query: Q
    show: boolean
    export: (query: Q, email: string) => Promise<string>
    close: () => void
    children: JSX.Element[]
    initialValues?: any
}

export default function ExportModal<Q extends BaseSearchQuery>(props: ExportModalProps<Q>) {

    const { profil } = useSelector((rootState: RootState) => rootState.profilState)

    const initialValues = {
        ...props.initialValues,
        exportMail: profil?.Contact?.Email ?? ''
    }

    const validationSchema = optionalObject<typeof initialValues>({
        exportMail: RequiredEmail
    })

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            props.export(props.query, context.values!.exportMail)
                .then(message => {
                    ToastService.showToast({
                        label: "Export",
                        type: "success",
                        content: message
                    })
                })
                .catch(e => {
                    log.error('Erreur export ' + props.query, e)
                    ToastService.showToast({
                        label: "Export",
                        type: "error",
                        content: "Une erreur est survenue durant l'export"
                    })
                })
                .finally( () => {
                    props.close()
                })
        },
    })

    return <>
        <MyModal
            show={ props.show }
            onClose={ props.close }
            label="Exporter mes données filtrées"
        >
            <Form context={form} noValidate>
                <AtlasHeading tag="h3" size="s">Filtres sélectionnés</AtlasHeading>
                <MyTags>{ props.children }</MyTags>

                <AtlasSpacer size="xl" />

                <AtlasHeading tag="h3" size="s">Choix du destinataire</AtlasHeading>

                <p>L'export sera généré et envoyé par mail à cette adresse.</p>

                <AtlasSpacer />

                <MyForm>
                    <TextField name="exportMail" label="Adresse e-mail" />
                    <MyForm.Actions>
                        <Button submit={ true } spinner={{spinning: form.submitting }}>Envoyer</Button>
                        <Button level={ 2 } onClick={ props.close }>Retour</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    </>
}