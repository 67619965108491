import { downloadDiagnosticExportDoc } from 'api/diagatlasAPI'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasHtml, MyDropdownMenu, MyModal } from 'atlas-ds'
import Link from 'components/Link'
import Button from 'components/reform/Button'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Diagnostic } from 'slices/diagatlas/diagnosticSlice'
import DiagDeleteModal from './DiagDeleteModal'
import DiagExportsButton from './DiagExportsButton'

export default function DiagPlanActionOperations(props: { diagnostic: Diagnostic | null }) {
    const [showAskExport, setShowAskExport] = useState(false)
    const [showAskDelete, setShowAskDelete] = useState(false)

    return <>
        <MyDropdownMenu
            key="gerer"
            level={2}
            items={[
                <Link key="besoins" icon="pen" to="/mon-diagnostic-besoins">
                    Modifier les besoins de l'entreprise
                </Link>,
                <Link key="metiers" icon="pen" to="/mon-diagnostic-metiers">
                    Modifier les métiers et compétences
                </Link>,
                <AtlasButton key="supprimer" icon="trash" onClick={ () => setShowAskDelete(true) }>
                    Supprimer le diagnostic et le plan d'action
                </AtlasButton>,
        ]}>Gérer mon diagnostic</MyDropdownMenu>
        <MyDropdownMenu
            key="export"
            level={2}
            items={[
                <AtlasButton key="pdf" icon="download" onClick={ () => setShowAskExport(true) }>
                    Exporter en PDF
                </AtlasButton>,
                <DiagExportsButton level={3} label="Voir les diagnostics exportés" />,
            ]}
        >Export</MyDropdownMenu>

        { showAskExport && <DiagExportModal diagnostic={ props.diagnostic } close={ () => setShowAskExport(false) } /> }

        { showAskDelete && <DiagDeleteModal diagnostic={ props.diagnostic } close={ () => setShowAskDelete(false) } /> }
    </>
}

export function DiagExportModal(props: { diagnostic: Diagnostic | null, close: () => void }) {
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const [saving, setSaving] = useState(false)

    const saveExport = () => {
        setSaving(true)
        downloadDiagnosticExportDoc(props.diagnostic!, `Diagnotic ${entreprise!.Name}.pdf`)
            .then(props.close)
            .finally(() => setSaving(false))
    }

    return (
        <MyModal
            label="Exporter le diagnostic"
            onClose={ props.close }
            okButton={ <Button onClick={ saveExport } spinner={{ spinning: saving }}>Enregistrer</Button>}
            cancelButton={ <Button onClick={ props.close }>Retour</Button>}
        >
            <AtlasHtml>
                <p>Votre diagnostic et Plan d'action vont être générés au format PDF.</p>
                <p>Une copie est consultable à partir de la page d'accueil de votre compte myAtlas Entreprise ou à partir des "Actions préconisées".</p>
            </AtlasHtml>
        </MyModal>
    )
}