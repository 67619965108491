import { AtlasValues, MyContainer } from 'atlas-ds'
import { formDiffElements, FormElements } from 'pages/dossier/metadata/FormElements'
import { SECTION_MODULE_OF } from 'pages/dossier/metadata/ModulesMetadata'
import { Fragment } from 'react'
import { BaseDossierModule, DossierAF } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapModules<M extends BaseDossierModule>(props: RecapProps<DossierAF<any, M>>) {
    const { navigateTo, steps } = useDossierNavigation(props.metadata, props.navigationGuard)
    
    const metadata = props.metadata
    const modulesEtape = steps.indexOf(props.step) + 1

    const skeleton = props.loading ? [{ cfa: {} } as M] : []
    const modules = props.dossier?.modules ?? skeleton
    const dossier = props.dossier ?? { modules } as any
    const initialDossier = props.initialDossier ?? { modules: skeleton } as any

    const onModifier = (m: M) => navigateTo(props.dossier!.NumeroDossier__c!, modulesEtape)

    if (props.metadata.api.isEdit) {
        // Cas des modifications multi-modules, pas encore possible
        return <>{ modules.map((m, index) => {
            const elementsOF = formDiffElements(props.metadata, dossier, initialDossier, 'modules', index, SECTION_MODULE_OF)
            const elements = formDiffElements(props.metadata, dossier, initialDossier, 'modules', index)
            if ((!elementsOF || elementsOF.length === 0) && (!elements || elements.length === 0)) return null

            return <Fragment key={ `modules[${index}]` }>
                { elementsOF && <RecapSection title="Centre de formation" onModifier={ () => onModifier(m) }>
                    <MyContainer>
                        <AtlasValues>
                            <AtlasValues.Grid>
                                { elementsOF }
                            </AtlasValues.Grid>
                        </AtlasValues>
                    </MyContainer>
                </RecapSection> }

                { elements && <RecapSection title={ props.step.title ?? 'Formation' } onModifier={ () => onModifier(m) }>
                    <MyContainer>
                        <AtlasValues>
                            <AtlasValues.Grid>
                                { elements }
                            </AtlasValues.Grid>
                        </AtlasValues>
                    </MyContainer>
                </RecapSection> }
            </Fragment>
        }) }</>
    }

    return <>{ modules.map((m, index) => {
        const recapOF = <FormElements<DossierAF<any, M>> dossier={ dossier } metadata={ metadata } path="modules" index={ index } 
            type="recap" section={ SECTION_MODULE_OF } />

        return (<Fragment key={ `modules[${index}]` }>
            { recapOF && <RecapSection
                title="Centre de formation"
                onModifier={ () => onModifier(m) }
            >
                <MyContainer>
                    <AtlasValues>
                        { recapOF }
                    </AtlasValues>
                </MyContainer>
            </RecapSection> }
            <RecapSection
                title={ props.step.title ?? 'Formation' }
                onModifier={ () => onModifier(m) }
            >
                <MyContainer>
                    {/* <FormElements<DossierAF<any, M>> dossier={ dossier } metadata={ metadata } path="modules" type={ TYPE_RECAP } section="" index={ index } /> */}
                    <AtlasValues>
                        <AtlasValues.Grid>
                            <FormElements<DossierAF<any, M>> dossier={ dossier } metadata={ metadata } path="modules" type="recap" section="" index={ index } />
                            { props.step.sections?.map(section =>
                                <Fragment key={section}>
                                    <FormElements<DossierAF<any, M>> dossier={ dossier } metadata={ metadata } path="modules" type="recap" section={section} index={ index } />
                                </Fragment>
                            )}
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection>
        </Fragment>)
    }) }</>
}
