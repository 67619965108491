import { FormMetadata } from "../metadata/FormMetadata"
import RecapAtlasValue from "./RecapAtlasValue"

interface RecapBooleanFieldProps {
    label: string,
    value: boolean | string | null | undefined,
    initialValue?: boolean |  string | null | undefined,
    editMode?: boolean,
    labelNo?: string,
    labelYes?: string,
}

export default function RecapBooleanField(props: RecapBooleanFieldProps) {

    const value = (props.value === true || props.value === '1') ? (props.labelYes ?? 'Oui') : (props.labelNo ?? 'Non')
    const initialValue = (props.initialValue === true || props.initialValue === '1') ? (props.labelYes ?? 'Oui') : (props.labelNo ?? 'Non')

    return  <RecapAtlasValue label={props.label} initialValue={initialValue} value={value} editMode={props?.editMode ?? false} />
}
