import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { Commune, getReferencesCommune } from 'api/referencesAPI'
import { BaseFieldProps } from 'components/reform/inputs/utils/BaseFieldProps'
import TextField from './TextField'
import AutocompleteBaseField from './AutocompleteBaseField'

interface CommuneFieldProps<T extends object> extends BaseFieldProps<string, T> {
    departementName?: string
}

export default function CommuneField<T extends object>(props: CommuneFieldProps<T>) {

    const context = useFormContext<T>()
    const fieldState = getFieldState<string | null>(context, props.name)
    
    const departementPath = props.departementName ? props.parentPath + '.' + props.departementName : undefined
    const departementFieldState = departementPath ? getFieldState<string | null>(context, props.parentPath + '.' + props.departementName) : undefined
    
    const onSelect = (commune: Commune) => {
        if (departementPath != null) {
            const departement =
                commune.Departement__c ??
                (commune.CdCodex__c.startsWith('20') ? '' :
                commune.CdCodex__c.startsWith('97') || commune.CdCodex__c.startsWith('98') ? commune.CdCodex__c.substring(0, 3) :
                commune.CdCodex__c.substring(0, 2))
            context.setValue(departementPath, departement)
        }

        context.setValue(props.name, commune.Nom__c, true)

        if (props.onChange)
            props.onChange(commune?.Nom__c ?? null, context)
    }

    const etranger = departementFieldState?.value && ['99', '099'].includes(departementFieldState.value)

    return (<>
        { etranger ?
        <TextField
            name={ props.name }
            label={ props.label ?? 'Commune' }
            disabled={ props.disabled }
        />
        :
        <AutocompleteBaseField<Commune>
            label={ props.label ?? 'Commune' }
            defaultValue={ fieldState.value ?? "" }
            error={ fieldState.error }
            required={ fieldState.constraints.required }
            disabled={ props.disabled }
            placeholder="Rechercher par code postal ou commune (3 caractères minimum)"
            tooltip={ props.tooltip }
            onBlur={ value => context.setValue(props.name, value === "" ? null : context.getValue(props.name), true) }
        
            search={ getReferencesCommune }
            
            optionFor={ commune => ({ id: commune.Id, content: `${ commune.CdCodex__c } - ${ commune.Nom__c }` })}
            onSelect={ onSelect }
        />
        }
    </>)
}
