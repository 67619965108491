import { SetValueOptions, UseFormReturn } from '@dsid-opcoatlas/reform'
import { StringSchema, ValidationContext } from '@dsid-opcoatlas/yop'
import { DossierModuleCoutDTO, DossierModuleDTO } from 'api/dossierAPI'
import { FORMATION_CERTIFIANTE_NON, FORMATION_CERTIFIANTE_OUI, OBJECTIF_GENERAL_FORMATION_COMPETENCES, OBJECTIF_GENERAL_FORMATION_QUALIFIANTES_CERTIFIANTES, OBJECTIF_GENERAL_OF_CERTIFICATION, TYPE_COUT_PEDAGOGIQUE, TYPE_COUT_REMUNERATION, TYPE_FORMATION_INTERNE } from 'api/references'
import { References } from 'api/referencesAPI'
import FormationInterneField from 'components/fields/FormationInterneField'
import MultilineTextField from 'components/reform/inputs/MultilineTextField'
import { AdresseValidationFieldsParams, OptionalString, optionalObject } from 'components/yop/constraints'
import { get, set, toPath } from 'lodash'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { Adresse, BaseDossier, BaseDossierModule, DossierCFA, DossierModuleAgora, ModuleAgora, TypeCout, createDossierCFA, nil } from 'slices/dossierCommon'
import { AdresseFieldMetadata, adresseMapping } from '../../../components/metadata/AdresseFieldMetadata'
import { BooleanFieldMetadata, CheckboxFieldMetadata } from '../../../components/metadata/BooleanFieldMetadata'
import { CFAFieldMetadata } from '../../../components/metadata/CFAFieldMetadata'
import { CodeRNCPFieldMetadata } from '../../../components/metadata/CodeRNCPFieldMetadata'
import { DateFieldMetadata } from '../../../components/metadata/DateFieldMetadata'
import { DurationFieldMetadata, floatingDurationMapping } from '../../../components/metadata/DurationFieldMetadata'
import { RequiredFormationInterneFieldMetadata } from '../../../components/metadata/FormationInterneFieldMetadata'
import { RadioRefFieldMetadata, SelectRefFieldMetadata } from '../../../components/metadata/RefFieldMetadata'
import { RequiredSpecialiteFormationFieldMetadata } from '../../../components/metadata/SpecialiteFormationFieldMetadata'
import { TextFieldMetadata } from '../../../components/metadata/TextFieldMetadata'
import { RequiredTypeCoutFieldMetadata } from '../../../components/metadata/TypeCoutFieldMetadata'
import { FieldMapping, MappingContext, dateMapping, mapDTOToModel, mapModelToDTO, simpleMapping } from './DossierMapping'
import { ElementContext, ObjectMetadata } from './FormMetadata'


export const SECTION_CFA = 'cfa'

export const SECTION_MODULE_OF = 'of'

export const SECTION_MODULE_AGORA = 'agora'


export const moduleFields = {
    formationInterne: RequiredFormationInterneFieldMetadata({
        props: {
            label: "Il s'agit d'un service de formation interne",
            labelYes: 'Oui, formation réalisée dans mon entreprise',
            labelNo: 'Non, formation réalisée par un organisme tiers',
            full: true
        },
        section: SECTION_MODULE_OF,
        input: FormationInterneField,
    }),
    cfa: CFAFieldMetadata({ 
        props: {
            label: 'Organisme de formation',
        },
        section: SECTION_MODULE_OF,
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne === false,
    }),
    subrogation: BooleanFieldMetadata({
        props: {
            label: "Paiement direct par l'OPCO à l'organisme de formation (subrogation)",
            disabled: (props: ElementContext<BaseDossierModule, BaseDossier>) => props.parent?.formationInterne !== false,
            full: true
        },
        section: SECTION_MODULE_OF,
        mapping: simpleMapping('SubrogationPaiement__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required(),
    attestationFormationInterne: CheckboxFieldMetadata({
        props: {
            label: "L'employeur atteste que le service de formation interne dispose des moyens nécessaires à la réalisation de la formation du salarié",
            full: true
        },
        mapping: simpleMapping('AttestationEmployeurFormationInterne__c', 'dossier'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne === true,
    }).mutate(yop => yop.when(
        (context: ValidationContext<boolean | null, BaseDossierModule>) => context.parent?.formationInterne === true ?
        yop.required('Veuillez cocher cette case pour confirmer').oneOf([true], 'Veuillez cocher cette case pour confirmer') :
        yop
    )),
    // }).mutate(yop => yop.requiredIf(
    //     (context: ValidationContext<boolean | null, BaseDossierModule>) => context.parent?.formationInterne === true,
    //     'Veuillez cocher cette case pour confirmer'
    // )),
    intitulePrecis: TextFieldMetadata({
        props: {
            label: 'Intitulé du stage', 
            tooltip: TOOLTIPS.formation.diplomeIntitulePrecis, 
        },
        mapping: simpleMapping('IntituleProgramme__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required().mutate(yop => yop.max(250)),
    niveauVise: SelectRefFieldMetadata({
        props: {
            label: 'Niveau visé',
            options: refs => refs.REF_NIVEAU_FORMATION.filter(r => r.IsActive__c),
            sorted: true,
        },
        mapping: simpleMapping('NiveauFormationVise__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required(),
    typeFormation: RadioRefFieldMetadata({
        props: {
            label: 'Type de formation',
            tooltip: TOOLTIPS.formation.typeFormation,
            options: refs => refs.REF_TYPE_FORMATION.filter(r => r.IsActive__c && r.Priorite__c && r.Categorie__c === 'EXT'),
        },
        mapping: simpleMapping('TypeFormation__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne === false,
    }).mutate(yop => yop.requiredIf<BaseDossierModule>(context => context.parent!.formationInterne !== true)),
    dateDebutFormation: DateFieldMetadata({
        props: {
            label: 'Date de début de la formation',
            disabled: false,
        },
        mapping: dateMapping('DateDebutFormation__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required(),
    dateFinFormation: DateFieldMetadata({
        props: {
            label: 'Date de fin de la formation',
            disabled: false,
        },
        mapping: dateMapping('DateFinFormation__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required().mutate(yop => yop.min<BaseDossierModule>(
        context => context.parent!.dateDebutFormation,
        'La date de fin de formation doit être après la date de début',
    )),
    dateExamen: DateFieldMetadata({
        props: {
            label: 'Date prévue de fin des épreuves ou des examens',
            disabled: false,
        },
        mapping: dateMapping('DateExamen__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required().mutate(yop => yop.min<BaseDossierModule>(
        context => context.parent!.dateDebutFormation,
        'La date des examens doit être après la date de début du cycle de formation',
    )),
    dureeTotale: DurationFieldMetadata({
        props: {
            label: 'Durée',
            suffix: 'heure(s)',
        },
        mapping: floatingDurationMapping('NombreHeureFormation__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required().mutate(yop => yop.min(5 / 60, "Doit être supérieur ou égal à 5 minutes")),
    dureeEnseignement: DurationFieldMetadata({
        props: {
            label: 'Dont durée des enseignements généraux, professionnels et technologiques',
            suffix: 'heure(s)',
        },
        mapping: floatingDurationMapping('DureeHeureEnseignement__c', 'dossier'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required().mutate(yop => yop.max<BaseDossierModule>(
        context => context.parent!.dureeTotale,
        'La durée des enseignements doit être inférieure ou égale à la durée totale',
    )),
    coutPedagogique: RequiredTypeCoutFieldMetadata({
        props: {
            label: 'Coût pédagogique total',
            suffix: '€ HT',
        },
        mapping: typeCoutMapping(TYPE_COUT_PEDAGOGIQUE),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }),
    commentaire: TextFieldMetadata({
        props: {
            label: 'Commentaire',
            full: true
        },
        mapping: simpleMapping('CommentaireFO__c', 'dossier'),
        input: MultilineTextField,
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.index === 0 && context.parent?.formationInterne != null,
    }).mutate(yop => yop.max(4000)),
    remuneration: RequiredTypeCoutFieldMetadata({
        props: {
            label: 'Remunération',
            suffix: '€ / mois',
        },
        mapping: typeCoutMapping(TYPE_COUT_REMUNERATION, 'Remuneration__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }),
    typeQualification: (typesQualificationsRNCP?: string[]) => SelectRefFieldMetadata({
        props: {
            label: 'Type qualification',
            options: refs => refs.REF_TYPE_QUALIFICATION.filter(ref => ref.Categorie__c?.split(',').includes('2') && ref.IsActive__c),
            sorted: true,
            onChange: (value: string | null, form: UseFormReturn<any>, parentPath?: string) => { 
                if (!typesQualificationsRNCP?.includes(value ?? '')) { form.setValue(parentPath + '.codeRNCP', null, true) } 
            },
        },
        mapping: simpleMapping('TypeQualification__c', 'dossier'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required(),
    certificationVisee: SelectRefFieldMetadata({
        props: {
            label: 'Certification visée',
            options: refs => refs.REF_DIPLOME.filter(r => r.IsActiveFO__c && r.Categorie__c?.includes('6')),
            sorted: true,
        },
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required(),
    codeRNCP: (typesQualifications?: string[]) => CodeRNCPFieldMetadata({
        props: {
            label: 'Code RNCP',
        },
        mapping: simpleMapping('CodeRNCP__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
        typesQualificationsRNCP: typesQualifications,
    }),
    specialiteFormation: RequiredSpecialiteFormationFieldMetadata({
        props: {
            label: 'Spécialité de formation',
        },
        mapping: simpleMapping('SpecialiteFormation__c', 'dossier'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }),
}


const requiredLieuPrincipalFormationAgora = () => OptionalString.requiredIf((context: ValidationContext<any>) => {
    const path = toPath(context.path)
    path.pop()
    path.pop()
    const module = get(context.root, path)
    return module?.formationInterne === true || module?.adresseRealisationIdentiqueAgora === false
}) as StringSchema<string>

export const moduleAgoraFields = {
    modalitePedagogiqueAgora: SelectRefFieldMetadata({
        props: {
            label: 'Modalités pédagogiques',
            options: refs => refs.REF_MODALITE_PEDAGOGIQUE.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
        },
        section: SECTION_MODULE_AGORA,
        mapping: simpleMapping('ModalitePedagogiqueAgora__c'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne != null,
    }).required(),
    adresseRealisationIdentiqueAgora: BooleanFieldMetadata({
        props: {
            label: "L'adresse de réalisation de la formation est la même que celle principale",
            onChange: (value: boolean | null, form: UseFormReturn<any>, parentPath?: string | null) => {
                if (value) {
                    form.setValue(parentPath + '.lieuPrincipalFormationAgora.appartement', '', SetValueOptions.Untouch)
                    form.setValue(parentPath + '.lieuPrincipalFormationAgora.batiment', '', SetValueOptions.Untouch)
                    form.setValue(parentPath + '.lieuPrincipalFormationAgora.adresse', '', SetValueOptions.Untouch)
                    form.setValue(parentPath + '.lieuPrincipalFormationAgora.complement', '', SetValueOptions.Untouch)
                    form.setValue(parentPath + '.lieuPrincipalFormationAgora.codePostal', '', SetValueOptions.Untouch)
                    form.setValue(parentPath + '.lieuPrincipalFormationAgora.commune', '', SetValueOptions.Untouch)
                }
            },    
        },
        section: SECTION_MODULE_AGORA,
        mapping: simpleMapping('AdresseRealisationIdentiqueAgora__c'),
        visibleIf: (context: ElementContext<BaseDossierModule & ModuleAgora, BaseDossier>) => context.parent?.formationInterne === false,
    }).required(),
    lieuPrincipalFormationAgora: AdresseFieldMetadata({
        props: {
            label: 'Adresse de réalisation de la formation',
        },
        section: SECTION_MODULE_AGORA,
        mapping: adresseMapping(
            'LieuPrincipalFormationAdresse1Agora__c', 'LieuPrincipalFormationAdresse2Agora__c', 
            'LieuPrincipalFormationAdresse3Agora__c', 'LieuPrincipalFormationAdresse4Agora__c', 
            'LieuPrincipalFormationCodePostalAgora__c', 'LieuPrincipalFormationCommuneAgora__c',
        ),
        visibleIf: (context: ElementContext<BaseDossierModule & ModuleAgora, BaseDossier>) => (
            context.parent?.formationInterne === true || context.parent?.adresseRealisationIdentiqueAgora === false
        ),
    }).mutate(_ => optionalObject<Adresse>({
        appartement: OptionalString.max(AdresseValidationFieldsParams.Apt),
        batiment: OptionalString.max(AdresseValidationFieldsParams.Bat),
        adresse: requiredLieuPrincipalFormationAgora().max(AdresseValidationFieldsParams.Adr),
        complement: OptionalString.max(AdresseValidationFieldsParams.Cpt),
        codePostal: requiredLieuPrincipalFormationAgora().max(AdresseValidationFieldsParams.Cp)
            .matches(AdresseValidationFieldsParams.CpRegex, AdresseValidationFieldsParams.CpMessage),
        commune: requiredLieuPrincipalFormationAgora().max(AdresseValidationFieldsParams.Com),
    })).required(),
    formationCertifianteAgora: SelectRefFieldMetadata({
        props: {
            label: 'Formation certifiante',
            tooltip: TOOLTIPS.formation.formationCertifianteAgora,
            options: refs => refs.REF_FORMATION_CERTIFIANTE.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('1')),
            onChange: (value: string | null, context: UseFormReturn<any>, parentPath?: string) => {
                if (value == null) {
                    context.setValue(`${parentPath}.categorieActionAgora`, null, true)
                    context.setValue(`${parentPath}.objectifAgora`, null, true)
                }
                else if (value === FORMATION_CERTIFIANTE_OUI) {
                    context.setValue(`${parentPath}.categorieActionAgora`, OBJECTIF_GENERAL_OF_CERTIFICATION, true)
                    context.setValue(`${parentPath}.objectifAgora`, OBJECTIF_GENERAL_FORMATION_QUALIFIANTES_CERTIFIANTES, true)
                }
                else {
                    const module = context.getValue(parentPath!) as DossierModuleAgora
                    if (module.categorieActionAgora === OBJECTIF_GENERAL_OF_CERTIFICATION)
                        context.setValue(`${parentPath}.categorieActionAgora`, null, true)
                    if (!module.objectifAgora || module.objectifAgora === OBJECTIF_GENERAL_FORMATION_QUALIFIANTES_CERTIFIANTES)
                        context.setValue(`${parentPath}.objectifAgora`, OBJECTIF_GENERAL_FORMATION_COMPETENCES, true)
                }
            },
            sorted: true,
        },
        mapping: simpleMapping('FormationCertifianteAgora__c'),
        section: SECTION_MODULE_AGORA,
    }).required(),
    categorieActionAgora: SelectRefFieldMetadata({
        props: {
            label: "Catégorie d'action",
            options: (refs: References, parent?: DossierModuleAgora) => refs.REF_OBJECTIF_GENERAL_OF.filter(ref =>
                ref.IsActiveFO__c &&
                ref.Categorie__c?.split(',').includes('1') && (
                    ref.Id === OBJECTIF_GENERAL_OF_CERTIFICATION ?
                    parent?.formationCertifianteAgora === FORMATION_CERTIFIANTE_OUI :
                    parent?.formationCertifianteAgora === FORMATION_CERTIFIANTE_NON
                )
            ),
            disabled: (context: ElementContext<any, any>) => context.parent?.formationCertifianteAgora === FORMATION_CERTIFIANTE_OUI,
            sorted: true,
        },
        mapping: simpleMapping('ObjectifGeneralOfAgora__c'),
        section: SECTION_MODULE_AGORA,
    }).required(),
    objectifAgora: SelectRefFieldMetadata({
        props: {
            label: 'Objectif',
            options: refs => refs.REF_OBJECTIF_GENERAL_FORMATION.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('1')),
            disabled: (context: ElementContext<any, any>) => context.parent?.formationCertifianteAgora === FORMATION_CERTIFIANTE_OUI,
            sorted: true,
        },
        mapping: simpleMapping('ObjectifGeneralFormationAgora__c'),
        section: SECTION_MODULE_AGORA,
    }).required(),
}



export function mapDTOToModule<M extends BaseDossierModule, MDTO extends DossierModuleDTO>(
    context: MappingContext,
    moduleMetadata: ObjectMetadata<M>,
    module: M,
    mdto: MDTO) {
    module.IdHeroku = mdto.IdHeroku
    mapDTOToModel(context, moduleMetadata, module, mdto)
    // module.typeFormation = mdto.TypeFormation__c
    module.formationInterne = mdto.TypeFormation__c === TYPE_FORMATION_INTERNE
    if (!module.formationInterne) {
        module.cfa = mapDTOToCFA(mdto)
    }
}

export function mapDTOToCFA<MDTO extends DossierModuleDTO>(moduleDTO: MDTO | null): DossierCFA | null {
    if (moduleDTO === null)
        return null
    
    const dossierCFA = createDossierCFA()
    dossierCFA.IdHeroku = moduleDTO.IdHeroku
    // Important: doit être undefined quand aucune valeur reçue pour que le formulaire reste en mode 'Sélectionner'
    dossierCFA.EtablissementOFId__c = moduleDTO.EtablissementOFId__c ?? undefined
    dossierCFA.CodeEtablissementOF__c = moduleDTO.CodeEtablissementOF__c
    dossierCFA.nom = moduleDTO.NomOF__c ?? (nil as string)
    dossierCFA.entreprise = false
    dossierCFA.etat = moduleDTO.EtatEtablissementOF__c ?? ''
    dossierCFA.siret = moduleDTO.SiretOF__c ?? (nil as string)
    dossierCFA.appartement = moduleDTO.Adresse1OF__c
    dossierCFA.batiment = moduleDTO.Adresse2OF__c
    dossierCFA.adresse = moduleDTO.Adresse3OF__c
    dossierCFA.complement = moduleDTO.Adresse4OF__c
    dossierCFA.codePostal = moduleDTO.CodePostalOF__c ?? (nil as string)
    dossierCFA.commune = moduleDTO.CommuneOF__c ?? (nil as string)
    dossierCFA.numeroDA = moduleDTO.NdaOF__c
    dossierCFA.numeroUAI = moduleDTO.UaiOF__c
    return dossierCFA
}

export function mapModuleToDTO<M extends BaseDossierModule, MDTO extends DossierModuleDTO>(moduleMetadata: ObjectMetadata<M>, dossier: any, module: M, moduleIndex: number): MDTO | null {
    if (module === null || !(module?.typeFormation || (module?.cfa && module?.cfa.EtablissementOFId__c !== undefined))) {
        return null
    }

    const dto = {
        IdHeroku: module?.IdHeroku ?? null,
        EtablissementOFId__c: module?.formationInterne ? null : module?.cfa?.EtablissementOFId__c ?? null,
        CodeEtablissementOF__c: module?.formationInterne ? null : module?.cfa?.CodeEtablissementOF__c ?? null,
        EtatEtablissementOF__c: module?.formationInterne ? null : module?.cfa?.etat ?? null,
        // TypeFormation__c: module?.typeFormation ?? null,
        Principal__c: moduleIndex == 0 ? true : null,
        NomOF__c: module?.formationInterne ? null : module?.cfa?.nom ?? null,
        SiretOF__c: module?.formationInterne ? null : module?.cfa?.siret ?? null,
        Adresse1OF__c: module?.formationInterne ? null : module?.cfa?.appartement ?? null,
        Adresse2OF__c: module?.formationInterne ? null : module?.cfa?.batiment ?? null,
        Adresse3OF__c: module?.formationInterne ? null : module?.cfa?.adresse ?? null,
        Adresse4OF__c: module?.formationInterne ? null : module?.cfa?.complement ?? null,
        CodePostalOF__c: module?.formationInterne ? null : module?.cfa?.codePostal ?? null,
        CommuneOF__c: module?.formationInterne ? null : module?.cfa?.commune ?? null,
        NdaOF__c: module?.formationInterne ? null : module?.cfa?.numeroDA ?? null,
        UaiOF__c: module?.formationInterne ? null : module?.cfa?.numeroUAI ?? null,
        CodeDiplome__c: null,
    } as unknown as MDTO
    return mapModelToDTO(moduleMetadata, dossier, module, dto)
}


export function typeCoutMapping(typeCout: string, path?: string, source?: string, pathPrefix?: string): FieldMapping<TypeCout> {
    const pathTypesCout = pathPrefix ? pathPrefix + '.TypesCout' : 'TypesCout'
    return {
        source,
        mapModelToDTO: (value, dto) => {
            if (path)
                dto[path] = value.montant
            const typesCout = get(dto, pathTypesCout) ?? (value ? [] : null) 
            if (value !== null && value !== undefined && value.montant) {
                typesCout.push({
                    IdHeroku: value.IdHeroku,
                    TypeCout__c: typeCout,
                    MontantDemande__c: value.montant,
                })
            }
            set(dto, pathTypesCout, typesCout)
        },
        mapDTOToModel: (dto) => {
            let montant = path ? dto[path] : null
            const typesCout = get(dto, pathTypesCout) as DossierModuleCoutDTO[]
            const cout = typesCout?.find(tc => tc.TypeCout__c === typeCout)
            return (cout !== null && cout !== undefined) ? {
                IdHeroku: cout.IdHeroku,
                montant: cout.MontantDemande__c ?? montant,
            } : { IdHeroku: null, montant: nil as number }
        },
    }
}