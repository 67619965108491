import { BaseSearchQuery, BaseSearchQuery2 } from 'api/baseAPI';
import { SIREN_ADH } from 'app/constants';
import { CONTEXTE_PARAM } from 'app/App';

export abstract class BaseSearchQueryImpl implements BaseSearchQuery {

    readonly contexte: string;
    readonly siren: string;
    readonly fromEmptySearch: boolean;
    page = 0;
    parPage = 20;
    tri: string;
    ordre = 'desc';

    constructor(params: URLSearchParams, defaultTri: string) {
        this.contexte = params.get(CONTEXTE_PARAM)!;
        this.siren = params.get(SIREN_ADH)!;
        this.fromEmptySearch = params.size === 2 && params.has(CONTEXTE_PARAM) && params.has(SIREN_ADH);
        this.tri = defaultTri;
    }

    applySearch(params: URLSearchParams): BaseSearchQueryImpl {
        this.page = Number(params.get('page') ?? this.page);
        this.parPage = Number(params.get('par-page') ?? this.parPage);
        this.tri = params.get('tri') ?? this.tri;
        this.ordre = params.get('ordre') ?? this.ordre;
        return this;
    }

    abstract clone(): BaseSearchQueryImpl;

    getPage(_: number) {
        return this.page
    }
    
    setPage(_: number, value: number): BaseSearchQueryImpl {
        this.page = value
        return this
    }
    
    getParPage(_: number) {
        return this.parPage
    }
    
    setParPage(_: number, value: number): BaseSearchQueryImpl {
        this.parPage = value
        return this
    }

    firstPage(): BaseSearchQueryImpl {
        this.page = 0;
        return this;
    }

    nextPage(index = 0): BaseSearchQueryImpl {
        this.page++;
        return this;
    }

    rewind(): BaseSearchQueryImpl {
        return this.firstPage()
    }

    setTri(tri: string): BaseSearchQueryImpl {
        if (this.tri !== tri) {
            this.tri = tri;
            this.ordre = 'desc';
        }
        else
            this.toggleOrdre();
        this.firstPage();
        return this;
    }

    toggleOrdre(): BaseSearchQueryImpl {
        this.ordre = (this.ordre === 'desc' ? 'asc' : 'desc');
        return this;
    }

    toSearchParams(forStorage = false): URLSearchParams {
        const params = new URLSearchParams();
        if (!forStorage) {
            params.set(CONTEXTE_PARAM, this.contexte);
            params.set(SIREN_ADH, this.siren);
            params.set('page', this.page.toFixed(0));
            params.set('par-page', this.parPage.toFixed(0));
        }
        params.set('tri', this.tri);
        params.set('ordre', this.ordre);
        return params;
    }
}


export abstract class BaseSearchQuery2Impl extends BaseSearchQueryImpl implements BaseSearchQuery2 {

    page2 = 0;
    parPage2 = 20;

    override applySearch(params: URLSearchParams): BaseSearchQueryImpl {
        super.applySearch(params)
        this.page2 = Number(params.get('page-2') ?? this.page2);
        this.parPage2 = Number(params.get('par-page-2') ?? this.parPage2);
        return this;
    }

    override getPage(index: number) {
        return index === 1 ? this.page2 : this.page
    }

    override setPage(index: number, value: number): BaseSearchQuery2Impl {
        if (index === 1)
            this.page2 = value
        else
            this.page = value
        return this
    }

    override getParPage(index: number) {
        return index === 1 ? this.parPage2 : this.parPage
    }

    override setParPage(index: number, value: number): BaseSearchQuery2Impl {
        if (index === 1)
            this.parPage2 = value
        else
            this.parPage = value
        return this
    }

    firstPage2(): BaseSearchQuery2Impl {
        this.page2 = 0;
        return this;
    }

    nextPage2(): BaseSearchQuery2Impl {
        this.page2++;
        return this;
    }

    override nextPage(index = 0): BaseSearchQuery2Impl {
        if (index === 0)
            this.page++;
        else
            this.page2++;
        return this;
    }

    override rewind(): BaseSearchQuery2Impl {
        super.rewind()
        return this.firstPage2()
    }

    override toSearchParams(forStorage = false): URLSearchParams {
        const params = super.toSearchParams(forStorage)
        if (!forStorage) {
            params.set('page-2', this.page2.toFixed(0));
            params.set('par-page-2', this.parPage2.toFixed(0));
        }
        return params;
    }
}
