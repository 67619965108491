import { Font } from '@react-pdf/renderer'
import { unwrapResult } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { appInfo, appTheme, RouteElement } from 'app/appInfo'
import { AtlasButton, AtlasEnv, AtlasIcon, AtlasSkip, MyDropdownMenu, MySiteInfo, MySplash, MyTemplate, MyTheme } from 'atlas-ds'
import BandeauAlert from 'components/BandeauAlert'
import Menu from 'components/Menu'
import Nav from 'components/Nav'
import Rgpd from 'components/Rgpd'
import AcceptationCguModal from 'components/blocs/AcceptationCguModal'
import Onboarding from 'components/joyride/onboarding/Onboarding'
import { LastLocationProvider } from 'components/lastlocation'
import Accessibilite from 'pages/accessibilite/accessibilite'
import PlanAction from 'pages/accessibilite/plan-action'
import Cgu from 'pages/cgu/Cgu'
import fonts from 'pages/dossier/cerfa/fonts/fonts'
import AucuneEntrepriseError from 'pages/error-pages/AucuneEntrepriseError'
import HttpError from 'pages/error-pages/http-error'
import Maintenance from 'pages/error-pages/maintenance-mode'
import OldBrowserWarning, { hasBeenOldBrowserWarned } from 'pages/error-pages/old-browser-warning'
import PlanDuSite from 'pages/plan-du-site/PlanDuSite'
import { createContext, useEffect, useState } from 'react'
import { browserVersion, fullBrowserVersion, isChrome, isDesktop, isEdge, isFirefox, isIE, isSafari } from "react-device-detect"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Link, Redirect, BrowserRouter as Router, useLocation } from 'react-router-dom'
import { configActions } from 'slices/configSlice'
import { AppType, AppTypes, InvalidAppType, setApp, setEnterprise } from 'slices/contextSlice'
import { resetUnseenSteps } from 'slices/onboardingSlice'
import { profilActions } from 'slices/profilSlice'
import { checkReferencesExpiry, referencesActions } from 'slices/referencesSlice'
import AppModuleProvider, { AppRoute } from './AppModuleProvider'
import { SIREN_ADH } from './constants'
import { RootState } from './rootReducer'
import { useAppDispatch } from './store'

// Chargement des polices nécessaires aux pdfs
fonts.forEach(font => Font.register(font))

interface StoreContextType {
    validationSchema?: any
    store?: ((data: any) => void)
    load?: ((id: string) => any | null)
}

// Déclaration pour informer TypeScript de la propriété _mtm
declare global {
    interface Window {
        _mtm: any[]
    }
}

export const StoreContext = createContext<StoreContextType>({})

export const CONTEXTE_PARAM = 'contexte'

function checkGlobalParam(
    params: URLSearchParams,
    name: string,
    currentValue: string | null,
    defaultValue: () => string | null,
    valueValidator: (v: string) => boolean,
    namePrefix?: string): boolean {

    let value = params.get(name)
    if (!value || value !== currentValue) {
        if (currentValue)
            value = currentValue
        else {
            if (!value || !valueValidator(value)) {
                value = localStorage.getItem((namePrefix ?? '') + name)
                if (!value || !valueValidator(value))
                    value = defaultValue()
            }
        }
        if (value) {
            localStorage.setItem((namePrefix ?? '') + name, value)
            params.set(name, value)
        }
        return true
    }
    return false
}

export const commonRoutes: RouteElement[][] = [
    [
        <AppRoute key="plan-du-site" path="/plan-du-site" component={PlanDuSite} label="Plan du site" />
    ],
    [
        <AppRoute key="conditions-utilisation" path="/conditions-utilisation" component={Cgu} label="Conditions générales d'utilisation" />,
    ],
    [
        <AppRoute key="accessibilite" path="/accessibilite" component={Accessibilite} label="Accessibilité" />,
        <AppRoute key="plan-action" path="/accessibilite/plan-action" component={PlanAction} label="Plan d'action" />,
    ],
]

function InsideRouter() {

    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenuOpen = () => {
        setMenuOpen(!menuOpen)
    }

    const dispatch = useAppDispatch()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const contexteValue = params.get(CONTEXTE_PARAM)
 
    const { app, entreprise } = useSelector((state: RootState) => state.contextState)
    const { fulfilled: references, rejected: errorReferences } = useSelector((state: RootState) => state.referencesState)
    const { services } = useSelector((state: RootState) => state.profilState)
    const { fulfilled: config } = useSelector((state: RootState) => state.configState)
    const { appTypes, profil, rejected: errorProfile } = useSelector((state: RootState) => state.profilState)
    
    const contexteAppInfo = contexteValue ? appInfo(contexteValue) : undefined
    const tagManagerMatomoArgs = contexteAppInfo?.env?.matomoIDSite
    const [ currentMatomoId, setCurrentMatomoId ] = useState<string | undefined>()

    useEffect(() => {
        dispatch(referencesActions.load()).then(unwrapResult).then(_ => {
            dispatch(profilActions.getServices())
        })
    }, [dispatch])

    useEffect(() => {
        dispatch(profilActions.clear())
        dispatch(configActions.clear())

        if (app && references && services) {
            dispatch(profilActions.get())
            dispatch(configActions.load(app))
        }

    }, [app, references, services, dispatch])

    const contexte = params.get(CONTEXTE_PARAM)
    useEffect(() => {
        const appType = AppTypes.enumOf(contexte)
        if (appType != null)
            dispatch(setApp(appType))
    }, [contexte, dispatch])

    const siren = params.get(SIREN_ADH)
    useEffect(() => {
        if (profil != null && siren != null) {
            const entreprise = profil.Entreprises.find(e => e.SIREN__c === siren)
            if (entreprise != null)
                dispatch(setEnterprise(entreprise))

        }
    }, [siren, profil, dispatch])

    useEffect(() => {
        if (profil && profil.Contact)
            Sentry.setUser({ accountid: profil.Contact.AccountId, guidIAM: profil.Contact.GuidIAM })
        if (app && entreprise)
            Sentry.setContext(CONTEXTE_PARAM, { app, siren: entreprise.SIREN__c })
    }, [profil, app, entreprise])

    useEffect(() => {
        if (!tagManagerMatomoArgs || tagManagerMatomoArgs === currentMatomoId)
            return
        setCurrentMatomoId(tagManagerMatomoArgs)
    }, [tagManagerMatomoArgs])
    useEffect(() => {
        if (!currentMatomoId) return
        
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
        _mtm.push(['HeatmapSessionRecording::disable']); // Désactive les heatmaps et enregistrements de session
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        if (s && s.parentNode) {
            const src = `https://cdn.matomo.cloud/opcoatlas.matomo.cloud/container_${currentMatomoId}.js`;
            g.async = true;
            g.src = src;
            s.parentNode.insertBefore(g, s);
        }
    }, [currentMatomoId])


    const { steps, unseenSteps } = useSelector((state: RootState) => state.onboardingState);
    const canReplayOnboarding = (): boolean => steps.length > 0 && unseenSteps.length === 0;

    const rewatchOnboarding = () => {
        dispatch(resetUnseenSteps())
    }

    if (!(references && services) || checkReferencesExpiry())
        return (<MyTheme theme={appTheme(contexteValue)}>
            <MySplash>Chargement du référentiel et de la configuration...</MySplash>
        </MyTheme>)

    if (app !== AppType.Compte && profil && (profil.Entreprises?.length ?? 0) === 0)
        return <AucuneEntrepriseError />

    let redirect = checkGlobalParam(
        params,
        CONTEXTE_PARAM,
        app,
        () => ((appTypes ?? []).includes(AppType.Entreprise) ? AppType.Entreprise : AppType.Prestataire),
        (v) => v === AppType.Compte || (appTypes ?? []).includes(AppTypes.enumOf(v) ?? InvalidAppType)
    )

    if (!redirect && profil && app !== AppType.Compte) {
        // Ne pas chercher dans le profil.Entreprises actuel en cas de changement d'appli, il n'est pas encore à jour
        // avec les entreprises du nouveau contexte
        redirect = checkGlobalParam(
            params,
            SIREN_ADH,
            entreprise?.SIREN__c ?? null,
            () => profil.Entreprises[0].SIREN__c,
            (v) => !!profil.Entreprises.find(e => e.SIREN__c === v),
            params.get(CONTEXTE_PARAM) ? params.get(CONTEXTE_PARAM) + ':' : undefined,
        )
    }

    if (redirect) {
        return <Redirect to={{
            pathname: location.pathname,
            search: params.toString(),
            state: location.state
        }} />
    }

    if (errorProfile || errorReferences)
        return <MyTheme theme={appTheme(contexteValue)}>
            <MySplash error>
                Le chargement a échoué.<br />
                Nous vous invitons à recharger la page et à réessayer ultérieurement si le problème persiste.
                <p>{new Date().toLocaleDateString()} à {new Date().toLocaleTimeString()}</p>
                {errorProfile && <p>{errorProfile}</p>}
                {errorReferences && <p>{errorReferences}</p>}
            </MySplash>
        </MyTheme>

    if (!(app && profil && config)) {
        return (
            <MyTheme theme={appTheme(contexteValue)}>
                <MySplash>Chargement du référentiel et de la configuration...</MySplash>
            </MyTheme>
        )
    }

    const currentAppInfo = appInfo(app)

    return (
        <>
            <HelmetProvider>
                <Helmet
                    htmlAttributes={{ lang: 'fr' }}
                    defaultTitle={`myAtlas ${currentAppInfo.name}`}
                    titleTemplate={`%s - myAtlas ${currentAppInfo.name}`} />
            </HelmetProvider>

            <MyTheme applyToBody theme={currentAppInfo.theme}>
                <AtlasSkip mainElementId="main" />

                <AppModuleProvider appInfo={currentAppInfo}
                    layout={ routes => <MyTemplate
                        isMenuOpen={menuOpen}
                        companySelector={currentAppInfo.header}
                        menu={<Menu toggleMenuOpen={toggleMenuOpen} />}
                        topNav={<Nav toggleMenuOpen={toggleMenuOpen} links={currentAppInfo.navLinks} />}
                        toasts={<BandeauAlert />}
                        siteInfo={<MySiteInfo
                            logo={currentAppInfo.logo}
                            links={[
                                <Link to="/plan-du-site" key="sitemap">
                                    Plan du site
                                </Link>,
                                <Link to="/conditions-utilisation" key="cgu">
                                    Conditions générales d'utilisation
                                </Link>,
                                <Link to="/accessibilite" key="a11y">
                                    Accessibilité<span> : partiellement conforme</span>
                                </Link>
                            ]}
                            dropdownMenu={canReplayOnboarding() 
                                ? <MyDropdownMenu
                                    opener={<AtlasIcon name="info" size="s" title="Aide assistée" />}
                                    items={[<AtlasButton
                                        key="aide"
                                        onClick={rewatchOnboarding}
                                        icon="rewatch"
                                    >Aide assistée</AtlasButton>]}
                                >Aide assistée</MyDropdownMenu>
                                : undefined}
                        />}>
                        { routes }
                    </MyTemplate> }>

                    { commonRoutes }

                    <Redirect to="/" />
                </AppModuleProvider>
            </MyTheme>

            <Onboarding></Onboarding>
            <Rgpd></Rgpd>
            <AcceptationCguModal />
            <HttpError />

            { import.meta.env.REACT_APP_ENV && <aside>
                <AtlasEnv
                    env={import.meta.env.REACT_APP_ENV}
                    href={import.meta.env.REACT_APP_ENV_SWITCHER
                        ? window.location.toString().replace(
                            window.location.origin,
                            import.meta.env.REACT_APP_ENV_SWITCHER
                        ) : undefined
                    }
                />
            </aside> }
        </>
    )
}


export default function App() {

    const { value: maintenance } = useSelector((state: RootState) => state.maintenanceState)

    if (maintenance)
        return (<Maintenance />)

    const warnOldBrowser = !hasBeenOldBrowserWarned() && isDesktop && (
        (isChrome && parseInt(browserVersion) < 87) ||
        (isFirefox && parseInt(browserVersion) < 98) ||
        (isSafari && parseFloat(fullBrowserVersion) < 15.4) ||
        (isEdge && parseInt(browserVersion) < 88) ||
        isIE
    )

    if (warnOldBrowser)
        return (<OldBrowserWarning />)

    return (
        <Router>
            <LastLocationProvider>
                <InsideRouter />
            </LastLocationProvider>
        </Router>
    )
}