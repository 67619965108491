import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { loadDossierProfessionnalisation } from 'api/dossierAPI'
import { DISPOSITIF_VAEI, TYPE_MODIFICATION_AVENANT } from 'api/references'
import { ObjectFieldMetadata, OptionalObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { DossierAPI } from 'slices/dossierSlice'
import { FormStep, IgnoredFieldMetadata, ObjectMetadata, override, registerEditMetadata } from 'pages/dossier/metadata/FormMetadata'
import { DossierModification, nil, normalizePiecesDTO } from 'slices/dossierCommon'
import { MetadataVAEI, mapDTOToDossierVAEI } from './VAEIFormMetadata'
import { VAEIContratMetadata, SECTION_VAEI_CONTRAT } from './VAEIContratMetadata'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import { AppType } from 'slices/contextSlice'
import { DossierVAEI, createDossierVAEI, createDossierVAEITuteur, createDossierVAEISalarie, DossierVAEIContrat } from './VAEIModel'
import { VAEIEmployeurMetadata } from './VAEIEmployeurMetadata'
import { VAEISalarieMetadata } from './VAEISalarieMetadata'
import { VAEIModuleAFESTMetadata, VAEIModulePrincipalMetadata } from './VAEIFormationMetadata'
import { VAEITuteurMetadata, tuteurTravailTemporaireMetadata } from './VAEITuteurMetadata'
import { salarieFields } from 'pages/dossier/metadata/SalariesMetadata'
import { OptionalString, RequiredDate } from 'components/yop/constraints'
import { dateMapping, simpleMapping } from 'pages/dossier/metadata/DossierMapping'
import { adresseMapping } from 'components/metadata/AdresseFieldMetadata'
import { DateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { isAfter, isBefore } from 'date-fns'
import { onDossierModificationRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { isEqual } from 'lodash'
import { References } from 'api/referencesAPI'
import { StringSchema } from '@dsid-opcoatlas/yop'


function createEditDossierVAEI() { return {
    ...createDossierVAEI(),

    wasAvenant: nil as boolean,
    typeModificationContrat: nil as string,
    numModificationContrat: nil as number,
}}
export type EditDossierVAEI = ReturnType<typeof createEditDossierVAEI> & DossierVAEI & DossierModification


const api: DossierAPI<EditDossierVAEI> = {
    isEdit: true,
    create: createEditDossierVAEI,
    createSalarie: createDossierVAEISalarie,
    createTuteur: createDossierVAEITuteur,
    load: async (dossierId: string, storedState?: EditDossierVAEI | null) => {
        if (storedState)
            return storedState
        const dossierDTO = await loadDossierProfessionnalisation(dossierId)
        const loadedDossier = mapDTOToDossierVAEI(MetadataEditVAEI, dossierDTO)
        return loadedDossier
    },
    loadWithPieces: async (dossierId: string, storedState?: EditDossierVAEI | null) => {
        let loadedDossier = storedState
        if (!storedState) {
            const dossierDTO = await loadDossierProfessionnalisation(dossierId)
            loadedDossier = mapDTOToDossierVAEI(MetadataEditVAEI, dossierDTO)
        } else {
            loadedDossier = { ...storedState }
        }
        const pieces = await loadAndMergeDossierPieces(dossierId, [])
        loadedDossier.pieces = normalizePiecesDTO(pieces)
        return loadedDossier
    },
    postEdit: async (dossier: EditDossierVAEI) => {
        dossier.wasAvenant = AVENANT_CONTRAT_TYPES.includes(dossier.contrat.typeContrat)
        if (dossier.typeModificationContrat === TYPE_MODIFICATION_AVENANT && !dossier.wasAvenant)
            dossier.contrat.typeContrat = ''
    },
    save: async (dossier: EditDossierVAEI) => {
        return await new Promise<EditDossierVAEI>(resolve => setTimeout(() => resolve(dossier), 200))
    },
    send: async (dossier: EditDossierVAEI) => {
        // Non utilisé normalement
        return dossier
    },
    postSend: async (dossier: EditDossierVAEI) => {
        await postSendDossier(MetadataEditVAEI, dossier)
    },
}


const EditVAEIStagiaireMetadata = {
    ...VAEISalarieMetadata,
    nir: salarieFields.nir,
    niveauMaximumClasseEntreeFormationAgora: salarieFields.niveauMaximumClasseEntreeFormationAgora.mutate(_ => OptionalString as StringSchema<string>)
} as any

export const EditVAEIModulePrincipalMetadata = {
    ...VAEIModulePrincipalMetadata,
    modalitePedagogiqueAgora: IgnoredFieldMetadata(simpleMapping('ModalitePedagogiqueAgora__c')),
    adresseRealisationIdentiqueAgora: IgnoredFieldMetadata(simpleMapping('AdresseRealisationIdentiqueAgora__c')),
    lieuPrincipalFormationAgora: IgnoredFieldMetadata(adresseMapping(
        'LieuPrincipalFormationAdresse1Agora__c', 'LieuPrincipalFormationAdresse2Agora__c', 
        'LieuPrincipalFormationAdresse3Agora__c', 'LieuPrincipalFormationAdresse4Agora__c', 
        'LieuPrincipalFormationCodePostalAgora__c', 'LieuPrincipalFormationCommuneAgora__c',
    ))
} as any

export const EditVAEIModuleAFESTMetadata = {
    ...VAEIModuleAFESTMetadata,
    modalitePedagogiqueAgora: IgnoredFieldMetadata(simpleMapping('ModalitePedagogiqueAgora__c')),
    adresseRealisationIdentiqueAgora: IgnoredFieldMetadata(simpleMapping('AdresseRealisationIdentiqueAgora__c')),
    lieuPrincipalFormationAgora: IgnoredFieldMetadata(adresseMapping(
        'LieuPrincipalFormationAdresse1Agora__c', 'LieuPrincipalFormationAdresse2Agora__c', 
        'LieuPrincipalFormationAdresse3Agora__c', 'LieuPrincipalFormationAdresse4Agora__c', 
        'LieuPrincipalFormationCodePostalAgora__c', 'LieuPrincipalFormationCommuneAgora__c',
    ))
} as any

const AVENANT_CONTRAT_TYPES = ['5']

export const EditVAEIContratMetadata = {
    ...VAEIContratMetadata,
    typeContrat: override(VAEIContratMetadata.typeContrat, { props: {
        options: (refs: References, _?: any, root?: EditDossierVAEI) => {
            return root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT || root?.wasAvenant
                ? refs?.REF_TYPE_CONTRAT.filter(ref => AVENANT_CONTRAT_TYPES.includes(ref.Id))
                : refs?.REF_TYPE_CONTRAT.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('2'))
        },
    } as any }),
    
    dateDebut : override(VAEIContratMetadata.dateDebut, { props: { 
        disabled: (ctx: { root: { typeModificationContrat: string } }) => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT ? true : false, 
    } as any }),

    dateConclusion: override(VAEIContratMetadata.dateConclusion.mutate(_ => RequiredDate), { props: { 
        disabled: (ctx: { root: { typeModificationContrat: string } }) => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT ? true : false, 
    } as any }),
    dateEffet: DateFieldMetadata({
        section: SECTION_VAEI_CONTRAT,
        props: {
            label: ctx => ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT ? "Date d'effet de l'avenant" : "Date d'effet de la correction", 
        },
        mapping: dateMapping('DateEffetAvenantContrat__c'),
    }).required().mutate(yop => yop
        .test<DossierVAEIContrat>(ctx => {
            const dateDebut = ctx.parent?.dateDebut
            const dateEffet = ctx.value
            if (!dateDebut || !dateEffet)
                return true
            
            if (ctx.root?.typeModificationContrat === TYPE_MODIFICATION_AVENANT) {
                if (isBefore(dateEffet, dateDebut))
                    return ctx.createError("La date d'effet doit être postérieure à la date de début du contrat")
            }
            else if (isAfter(dateEffet, dateDebut))
                return ctx.createError("La date d'effet doit être antérieure à la date de début du contrat")
            
            return true
        })
    ),
    dateConclusionAvenant: DateFieldMetadata({
        section: SECTION_VAEI_CONTRAT,
        props: {
            label: "Date de conclusion de l'avenant",
        },
        visibleIf: ctx => ctx.root.typeModificationContrat === TYPE_MODIFICATION_AVENANT,
    }).mutate(yop => yop
        .requiredIf(ctx => ctx.root.typeModificationContrat === TYPE_MODIFICATION_AVENANT)
        .test<DossierVAEIContrat>(ctx => {
            const dateDuJour = new Date();
            const dateConclusionAvenant = ctx.value
            const dateEffet = ctx.parent?.dateEffet
            if (!dateEffet || !dateConclusionAvenant)
                return true
            
            if (isAfter(dateConclusionAvenant, dateEffet))
                return ctx.createError("La date de conclusion de l'avenant doit être antérieure à la date d'effet")
               
            if (isAfter(dateConclusionAvenant, dateDuJour)) {
                    return ctx.createError("La date de conclusion de l'avenant doit être  égale ou antérieure à la date du jour de la modification")
                }
            const dateConclusion = ctx.parent?.dateConclusion
            if (dateConclusion && isBefore(dateConclusionAvenant, dateConclusion))
                return ctx.createError("La date de conclusion (avenant) doit être postérieure à la date de conclusion")

            return true
        })
    )
} as any

type EditDossierVAEILight = Omit<EditDossierVAEI, 'pieces' | 'pointsReserve' | 'indicateurConformite'
    | 'wasAvenant' | 'typeModificationContrat' | 'numModificationContrat' | 'nbModificationsContrat' | 'etatModificationContrat' | 'attestationMandat'>
export const EditVAEIMetadataLight: ObjectMetadata<EditDossierVAEILight> = {
    employeur: ObjectFieldMetadata(VAEIEmployeurMetadata, 'Employeur'),
    salarie: ObjectFieldMetadata(EditVAEIStagiaireMetadata, 'Apprenti(e)'),
    modulePrincipal: ObjectFieldMetadata(EditVAEIModulePrincipalMetadata, 'Module principal'),
    moduleAfest: OptionalObjectFieldMetadata(EditVAEIModuleAFESTMetadata, 'Module AFEST'),
    tuteurTravailTemporaire: tuteurTravailTemporaireMetadata,
    tuteur: ObjectFieldMetadata(VAEITuteurMetadata, 'Tuteur'),
    contrat: ObjectFieldMetadata(EditVAEIContratMetadata, 'Contrat'),
    signature: MetadataVAEI.fields.signature,
}
const EditVAEIMetadata = EditVAEIMetadataLight as ObjectMetadata<EditDossierVAEI>


export const MetadataEditVAEI = registerEditMetadata<EditDossierVAEI>(AppType.Entreprise, {
    dispositif: DISPOSITIF_VAEI,
    title: (dossier: EditDossierVAEI | null) => (dossier?.typeModificationContrat ?? 'Modification') + " d'un dossier VAEI",
    pathname: '/modifier-dossier-vaei',
    steps: MetadataVAEI.steps.map(s => { 
        if (s.rubrique === 'APPRENTI') {
            return { ...s, sections: [] } as FormStep<EditDossierVAEI>
        } else if (s.rubrique === 'CONTRAT') {
            return { ...s,
                // sections: s.sections.concat(SECTION_CPRO_CONTRAT_MODIFICATION),
                submitDisabled: (dossier, pending, initialDossier) => {
                   if (pending || !dossier || !initialDossier) return true
                   return isEqual(initialDossier, dossier)
               },
            } as FormStep<EditDossierVAEI>
       } else if (s.rubrique === 'RECAP') {
           return { ...s,
               submitDisabled: (dossier, pending, initialDossier) => {
                   if (pending || !dossier || !initialDossier) return true
                   return isEqual(initialDossier, dossier)
               },
               onSubmit: onDossierModificationRecapitulatifSubmit,
           } as FormStep<EditDossierVAEI>
       }
       return { ...s } as FormStep<EditDossierVAEI>
    }),
    confirmationContent: null,
    api,
    fields: EditVAEIMetadata,
    exportProps: MetadataVAEI.exportProps as any,
})
