import { AtlasIcon, AtlasIconButton, AtlasLoading } from "atlas-ds";
import { MouseEventHandler } from "react";
import { EVOLUTIONS, direction } from "../MyEvolutionList/MyEvolutionList";

export interface MyEvolutionProps {
  /**
   * L'intitulé de l'évolution
   */
  children: string;
  /**
   * De forte baisse (-2) à forte hausse (2), la direction de cette évolution.
   * Dans le cas d'une évolution éditable, cette valeur sera la valeur
   * sélectionée par défaut.
   */
  direction: direction;
  /**
   * L'action à éxécuter au clic sur les infos
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * La fonction à éxécuter lorsque l'utilisateur change la direction de
   * l'évolution. L'évolution n'est pas modifiable si ce paramètre est absent.
   * L'édition ne fonctionne pas dans Storybook car le contexte appelant doit
   * modifier le paramètre `direction` grâce à ce callback.
   */
  onChange?: (direction: direction) => void;
}

/**
 * Une tendance d'évolution (hausse, baisse...) d'un facteur donné.
 */
export function MyEvolution(props: MyEvolutionProps) {
  const id = props.children.replace(/\W/g, "_");
  const selectedEvolution = EVOLUTIONS.find(
    (evolution) => evolution.direction === props.direction
  );

  if (!selectedEvolution) {
    return <></>;
  }

  return (
    <div className="my-evolution">
      <div className="my-evolution__inner">
        {props.onChange ? (
          <fieldset className="my-evolution__selector">
            <legend>
              Sélectionner l'évolution attendue pour : {props.children}
            </legend>
            <div className="my-evolution__options">
              {EVOLUTIONS.map((evolution) => (
                <div key={`${evolution.label}-${evolution.id}`}>
                  <input
                    type="radio"
                    id={`${id}-${evolution.id}`}
                    value={evolution.direction}
                    name={id}
                    checked={selectedEvolution.id === evolution.id}
                    onChange={() =>
                      props.onChange && props.onChange(evolution.direction)
                    }
                  />
                  <label htmlFor={`${id}-${evolution.id}`}>
                    <AtlasIcon
                      size="l"
                      name={evolution.icon}
                      title={`${evolution.label} - ${evolution.label}`}
                    />
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        ) : (
          <AtlasIcon
            size="l"
            name={selectedEvolution.icon}
            title={selectedEvolution.label}
          />
        )}
        <div className="my-evolution__labelAndInfos">
          <AtlasLoading.Loader />
          <div className="my-evolution__label">{props.children}</div>
          {props.onClick && (
            <div className="my-evolution__infos">
              <AtlasIconButton
                ariaLabel={`Plus d'infos sur ${props.children}`}
                onClick={props.onClick}
              >
                <AtlasIcon size="m" name="info" />
              </AtlasIconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
