import { RootState } from 'app/rootReducer'
import { useAppDispatch } from "app/store"
import { AtlasButton, AtlasHtml, AtlasSpacer, MyModal } from 'atlas-ds'
import Link from 'components/Link'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { profilActions } from "slices/profilSlice"

export default function AcceptationCguModal() {

    const dispatch = useAppDispatch()
    const location = useLocation()

    const { profil, pending } = useSelector((state: RootState) => state.profilState)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setShowModal(
            location.pathname !== "/conditions-utilisation"
            && !profil?.Contact.AcceptationCGU__c
        )
    }, [profil?.Contact, location.pathname])

    const accepterCgu = () => {
        dispatch(profilActions.acceptCgu()).finally(() => setShowModal(false))
    }

    return <MyModal
        label='Mise à jour des conditions générales d’utilisation'
        show={showModal}
        okButton={ <AtlasButton onClick={accepterCgu} spinner={{spinning: pending}}>
            Confirmer
        </AtlasButton> }
    >
        <AtlasHtml>
            <p>J’atteste avoir pris connaissance des Conditions générales d’utilisation (CGU) de myAtlas et en accepter les termes sans réserve.</p>

            <p><strong>Quels sont les changements ?</strong></p>
            <ul>
                <li>myAtlas vous enverra mensuellement un courriel listant les dossiers requérant une action de votre part, si vous en avez.</li>
            </ul>
        </AtlasHtml>

        <AtlasSpacer />
        <Link icon="article" to="/conditions-utilisation" level={ 3 }>
            Lire les conditions générales d'utilisation
        </Link>
    </MyModal>
}
