import { TestValidationContext, ValidationContext } from '@dsid-opcoatlas/yop'
import { loadAndMergeDossierPieces } from 'api/dossierAPI'
import { DossierProADTO, loadDossierProA, saveDossierProA, sendDossierProA } from 'api/dossierAPI'
import { DISPOSITIF_PROA, STATUT_PERSONNE_ENTREE_FORMATION_SALARIE, TYPE_CERTIFICATION_RNCP, TYPE_COUT_PEDAGOGIQUE, TYPE_FORMATION_INTER, TYPE_FORMATION_INTERNE } from 'api/references'
import { References } from 'api/referencesAPI'
import { normalizeAPIDate } from 'components/format/Format'
import { RequiredNumber } from 'components/yop/constraints'
import { differenceInDays, endOfMonth, isAfter } from 'date-fns'
import { SECTION_CONTRAT_EMPLOI_OCCUPE, SECTION_CONTRAT_EMPLOI_VISE, contratFields } from 'pages/dossier/metadata/ContratMetadata'
import { MappingContext, dateMapping, mapCoreDTOToBaseDossier, mapDTOToModel, mapModelToDTO, simpleMapping, } from 'pages/dossier/metadata/DossierMapping'
import { SECTION_EMPLOYEUR_CONTACT, SECTION_EMPLOYEUR_EXTRA, employeurExtendedMetadata, mapDTOToEmployeur, mapEmployeurToDTO } from 'pages/dossier/metadata/EmployeurMetadata'
import { ElementContext, IgnoredFieldMetadata, ObjectMetadata, ignored, override, registerMetadata } from 'pages/dossier/metadata/FormMetadata'
import { SECTION_MODULE_AGORA, SECTION_MODULE_OF, mapDTOToModule, mapModuleToDTO, moduleAgoraFields, moduleFields, typeCoutMapping } from 'pages/dossier/metadata/ModulesMetadata'
import { SECTION_SALARIE_AGORA, mapDTOToStagiaire, mapStagiaireToDTO, salarieFields } from 'pages/dossier/metadata/SalariesMetadata'
import { signatureMetadata } from 'pages/dossier/metadata/SignatureMetadata'
import { tuteurFields } from 'pages/dossier/metadata/TuteursMetadata'
import { AdresseFieldMetadata, adresseMapping } from 'components/metadata/AdresseFieldMetadata'
import { BooleanFieldMetadata } from 'components/metadata/BooleanFieldMetadata'
import { DateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { DurationFieldMetadata, floatingDurationMapping } from 'components/metadata/DurationFieldMetadata'
import { RequiredDurationMnFieldMetadata, durationMnMapping, durationMnSchema } from 'components/metadata/DurationMnFieldMetadata'
import { ArrayFieldMetadata, ObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { SelectRefFieldMetadata } from 'components/metadata/RefFieldMetadata'
import { EmailFieldMetadata, PhoneFieldMetadata, TextFieldMetadata } from 'components/metadata/TextFieldMetadata'
import { DossierContratElements } from 'pages/dossier/elements/DossierContratElements'
import { DossierEmployeurElements } from 'pages/dossier/elements/DossierEmployeurElements'
import { DossierModulesElements, dossierModulesInitializer } from 'pages/dossier/elements/DossierModulesElements'
import { DossierPiecesElements, piecesSubmitDisabled } from 'pages/dossier/elements/DossierPiecesElements'
import { DossierRecapitulatifElements, DossierRecapitulatifPreElements, dossierRecapitulatifInitializer, onDossierRecapitulatifSubmit } from 'pages/dossier/elements/DossierRecapitulatifElements'
import { DossierSalariesElements, dossierSalariesInitializer } from 'pages/dossier/elements/DossierSalariesElements'
import { DossierTuteurElements } from 'pages/dossier/elements/DossierTuteurElements'
import { postSendDossier } from 'pages/dossier/SubmitDossier'
import RecapContrat from 'pages/dossier/recap/RecapContrat'
import RecapEmployeur from 'pages/dossier/recap/RecapEmployeur'
import RecapModules from 'pages/dossier/recap/RecapModules'
import RecapPieces from 'pages/dossier/recap/RecapPieces'
import RecapSalaries from 'pages/dossier/recap/RecapSalaries'
import RecapTuteur from 'pages/dossier/recap/RecapTuteur'
import { AppType } from 'slices/contextSlice'
import { BaseDossier, BaseDossierModule, DossierModuleWithRNCP, DossierWithModules, DossierWithSalaries, ModuleAgora, createAdresse, createBaseDossierModule, createBaseDossierStagiaire, createBaseDossierTuteur, createDossierAF, createDossierEmployeurExtended, nil, normalizePiecesDTO } from 'slices/dossierCommon'
import { DossierAPI } from 'slices/dossierSlice'
import { createCerfaProA } from './cerfa/CerfaProA'
import { searchTuteur } from 'pages/dossier-cpro/CPROTuteurMetadata'


export function createDossierProAEmployeur() { return {
    ...createDossierEmployeurExtended(),
    libelleCCN: nil as string,
    // nomContact: nil as string,
    // prenomContact: nil as string,
}}
export type DossierProAEmployeur = ReturnType<typeof createDossierProAEmployeur>


export function createDossierProASalarie() { return {
    ...createBaseDossierStagiaire(),
    telephone: nil as string,
    adresse: createAdresse(),
    situationAvantContrat: nil as string,
    plusHautDiplome: nil as string,
    poleEmploi: nil as boolean,
    typeMinimumSocial: nil as (string | null),
    cspAgora: nil as string,
    niveauMaximumClasseEntreeFormationAgora: nil as string,
}}
export type DossierProASalarie = ReturnType<typeof createDossierProASalarie>


export function createDossierProATuteur() { return {
    ...createBaseDossierTuteur(),
    eligibilite: nil as boolean,
    anneesExperience: nil as number,
    salariesSuivis: nil as number,
}}
export type DossierProATuteur = ReturnType<typeof createDossierProATuteur>


export function createDossierProAModule() { return { 
    ...createBaseDossierModule(),
    attestationFormationInterne: nil as (boolean | null),
    typeQualification: nil as string,
    certificationVisee: nil as string,
    codeRNCP: nil as (string | null),
    specialiteFormation: nil as string,
    dureeEvaluation: nil as number,
    dureeEnseignement: nil as number,
    dateExamen: nil as Date,
    modalitePedagogiqueAgora: nil as string,
    adresseRealisationIdentiqueAgora: nil as boolean,
    lieuPrincipalFormationAgora: createAdresse(),
}}
export type DossierProAModule = ReturnType<typeof createDossierProAModule> & DossierModuleWithRNCP & ModuleAgora


export function createDossierProAContrat() { return { 
    emploiOccupe: nil as (string | null),
    dateDebutContrat: nil as Date,
    classificationEmploi: nil as (string | null),
    niveauEmploi: nil as (string | null),
    coefficientHierarchique: nil as (string | null),
    typeProA: nil as string,
    vaeProA: nil as boolean,
    tempsTravailProA: nil as boolean,
    emploiViseProA: nil as (string | null),
    dateDebutProA: nil as Date,
    dateFinProA: nil as Date,
    dureeHebdoProA: { hours: nil as number, minutes: null as (number | null) },
    classificationEmploiProA: nil as (string | null),
    niveauEmploiProA: nil as (string | null),
    coefficientHierarchiqueProA: nil as (string | null),
}}
export type DossierProAContrat = ReturnType<typeof createDossierProAContrat>


export function createDossierProA() { return {
    ...createDossierAF<DossierProASalarie, DossierProAModule>(DISPOSITIF_PROA),
    employeur: createDossierProAEmployeur(),
    tuteur: nil as DossierProATuteur,
    contrat: createDossierProAContrat(),
}}
export type DossierProA = ReturnType<typeof createDossierProA> & DossierWithModules<DossierProAModule> & DossierWithSalaries<DossierProASalarie>


const mapDTOToDossierProA = (dossierDTO: DossierProADTO): DossierProA => {

    const dossier = createDossierProA()
    mapCoreDTOToBaseDossier(dossier, dossierDTO)

    const context: MappingContext = {
        metadata: ProAEmployeurMetadata,
        editMode: false,
        dossier,
        dossierDTO,
    }

    // Employeur
    mapDTOToEmployeur(context, ProAEmployeurMetadata, dossier.employeur, dossierDTO)

    // Tuteur
    if (dossierDTO.NomTuteur__c == null)
        dossier.tuteur = nil as DossierProATuteur
    else {
        dossier.tuteur = createDossierProATuteur()
        dossier.tuteur.IdContact = dossierDTO.Tuteur__c ?? null
        mapDTOToModel(context, ProATuteurMetadata!, dossier.tuteur, dossierDTO)
    }

    // Salaries
    dossier.salaries = dossierDTO.Stagiaires?.map(salarieDTO => {
        const salarie = createDossierProASalarie()
        mapDTOToStagiaire(context, ProASalarieMetadata, salarie, salarieDTO)
        return salarie
    }) ?? []

    // Modules
    dossier.modules = dossierDTO.Modules?.map(moduleDTO => {
        const module = createDossierProAModule()
        mapDTOToModule(context, ProAModuleMetadata!, module, moduleDTO)
        return module
    }) ?? []
    if (dossier.modules.length > 0) {
        const mainModule = dossier.modules[0]
        mapDTOToModel(context, ProAModuleMetadata!, mainModule, dossierDTO, 'dossier')
        // Spécifique ProA: 
        mainModule.dateExamen = normalizeAPIDate(dossierDTO.Modules[0].DateExamen__c ?? dossierDTO.DateExamen__c) ?? (nil as Date)
        mainModule.certificationVisee = dossierDTO.Stagiaires?.[0]?.DiplomeVise__c ?? (nil as string)
    }

    // Contrat
    mapDTOToModel(context, ProAContratMetadata, dossier.contrat, dossierDTO)

    // Signature
    dossier.signature.nom = dossierDTO.NomPrenomSignature__c ?? (nil as string)
    dossier.signature.lieu = dossierDTO.LieuSignature__c ?? (nil as string)
    dossier.signature.date = dossierDTO.DateSignature__c ?? (nil as Date)

    dossier.pieces = normalizePiecesDTO(dossierDTO.Pieces)

    return dossier
}


const mapDossierProAToDTO = (dossier: DossierProA): DossierProADTO => {
    const mainModule = dossier.modules?.[0]
    const dto = {
        IdHeroku: dossier.IdHeroku ?? null,
        NumeroDossier__c: dossier.NumeroDossier__c ?? null,
        TypeDossier__c: null,   // Non utilisé
        DispositifFO__c: dossier.DispositifFO__c ?? null,

        Intitule__c: mainModule?.intitulePrecis ?? null,

        // Employeur
        ...mapEmployeurToDTO(ProAEmployeurMetadata, dossier, dossier.employeur),

        // Tuteur
        Tuteur__c: dossier.tuteur?.IdContact ?? null,
        ...mapModelToDTO(ProATuteurMetadata!, dossier, dossier.tuteur),

        // Salaries
        Stagiaires: dossier.salaries.map(s => {
            const sdto = mapStagiaireToDTO(ProASalarieMetadata, dossier, s)
            // Spécifique ProA: champ stagiaire copié du module + champ Agora en dur
            sdto!.DiplomeVise__c = dossier.modules?.[0]?.certificationVisee ?? null
            sdto!.StatutEntreeEnFormationAgora__c = STATUT_PERSONNE_ENTREE_FORMATION_SALARIE
            return sdto
        }),

        // Modules
        Modules: dossier.modules.map((m, index) => {
            const mdto = mapModuleToDTO(ProAModuleMetadata!, dossier, m, index)
            if (mdto) {
                // Spécifique ProA: module toujours principal + type formation en fonction de formationInterne
                mdto.Principal__c = true 
                mdto.TypeFormation__c = m.formationInterne ? TYPE_FORMATION_INTERNE : TYPE_FORMATION_INTER 
            }
            return mdto
        }).filter(mdto => mdto), // TODO: le tableau des modules ne doit contenir que des éléments valides !

        DateExamen__c: mainModule?.dateExamen ?? null,
    
        // Contrat
        ...mapModelToDTO(ProAContratMetadata!, dossier, dossier.contrat),

        // Signature
        NomPrenomSignature__c: dossier.signature?.nom ?? null,
        DateSignature__c: dossier.signature?.date ?? null,
        LieuSignature__c: dossier.signature?.lieu ?? null,

        // Autres
        EtatDossierFO__c: null,
        NumModificationContrat__c: null,
    } as DossierProADTO

    // Formation (niveau dossier)
    mapModelToDTO(ProAModuleMetadata!, dossier, mainModule, dto, 'dossier')

    return dto
}


const api: DossierAPI<DossierProA> = {
    create: createDossierProA,
    createModule: createDossierProAModule,
    createSalarie: createDossierProASalarie,
    createTuteur: createDossierProATuteur,
    load: async (dossierId: string) => {
        const dossierDTO = await loadDossierProA(dossierId)
        return mapDTOToDossierProA(dossierDTO)
    },
    loadWithPieces: async (dossierId: string) => {
        const dossierDTO = await loadDossierProA(dossierId)
        if (dossierDTO) {
            dossierDTO.Pieces = await loadAndMergeDossierPieces(dossierId, dossierDTO.Pieces ?? [])
        }
        return mapDTOToDossierProA(dossierDTO)
    },
    save: async (dossier: DossierProA) => {
        const dossierDTO = mapDossierProAToDTO(dossier)
        const savedDossierDTO = await saveDossierProA(dossierDTO)
        return mapDTOToDossierProA(savedDossierDTO)
    },
    send: async (dossier: DossierProA) => {
        const dossierDTO = mapDossierProAToDTO(dossier)
        const sentDossierDTO = await sendDossierProA(dossierDTO)
        return mapDTOToDossierProA(sentDossierDTO)
    },
    postSend: async (dossier: DossierProA) => {
        await postSendDossier(MetadataProA, dossier)
    },
}

const { telephone, email, ...employeurBaseMetadata } = employeurExtendedMetadata

export const ProAEmployeurMetadata: ObjectMetadata<DossierProAEmployeur> = { 
    ...employeurBaseMetadata,
    libelleCCN: IgnoredFieldMetadata(simpleMapping('LibelleCCN__c')),
    /* nomContact: RequiredTextFieldMetadata({
        section: SECTION_EMPLOYEUR_CONTACT,
        label: 'Nom',
        mapping: simpleMapping('NomContactADH__c'),
        disabled: (props: any) => !props.parent?.Id,
    }),
    prenomContact: RequiredTextFieldMetadata({
        section: SECTION_EMPLOYEUR_CONTACT,
        label: 'Prénom',
        mapping: simpleMapping('PrenomContactADH__c'),
        disabled: (props: any) => !props.parent?.Id,
    }), */
    email: EmailFieldMetadata({
        props: {
            label: 'E-mail',
            disabled: (props: any) => !props.parent?.Id,
        },
        section: SECTION_EMPLOYEUR_CONTACT,
        mapping: simpleMapping('EmailADH__c'),
    }).required(),
    telephone: PhoneFieldMetadata({
        props: {
            label: 'Téléphone',
            disabled: (props: any) => !props.parent?.Id,
        },
        section: SECTION_EMPLOYEUR_CONTACT,
        mapping: simpleMapping('TelephoneADH__c'),
    }).required(),
}

export const ProASalarieMetadata: ObjectMetadata<DossierProASalarie> = {
    nomNaissance: salarieFields.nomNaissance,
    nom: salarieFields.nom,
    prenom: salarieFields.prenom,
    sexe: salarieFields.sexe,
    dateNaissance: salarieFields.dateNaissance,
    nir: salarieFields.nir.required(),
    ntt: salarieFields.ntt,
    nirVerifError: salarieFields.nirVerifError,
    courriel: salarieFields.courriel,
    telephone: salarieFields.telephone,
    adresse: AdresseFieldMetadata({
        mapping: adresseMapping('Adresse1__c', 'Adresse2__c', 'Adresse3__c', 'Adresse4__c', 'CodePostal__c', 'Ville__c')
    }).required(),
    handicap: salarieFields.handicap,
    situationAvantContrat: override(salarieFields.situationAvantContrat, { props: {
        label: 'Situation du salarié',
        options: (refs: References) => refs.REF_SITUATION_SALARIE.filter(ref => ref.IsActiveFO__c && ref.Categorie__c?.split(',').includes('6')),
    }}),
    plusHautDiplome: salarieFields.plusHautDiplome,
    poleEmploi: salarieFields.poleEmploi,
    typeMinimumSocial: salarieFields.typeMinimumSocial,
    cspAgora: salarieFields.cspAgora,
    niveauMaximumClasseEntreeFormationAgora: salarieFields.niveauMaximumClasseEntreeFormationAgora,
    situationHandicap: IgnoredFieldMetadata(simpleMapping('SituationHandicap__c')),
}

export const ProATuteurMetadata: ObjectMetadata<DossierProATuteur> = {
    nomNaissance: tuteurFields.nomNaissance(searchTuteur),
    nom: tuteurFields.nom,
    prenom: tuteurFields.prenom,
    dateNaissance: tuteurFields.dateNaissance,
    emploiOccupe: tuteurFields.emploiOccupe,
    employeurDirigeant: tuteurFields.employeurDirigeant,
    anneesExperience: tuteurFields.anneesExperience,
    salariesSuivis: tuteurFields.salariesSuivis,
    priseEnChargeFonctionTutorale: tuteurFields.priseEnChargeFonctionTutorale,
    exerciceFonction2Annees: override(tuteurFields.exerciceFonction2Annees, {
        props: {
            label: 'Tutorat exercé au cours des 2 dernières années',
        },
        mapping: simpleMapping('Exercice2AnneesTuteur__c'),
    }),
    formation: tuteurFields.formation,
    dateFormation: tuteurFields.dateFormation,
    eligibilite: tuteurFields.eligibilite,
}

export const ProAModuleMetadata: ObjectMetadata<DossierProAModule> = {
    formationInterne: override(moduleFields.formationInterne, { section: SECTION_MODULE_OF }),
    cfa: override(moduleFields.cfa, { props: { da: true }, section: SECTION_MODULE_OF }),
    subrogation: override(moduleFields.subrogation, { section: SECTION_MODULE_OF }),
    attestationFormationInterne: moduleFields.attestationFormationInterne,
    intitulePrecis: moduleFields.intitulePrecis,
    typeQualification: override(moduleFields.typeQualification([ TYPE_CERTIFICATION_RNCP ]), { 
        props: { label: 'Type certification visée',  options: (refs: References) => refs.REF_TYPE_QUALIFICATION.filter(r => r.IsActiveFO__c && r.Categorie__c?.includes('6')) } 
    }),
    certificationVisee: moduleFields.certificationVisee,
    codeRNCP: moduleFields.codeRNCP([ TYPE_CERTIFICATION_RNCP ]),
    specialiteFormation: moduleFields.specialiteFormation,
    dureeTotale: IgnoredFieldMetadata(),
    dureeEvaluation: DurationFieldMetadata({
        props: {
            label: "Durée totale des actions d'évaluation, d'accompagnement et des enseignements",
            suffix: 'heure(s)',
        },
        mapping: floatingDurationMapping('DureeHeureEvaluation__c', 'dossier'),
        visibleIf: (context: ElementContext<BaseDossierModule, BaseDossier>) => context.parent?.formationInterne !== null,
    }).required().mutate(yop => yop.min(1)), //.max(410, 'La durée totale de formation est plafonnée à 410 heures'),
    dureeEnseignement: override(moduleFields.dureeEnseignement, { yop: RequiredNumber.min(0).max<DossierProAModule>(
        context => context.parent!.dureeEvaluation,
        'La durée des enseignements doit être inférieure à la durée totale',
    ) }),
    dateDebutFormation: moduleFields.dateDebutFormation,
    dateExamen: moduleFields.dateExamen,
    dateFinFormation: IgnoredFieldMetadata(),
    coutPedagogique: override(moduleFields.coutPedagogique, { mapping: typeCoutMapping(TYPE_COUT_PEDAGOGIQUE, 'CoutPedagogique__c', 'dossier', 'Modules[0]') }),
    typeFormation: ignored(moduleFields.typeFormation),
    commentaire: IgnoredFieldMetadata(),
    modalitePedagogiqueAgora: moduleAgoraFields.modalitePedagogiqueAgora,
    adresseRealisationIdentiqueAgora: moduleAgoraFields.adresseRealisationIdentiqueAgora,
    lieuPrincipalFormationAgora: moduleAgoraFields.lieuPrincipalFormationAgora,
}

export const ProAContratMetadata: ObjectMetadata<DossierProAContrat> = {
    emploiOccupe: contratFields.emploiOccupe,
    dateDebutContrat: DateFieldMetadata({
        props: {
            label: "Date de début d'exécution du contrat",
        },
        section: SECTION_CONTRAT_EMPLOI_OCCUPE,
        mapping: dateMapping('DateDebutExecutionContrat__c'),
    }).required(),
    classificationEmploi: contratFields.classificationEmploi,
    niveauEmploi: contratFields.niveauEmploi,
    coefficientHierarchique: contratFields.coefficientHierarchique,
    typeProA: SelectRefFieldMetadata({
        props: {
            label: 'Pro-A visé',
            options: refs => refs.REF_TYPE_PROA.filter(r => r.IsActiveFO__c),
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: simpleMapping('TypeProA__c'),
    }).required(),
    vaeProA: BooleanFieldMetadata({
        props: {
            label: 'La Pro-A comporte-t-elle des actions permettant de valider les acquis de l’expérience (VAE) ?',
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: simpleMapping('VAEProA__c'),
    }).required(),
    tempsTravailProA: BooleanFieldMetadata({ 
        props: {
            label: 'La Pro-A est effectuée sur le temps de travail',
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: simpleMapping('TempsTravailProA__c'),
    }).required(),
    emploiViseProA: TextFieldMetadata({
        props: {
            label: 'Emploi visé par la Pro-A (intitulé précis)',
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: simpleMapping('EmploiViseProA__c'),
    }).mutate(yop => yop.max(80)),
    dateDebutProA: DateFieldMetadata({
        props: {
            label: "Date de début d'exécution de la Pro-A", 
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: dateMapping('DateDebutContrat__c'),
    }).required().mutate(yop => yop.test(function(context: TestValidationContext<Date | null>) {
        const dateDebutProA = context.value
        if (dateDebutProA === null)
            return true
        const dateDebutFormation = context.root.modules?.[0]?.dateDebutFormation
        if (!dateDebutFormation)
            return true
        if (isAfter(dateDebutProA, dateDebutFormation))
            return context.createError("La période d'exécution de la ProA ne peut pas commencer après la date de début de formation")
        if (differenceInDays(dateDebutFormation, dateDebutProA) > 61)
            return context.createError('Il ne doit pas y avoir plus de 2 mois entre date de début d’exécution de la période ProA et la date de début  de formation')
        return true
    })),
    dateFinProA: DateFieldMetadata({
        props: {
            label: "Date de fin d'exécution de la Pro-A", 
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: dateMapping('DateFinContrat__c'),
    }).required().mutate(yop => yop.min((context: ValidationContext<Date, DossierProAContrat>) => context.parent?.dateDebutProA).test(function(context: TestValidationContext<Date | null>) {
        const dateFinProA = context.value
        if (dateFinProA === null)
            return true
        const dateFinFormation = context.root.modules[0].dateFinFormation
        if (!dateFinFormation)
            return true
        const certificationVisee = context.root.modules[0].certificationVisee
        if (!certificationVisee)
            return true
        if (certificationVisee === TYPE_CERTIFICATION_RNCP) {
            if (differenceInDays(dateFinProA, dateFinFormation) > 61)
                return context.createError('Il ne doit pas y avoir plus de 2 mois entre date de fin d’exécution de la période ProA et date de fin d’exécution de la formation')
        }
        else {
            const moisFinFormation = endOfMonth(dateFinFormation)
            if (isAfter(dateFinProA, moisFinFormation))
                return context.createError('La date de fin d’exécution de la période ProA ne peut aller au-delà de la fin du mois de fin d’exécution de la formation')
        }
        return true
    })),
    dureeHebdoProA: RequiredDurationMnFieldMetadata({
        props: {
            label: 'Durée hebdomadaire du travail',
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: durationMnMapping('DureeHebdomadaireTravailHeures__c', 'DureeHebdomadaireTravailMinutes__c'),
    }).mutate(_ => durationMnSchema(48, 'Maximum 48 heures par semaine')),
    classificationEmploiProA: TextFieldMetadata({
        props: {
            label: "Classification de l'emploi dans la convention collective",
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: simpleMapping('ClassificationEmploiProA__c'),
    }).mutate(yop => yop.max(250)),
    niveauEmploiProA: TextFieldMetadata({
        props: {
            label: 'Niveau ou position',
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: simpleMapping('NiveauCCNProA__c'),
    }).mutate(yop => yop.min(1).max(15)),
    coefficientHierarchiqueProA: TextFieldMetadata({
        props: {
            label: 'Coefficient hiérachique',
        },
        section: SECTION_CONTRAT_EMPLOI_VISE,
        mapping: simpleMapping('CoefficientCCNProA__c'),
    }).mutate(yop => yop.min(1).max(10)),
}

type DossierProALight = Omit<DossierProA, 'pieces'>
const ProAMetadataLight: ObjectMetadata<DossierProALight> = {
    employeur: ObjectFieldMetadata(ProAEmployeurMetadata),
    tuteur: ObjectFieldMetadata(ProATuteurMetadata),
    modules: ArrayFieldMetadata(ProAModuleMetadata),
    salaries: ArrayFieldMetadata(ProASalarieMetadata),
    contrat: ObjectFieldMetadata(ProAContratMetadata),
    signature: ObjectFieldMetadata(signatureMetadata),
}
const ProAMetadata = ProAMetadataLight as ObjectMetadata<DossierProA>

export const MetadataProA = registerMetadata(AppType.Entreprise, {
    dispositif: DISPOSITIF_PROA,
    title: "Dépôt dossier Contrat Pro A",
    pathname: '/nouveau-dossier-proa',
    steps: [
        { 
            title: 'Employeur', 
            sections: [ SECTION_EMPLOYEUR_EXTRA, SECTION_EMPLOYEUR_CONTACT ],
            recap: RecapEmployeur,
            yopPath: 'employeur',
            formContent: DossierEmployeurElements,
            options: {
                forceLoading: true,
            },
        }, {
            title: 'Salarié',
            sections: [ SECTION_SALARIE_AGORA ],
            recap: RecapSalaries,
            initializer: dossierSalariesInitializer,
            yopPath: 'salaries',
            formContent: DossierSalariesElements,
            options: {
                minElements: 1,
                maxElements: 1,
            },
        }, {
            title: 'Tuteur',
            sections: [],
            recap: RecapTuteur,
            yopPath: 'tuteur',
            yopPathSchemaAppender: s => s.required('Vous devez sélectionner un tuteur ou en créer un nouveau'),
            formContent: DossierTuteurElements,
        }, {
            title: 'Formation',
            sections: [ SECTION_MODULE_AGORA ],
            recap: RecapModules,
            initializer: dossierModulesInitializer,
            yopPath: 'modules',
            formContent: DossierModulesElements,
        }, {
            title: 'Contrat',
            sections: [ SECTION_CONTRAT_EMPLOI_OCCUPE, SECTION_CONTRAT_EMPLOI_VISE ],
            recap: RecapContrat,
            yopPath: 'contrat',
            formContent: DossierContratElements,
        }, {
            title: 'Pièces',
            sections: [],
            recap: RecapPieces,
            formContent: DossierPiecesElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                forceLoading: true,
                withPieces: true,
            },
        }, {
            title: 'Récapitulatif et envoi',
            sections: [],
            initializer: dossierRecapitulatifInitializer,
            preFormContent: DossierRecapitulatifPreElements,
            yopPath: 'signature',
            onSubmit: onDossierRecapitulatifSubmit,
            formContent: DossierRecapitulatifElements,
            submitDisabled: piecesSubmitDisabled,
            options: {
                noFormHeading: true,
                forceLoading: true,
                withPieces: true,
            },
        },
    ],
    api,
    fields: ProAMetadata,
    exportProps: [{
        label: 'Imprimer le Cerfa',
        exportDossier: async (metadata, dossier) => createCerfaProA(dossier),
        documentType: 'CERFA',
        documentName: 'Cerfa-ProA.pdf',
    }],
})

