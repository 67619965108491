import { getFieldState, useFormContext } from '@dsid-opcoatlas/reform'
import { Etablissement } from 'api/commonAPI'
import { MyForm } from 'atlas-ds'
import { join } from 'components/reform/inputs/utils/BaseFieldProps'
import { cloneDeep, set } from 'lodash'
import { useRef } from 'react'
import AutocompleteBaseField from './AutocompleteBaseField'
import { CompanySummary } from './CompanySummary'

interface EtablissementSelectorFieldProps {
    getEtablissements: (recherche: string, actif?: boolean) => Promise<Etablissement[]>
    label?: string
    disabled?: boolean
    employeurPath?: string
    adresse?: boolean
    code?: boolean
    onChange?: (etablissement: Etablissement | null) => void
    showSelectedInfo?: boolean
    requireSearch?: boolean
}

export default function EtablissementSelectorField(props: EtablissementSelectorFieldProps) {

    const form = useFormContext()
    const name = props.employeurPath ?? 'employeur'
    const fieldState = getFieldState<any>(form, name)
    const selectedEtablissementRef = useRef<Etablissement | null>(null)

    const onSelect = (etablissement: Etablissement) => {
        const value = cloneDeep(fieldState.value ?? {})
        
        set(value, 'Id', etablissement.Id)
        set(value, 'ParentId', etablissement.ParentId)
        set(value, 'nom', etablissement.Name)
        set(value, 'numeroSiren', etablissement.SIREN__c)
        set(value, 'numeroSiret', etablissement.SIRET__c)
        
        if (props.adresse !== false) {
            set(value, 'appartement', etablissement.Adresse1__c)
            set(value, 'batiment', etablissement.Adresse2__c)
            set(value, 'adresse', etablissement.Adresse3__c ?? etablissement.BillingStreet ?? '(adresse non renseignée)')
            set(value, 'complement', etablissement.Adresse4__c)
            set(value, 'codePostal', etablissement.BillingPostalCode)
            set(value, 'commune', etablissement.BillingCity)
        }

        if (props.code !== false) {
            set(value, 'codeNaf', etablissement.CodeNAF__c)
            set(value, 'codeIdcc', etablissement.CodeIDCC__c)
            set(value, 'nomContact', null)
            set(value, 'prenomContact', null)
            set(value, 'telephone', null)
            set(value, 'email', null)
        }
        
        form.setValue(name, value, true)

        props.onChange?.(etablissement)

        selectedEtablissementRef.current = etablissement
    }
    
    const renderEtablissement = (etablissement: Etablissement) => <CompanySummary
        nom={ etablissement.Name }
        siret={ etablissement.SIRET__c }
        adresse={ join(etablissement.Adresse3__c ?? etablissement.BillingStreet, etablissement.BillingPostalCode, etablissement.BillingCity) }
    />

    return (
        <MyForm.Field size="full">
            <AutocompleteBaseField<Etablissement>
                label={ props.label ?? "Sélectionner l'établissement" }
                defaultValue={ (props.showSelectedInfo ? fieldState.value?.nom : null) ?? "" }
                disabled={ props.disabled }
                placeholder="Rechercher par SIRET, raison sociale ou commune (3 caractères minimum)"
                required={ fieldState.constraints.required }
                error={ fieldState.error }
                onBlur={ value => form.setValue(name, props.showSelectedInfo && value === "" ? null : form.getValue(name), true) }
                searchDebounceOptions={ props.requireSearch ? undefined : { immediate: true, empty: true } }
                search={ props.getEtablissements }
                optionFor={ etablissement => ({ id: etablissement.Id, content: renderEtablissement(etablissement) }) }
                onSelect={ onSelect }
            />
            { props.showSelectedInfo && selectedEtablissementRef.current && fieldState.value?.Id === selectedEtablissementRef.current.Id && <CompanySummary
                siret={ selectedEtablissementRef.current.SIRET__c }
                adresse={ join(
                    selectedEtablissementRef.current.Adresse3__c ?? selectedEtablissementRef.current.BillingStreet,
                    selectedEtablissementRef.current.BillingPostalCode,
                    selectedEtablissementRef.current.BillingCity
                )}
            />
            }
        </MyForm.Field>
    )
}
