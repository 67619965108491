import { FieldMetadata, ClassConstructor, ClassOverride, classFieldsMetadataDecorator } from "elements/metadata";

export function propsOf<Decorator extends ((props?: any) => void)>(_: Decorator, props: Parameters<Decorator>[0]) {
    return props
}
function merge(dst: FieldMetadata<any, any>, src: FieldMetadata<any, any>) {
    if (dst.dto != null && src.dto != null) {
        if (typeof dst.dto === "string")
            dst.dto = { path: dst.dto }

        let srcDto = src.dto
        if (typeof srcDto === "string")
            srcDto = { path: srcDto }

        dst.dto = { ...srcDto, ...dst.dto }
    }
    return dst
}

export function overrides<T extends ClassConstructor>(fields: ClassOverride<T>) {
    return classFieldsMetadataDecorator<T>(fields, merge)
}
