import { UseFormReturn, useForm } from '@dsid-opcoatlas/reform'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'app/store'
import { AtlasColumns, AtlasHeading, AtlasInfo, MyForm } from 'atlas-ds'
import PageHeader from "components/PageHeader"
import Button from 'components/reform/Button'
import log from 'loglevel'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { dossierSliceActions } from 'slices/dossierSlice'
import { BaseDossierComponentProps, DossierIdParams, useDossier } from 'pages/dossier/useDossier'
import { useDossierNavigation } from 'pages/dossier/useDossierNavigation'
import { useIntegerQueryParam } from 'pages/dossier/useQueryParam'
import { DossierAF, DossierAFSalarie } from 'elements/model/dossierClasses'
import { useObservers } from 'elements/observers'
import { ClassConstructor } from 'elements/metadata'
import { createValidationSchema } from "elements/validation"
import { FormFields } from 'elements/FormFields'
import { ElementsForm } from 'elements/ElementsForm'
import { Path } from 'elements/Path'

export function SalariesEditFields(props: BaseDossierComponentProps<any>) {
    
    const model = props.metadata.model! as ClassConstructor<DossierAF>
    
    const dispatch = useAppDispatch() 
    const { dossierId } = useParams<DossierIdParams>()
    const salarieId = useIntegerQueryParam("id")
    
    const { etape, step, navigateTo } = useDossierNavigation(props.metadata)
    const { loading, saving, dossier, error } = useDossier(props.metadata.api, dossierId)
    // TODO: supprimer cette ligne lorsque useDossier pourra utiliser le type DossierAF 
    const initialValues = dossier as DossierAF

    const index = Math.max(0, 
        salarieId == null ?
        initialValues?.salaries?.length ?? 0 :
        initialValues?.salaries?.findIndex(salarie => salarie.IdHeroku === salarieId) ?? 0
    )

    const path = Path.fromString(`${ step.path!.toString() }[${ index }]`, model).toString()

    const initialValuesConverter = (dossier: DossierAF) => {
        if (salarieId == null)
            dossier.salaries.push(props.metadata.api.createSalarie(dossier) as unknown as DossierAFSalarie)
        return dossier
    }
    
    const form = useForm<DossierAF>({
        initialValues,
        initialValuesConverter,
        validationSchema: createValidationSchema(model).focusOn(path),
        onSubmit: (context: UseFormReturn<DossierAF>) => {
            const dossier = context.values! as any
            dispatch(dossierSliceActions.save({ api: props.metadata.api as any, dossier })).then(unwrapResult)
                .then(dossier => {
                    navigateTo(dossier.NumeroDossier__c!, etape)
                })
                .catch(error => {
                    log.error("Erreur dossier save salarie", error)
                    context.setSubmitting(false)
                })
        }
    })

    useObservers(model, form)

    useEffect(() => {
        if (salarieId != null && initialValues != null && form.validateAt(`salaries[${ index }]`, false)) {
            const firstErrorKey = form.getErrorPaths()?.[0]
            const element = global.window.document.getElementById(firstErrorKey)
            if (element) {
                setTimeout(() => {
                    element.focus();
                    element.scrollIntoView({ block: 'center' });
                }, 250)
            }

            form.renderForm()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salarieId, initialValues])


    function onAnnuler () {
        navigateTo(dossierId!, etape)
    }
    
    return (
        <>
            <PageHeader>
                { `${ props.metadata.title } ${ dossierId ? `<br />${ dossierId }` : ''}` }
            </PageHeader>
            
            <AtlasColumns>
                <div>
                    <ElementsForm form={ form } metadata={ props.metadata } step={ step } disabled={ loading }>
                        <AtlasHeading tag="h2" size="m">{ salarieId == null ? "Ajouter un salarié" : "Modifier un salarié" }</AtlasHeading>
                        <MyForm pending={loading}>
                            { !loading && error &&
                            <AtlasInfo type="error" title="Erreur sur ce dossier">{ error }</AtlasInfo>
                            }

                            <MyForm.Grid>
                                <FormFields model={ model } names={{ salaries: [index, {
                                    nomNaissance: true,
                                    nom: true,
                                    prenom: true,
                                    sexe: true,
                                    dateNaissance: true,
                                    nir: true,
                                    courriel: true,
                                    handicap: true,
                                    statut: { alone: true },
                                    contrat: true,
                                    niveauFormation: true,
                                    dateEntree: true,
                                    categorieAction: true,
                                    heures: true,
                                    heuresFoad: true,
                                    coefficient: true,
                                    autreMetier: true,
                                }] }} />

                                {/* <MyForm.Fieldset legend="Informations complémentaires AGORA" tooltipContent={ TOOLTIPS.cpro.agora }>
                                    <MyForm.Grid>
                                        <FormElements metadata={ props.metadata } section={ SECTION_SALARIE_AGORA } path={ props.name } type="input"
                                        index={ props.index != null ? 0 : undefined } />
                                    </MyForm.Grid>
                                </MyForm.Fieldset> */}
                            </MyForm.Grid>

                            { !loading && <MyForm.Actions>
                                <Button level={ 2 } onClick={ onAnnuler }>Annuler</Button>
                                <Button submit={ true } spinner={{spinning: saving}}>Enregistrer</Button>
                            </MyForm.Actions> }
                        </MyForm>
                    </ElementsForm>
                </div>
            </AtlasColumns>
        </>
    )
}
