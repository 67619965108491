import { Form, useForm } from "@dsid-opcoatlas/reform"
import { DossierDetail, DossierReporterDates, patchReporterDateDossier } from 'api/dossierAPI'
import { ETAT_DOSSIER_BO_ARRIVE } from 'api/references'
import { AtlasHtml, MyForm, MyModal } from "atlas-ds"
import { date2APIDate, formatDate, parseDate } from 'components/format/Format'
import Button from 'components/reform/Button'
import DateField from 'components/reform/inputs/DateField'
import { RequiredDate, optionalObject } from "components/yop/constraints"
import { differenceInMonths, isAfter } from 'date-fns'
import { useEffect, useState } from 'react'
import { ToastService } from "./BandeauAlert"


interface ChangerPeriodeModalProps {
    show: boolean
    close: () => void
    dossier: DossierDetail
    getDateFinValidite?: (dossier: DossierDetail) => Promise<string>
}

export default function ChangerPeriodeModal(props: ChangerPeriodeModalProps) {

    const [etape, setEtape] = useState(1)
    const [dateFinValidite, setDateFinValidite] = useState<Date>()

    useEffect(() => {
        if (props.getDateFinValidite) {
            props.getDateFinValidite(props.dossier).then(date => {
                const parsedDate = parseDate(date)
                if (parsedDate)
                    setDateFinValidite(parsedDate)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const etatDossierArrive = props.dossier.EtatDossier__c === ETAT_DOSSIER_BO_ARRIVE

    const initialValues = {
        dateDebut: parseDate(props.dossier.Modules[0]!.DateDebutFormation__c)!,
        dateFin: parseDate(props.dossier.Modules[0]!.DateFinFormation__c)!
    }
    
    type Periode = typeof initialValues

    const validationSchema = optionalObject<Periode>({
        dateDebut: RequiredDate
            .test<Periode>(context => {
                if (context.value!.getFullYear() !== initialValues.dateDebut.getFullYear())
                    return context.createError("Vous ne pouvez pas modifier l’année de la date de début de la formation. Merci de nous contacter.")
                if (dateFinValidite && isAfter(context.value!, dateFinValidite))
                    return context.createError(`La formation doit commencer avant la date de fin de validité (${ formatDate(dateFinValidite) }) de l’action collective`)
                return true
            }),
        dateFin: RequiredDate
            .min<Periode>(context => context.parent!.dateDebut, "La date de fin de formation ne doit pas être antérieure à la date de début")
            .test<Periode>(context => {
                if (!etatDossierArrive) {
                    if (context.parent?.dateDebut && differenceInMonths(context.value, context.parent.dateDebut) > 12)
                        return context.createError("L’écart entre la date de fin de formation et celle du début ne peut excéder 12 mois en ligne. Merci de nous contacter.")
                    if (differenceInMonths(context.value, initialValues.dateFin) > 6)       
                        return context.createError("Vous ne pouvez pas reporter la date de fin de formation de plus de 6 mois en ligne. Merci de nous contacter.")             
                }
                return true
            })
    })

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            if (etape === 1) {
                setEtape(2)
                context.setSubmitting(false)
                return
            }

            const params: DossierReporterDates = {
                DateDebutInitiale: date2APIDate(initialValues.dateDebut),
                DateDebutSouhaitee: date2APIDate(context.values!.dateDebut),
                DateFinInitiale: date2APIDate(initialValues.dateFin),
                DateFinSouhaitee: date2APIDate(context.values!.dateFin)
            }
            const idDossier = props.dossier.NumeroDossier__c ?? ''
            patchReporterDateDossier(idDossier, params)
                .then(_ => {
                    ToastService.showToast({
                        label: `Demande de report de dates concernant le dossier ${idDossier}`,
                        type: 'success',
                        content: `Votre demande de report de dates concernant le dossier ${idDossier} a bien été enregistrée. Elle sera traitée dans les plus brefs délais.`
                    })
                }).finally(() => {
                    props.close()
                })
        },
    })

    return (
        <MyModal
            onClose={ props.close }
            isPending={ form.submitting }
            label={`Demande de report de dates sur le dossier ${props.dossier.NumeroDossier__c}`}
        >
            <Form context={ form } noValidate>
                { etape === 1 && <MyForm>
                    <MyForm.Grid>
                        <DateField name="dateDebut" label="Date de début de formation" onBlur={ () => form.renderForm() } />
                        <DateField name="dateFin" label="Date de fin de formation" onBlur={ () => form.renderForm() } />
                    </MyForm.Grid>
                    <MyForm.Actions>
                        <Button
                            submit={ true }
                            disabled={ !form.isDirty() || (props.getDateFinValidite && !dateFinValidite) }
                        >Continuer</Button>
                        <Button level={ 2 } onClick={ props.close }>Retour</Button>
                    </MyForm.Actions>
                </MyForm> }
                { etape === 2 && <MyForm>
                    <AtlasHtml>
                        <p>Vous avez demandé de modifier les dates suivantes :</p>
                        <ul>
                            <li>
                                Date de début de formation demandée&nbsp;: <strong>{formatDate(form.values?.dateDebut)}</strong><br/>
                                {`(Initialement prévue le ${formatDate(initialValues.dateDebut)})`}
                            </li>
                            <li>
                                Date de fin de formation demandée&nbsp;: <strong>{formatDate(form.values?.dateFin)}</strong><br/>
                                {`(Initialement prévue le ${formatDate(initialValues.dateFin)})`}
                            </li>
                        </ul>
                        <p>Confirmez-vous cette demande ?</p>
                    </AtlasHtml>
                    <MyForm.Actions>
                        <Button submit={ true } spinner={{spinning: form.submitting }}>Confirmer</Button>
                        <Button level={ 2 } onClick={ () => setEtape(1) }>Retour</Button>
                    </MyForm.Actions>
                </MyForm> }
            </Form>
        </MyModal>
    )
}
