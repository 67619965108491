import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import { formatDuration } from '../recap/RecapDurationMnField'

interface ExportDurationMnFieldProps {
    label: string
    row?: boolean
    cell?: boolean
    value: { hours: number, minutes: number } | null | undefined
}

export default function ExportDurationMnField(props: ExportDurationMnFieldProps) {
    if (props.value === null || props.value === undefined || !props.value.hours) {
        return props.cell ? <Value value={{ cell: true, value: '' }} /> : null
    }

    return <Value row={ props.row } cell={ props.cell } value={{
        label: props.label,
        value: formatDuration(props.value)
    }} />
}
