import { Facture, FacturesSearchQuery, exportFactures } from 'api/factureAPI'
import { TYPE_FACTURE_AVOIR, TYPE_FACTURE_FACTURE, getAllStatutFactureFO } from 'api/references'
import { MyTableColumn } from 'atlas-ds'
import { FiltreCheckboxes, FiltrePeriode, FiltreRadioGroup, FiltreRecherche } from 'components/recherche/Filtres'
import RechercheBase, { DEFAULT_DATE_RANGES, FiltreFunctionProps } from 'components/recherche/RechercheBase'
import { ColonneDateEmission, ColonneEtat, ColonneMontant, ColonneNumeroDossier, ColonneRef, FacturesSearchQueryImpl, factureActions, factureTabs } from 'pages/mes-factures/MesFactures'
import { FiltreOrganisme } from '../FiltresPrestataire'
import DossierDeposerFactureSelfe from 'pages/detail-dossier/DossierDeposerFactureSelfe'


export default function MesFacturesPrestataire() {

    const etatFacturesCheckboxes = (getAllStatutFactureFO() ?? []).map(etatFacture => ({
        label: etatFacture.Libelle__c,
        value: etatFacture.Id
    }))
    
    const getFiltres = (props: FiltreFunctionProps) => {
        const filtres = [
            FiltreRecherche({ label: 'Recherche', placeholder: 'Référence, dossier...' }),
            FiltrePeriode({ label: "Date d'émission de la facture", placeholder: 'Choisir une période', dateRanges: DEFAULT_DATE_RANGES }),
            FiltreOrganisme({ withSiret: true }),
            FiltreCheckboxes({ name: 'etatFactureIds', label: "État de la facture", 
                paramName: 'etat-facture-fo', valueName: 'etatFactureIds', queryName: 'etatFactureFo', checkboxes: etatFacturesCheckboxes }),
        ]

        if (import.meta.env.REACT_APP_RELANCE === 'true') {
            filtres.push(
                FiltreRadioGroup({
                    name: 'alerte',
                    label: "Facture en alerte",
                    paramName: 'alerte',
                    defaultValue: '',
                    options: [
                        { label: "Oui", value: "oui" },
                        { label: "Non", value: "non" },
                        { label: "Toutes", value: "" },
                    ]
                }),
                /* FiltreRadioGroup({
                    name: 'demande',
                    label: "Demande en cours de traitement par Atlas",
                    paramName: 'demande',
                    defaultValue: '',
                    options: [
                        { label: "Oui", value: "oui" },
                        { label: "Non", value: "non" },
                        { label: "Tous", value: "" },
                    ]
                }), */
            )
        }

        filtres.push(FiltreRadioGroup({ name: 'typeFacture', label: "Type", paramName: 'type-facture', defaultValue: TYPE_FACTURE_FACTURE, options: [
            { label: "Facture", value: TYPE_FACTURE_FACTURE },
            { label: "Avoir", value: TYPE_FACTURE_AVOIR }
        ] }))

        return filtres
    }

    const headerActions = (
        <DossierDeposerFactureSelfe mode="facture" level={1} label="Déposer une facture" />
    )

    const colonnes = (_: FacturesSearchQuery): MyTableColumn[] => [
        ColonneRef,
        ColonneNumeroDossier,
        ColonneDateEmission,
        ColonneEtat,
        ColonneMontant,
    ]
    

    const newQuery = (params: URLSearchParams) => new FacturesSearchQueryImpl(params)

    return (
        <RechercheBase<Facture, FacturesSearchQueryImpl>
            name="MesFactures"
            title="Factures"
            headerActions={ headerActions }
            newQuery={ newQuery }
            filtres={ getFiltres }
            colonnes={ colonnes }
            tabs={ factureTabs }
            executeExport={ exportFactures }
            tableActions={ factureActions }
            getErrorRowsIndexes={
                import.meta.env.REACT_APP_RELANCE === 'true' ?
                (rows: Facture[]) => new Set<number>(rows.map((row, index) => row.TypeAlerteOF__c ? index : -1).filter(index => index != -1)) :
                undefined
            }
            errorLabel="Cette facture est en alerte"
        />
    )
}
