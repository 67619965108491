import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasFlex, AtlasHeading, AtlasInfo, AtlasLoading, MyModal, MyTable } from 'atlas-ds'
import { formatApiDate } from 'components/format/Format'
import { cloneDeep } from 'lodash'
import log from 'loglevel'
import { revert } from 'pages/dossier/metadata/DossierMapping'
import { PreFormContentProps } from 'pages/dossier/metadata/FormMetadata'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { BaseDossier, BaseDossierModule } from 'slices/dossierCommon'
import { dossierSliceActions } from 'slices/dossierSlice'
import { DossierIdParams } from './useDossier'
import { useDossierNavigation } from './useDossierNavigation'


export function DossierModulesTable<D extends BaseDossier>(props: (PreFormContentProps<D> & {
    modulesProperty?: string
    tableEmptyText?: string
})) {

    const modulesProperty = props.modulesProperty ?? "modules"

    const dispatch = useAppDispatch() 
    const { etape, step, navigateEdit } = useDossierNavigation(props.metadata)
    const { dossierId } = useParams<DossierIdParams>()
    const [ moduleToDelete, setModuleToDelete ] = useState<BaseDossierModule | null>(null)
    const [ deletePending, setDeletePending ] = useState(false)

    const supprimerModule = (moduleId: number) => {
        if ((props.dossier as { [key: string]: any })?.[modulesProperty]) {
            setDeletePending(true)
            
            const clone = cloneDeep(props.dossier) as { [key: string]: any }
            clone[modulesProperty] = (clone[modulesProperty] as BaseDossierModule[]).filter(module => module.IdHeroku !== moduleId)
            const dossier = revert(clone as D, props.metadata!)
            dispatch(dossierSliceActions.save({ api: props.metadata.api as any, dossier })).then(unwrapResult)
                .catch(error => {
                    log.error("Erreur dossier supprimer module", error)
                })
                .finally(() => {
                    setDeletePending(false)
                    setModuleToDelete(null)
                })
        }
    }

    const errorRowsIndexes = new Set<number>()
    props.form?.getErrorPaths().forEach(errorPath => {
        const errorIndex = errorPath.match(`^${ modulesProperty }\\[(\\d+)\\]`)?.[1]
        if (errorIndex)
            errorRowsIndexes.add(parseInt(errorIndex))
    })

    const modulesActions = (module: BaseDossierModule, index: number) => {
        return [
            <AtlasButton
                key="supprimer"
                icon="trash"
                onClick={ () => setModuleToDelete(module) }
                level={ 3 }
            >Supprimer</AtlasButton>,
            <AtlasButton
                key="modifier"
                icon="details"
                onClick={ () => navigateEdit(dossierId!, etape, module.IdHeroku!) }
                level={ 3 }
            >Modifier</AtlasButton>,
        ]
    }

    return (
        <div>
            <AtlasHeading
                tag="h2"
                size="m"
                button={<AtlasButton
                    onClick={() => navigateEdit(dossierId!, etape)}
                    level={3}
                    icon="plus"
                >Ajouter un module de formation</AtlasButton>}
            >{ step.title }</AtlasHeading>

            <AtlasFlex column gap="l">
                <AtlasLoading loading={props.loading ?? props.saving ?? false}>
                    <MyTable
                        caption="Modules de formation"
                        rows={ (props.dossier as { [key: string]: any })?.[modulesProperty] ?? [] }
                        rowKey={ row => row.IdHeroku }
                        rowError={ (row, index) => errorRowsIndexes.has(index) ? "Ce module doit être mis à jour" : undefined }
                        emptyText={ props.tableEmptyText ?? "Aucun module de formation n'est associé à ce dossier pour le moment." }
                        rowActions={ modulesActions }
                        columns={[
                            {
                                id: "intitulePrecis",
                                label: "Intitulé"
                            },
                            {
                                id: "dateDebutFormation",
                                label: "Date de début de formation",
                                value: (row: any) => formatApiDate(row.dateDebutFormation)
                            }
                        ]}
                    />
                </AtlasLoading>

                { props.form?.getError('modules') != null && <AtlasInfo type="error" title="Erreur">
                    { props.form?.getError('modules')?.message || '' }
                </AtlasInfo>}
                { errorRowsIndexes.size > 0 && <AtlasInfo type="error" title="Erreur" >
                    Certains modules ci-dessus comportent des erreurs. Veuillez les modifier.
                </AtlasInfo> }
            </AtlasFlex>

            { moduleToDelete && <MyModal
                label="Confirmation"
                isPending={ deletePending }
                okButton={<AtlasButton onClick={() => supprimerModule(moduleToDelete.IdHeroku!)}>Confirmer</AtlasButton>}
                cancelButton={<AtlasButton onClick={() => setModuleToDelete(null)}>Retour</AtlasButton>}
            >
                <p>Voulez-vous vraiment supprimer le module "{ moduleToDelete.intitulePrecis }" ?</p>
            </MyModal> }
        </div>
    )
}
