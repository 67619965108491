import { Form, useForm } from '@dsid-opcoatlas/reform'
import { DossierDetail } from 'api/dossierAPI'
import { dispatchAppEvent } from 'app/events'
import { AtlasButton, MyForm, MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import Button from 'components/reform/Button'
import log from 'loglevel'
import { useState } from 'react'
import { detailDossierContratRefreshEvent, detailDossierDocumentsRefreshEvent } from '../DetailDossierContrat'


interface DossierRelanceActionProps {
    id: string
    dossier: DossierDetail
    label: string
    of?: boolean
    typeDemande: number
    successMessage: string
    formContent?: JSX.Element
    initialValues?: any
    validationSchema?: any
    params?: (values: any) => any
    doAction: (dossier: DossierDetail, params?: any) => Promise<void>
    postActionDelay?: number
}


export default function DossierRelanceAction(props: DossierRelanceActionProps) {
    const [showModal, setShowModal] = useState(false)
 
    const form = useForm({
        initialValues: props.initialValues,
        validationSchema: props.validationSchema,
        dispatchEvent: false,
        onSubmit(context) {
            const numDossier = props.dossier.NumeroDossier__c
            props.doAction(props.dossier, props.params ? props.params(context.values) : undefined)
                .then(_ => {
                    // Attend 5 secondes et rafraîchit le statut et la liste des documents (récupération du récépissé)
                    setTimeout(() => {
                        dispatchAppEvent(detailDossierContratRefreshEvent)
                        dispatchAppEvent(detailDossierDocumentsRefreshEvent)
                        form.setSubmitting(false)
                        setShowModal(false)
                        ToastService.showToast({
                            label: props.label,
                            type: 'success',
                            content: props.successMessage,
                        })
                    }, props.postActionDelay ?? 5000)
                })
                .catch(e => {
                    log.error('Erreur action ' + props.label + ' dossier ' + numDossier, e)
                    dispatchAppEvent(detailDossierContratRefreshEvent)
                    dispatchAppEvent(detailDossierDocumentsRefreshEvent)
                    form.setSubmitting(false)
                    setShowModal(false)
                    ToastService.showToast({
                        label: props.label,
                        type: e.response.data?.status === 409 ? 'warning' : 'error',
                        content: e.response.data?.message ?? "Une erreur est survenue. Veuillez réessayer ultérieurement",
                    })
                })
        },
    })

    const demandes = props.dossier.DemandesEnCours ?? []

    return <>
        <AtlasButton
            id={ props.id }
            disabled={ !!props.typeDemande && demandes?.includes(props.typeDemande) }
            level={ 2 }
            onClick={() => setShowModal(true)}
        >{ props.label }</AtlasButton>

        { showModal && <MyModal
            label={ props.label }
            isPending={ form.submitting }
        >
            <Form context={ form } noValidate>
                <MyForm>
                    { props.formContent }
                    <MyForm.Actions>
                        <Button
                            submit
                            spinner={{spinning: form.submitting }}
                            disabled={ props.initialValues && !form.isDirty() }
                        >Confirmer</Button>
                        <Button level={ 2 } onClick={ () => setShowModal(false) }>
                            Retour
                        </Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal> }

        { /*
            okButton={<AtlasButton destructive onClick={form.submit} >Confirmer</AtlasButton>} 
            cancelButton={<AtlasButton onClick={() => setShowModal(false)}>Retour</AtlasButton>}
        <MyModal
            onClose={ props.close }
            isPending={ form.submitting }
            label={`Demande de report de dates sur le dossier ${props.dossier.NumeroDossier__c}`}
        >
            <Form context={ form } noValidate>
                { etape === 1 && <MyForm>
                    <MyForm.Grid>
                        <DateField name="dateDebut" label="Date de début de formation" onBlur={ () => form.renderForm() } />
                        <DateField name="dateFin" label="Date de fin de formation" onBlur={ () => form.renderForm() } />
                    </MyForm.Grid>
                    <MyForm.Actions>
                        <Button
                            level={ 2 }
                            submit={ true }
                            disabled={ !form.isDirty() || (props.getDateFinValidite && !dateFinValidite) }
                        >Continuer</Button>
                        <Button level={ 3 } onClick={ props.close }>Retour</Button>
                    </MyForm.Actions>
                </MyForm> }
                { etape === 2 && <>
                    <AtlasHtml>
                        <p>Vous avez demandé de modifier les dates suivantes :</p>
                        <ul>
                            <li>
                                Date de début de formation demandée&nbsp;: <strong>{formatDate(form.values?.dateDebut)}</strong><br/>
                                {`(Initialement prévue le ${formatDate(initialValues.dateDebut)})`}
                            </li>
                            <li>
                                Date de fin de formation demandée&nbsp;: <strong>{formatDate(form.values?.dateFin)}</strong><br/>
                                {`(Initialement prévue le ${formatDate(initialValues.dateFin)})`}
                            </li>
                        </ul>
                        <p>Confirmez-vous cette demande ?</p>
                    </AtlasHtml>
                    <MyForm.Actions>
                        <Button submit={ true } spinner={{spinning: form.submitting }}>Confirmer</Button>
                        <Button level={ 3 } onClick={ () => setEtape(1) }>Retour</Button>
                    </MyForm.Actions>
                </> }
            </Form>
        </MyModal>
        */ }
    </>
}
