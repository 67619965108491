import { PieceTypeDTO } from 'api/documentAPI'
import { Dossier, DossierDetail } from 'api/dossierAPI'
import { ETAT_DOSSIER_ACCORDE, ETAT_DOSSIER_BROUILLON, ETAT_DOSSIER_CLOTURE, ETAT_DOSSIER_EN_COURS, ETAT_DOSSIER_INCOMPLET, ETAT_DOSSIER_RUPTURE } from 'api/references'
import { AppDetailModule } from 'app/AppContext'
import { AppRoute } from 'app/AppModuleProvider'
import dossierACNModule from 'pages/dossier-acn/DossierACNModule'
import dossierCAPPModule from 'pages/dossier-capp/DossierCAPPModule'
import dossierCDICModule from 'pages/dossier-cdic/DossierCDICModule'
import dossierCPROModule from 'pages/dossier-cpro/DossierCPROModule'
import dossierFMAModuleX from 'pages/dossier-fma-x/DossierFMAModule'
import dossierFMAModule from 'pages/dossier-fma/DossierFMAModule'
import dossierPACCRHModule from 'pages/dossier-paccrh/DossierPACCRHModule'
import dossierPDCModule from 'pages/dossier-pdc/DossierPDCModule'
import dossierProAModule from 'pages/dossier-proa/DossierProAModule'
import dossierTUTModule from 'pages/dossier-tut/DossierTUTModule'
import dossierVAEModule from 'pages/dossier-vae/DossierVAEModule'
import dossierVAEIModule from 'pages/dossier-vaei/DossierVAEIModule'
import DossierConfirmationForm from 'pages/dossier/DossierConfirmationForm'
import { DossierForm } from 'pages/dossier/DossierForm'
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { AppType } from 'slices/contextSlice'
import { BaseDossier } from 'slices/dossierCommon'

export interface AppDossierModule extends AppDetailModule {
    dispositifId: string
    metadata: {
        [app in AppType]?: { create: FormMetadata<any>, edit?: FormMetadata<any> }
    },
    documentTypeFilter?: (docType: PieceTypeDTO) => boolean
    documentWarningMessage?: JSX.Element
}

const appDossierModules = [ 
    dossierCAPPModule,
    dossierCPROModule,
    dossierVAEIModule,
    dossierCDICModule,
    dossierACNModule,
    dossierPDCModule,
    dossierVAEModule,
    dossierTUTModule,
    import.meta.env.REACT_APP_ALLOW_DECORATORS === "true" ? dossierFMAModuleX : dossierFMAModule,
    dossierPACCRHModule,
    dossierProAModule,
] as AppDossierModule[]

export default appDossierModules


export function buildRoutes<D extends BaseDossier>(key: string, metadata: FormMetadata<D>, edit: boolean, routes: JSX.Element[]) {
    let stepIndex = 0
    const step1 = metadata.steps.findIndex(s => !s.options?.step0)
    metadata.steps.forEach(step => {
        const path = stepIndex > 0 || edit 
            ? `${ metadata.pathname }-etape${stepIndex+1}/:dossierId${stepIndex === step1 ? '?' : ''}` 
            : `${ metadata.pathname }/:dossierId?`
        const Form = step.form ?? DossierForm

        if (step.pathname) {
            routes.push(
                <AppRoute exact key={ `${key}${stepIndex+1}-alt` } path={ step.pathname }>
                    <Form metadata={ metadata } step={ step } />
                </AppRoute>
            )
        }
        if (step.editForm) {
            const editPath = stepIndex > 0 ? `${ metadata.pathname }-etape${stepIndex+1}-edit/:dossierId` : `${ metadata.pathname }-edit/:dossierId?`
            const EditForm = step.editForm
            routes.push(
                <AppRoute exact key={ `${key}${stepIndex+1}-edit` } path={ editPath }>
                    <EditForm metadata={ metadata } step={ step } />
                </AppRoute>
            )
        }
        if (!step.stepButton) {
            stepIndex++
        }
        
        routes.push(
            <AppRoute exact key={ `${key}${stepIndex}` } path={ path }>
                <Form metadata={ metadata } step={ step } />
            </AppRoute>
        )
    })
    routes.push(
        <AppRoute exact key={ `${key}${stepIndex+1}` } path={ `${ metadata.pathname }-confirmation/:dossierId` }>
            <DossierConfirmationForm metadata={ metadata } step={{ title: '', sections: [], form: () => <></> }} />
        </AppRoute>
    )
}

export const peutCorrigerDossier = (dossier: DossierDetail) => {
    return dossier.EtatDossierFO__c !== ETAT_DOSSIER_BROUILLON
}

export const peutAnnulerDossier = (dossier: DossierDetail) => {
    return (dossier.EtatDossierFO__c === ETAT_DOSSIER_EN_COURS || dossier.EtatDossierFO__c === ETAT_DOSSIER_INCOMPLET || dossier.EtatDossierFO__c === ETAT_DOSSIER_ACCORDE) && ((dossier.MontantTotalPaye__c ?? 0) < 0.01)
}

export const dossierEstAccorde = (dossier: Dossier) => {
    return dossier.EtatDossierFO__c === ETAT_DOSSIER_ACCORDE
}

export const dossierEstEnRupture = (dossier: Dossier) => {
    return dossier.EtatDossierFO__c === ETAT_DOSSIER_RUPTURE
}

export const peutChangerPeriodeDossier = (dossier: DossierDetail) => {
    return (dossier.EtatDossierFO__c === ETAT_DOSSIER_EN_COURS || dossier.EtatDossierFO__c === ETAT_DOSSIER_INCOMPLET || dossier.EtatDossierFO__c === ETAT_DOSSIER_ACCORDE)
}

export const peutRompreDossier = (dossier: DossierDetail) => {
    return (dossier.EtatDossierFO__c === ETAT_DOSSIER_EN_COURS || dossier.EtatDossierFO__c === ETAT_DOSSIER_INCOMPLET 
        || dossier.EtatDossierFO__c === ETAT_DOSSIER_ACCORDE || dossier.EtatDossierFO__c === ETAT_DOSSIER_CLOTURE)
}
