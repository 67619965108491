import { AtlasValue, AtlasValues } from 'atlas-ds'
import { formatAdresse } from 'components/format/Format'
import { get } from 'lodash'
import { BaseDossier, BaseDossierModule, DossierCFA } from 'slices/dossierCommon'
import RecapBooleanField from './RecapBooleanField'
import RecapAtlasValue from './RecapAtlasValue'


interface RecapOrganismeFormationProps {
    root: BaseDossier | null
    parent: BaseDossierModule | null
    initialValue?: any | null
    path: string
    entreprise?: boolean
    uai?: boolean
    uaiLabel?: string
    da?: boolean
    editMode?: boolean
  
}

export default function RecapOrganismeFormation(props: RecapOrganismeFormationProps) {
    const cfa = get(props.root, props.path)
    const initialCfa = props.initialValue

    const entrepriseValue = cfa?.entreprise ? 'Oui' : 'Non'
    const entrepriseInitialValue = initialCfa?.entreprise ? 'Oui' : 'Non'
    const valpostalCommune = formatAdresse('', cfa?.codePostal, cfa?.commune)
    const valInitialpostalCommune = formatAdresse('', initialCfa?.codePostal, initialCfa?.commune)

    if (props.editMode && initialCfa) {
        return (<AtlasValues>
            <AtlasValues.Grid>
            {(initialCfa.nom || cfa.nom ) && <RecapAtlasValue label="Nom de l'établissement" initialValue={initialCfa.nom} value={cfa?.nom} editMode={props.editMode || false} />}
            {(initialCfa.siret || cfa.nom ) && <RecapAtlasValue label="SIRET" initialValue={initialCfa.siret} value={cfa?.siret} editMode={props.editMode || false} />}
            {props.da && (initialCfa.numeroDA || cfa.numeroDA ) && <RecapAtlasValue label="N° de déclaration d'activité" initialValue={initialCfa.numeroDA} value={cfa?.numeroDA} editMode={props.editMode || false} />}
            {props.uai && (initialCfa.numeroUAI || cfa.numeroUAI )  && <RecapAtlasValue label={props.uaiLabel ?? 'N° UAI de l\'organisme'} initialValue={initialCfa.numeroUAI} value={cfa?.numeroUAI} editMode={props.editMode || false} />}
            {('entreprise' in initialCfa) && props.entreprise && ( initialCfa.entreprise != null || cfa.entreprise != null )  && <RecapAtlasValue label="CFA entreprise" initialValue={entrepriseInitialValue} value={entrepriseValue} editMode={props.editMode || false} />}
            {('appartement' in initialCfa) && (initialCfa?.appartement || cfa?.appartement ) && <RecapAtlasValue label={"Appartement"} initialValue={initialCfa?.appartement} value={cfa?.appartement} editMode={props.editMode || false} />}
            {('batiment' in initialCfa) && (initialCfa?.batiment || cfa?.batiment )  && <RecapAtlasValue label={"Bâtiment"} initialValue={initialCfa?.batiment} value={cfa?.batiment} editMode={props.editMode || false} />}
            {('adresse' in initialCfa) && <RecapAtlasValue label="N° et voie" initialValue={initialCfa?.adresse} value={cfa?.adresse} editMode={props.editMode || false} />}
            {('complement' in initialCfa) && (initialCfa?.complement || cfa?.complement )  && <RecapAtlasValue label={"Lieu dit/BP"} initialValue={initialCfa?.complement} value={cfa?.complement} editMode={props.editMode || false} />}
            {('codePostal' in initialCfa) && (valpostalCommune || valInitialpostalCommune )  && <RecapAtlasValue label={"Code postal et commune"} initialValue={valInitialpostalCommune} value={valpostalCommune} editMode={props.editMode || false} />}
            </AtlasValues.Grid>
            
        </AtlasValues>)
    }
   

    return (<AtlasValues>
        <AtlasValues.Grid>
            <AtlasValue label="Nom de l'établissement">{cfa?.nom}</AtlasValue>
            <AtlasValue label="SIRET">{cfa?.siret}</AtlasValue>

            {props.da && <AtlasValue label="N° de déclaration d'activité">{cfa?.numeroDA}</AtlasValue>}
            {props.uai && <AtlasValue label={props.uaiLabel ?? 'N° UAI de l\'organisme'}>{cfa?.numeroUAI}</AtlasValue>}
            {props.entreprise && <RecapBooleanField label="CFA entreprise" value={cfa?.entreprise} />}
        </AtlasValues.Grid>
        <AtlasValue label="Adresse">{formatAdresse(cfa?.adresse, cfa?.codePostal, cfa?.commune)}</AtlasValue>
    </AtlasValues>)
}
