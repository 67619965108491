import { PieceTypeDTO } from 'api/documentAPI'
import useApiState from 'api/useApiState'
import { AtlasButton, AtlasInfo, MyForm, MyModal, MyTable } from 'atlas-ds'
import ReformButton from 'components/reform/Button'
import ErrorBanner from 'components/reform/ErrorBanner'
import FileField from 'components/reform/inputs/FileField'
import SelectField from 'components/reform/inputs/SelectField'
import { AcceptedDocuments, Ignored, OptionalFileAccept } from 'components/yop/constraints'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Form, useForm, UseFormReturn } from '@dsid-opcoatlas/reform'
import { Yop } from '@dsid-opcoatlas/yop'
import { RootState } from 'app/rootReducer'
import { useAppContext } from 'app/useAppContext'

export interface DepotFichierModalProps<D> {
    idParent: string
    nom: string
    withType?: boolean
    getDocumentTypes: (id: string) => Promise<PieceTypeDTO[]>
    withObligatoires?: boolean
    documentTypeFilter?: (docType: PieceTypeDTO) => boolean
    warningMessage?: JSX.Element
    isUpdating?: boolean
    upload: (idParent: string, nom: string, file: File | Blob, fileName?: string | undefined) => Promise<D>
    uploaded?: (doc: D) => void
    close: (ok: boolean) => void
    hideWarningFactures?: boolean
}

interface Piece {
    typeDocument: string
    file: File
}

interface PiecesForm {
    typeDocument: string
    file: File | null
    pieces: Piece[]
}

export default function DepotFichierModal<D>(props: DepotFichierModalProps<D>) {
    const appContext = useAppContext()
    const [{ value: documentTypes }, withGetDocumentTypes] = useApiState(props.getDocumentTypes)
    const [error, setError] = useState<string>()

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const selfeUrl = appContext?.appInfo?.env?.selfeUrl ?? '#'
    
    const initialValues: PiecesForm = {
        typeDocument: props.nom,
        file: null,
        pieces: [],
    }

    const validationSchema = Yop.object<PiecesForm>({
        typeDocument: Ignored,
        file: OptionalFileAccept(Object.keys(AcceptedDocuments)),
        pieces: Ignored,
    })

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<PiecesForm>) => {
            const uploadAll = context.values!.pieces.map(p => {
                return props.upload(props.idParent, props.nom ? props.nom : (props.withType ? p.typeDocument : p.file.name), p.file)
                    .then(doc => {
                        if (props.uploaded)
                            props.uploaded(doc)    
                    })
            })
            Promise.all(uploadAll)
                .then(() => {
                    props.close(true)
                })
                .catch(res => {
                    setError("Les documents n'ont pas pu être envoyés")
                })
                .finally(() => context.setSubmitting(false))
            },
    })

    useEffect(() => {
        if (props.withType && props.idParent)
            withGetDocumentTypes(props.idParent)
    }, [props.withType, props.idParent, withGetDocumentTypes])

    const typesDocuments = documentTypes
        ?.filter(dt => {
            return props.documentTypeFilter 
                ? ((props.withObligatoires !== false) || !dt.obligatoire) && props.documentTypeFilter(dt) 
                : (props.withObligatoires !== false) || !dt.obligatoire}
        )?.map(dt => ({ value: dt.nom, label: dt.nom })) ?? []

    typesDocuments?.sort((t1, t2) => t1.value === t2.value ? 0 : t1.value < t2.value ? -1 : 1)

    const piecesActions = (piece: Piece) => [
        <AtlasButton
            key="supprimer"
            icon="trash"
            level={ 3 }
            onClick={() => retirerDocument(piece)}
        >Retirer</AtlasButton>
    ]

    const retirerDocument = (piece: Piece) => {
        const index = form.values!.pieces.findIndex(p => p.typeDocument === piece.typeDocument && p.file.name === piece.file.name)
        if (index >= 0) {
            form.values?.pieces.splice(index, 1)
        }
        form.renderForm()
    }

    const ajoutDocument = (file: File | null, context: UseFormReturn<PiecesForm>) => {
        if (!context.getError('file')) {
            context.values!.pieces.push({ typeDocument: context.values!.typeDocument, file: file! })
            context.setValue('file', null)
            if (props.withType)
                context.setValue('typeDocument', null)
        }
    }

    const onClose = () => {
        props.close(false)
    }

    return (
        <MyModal
            onClose={ onClose }
            isPending={ form.submitting }
            label="Dépôt de pièces"
        >
            <Form context={ form } noValidate autoComplete="off">
                <MyForm>
                    { props.hideWarningFactures !== true && <AtlasInfo type="warning" title="Factures, avoir et justificatifs" >
                        Les factures, avoirs et leurs justificatifs doivent être <a href={`${selfeUrl}?siren=${entreprise?.SIREN__c ?? ""}`} target="_blank" rel="noopener noreferrer">déposés ici</a>
                    </AtlasInfo> }

                    { props.warningMessage }

                    {props.withType &&
                        <SelectField selectionner name="typeDocument" label="Type de document" options={typesDocuments} />
                    }
                    { (props.withType ? form.values?.typeDocument : (form.values?.pieces ?? []).length === 0) &&
                        <FileField name="file" label="Ajouter un document" onChange={ ajoutDocument }
                            tooltip={ `Formats acceptés : ${ Object.values(AcceptedDocuments).join(', ') } — 15 Mo maximum` } />
                    }

                    <ErrorBanner title="Erreur lors de l'ajout du document" message={ error } />

                    <MyTable
                        caption="Pièces"
                        rows={ form.values?.pieces ?? [] }
                        rowKey={ row => row.typeDocument + ' ' + row.file.name }
                        rowActions={ piecesActions }
                        emptyText="Aucun document à déposer pour le moment"
                        columns={[
                            {
                                id: "fichier",
                                label: "Nom document",
                                value: (row: any) => row.file.name,
                                wrap: true
                            },
                            {
                                id: "typeDocument",
                                label: "Type"
                            }
                        ]}
                    />

                    <MyForm.Actions>
                        <ReformButton
                            submit={ true }
                            spinner={{spinning: form.submitting}}
                            disabled={ !form.values?.pieces?.length }
                        >Déposer</ReformButton>

                        <ReformButton level={ 2 } onClick={ onClose }>Retour</ReformButton>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}