import { DossierAF } from "slices/dossierCommon"
import { FormMetadata } from 'pages/dossier/metadata/FormMetadata'
import { getFneData } from "api/dossierAPI"
import { formatAdresse, formatCurrency, formatDate, formatNumber } from "components/format/Format"
import { MODALITE_STAGE_CLASSE_VIRTUELLE, MODALITE_STAGE_EN_LIGNE } from 'api/references'
import { durationToString } from "components/reform/inputs/DurationFloatingField"


export async function exportAttestationFNE<D extends DossierAF<any, any>>(metadata: FormMetadata<D>, dossier: D | null): Promise<any> {

  const fneData = await getFneData(dossier!.NumeroDossier__c!)

  const module = fneData.Modules?.[0]

  const data = {
    entreprise: {
      denominationSociale: fneData.Account.Name,
      siren: fneData.Account.Siren__c,
      adresse: `${ fneData.Account.Adresse3__c } ${ fneData.Account.BillingPostalCode } ${ fneData.Account.BillingCity }`,
      effectif: fneData.Account.Effectiftotal__c != null ? formatNumber(fneData.Account.Effectiftotal__c) : 'N/C',
      contact: {
        nom: `${ fneData.Contact.Salutation } ${ fneData.Contact.FirstName } ${ fneData.Contact.LastName }`,
        fonction: fneData.Contact.FonctionMetier__c,
        telephone: fneData.Contact.Phone ?? fneData.Contact.MobilePhone ?? '',
        email: fneData.Contact.Email ?? ''
      },
    },
    formation: {
      referenceDossier: dossier!.NumeroDossier__c,
      formation: module?.IntituleProgramme__c ?? '',
      of: module?.NomOF__c ?? '',
      lieu: fneData.Session.Modalite__c === MODALITE_STAGE_EN_LIGNE ? 'En ligne' :
        fneData.Session.Modalite__c === MODALITE_STAGE_CLASSE_VIRTUELLE ? 'Classe virtuelle' : 
        formatAdresse(fneData.Session.Adresse3__c, fneData.Session.CodePostal__c, fneData.Session.Ville__c),
      dateDebut: module?.DateDebutFormation__c,
      dateFin: module?.DateFinFormation__c,
      heures: durationToString(module?.DureeHeure__c),
      coutTotal: formatCurrency(module?.MontantDemande__c),
      categorieEntreprise: fneData.Account.Categorie ?? 'N/C',
      montantSubventionFne: module?.MontantSubventionFNE != null ? formatCurrency(module?.MontantSubventionFNE) : 'N/C'
    },
    salaries: fneData.Stagiaires.map(stagiaire => {
        return {
            nom: stagiaire.Nom__c,
            prenom: stagiaire.Prenom__c,
            dateNaissance: stagiaire.DateNaissance__c,
            contrat: stagiaire.NatureContrat__c
        }
    }),
    soussigne: `${ fneData.Contact.FirstName } ${ fneData.Contact.LastName }`,
    date: formatDate(new Date())
  }      

  return Promise.resolve(data)
}
