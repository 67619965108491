import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AtlasButton, MyForm, MyModal } from 'atlas-ds'
import { getFacturesSelfeRIBValid } from 'api/factureAPI'
import { RootState } from 'app/rootReducer'
import Link from 'components/Link'
import Button from 'components/reform/Button'
import { checkDetailAction, DetailActionProps } from 'pages/detail-common/Detail'
import { Dossier } from 'api/dossierAPI'


interface DossierDeposerFactureSelfeProps extends DetailActionProps<Dossier> {
    mode: 'facture' | 'proforma' | 'tuteur'
    link?: boolean
    icon?: string
    level?: 1 | 2 | 3 | 4
    label?: string
}

export default function DossierDeposerFactureSelfe(props: DossierDeposerFactureSelfeProps) {
    const dossier = checkDetailAction(props)
    if (props.when && !dossier) return null

    const { entreprise } = useSelector((rootState: RootState) => rootState.contextState)
    const history = useHistory()
    const [ showAddRib, setShowAddRib ] = useState(false)

    const params = new URLSearchParams({ 'siren': (entreprise?.SIREN__c ?? '').replaceAll(' ', '') })
    let baseUrl = import.meta.env.REACT_APP_SELFE ?? '#'
    if (props.mode === 'proforma') {
        baseUrl = import.meta.env.REACT_APP_SELFE_PROFORMA ?? '#'
    } else if (props.mode === 'tuteur') {
        baseUrl = import.meta.env.REACT_APP_SELFE_AFTMA ?? '#'
    }
    const url = `${baseUrl}?${params.toString()}`

    let label = props.label ?? "Déposer une facture / Certificat de réalisation"
    if (!props.label) {
        if (props.mode === 'proforma') {
            label = "Générer une facture Proforma"
        } else if (props.mode === 'tuteur') {
            label = "Facturation tuteur"
        }
    }
    
    const openSelfe = (force?: boolean) => {
        if (!force && import.meta.env.REACT_APP_CHECK_RIB_BEFORE_SELFE !== 'false' && entreprise?.SIREN__c) {
            getFacturesSelfeRIBValid(entreprise?.SIREN__c)
                .then(valid => {
                    if (valid) {
                        window.open(url, '_blank')
                    } else {
                        setShowAddRib(true)
                    }
                })
        } else {
            window.open(url, '_blank')
            setShowAddRib(false)
        }
    }

    const openEditRIB = () => {
        setShowAddRib(false)
        history.push({ pathname: '/ajout-ref-bancaire-adherent', state: { back: true } })
    }

    return <>
        { props.link ? <Link
            icon={ props.icon ?? "bill" }
            level={ props.level ?? 3 }
            onClick={ () => openSelfe() }
            href="#"
        >{ label }</Link> : <AtlasButton
            icon={ props.icon }
            level={ props.level ?? 2 }
            onClick={ () => openSelfe() }
        >{ label }</AtlasButton> }

        { showAddRib && <MyModal onClose={ () => setShowAddRib(false) } label="Mise à jour du RIB">
            <MyForm>
                <p>Attention, votre RIB n'est pas à jour. Voulez-vous le saisir ?</p>
                <MyForm.Actions>
                    <Button onClick={ openEditRIB }>Mettre à jour mon RIB</Button>

                    <Button level={ 2 } onClick={ () => openSelfe(true) }>Me le rappeler plus tard</Button>
                </MyForm.Actions>
            </MyForm>
        </MyModal> }
    </>
}

