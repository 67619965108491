import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import { durationToString } from 'components/reform/inputs/DurationFloatingField'

interface ExportDurationFieldProps {
    label: string
    row?: boolean
    cell?: boolean
    value: number | null | undefined
}

export default function ExportDurationField(props: ExportDurationFieldProps) {
    if (props.value == null)
        return props.cell ? <Value value={{ cell: true, value: '' }} /> : null

    return <Value row={ props.row } cell={ props.cell } value={{
        label: props.label,
        value: durationToString(props.value)
    }} />
}
