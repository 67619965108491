import {
  AtlasButtonProps,
  AtlasIcon,
  AtlasInfo,
  AtlasLinkProps,
  AtlasLoading,
  AtlasTooltip,
  AtlasValue,
} from "atlas-ds";
import classNames from "classnames";
import { ForwardedRef, forwardRef, useEffect, useRef } from "react";

/**
 * Une colonne de tableau
 */
export interface MyTableColumn {
  /**
   * L'identifiant de la colonne
   */
  id: string;
  /**
   * Le label affiché
   */
  label: string;
  /**
   * Une fonction retournant la valeur à afficher pour une ligne donnée, dans
   * le cas où il ne s'agit pas d'une simple récupération de clé de l'objet.
   * Cette valeur peut être un tableau. En ce cas, chaque élément sera affiché
   * sur sa propre ligne.
   */
  value?: (row: any) => React.ReactNode | React.ReactNode[];
  /**
   * Permettre le tri sur cette colonne
   */
  sortable?: boolean;
  /**
   * Empêcher les retours à la ligne
   */
  nowrap?: boolean;
  /**
   * Forcer les retours à la ligne, même au sein d'un mot
   */
  wrap?: boolean;
  /**
   * Utiliser des points de suspension pour raccourcir les valeurs trop longues
   * Attention : une seule colonne tronquée est supportée pour le moment.
   */
  truncate?: boolean;
  /**
   * Afficher la valeur en gras
   */
  bold?: boolean;
}

export interface MyTableProps {
  /**
   * Le titre du tableau (utilisé pour le heading et l'élément `<caption>`,
   * caché mais nécessaire à l'accessibilité)
   */
  caption: string;
  /**
   * Les colonnes du tableau et leurs caractéristiques
   */
  columns: MyTableColumn[];
  /**
   * Les valeurs à afficher
   */
  rows: any[];
  /**
   * Une fonction retournant, pour une ligne, la clé à utiliser pour la
   * distinguer des autres
   */
  rowKey?: (row: any, index: number) => string;
  /**
   * Une fonction retournant, pour une ligne, la liste des actions proposées
   */
  rowActions?: (
    row: any,
    index: number
  ) => (
    | React.ReactElement<AtlasLinkProps>
    | React.ReactElement<AtlasButtonProps>
  )[];
  /**
   * Une fonction retournant, pour une ligne, une erreur associée.
   */
  rowError?: (row: any, index: number) => string | undefined;
  /**
   * Une fonction retournant, pour une ligne, une icône à afficher.
   * En cas de ligne en erreur, cette icône s'affichera en supplément.
   */
  rowIcon?: (
    row: any,
    index: number
  ) =>
    | {
        /**
         * Le nom de l'icône
         */
        name: string;
        /**
         * Le label accessible (doit être court)
         */
        label: string;
        /**
         * Le contenu de l'info-bulle qui sera affichée au survol
         */
        tooltipContent: string;
      }
    | undefined;
  /**
   * La clé de tri active
   */
  sortBy?: string;
  /**
   * L'ordre de tri actif
   */
  sortOrder?: string;
  /**
   * L'action à éxécuter lorsque la colonne ou l'ordre de tri est changé
   */
  onSortChange?: (property: string) => void;
  /**
   * Le texte à utiliser si aucune ligne n'est présente
   */
  emptyText?: string;
  /**
   * Une erreur de récupération des données
   */
  error?: string;
  /**
   * Usage interne uniquement.
   * Permet au composant de savoir s'il est inclus dans un composant de
   * tableau infini.
   */
  internalInfinite?: boolean;
}

/**
 * Un tableau de données. En cas d'espace insuffisant, la mise en page change
 * pour afficher les éléments les uns en dessous des autres.
 */
export const MyTable = forwardRef(function MyTable(
  props: MyTableProps,
  forwardedRef: ForwardedRef<any>
) {
  const tableRef = useRef<HTMLTableElement>(null);
  const thsRefs = useRef<HTMLElement[]>([]);

  // The truncate effect cannot be done with CSS only
  // We have to set a specific width for the truncation to happen
  const onResize = () => {
    if (tableRef.current) {
      // If this is a window resize event (not initial call)
      if (
        tableRef.current.style.getPropertyValue(
          "--my-table-truncate-white-space"
        )
      ) {
        // Allow the cell to expand again before reading its width
        tableRef.current.style.removeProperty("--my-table-truncate-max-width");
        tableRef.current.style.removeProperty(
          "--my-table-truncate-white-space"
        );
      }

      // Only one truncated column is supported for now
      const truncatedIndex = props.columns.findIndex(
        (column) => column.truncate
      );

      tableRef.current.style.setProperty(
        "--my-table-truncate-max-width",
        thsRefs.current[truncatedIndex].clientWidth + "px"
      );

      tableRef.current?.style.setProperty(
        "--my-table-truncate-white-space",
        "nowrap"
      );
    }
  };

  useEffect(() => {
    if (props.columns.find((column) => column.truncate)) {
      onResize();
      window.addEventListener("resize", onResize);

      return () => {
        window.removeEventListener("resize", onResize);
      };
    }
  }, []);

  const getTableHead = (column: MyTableColumn, index: number) => {
    return (
      <th
        scope="col"
        key={column.id}
        ref={(column) => (thsRefs.current[index] = column!)}
      >
        {column.sortable ? (
          <button
            className={classNames("my-table__head", {
              "my-table__head--asc":
                props.sortBy === column.id && props.sortOrder === "asc",
              "my-table__head--desc":
                props.sortBy === column.id && props.sortOrder === "desc",
            })}
            type="button"
            aria-label={`Trier par ${column.label} croissant`}
            onClick={() => props.onSortChange && props.onSortChange(column.id)}
          >
            <div className="my-table__headInner">
              {column.label}

              <AtlasIcon name="arrow-down" size="xs" />
            </div>
          </button>
        ) : (
          <div className="my-table__head">
            <div className="my-table__headInner">{column.label}</div>
          </div>
        )}
      </th>
    );
  };

  const getTableRow = (row: any, rowIndex: number) => {
    const error = props.rowError ? props.rowError(row, rowIndex) : undefined;

    const icons = error
      ? [{ name: "error", label: "Erreur", tooltipContent: error }]
      : [];

    if (props.rowIcon) {
      const rowIcon = props.rowIcon(row, rowIndex);

      if (rowIcon) {
        icons.unshift(rowIcon);
      }
    }

    const values: any[] = [];

    return (
      <tr
        key={props.rowKey ? props.rowKey(row, rowIndex) : (row.id ?? row.Id)}
        className={classNames("my-table__row", "my-table__row--loaded", {
          "my-table__row--error": error,
        })}
      >
        {props.columns.map((column, columnIndex) => {
          const rawValue = column.value ? column.value(row) : row[column.id];

          let value = Array.isArray(rawValue)
            ? rawValue.map((part, index) => (
                <div className="my-table__valuePart" key={`value-${index}`}>
                  {part}
                </div>
              ))
            : rawValue;

          if (icons.length && columnIndex === 0) {
            value = (
              <div className="my-table__iconValue">
                <span className="my-table__icons">
                  {icons.map((icon) => (
                    <AtlasTooltip
                      key={icon.label}
                      opener={
                        <AtlasIcon
                          name={icon.name}
                          title={icon.label}
                          size="s"
                        />
                      }
                    >
                      {icon.tooltipContent}
                    </AtlasTooltip>
                  ))}
                </span>
                <div>{value}</div>
              </div>
            );
          }

          // Aggregate the values to generate the compact row at the end
          values.push(value);

          return (
            <td
              key={column.id}
              className={classNames("my-table__cell", {
                "my-table__cell--nowrap": column.nowrap,
                "my-table__cell--wrap": column.wrap,
              })}
            >
              <div
                className={classNames("my-table__value", {
                  "my-table__value--bold": column.bold,
                  "my-table__value--truncate": column.truncate,
                })}
              >
                {value}
              </div>

              {columnIndex === props.columns.length - 1 && (
                <>
                  <div className="my-table__compactRow">
                    {props.columns.map((column, index) => (
                      <AtlasValue key={column.id} label={column.label}>
                        {values[index]}
                      </AtlasValue>
                    ))}
                  </div>

                  {props.rowActions &&
                    props.rowActions(row, rowIndex).length > 0 && (
                      <div className="my-table__actions">
                        <div className="my-table__actionsInner">
                          {props.rowActions(row, rowIndex)}
                        </div>
                      </div>
                    )}
                </>
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div
      className={classNames("my-table", {
        "my-table--manyColumns": props.columns.length > 5,
        "my-table--infinite": props.internalInfinite,
      })}
    >
      <table className="my-table__table" ref={tableRef}>
        <caption>{props.caption}</caption>

        <thead>
          <tr ref={forwardedRef}>{props.columns.map(getTableHead)}</tr>
        </thead>

        <tbody>
          {props.rows?.map(getTableRow)}

          {[0, 1, 2].map((rowIndex) => (
            <AtlasLoading.Loaders
              tag="tr"
              className="my-table__row my-table__row--loading"
              key={`loading-row-${rowIndex}`}
            >
              {props.columns.map((_, cellIndex) => (
                <td
                  className="my-table__cell"
                  key={`loading-row-${rowIndex}-cell-${cellIndex}`}
                >
                  <AtlasLoading.Loader />
                </td>
              ))}
            </AtlasLoading.Loaders>
          ))}

          {props.rows.length === 0 && (
            <tr className="my-table__row my-table__row--empty">
              <td className="my-table__cell" colSpan={10}>
                {props.error ? (
                  <AtlasInfo type="error" title="Erreur">
                    {props.error}
                  </AtlasInfo>
                ) : (
                  (props.emptyText ??
                  "Votre recherche n'a retourné aucun résultat. Nous vous invitons à modifier les critères de recherche.")
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
});
