import { AtlasSpinner } from "atlas-ds/atlas/components/loading/AtlasSpinner/AtlasSpinner";
import classNames from "classnames";

export interface MySplashProps {
  /**
   * Le contenu
   */
  children: React.ReactNode;
  /**
   * L'application présente-t-elle une erreur ? Si oui, le loader ne sera pas
   * affiché.
   */
  error?: boolean;
}

/**
 * Indicateur de chargement de l'application
 */
export function MySplash(props: MySplashProps) {
  return (
    <div
      className={classNames("my-splash", {
        "my-splash--error": props.error,
      })}
    >
      {props.children}

      {!props.error && (
        <div className="my-splash__throbber">
          <AtlasSpinner />
        </div>
      )}
    </div>
  );
}
