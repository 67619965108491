import axios from 'axios'
import { BaseSearchQuery, getRequestConfig, Page } from 'api/baseAPI'
import store from 'app/store'
import { format, startOfToday, subYears } from 'date-fns'
import { DATE_FORMAT, SIREN_PRESTATAIRE } from 'app/constants'
import { Dossier, Facture, Reglement } from './dossierAPI'


export type { Page } from 'api/baseAPI'

export interface ReglementsSearchQuery extends BaseSearchQuery {
    recherche?: string
    modeReglement?: string[]
    dateDebut?: string
    dateFin?: string
    organisme?: string
}

export interface EtablissementReglement {
    BillingCity: string
    Name: string
    
}

export interface FactureReglement extends Facture {
    
    EtablissementEmetteur: EtablissementReglement
}

export interface ReglementDetail extends Reglement {
    Dossier__c: string
    Dossier: Dossier
    Facture: FactureReglement
}


function getSearchReglementsParams(query: ReglementsSearchQuery): URLSearchParams {

    const params = new URLSearchParams()

    const entreprise = store.getState().contextState.entreprise

    if (query.tri) {
        params.append('tri', query.tri)
        params.append('ordre', query.ordre ?? 'desc')
    }

    if (query.recherche)
        params.append('recherche', query.recherche.trim())

    if (query.modeReglement && query.modeReglement.length > 0) {
        query.modeReglement.forEach(value => {
            params.append('mode-reglement', value)
        })
    }

    if (query.organisme)
        params.append('etablissementId', query.organisme)

    const today = startOfToday()
    params.append('date-debut', query.dateDebut ?? format(subYears(today, 5), DATE_FORMAT))
    params.append('date-fin', query.dateFin ?? format(today, DATE_FORMAT))
    params.append(SIREN_PRESTATAIRE, entreprise?.SIREN__c ?? '')

    return params
}

export async function getReglements(query: ReglementsSearchQuery): Promise<Page<ReglementDetail>> {

    const requestConfig = await getRequestConfig()
    const params = getSearchReglementsParams(query)
    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 20).toFixed(0))
    requestConfig.params = params

    const { data, headers } = await axios.get<ReglementDetail[]>('/reglements/recherche', requestConfig)
    const totalCount = parseInt(headers['x-total-count'], 10) || data.length
    return {
        totalCount: totalCount,
        items: data
    }
}

export async function exportReglements(query: ReglementsSearchQuery, email: string): Promise<string> {
    const requestConfig = await getRequestConfig()

    const params = getSearchReglementsParams(query)
    params.append('mail', email)

    await axios.post('/reglements/exporter', params, requestConfig)

    return `Le fichier de données est en cours d'export et vous sera envoyé par email à l'adresse ${email}`
}

