import { SchemaForType } from "@dsid-opcoatlas/yop";
import { fieldMetadataDecorator } from "elements/metadata";
import { ValidationContextProperty } from "elements/validation";
import { DTOProperty } from "elements/mapping";
import { FormContextProperty } from "elements/FormFields";


export function input<ParentClass>(value: ((props: any) => JSX.Element)) {
    return fieldMetadataDecorator<ParentClass, any>({ input: value, kind: undefined })
}

export function dto<ParentClass>(value: DTOProperty) {
    return fieldMetadataDecorator<ParentClass, any>({ dto: value, kind: undefined })
}

export function schema<ParentClass, FieldType>(value: SchemaForType<FieldType | null> | (() => SchemaForType<FieldType | null>)) {
    return fieldMetadataDecorator<ParentClass, FieldType | null>({ schema: value, kind: undefined })
}

export function label<ParentClass>(value: FormContextProperty<ParentClass, any, string | JSX.Element>) {
    return fieldMetadataDecorator<ParentClass, any>({ label: value, kind: undefined })
}

export function tooltip<ParentClass>(value: FormContextProperty<ParentClass, any, string | JSX.Element>) {
    return fieldMetadataDecorator<ParentClass, any>({ tooltip: value, kind: undefined })
}

export function disabled<ParentClass>(value: FormContextProperty<ParentClass, any, boolean>) {
    return fieldMetadataDecorator<ParentClass, any>({ disabled: value, kind: undefined })
}

export function visible<ParentClass>(value: FormContextProperty<ParentClass, any, boolean>) {
    return fieldMetadataDecorator<ParentClass, any>({ visible: value, kind: undefined })
}

export function required<ParentClass, FieldType>(value?: ValidationContextProperty<ParentClass, boolean, FieldType>) {
    return fieldMetadataDecorator<ParentClass, any>({ required: value ?? true, kind: undefined })
}

export function ignored<ParentClass, FieldType>(value?: ValidationContextProperty<ParentClass, boolean, FieldType>) {
    return fieldMetadataDecorator<ParentClass, any>({ ignored: value ?? true, kind: undefined })
}

type FieldDecorator<FieldType> = ((_: any, context: ClassFieldDecoratorContext<unknown, FieldType>) => void)

export function min<ParentClass>(value: ValidationContextProperty<ParentClass, number | null | undefined, string | null>): FieldDecorator<string | null>
export function min<ParentClass>(value: ValidationContextProperty<ParentClass, number | null | undefined, number | null>): FieldDecorator<number | null>
export function min<ParentClass>(value: ValidationContextProperty<ParentClass, number | null | undefined, File | null>): FieldDecorator<File | null>
export function min<ParentClass>(value: ValidationContextProperty<ParentClass, Date | null | undefined, Date | null>): FieldDecorator<Date | null>

export function min<ParentClass>(value: ValidationContextProperty<ParentClass, any, any>) {
    return fieldMetadataDecorator<ParentClass, any>({ min: value, kind: undefined })
}

export function max<ParentClass>(value: ValidationContextProperty<ParentClass, number | null | undefined, string | null>): FieldDecorator<string | null>
export function max<ParentClass>(value: ValidationContextProperty<ParentClass, number | null | undefined, number | null>): FieldDecorator<number | null>
export function max<ParentClass>(value: ValidationContextProperty<ParentClass, number | null | undefined, File | null>): FieldDecorator<File | null>
export function max<ParentClass>(value: ValidationContextProperty<ParentClass, Date | null | undefined, Date | null>): FieldDecorator<Date | null>

export function max<ParentClass>(value: ValidationContextProperty<ParentClass, any, any>) {
    return fieldMetadataDecorator<ParentClass, any>({ max: value, kind: undefined })
}

export function override<ParentClass, Decorator extends ((props?: any) => any)>(_: Decorator, props: Parameters<Decorator>[0]) {
    return fieldMetadataDecorator<ParentClass, any>(props)
}
