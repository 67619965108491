import { uploadDocument } from 'api/documentAPI'
import { DossierDetail, DossierPieceDTO, getDossier, getDossierDocumentTypes, getDossierDocumentsStatus, sendDossierDocuments } from 'api/dossierAPI'
import DepotFichierModal, { DepotFichierModalProps } from 'components/docs/DepotFichierModal'
import DepotFichier from 'pages/detail-common/DepotFichier'
import DetailDossierContrat, { detailDossierDocumentsRefreshEvent } from 'pages/detail-dossier/DetailDossierContrat'
import DossierDeposerFactureSelfe from 'pages/detail-dossier/DossierDeposerFactureSelfe'
import useDossiersModuleContext from './useDossiersModuleContext'


export default function DetailDossierEntreprise() {
    const ctx = useDossiersModuleContext()

    const depotPieces = (props: {dossier: DossierDetail, buttonLevel?: 1 | 2 | 3 | 4, buttonIcon?: string}) => {
        const appModule = ctx?.findModule(props.dossier)

        return <DepotFichier key="depotPieces"
            idParent={ props.dossier?.NumeroDossier__c }
            transmissionEnCours={ props.dossier?.EnCoursTransmissionSOR__c }
            sendDocuments={ sendDossierDocuments }
            checkSendDocumentsStatus={ getDossierDocumentsStatus }
            sendSuccessMessage="Les nouvelles pièces ont bien été ajoutées au dossier"
            refreshEvent={ detailDossierDocumentsRefreshEvent }
            buttonIcon={ props.buttonIcon }
            buttonLevel={ props.buttonLevel }
            DepotFichierModal={ (props: DepotFichierModalProps<DossierPieceDTO>) =>
                <DepotFichierModal<DossierPieceDTO>
                    { ...props }
                    warningMessage={ appModule?.documentWarningMessage }
                    documentTypeFilter={ appModule?.documentTypeFilter }
                    getDocumentTypes={ getDossierDocumentTypes }
                    upload={ uploadDocument }
                />
            }
        />
    }

    return <DetailDossierContrat
        getDossier={ getDossier }
        depotPieces={ depotPieces }
        actions={ (dossier, actions) => {
            if (actions.length === 0) {
                // Ajout de facture par défaut pour les types de dossiers non gérés
                actions.push(<DossierDeposerFactureSelfe key="deposerFacture" detail={ dossier } mode="facture" />)
            }

            const depotPiecesAction = depotPieces({dossier, buttonLevel: 2, buttonIcon: ""})
            actions.push(depotPiecesAction)
        } } />
}
