import { Form, useForm, UseFormReturn } from '@dsid-opcoatlas/reform'
import { Yop } from "@dsid-opcoatlas/yop"
import { postTransmettrecertificatDossier } from 'api/dossierAPI'
import { dispatchAppEvent } from "app/events"
import { AtlasButton, AtlasInfo, AtlasSpacer, MyForm, MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import Button from "components/reform/Button"
import FileField from 'components/reform/inputs/FileField'
import { AcceptedDocuments, RequiredFile } from 'components/yop/constraints'
import log from 'loglevel'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { useState } from 'react'
import { detailDossierDocumentsRefreshEvent, DossierActionProps } from './DetailDossierContrat'


export default function DossierCertificatRealisation(props: DossierActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null

    const [showCertificatDossier, setShowCertificatDossier] = useState(false)
    const [actionPending, setActionPending] = useState(false)

    function createCertificatRealisation() {
        return {
            fileCertificat: null as (File | null),
        }
    }

    type RefCertificatRealisation = ReturnType<typeof createCertificatRealisation>
    const initialValues = createCertificatRealisation()
    const validationSchema = Yop.object<RefCertificatRealisation>({
        fileCertificat: RequiredFile,

    })

    const form = useForm<RefCertificatRealisation>({
        initialValues,
        validationSchema,
        onSubmit: async (context: UseFormReturn<RefCertificatRealisation>) => {
            const { fileCertificat } = context.values!
            try {
                if (fileCertificat != null) {
                    await postTransmettrecertificatDossier(dossier.NumeroDossier__c, 'CERTIFICAT DE REALISATION', fileCertificat)
                    context.setSubmitting(false)
                    dispatchAppEvent(detailDossierDocumentsRefreshEvent)
                    ToastService.showToast({
                        label: 'Transmettre le certificat de réalisation final',
                        type: 'success',
                        content: 'La transmission du certificat de réalisation final a bien été prise en compte. Elle sera traitée dans les meilleurs délais.',
                    })
                }
            }
            catch (e) {
                log.error('Erreur Transmettre le certificat de réalisation final ' + dossier.NumeroDossier__c, e)
                ToastService.showToast({
                    label: 'Transmettre le certificat de réalisation final',
                    type: 'error',
                    content: 'Une erreur est survenue. Veuillez réessayer ultérieurement',
                })
            }
            finally {

                context.setSubmitting(false)
                form.reset()
                setShowCertificatDossier(false)
            }

        }
    })

    return <>
        <AtlasButton level={2} onClick={() => setShowCertificatDossier(true)}>
            Transmettre le certificat de réalisation final
        </AtlasButton>

        <MyModal show={showCertificatDossier}
            label="Transmettre le certificat de réalisation final"
            isPending={actionPending}

        >
            <AtlasInfo type="warning" title="Attention">
                <p>Au terme du contrat d'apprentissage, le CFA transmet à l'OPCO le certificat de réalisation couvrant la totalité de la période du contrat qui clôture le dossier.</p>

                <p> Sans ce document, l'OPCO pourra demander le remboursement de la dernière échéance.</p>

                <p>Le certificat de réalisation que vous allez joindre doit couvrir l'ensemble des périodes facturées.</p>

                <p>Tout autre document joint ici ne sera pas pris en compte par les équipes d'Atlas.</p>
            </AtlasInfo>
            <AtlasSpacer size="xl" />
            <Form context={form} autoComplete="off" noValidate >

                <MyForm>
                    <FileField name="fileCertificat" label="Transmettre le certificat de réalisation final" tooltip={ `Formats acceptés : ${ Object.values(AcceptedDocuments).join(', ') } — 15 Mo maximum` }/>
                </MyForm>

                <AtlasSpacer size="xl" />
                <MyForm>
                    <MyForm.Actions>
                        <Button submit={true} spinner={{ spinning: form.submitting }}>Valider</Button>
                        <Button level={2} onClick={() => setShowCertificatDossier(false)}>Retour</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    </>
}
