import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasFlex, AtlasHeading, AtlasInfo, AtlasLoading, MyModal, MyTable } from 'atlas-ds'
import { formatApiDate } from 'components/format/Format'
import log from 'loglevel'
import { Bound, PreFormContentProps, getBoundValue } from 'pages/dossier/metadata/FormMetadata'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { dossierSliceActions } from 'slices/dossierSlice'
import { DossierIdParams } from 'pages/dossier/useDossier'
import { useDossierNavigation } from 'pages/dossier/useDossierNavigation'
import { BaseDossierSalarie } from 'elements/model/dossierClasses'


export function SalariesTable(props: PreFormContentProps<any>) {

    const dispatch = useAppDispatch() 
    const { etape, step, navigateEdit } = useDossierNavigation(props.metadata)
    const salariesPath = step.path!.toString()
    const { dossierId } = useParams<DossierIdParams>()
    const [ stagiaireToDelete, setStagiaireToDelete ] = useState<{ salarie: BaseDossierSalarie, index: number } | null>(null)
    const [ deletePending, setDeletePending ] = useState(false)

    const supprimerSalarie = async (index: number) => {
        setDeletePending(true)
        await props.form!.array(salariesPath)!.remove(index, false)
        dispatch(dossierSliceActions.save({ api: props.metadata.api, dossier: props.form!.values })).then(unwrapResult)
            .catch(error => {
                log.error("Erreur dossier suppression salarie", error)
            })
            .finally(() => {
                setDeletePending(false)
                setStagiaireToDelete(null)
            })
    }

    const validationErrorIndexes = new Set<number>()
    props.form?.getErrorPaths().forEach(errorPath => {
        const errorIndex = errorPath.match(/^salaries\[(\d+)\]/)?.[1]
        if (errorIndex)
            validationErrorIndexes.add(parseInt(errorIndex))
    })

    const salariesActions = (salarie: BaseDossierSalarie, index: number) => {
        return [
            <AtlasButton key="supprimer" icon="trash" level={ 3 } onClick={ () => setStagiaireToDelete({ salarie, index }) }>
                Supprimer
            </AtlasButton>,
            <AtlasButton key="modifier" icon="details" level={ 3 } onClick={ () => navigateEdit(dossierId!, etape, salarie.IdHeroku!) }>
                Modifier
            </AtlasButton>,
        ]
    }

    const minSalaries = props.dossier && typeof props.step.options?.minElements === 'function' ? props.step.options?.minElements(props.dossier) : props.step.options?.minElements as Bound | null
    let maxSalaries = props.dossier && typeof props.step.options?.maxElements === 'function' ? props.step.options?.maxElements(props.dossier) : props.step.options?.maxElements as Bound | null
    
    const subcaption = minSalaries && maxSalaries ?
        (getBoundValue(maxSalaries) === 1 ?
            'Renseignez un(e) stagiaire' :
            `Renseignez ${getBoundValue(minSalaries)} à ${getBoundValue(maxSalaries)} stagiaires`) :
        `Renseignez un(e) ou plusieurs stagiaires (50 maximum)`
    
    if (maxSalaries == null)
        maxSalaries = 50

    return (
        <div>
            <AtlasHeading
                tag="h2"
                size="m"
                button={
                    <AtlasButton
                        onClick={() => navigateEdit(dossierId!, etape)}
                        disabled={maxSalaries ? ((props.dossier?.salaries?.length ?? 0) >= getBoundValue(maxSalaries)) : false}
                        level={3}
                        icon="plus">
                        Ajouter un salarié
                    </AtlasButton>
                }>
                { step.title }
            </AtlasHeading>
            
            <AtlasHeading tag="h3" size="s">{ subcaption }</AtlasHeading>

            <AtlasFlex column gap="l">
                <AtlasLoading loading={props.loading ?? props.saving ?? false}>
                    <MyTable
                        caption="Salariés"
                        rows={ props.dossier?.salaries ?? [] }
                        rowKey={ row => row.IdHeroku }
                        rowError={ (row, index) => validationErrorIndexes.has(index) ? "Ce salarié doit être mis à jour" : undefined }
                        emptyText="Aucun salarié n'est associé à ce dossier pour le moment."
                        rowActions={ salariesActions }
                        columns={[{
                            id: "name",
                            label: "Nom",
                            value: (row: BaseDossierSalarie) => `${row.prenom} ${row.nom}`
                        }, {
                            id: "dateNaissance",
                            label: "Date de naissance",
                            value: (row: BaseDossierSalarie) => formatApiDate(row.dateNaissance)
                        }, {
                            id: "courriel",
                            label: "Adresse mail"
                        }]}
                    />
                </AtlasLoading>

                { props.form?.getError('salaries') != null &&
                <AtlasInfo type="error" title="Erreur">
                    { props.form?.getError('salaries')?.message || ''}
                </AtlasInfo> }
                
                { validationErrorIndexes.size > 0 &&
                <AtlasInfo type="error" title="Erreur" >
                    Certains salariés ci-dessus comportent des erreurs. Veuillez les modifier.
                </AtlasInfo> }
            </AtlasFlex>

            { stagiaireToDelete &&
            <MyModal
                label="Confirmation"
                isPending={ deletePending }
                okButton={<AtlasButton onClick={() => supprimerSalarie(stagiaireToDelete.index)}>Confirmer</AtlasButton>}
                cancelButton={<AtlasButton onClick={() => setStagiaireToDelete(null)}>Retour</AtlasButton>}>
                <p>Voulez-vous vraiment supprimer { stagiaireToDelete.salarie.prenom } { stagiaireToDelete.salarie.nom } ?</p>
            </MyModal>
            }
        </div>
    )
}
