import { AtlasValues, MyContainer } from 'atlas-ds'
import { formDiffElements, FormElements } from 'pages/dossier/metadata/FormElements'
import { DossierAF } from 'slices/dossierCommon'
import { useDossierNavigation } from '../useDossierNavigation'
import { RecapProps } from './RecapProps'
import { RecapSection } from './RecapSection'


export default function RecapTuteur<D extends DossierAF<any, any>>(props: RecapProps<D>) {
    const { navigateTo } = useDossierNavigation(props.metadata, props.navigationGuard)

    const dossier = props.dossier ?? { tuteur: {} } as any
    const initialDossier = props.initialDossier ?? { tuteur: {} } as any

    if (props.metadata.api.isEdit) {
        const elements = formDiffElements(props.metadata, dossier, initialDossier, 'tuteur')
        if (!elements || elements.length === 0) return null
        return <RecapSection title={ props.step.title ?? 'Tuteur' } 
                    onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, props.etape) }>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            { elements }
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>
            </RecapSection>
    }

    return <>
        <RecapSection
            title={ props.step.title ?? 'Tuteur' } 
            onModifier={ () => navigateTo(props.dossier!.NumeroDossier__c!, props.etape) }>
            <MyContainer>
                <AtlasValues>
                    <AtlasValues.Grid>
                        <FormElements metadata={ props.metadata } dossier={ props.dossier } path="tuteur" type="recap" />
                    </AtlasValues.Grid>
                </AtlasValues>
            </MyContainer>
        </RecapSection>
    </>
}
