import { Etablissement } from 'api/commonAPI'
import { Profil } from 'api/profilAPI'
import { Config, Entreprise, Service } from 'api/referencesAPI'
import AppEntreprise from 'app/entreprise/AppEntreprise'
import AppPrestataire from 'app/prestataire/AppPrestataire'
import { AtlasLogoProps, MyThemeProps } from 'atlas-ds'
import { ComponentType, ReactElement } from 'react'
import { Route } from 'react-router-dom'
import { AppType } from 'slices/contextSlice'
import { AppRouteProps } from './AppModuleProvider'
import AppCompte from './compte/AppCompte'
import { Extension } from './ExtensionPoint'

export const campusEnabled = (appState: AppState) => {
    return import.meta.env.REACT_APP_CAMPUS !== 'false' && appState.entreprise?.OFCampus__c
}

export type RouteElement = ReactElement<AppRouteProps, ComponentType<Route>>

export interface AppState {
    app: AppType | null
    config: Config | null
    entreprise: Entreprise | null
    profil: Profil | null
    services: Service[] | null
    appTypes: AppType[] | null
}

export type AppModule<C = any> = {
    id: string
    isEnabled?: (appState: AppState) => boolean
    context?: (appState: AppState) => C
    routes: RouteElement[] | ((ctx: C, appState: AppState) => RouteElement[])
    extensions?: Record<string, Extension>
} & {
    [app in AppType]?: Record<string, Extension>
}

export interface AppInfo {
    id: string
    app: AppType
    name: string
    logo?: React.ReactElement<AtlasLogoProps>
    header?: JSX.Element
    theme: MyThemeProps['theme']
    navLinks?: JSX.Element[]
    menuDisabled?: (appState: AppState) => boolean
    modules: AppModule[][]
    options?: {
        typeOrigine: string
        getEtablissements: (recherche: string, actif?: boolean) => Promise<Etablissement[]>
    },
    env?: Record<string, string>
}

const appInfos = [
    AppEntreprise,
    AppPrestataire,
    AppCompte,
]

export const appInfo = (app: AppType | string): AppInfo => {
    return appInfos.find(i => i.app === app)!
}

export const appTheme = (context: string | null): MyThemeProps['theme'] => {
    return appInfos.find(i => i.app === context)?.theme ?? 'entreprise'
}