import { AtlasLinkProps, AtlasLogo } from "atlas-ds/atlas";
import characterEntreprise from "../../../assets/img/character_entreprise.svg";
import characterPrestataire from "../../../assets/img/character_prestataire.svg";
import { MyTheme } from "../../theme";

export interface MyAppAccessProps {
  /**
   * L'application présentée
   */
  app: "entreprise" | "prestataire";
  /**
   * La présentation de l'application, en plusieurs points clés
   */
  keyPoints: string[];
  /**
   * Le lien d'accès à l'application
   */
  link: React.ReactElement<AtlasLinkProps>;
}

/**
 * Un encart de présentation d'une application myAtlas
 */
export function MyAppAccess(props: MyAppAccessProps) {
  return (
    <article className="my-appAccess">
      <MyTheme theme={props.app}>
        <div className="my-appAccess__inner">
          <div className="my-appAccess__image">
            <img
              src={
                props.app === "entreprise"
                  ? characterEntreprise
                  : characterPrestataire
              }
              alt=""
            />
          </div>
          <div className="my-appAccess__content">
            <AtlasLogo logo={props.app} />
            <ul className="my-appAccess__keyPoints">
              {props.keyPoints.map((keyPoint, index) => (
                <li key={`keyPoint-${index}`}>{keyPoint}</li>
              ))}
            </ul>
            {props.link}
          </div>
        </div>
      </MyTheme>
    </article>
  );
}
