import { DISPOSITIF_CONTRAT_APPRENTISSAGE } from "api/references"
import { createDossierEmployeurExtended, createDossierStagiaire, createAdresse, createBaseDossierTuteur, createDossierModuleAgora, createBaseDossier, DossierWithPointsReserve, nil, DossierCFA, DossierStagiaireRepresentant, DossierPointReserve } from "slices/dossierCommon"

export const v10required = (import.meta.env.REACT_APP_CAPP_V10 ?? 'required') === 'required'


function createDossierCAPPEmployeur() { return {
    ...createDossierEmployeurExtended(),
    caisseRetraiteComplementaire: nil as string,
    effectifSalarie: nil as number,
    typeEmployeur: nil as string,
    employeurSpecifique: nil as string,
    employeurPublic: false,
    regimeSpecifique: nil as (boolean | null),
    nomContact: nil as (string | null),
    prenomContact: nil as (string | null),
}}
export type DossierCAPPEmployeur = ReturnType<typeof createDossierCAPPEmployeur>


export function createDossierCAPPStagiaire() { return {
    ...createDossierStagiaire(),
    telephone: nil as string,
    nationalite: nil as string,
    adresse: createAdresse(),
    // Stagiaire
    naissanceFrance: true as boolean,
    communeNaissance: nil as string,
    departementNaissance: nil as string,
    regimeSocial: nil as string,
    dernierDiplome: nil as string,
    derniereClasse: nil as string,
    intituleDernierDiplome: nil as string,
    listeSportifs: false as boolean,
    projetEntreprise: false as boolean,
    statutEntreeEnFormationAgora: nil as string,
    niveauMaximumClasseEntreeFormationAgora: nil as string,
}}
export type DossierCAPPStagiaire = ReturnType<typeof createDossierCAPPStagiaire>


export function createDossierCAPPMaitre() { return {
    ...createBaseDossierTuteur(),
    sexe: nil as string,
    email: nil as string,
    plusHautDiplome: nil as (string | null),
    niveauPlusHautDiplome: nil as (string | null),
    anneesExperience: nil as number,
    salariesSuivis: nil as number,
}}
export type DossierCAPPMaitre = ReturnType<typeof createDossierCAPPMaitre>

export function createDossierDiplome() { return {
    ...({} as {
        IdFormation?: string | null
    }),
    intitulePrecis: nil as string,
    codeDiplome: nil as string,
    codeRNCP: nil as string,
    eligibleRNCP: false,
    formationReferentielLBAC: false,
}}
export type DossierDiplome = ReturnType<typeof createDossierDiplome>

export function createDossierCAPPFormation() { return { ...createDossierModuleAgora(), 
    subrogation: true as boolean,
    diplome: nil as DossierDiplome,
    organismeFormationCout: nil as number,
    majorationHandicapCout: nil as (number | null),
    fraisAnnexesInternational: nil as boolean,
    conditionRealisation: nil as (string | null),
    fraisAnnexesHebergement: nil as (number | null),
    fraisAnnexesRestauration: nil as (number | null),
    fraisAnnexesEquipementNature: nil as (string | null),
    fraisAnnexesEquipementCout: nil as (number | null),
    modalitePedagogiqueAgora: nil as string,
    adresseRealisationIdentiqueAgora: true as boolean,
    organismePrincipalFormationAgora: nil as (DossierCFA | null),
}}
export type DossierCAPPFormation = ReturnType<typeof createDossierCAPPFormation>

function createDossierCAPPAnneeRemuneration() { return {
    IdHeroku1: nil as number,
    IdHeroku2: nil as number,
    dossierC: nil as string,
    remunerationDateDebut: nil as (Date | null),
    remunerationDateChangement1: nil as (Date | null),
    remunerationDateChangement: nil as (Date | null),
    remunerationDateFin: nil as (Date | null),
    remunerationPourcentage1: nil as (number | null),
    remunerationType1: nil as (string | null),
    remunerationTranche1: nil as (number | null),
    remunerationGrille1: 0,
    remunerationPourcentage2: nil as (number | null),
    remunerationType2: nil as (string | null),
    remunerationTranche2: nil as (number | null),
    remunerationGrille2: 0,
    remunerationForceType1: null as (string | null),
    remunerationForceType2: null as (string | null),
    remunerationAge1: 0 as (number | null),
    remunerationAge2: 0 as (number | null),
}}
export type DossierCAPPAnneeRemuneration = ReturnType<typeof createDossierCAPPAnneeRemuneration>

function createDossierCAPPRemuneration() { return {
    anneesRemuneration: [] as DossierCAPPAnneeRemuneration[],
    modeManuelRemuneration: false as (boolean | null),
    employeurAttesteRemuneration: null as (boolean | null),
}}
export type DossierCAPPRemuneration = ReturnType<typeof createDossierCAPPRemuneration>


export function createDossierCAPPContrat() { return {
    typeContrat: nil as string,
    numContratPrecedent: nil as (string | null),
    typeDerogation: nil as (string | null),
    motifReduction: nil as (string | null),
    modeContractuelApprentissage: nil as string,
    dateDebut: nil as Date,
    dateFin: nil as Date,
    dateConclusion: nil as Date,
    dateDebutPratique: nil as (Date | null),
    dureeHebdomadaireTravail: { hours: 0, minutes: 0 as (number | null) },
    travailDangereux: nil as boolean,
    anneeFormationEntreeContrat: nil as string,
    niveauOuEchelon: nil as (string | null),
    coefficientHierarchique: nil as (string | null),
    salaireEmbauche: nil as number,
    nourriture: nil as (number | null),
    logement: nil as (number | null),
    autres: false as (boolean | null),
    dateEffet: nil as (Date | null),
    dateConclusionAvenant: nil as (Date | null),
    remuneration: createDossierCAPPRemuneration(),
}}
export type DossierCAPPContrat = ReturnType<typeof createDossierCAPPContrat>


export function createDossierCAPP() { return {
    ...createBaseDossier(DISPOSITIF_CONTRAT_APPRENTISSAGE),
    employeur: createDossierCAPPEmployeur(),
    stagiaire: nil as DossierCAPPStagiaire,
    maitre1: nil as DossierCAPPMaitre,
    maitre2: null as (DossierCAPPMaitre | null),
    eligibilite: false as boolean,
    formation: nil as DossierCAPPFormation,
    contrat: createDossierCAPPContrat(),
    representant: null as (DossierStagiaireRepresentant | null),
    attestationPieces: false as boolean,
    indicateurConformite: nil as (string | null),
    pointsReserve: null as DossierPointReserve[] | null | undefined,
}}
export type DossierCAPP = ReturnType<typeof createDossierCAPP> & DossierWithPointsReserve
