import { AtlasHtml, AtlasIcon, AtlasLoading } from "atlas-ds";
import { useId } from "react";

/**
 * Une question
 */
export interface MyQuestionsQuestion {
  /**
   * L'identifiant
   */
  id: string;
  /**
   * L'intitulé de la question
   */
  label: string | React.ReactNode;
  /**
   * La réponse à la question
   */
  answer: string | React.ReactNode;
}

export interface MyQuestionsProps {
  /**
   * Les questions
   */
  questions: MyQuestionsQuestion[];
  /**
   * Au clic sur une question. Ce callback peut permettre de charger
   * dynamiquement les réponses à l'ouverture seulement.
   */
  onClick?: (question: MyQuestionsQuestion) => void;
}

/**
 * Une liste de questions / réponses
 */
export function MyQuestions(props: MyQuestionsProps) {
  const name = useId();

  return (
    <div className="my-questions">
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      <AtlasLoading.Loader />
      {props.questions.map((question) => (
        <details name={name} key={question.id} className="my-questions__item">
          <summary>
            <div
              className="my-questions__cta"
              onClick={() => {
                if (props.onClick) {
                  props.onClick(question);
                }
              }}
            >
              {question.label}
              <div className="my-questions__buttons">
                <AtlasIcon name="arrow-right" size="xs" />
                <AtlasIcon name="close" size="xs" />
              </div>
            </div>
          </summary>
          <AtlasHtml>{question.answer}</AtlasHtml>
        </details>
      ))}
    </div>
  );
}
