import { AccesRapide } from 'api/referencesAPI'
import { AtlasButton, MyQuickAccessesModal } from 'atlas-ds'

interface InterfaceModal {
    show: boolean,
    nbMaxAcces: number
    accesRapidesEligibles: AccesRapide[] | undefined
    accesRapidesModal: AccesRapide[]
    setAccesRapidesModal: (accesRapides: AccesRapide[]) => void
    save: (accesRapides: AccesRapide[]) => void
    annuler: () => void
    close: () => void
}

export default function AccessesModal(props: InterfaceModal) {

    const toggleAccesRapide = (accesRapide: AccesRapide) => {
        if (accesRapideEstActif(accesRapide))
            props.setAccesRapidesModal(props.accesRapidesModal.filter(item => accesRapide.Id !== item.Id))
        else
            props.setAccesRapidesModal([...props.accesRapidesModal, accesRapide])
    }

    const accesRapideEstActif = (accesRapide: AccesRapide) => {
        return (props.accesRapidesModal.filter(item => accesRapide.Id === item.Id).length) ? true : false
    }

    return <MyQuickAccessesModal
        show={props.show}
        label={`Gestion de mes ${props.nbMaxAcces} accès rapides`}
        instructions={`Vous pouvez ajouter ${props.nbMaxAcces} entrées maximum sur votre page d'accueil`}
        slotsCount={4}
        selectedItems={props.accesRapidesModal?.map(acces => ({
            label: acces.Nom,
            onClick: () => toggleAccesRapide(acces)
        }))}
        availableItems={props.accesRapidesEligibles?.map(acces => ({
            label: acces.Nom,
            onClick: () => toggleAccesRapide(acces)
        })) ?? []}
        okButton={<AtlasButton onClick={ () => { props.save(props.accesRapidesModal); props.close() } }>
            Sauvegarder
        </AtlasButton>}
        cancelButton={<AtlasButton onClick={ props.annuler }>Retour</AtlasButton>}
    />
}
