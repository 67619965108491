import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from '@dsid-opcoatlas/yop'
import { getContacts } from 'api/commonAPI'
import { REF_TYPE_REGLEMENT, checkEtablissementDispositifDoublon, createReferenceBancaire } from 'api/refBancaireAPI'
import { postCreationSollicitation } from 'api/sollicitationAPI'
import useApiState from "api/useApiState"
import { useAppContext } from 'app/useAppContext'
import { AtlasColumns, AtlasFlex, AtlasInfo, AtlasSpacer, MyForm, MyModal } from "atlas-ds"
import PageAlert from 'components/PageAlert'
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import ErrorBanner from 'components/reform/ErrorBanner'
import EtablissementSelectorField from 'components/reform/inputs/EtablissementSelectorField'
import FileField from 'components/reform/inputs/FileField'
import IBANField from 'components/reform/inputs/IBANField'
import PhoneField from "components/reform/inputs/PhoneField"
import PseudoTextField from "components/reform/inputs/PseudoTextField"
import RadioField from 'components/reform/inputs/RadioField'
import SelectField from 'components/reform/inputs/SelectField'
import SelectOrCreateEntityField from "components/reform/inputs/SelectOrCreateEntityField"
import TextField from "components/reform/inputs/TextField"
import { AcceptedImages, OptionalFile, RequiredEmail, RequiredFile, RequiredPhone, RequiredString, requiredObject } from "components/yop/constraints"
import { isValidIBAN } from 'ibantools'
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { nil } from "slices/dossierCommon"


interface RefBancaireEtablissement {
    Id: string
    ParentId: string
    nom: string
    numeroSiren: string
    numeroSiret: string
}

function createContact() { return {
    Id: null as (string | null),
    civilite: nil as string,
    prenom: nil as string,
    nom: nil as string,
    telephone: nil as string,
    courriel: nil as string,
}}

type RefBancaireContact = ReturnType<typeof createContact>

function createRefBancaire() { return {
    etablissement: nil as RefBancaireEtablissement,
    typeReglement: nil as string,
    iban: nil as string,
    contact: nil as RefBancaireContact,
    ibanpdf: null as (File | null),
}}

type RefBancaire = ReturnType<typeof createRefBancaire>

const interruption = import.meta.env.REACT_APP_INTERRUPTION_REFS_BANCAIRES === 'true'

export default function AjoutRefBancaireAdherent() {

    const location = useLocation()
    const history = useHistory()
    const [status409, setStatus409] = useState<string>()
    const appContext = useAppContext()
    
    const [
        { pending: checkDoublonPending, value: checkDoublonResult /*, error: checkDoublonError */ },
        withGetCheckEtablissementDispositifDoublon,
        setCheckDoublonState
    ] = useApiState(checkEtablissementDispositifDoublon, { pending: false })

    const [
        { pending: referenceBancairePending, value: referenceBancaire, error: referenceBancaireError },
        withCreateReferenceBancaire
    ] = useApiState(createReferenceBancaire, { pending: false })

    const [
        { pending: sollicitationPending, value: sollicitationId, error: sollicitationError },
        withPostCreationSollicitation
    ] = useApiState(postCreationSollicitation, { pending: false })

    const sexes = [
        { value: '1', label: 'Monsieur' },
        { value: '2', label: 'Madame' },
    ]

    const initialValues = createRefBancaire()

    const validationSchema = Yop.object<RefBancaire>({
        etablissement: requiredObject<RefBancaireEtablissement>({
            Id: RequiredString,
            ParentId: Yop.ignored(),
            nom: Yop.ignored(),
            numeroSiren: Yop.ignored(),
            numeroSiret: Yop.ignored(),
        }, 'Vous devez sélectionner un établissement'),
        typeReglement: RequiredString.oneOf(REF_TYPE_REGLEMENT.map(tr => tr.Id)),
        iban: RequiredString.test(ctx => {
            return !ctx.value.startsWith('FR') || isValidIBAN(ctx.value) || ctx.createError('Format IBAN incorrect')
        }),
        contact: requiredObject<RefBancaireContact>({
            Id: Yop.string().defined().ignored(),
            civilite: RequiredString.oneOf(sexes.map(s => s.value)),
            prenom: RequiredString,
            nom: RequiredString,
            courriel: RequiredEmail,
            telephone: RequiredPhone,
        }),
        ibanpdf: (referenceBancaireError == null ? OptionalFile : RequiredFile).max(15 * 1024 * 1024, 'Fichier trop volumineux (15 Mo maximum)'),
    })

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<RefBancaire>) => {
            setStatus409(undefined)
            
            const values = context.values!
            
            if (values.ibanpdf != null) {
                withPostCreationSollicitation({
                    Subject: 'Demande de création de RIB',
                    Priority: 'Haute',
                    Description: `Siret : ${ context.values?.etablissement?.numeroSiret } (${ context.values?.etablissement?.nom })`,
                    Origin: appContext.appInfo.options!.typeOrigine,
                    Type: "demandes-assistance",
                    SousType__c: "Gestion-des-references-bancaires",
                }, values.ibanpdf.name, values.ibanpdf)
                .finally(() => {
                    context.setSubmitting(false)
                })
            }
            else {
                const contact = values.contact
                withCreateReferenceBancaire(values.etablissement.Id, values.typeReglement, values.iban, {
                    Id: contact.Id,
                    Salutation: contact.civilite,
                    FirstName: contact.prenom,
                    LastName: contact.nom,
                    Email: contact.courriel,
                    Phone: contact.telephone,
                })
                .catch(error => {
                    if (error.status === 409)
                        setStatus409(error.message)
                })
                .finally(() => {
                    context.setSubmitting(false)
                })
            }
        },
    })

    const checkDoublon = () => {
        if (form.values?.etablissement?.Id != null && form.values?.typeReglement != null)
            withGetCheckEtablissementDispositifDoublon(form.values.etablissement.Id, form.values.typeReglement)
        else
            setCheckDoublonState({ pending: false, value: null, error: null })
    }

    async function searchContact(recherche: string): Promise<RefBancaireContact[]> {
        const items = await getContacts(form.values!.etablissement.Id, recherche)
        return items.map(item => ({
            Id: item.Id ?? null,
            civilite: item.Salutation ?? null,
            prenom: item.FirstName ?? null,
            nom: item.LastName ?? null,
            courriel: item.Email ?? null,
            telephone: item.Phone ?? null,
        }))
    }

    const renderContact = (contact: RefBancaireContact) => {
        return `${ contact.nom } ${ contact.prenom } (${contact.courriel ?? ''})`
    }

    const goBack = () => {
        if ((location.state as any)?.back === true) {
            history.goBack()
        } else {
            history.push("/mes-refs-bancaires")
        }
    }

    const disableAll = interruption || status409 != null || checkDoublonPending || referenceBancairePending || sollicitationPending || referenceBancaire != null || sollicitationId != null
    const disableSearchContact = disableAll || form.values?.etablissement?.Id == null
    const disableContact = disableAll || form.values?.contact?.Id !== null

    return (
        <>
            <PageHeader>Ajouter un RIB</PageHeader>

            { interruption &&
            <>
            <AtlasInfo type="warning" title="Interruption de service">
                Pour des raisons indépendantes d’Atlas, les services de dépôt de références bancaires sont temporairement interrompus.
                Veuillez nous excuser pour la gêne occasionnée.
            </AtlasInfo>
            <AtlasSpacer size="xl" />
            </>
            }

            <AtlasColumns>
                <AtlasFlex column gap="xl">
                    <PageAlert config="BandeauxRefBancaires" />
                    
                    <Form context={ form } noValidate>
                        <MyForm>
                            <EtablissementSelectorField
                                getEtablissements={ appContext.appInfo.options!.getEtablissements }
                                label="Établissement titulaire"
                                employeurPath="etablissement"
                                adresse={ false }
                                code={ false }
                                disabled={ disableAll }
                                onChange={ checkDoublon }
                                showSelectedInfo
                            />

                            <SelectField
                                selectionner
                                name="typeReglement"
                                label="Dispositif associé pour le règlement"
                                disabled={ disableAll }
                                options={REF_TYPE_REGLEMENT.map(tr => ({ value: tr.Id, label: tr.Libelle }))}
                                onChange={ checkDoublon }
                                warningMessage={
                                    status409 != null ?
                                    undefined :
                                    checkDoublonPending ?
                                        "Chargement en cours..." :
                                        (checkDoublonResult ?? undefined)
                                }
                            />

                            { status409 != null && <>
                                <IBANField name="iban" label="IBAN" disabled />
                                
                                <AtlasInfo type="success" title={ status409 } />
                            </>}

                            { referenceBancaireError == null && <>                            
                                <IBANField name="iban" label="IBAN" disabled={ disableAll } />

                                <SelectOrCreateEntityField
                                    name="contact"
                                    label="Contact"
                                    placeholder="Rechercher par nom ou prénom (3 caractères minimum)"
                                    tooltip="Vous pouvez choisir une personne dans la liste en entrant les premières lettres de son nom ou de son prénom, certains champs
                                        seront remplis automatiquement. Si la recherche ne remonte pas de résultat, vous pourrez aussi entrer manuellement une nouvelle personne."
                                    searchValues={ async (recherche) => await searchContact(recherche) }
                                    renderValue={ renderContact }
                                    getValueId={ value => (value.Id ?? "") }
                                    disabled={ disableSearchContact }
                                    searchDebounceOptions={ { immediate: !disableSearchContact, empty: true } }
                                    create={{
                                        newValue: createContact,
                                        buttonLabel: "Ajouter un contact",
                                        infoTitle: "Ajout d'un nouveau contact",
                                        infoContent: <>Vous n'avez pas trouvé le contact que vous cherchiez ?<br/>Utilisez les champs ci-dessous pour renseigner votre contact.</>,
                                    }}
                                />
                                
                                <RadioField name="contact.civilite" label="Civilité" options={ sexes }
                                    disabled={ disableContact } />

                                <MyForm.Grid>
                                    <TextField name="contact.prenom" label="Prénom" disabled={ disableContact } />
                                    <TextField name="contact.nom" label="Nom" disabled={ disableContact } />
                                    <TextField name="contact.courriel" label="Email" disabled={ disableContact } />
                                    <PhoneField name="contact.telephone" label="Téléphone" disabled={ disableContact } />
                                </MyForm.Grid>
                                
                                { referenceBancaire != null && referenceBancaire.Error_Message == null && <>
                                    <MyForm.Grid>
                                        <PseudoTextField label="BIC" value={ referenceBancaire.IbanVerificationResult.Iban.Bic } />
                                        <PseudoTextField label="Banque" value={ referenceBancaire.IbanVerificationResult.Iban.InstitutionName } />
                                    </MyForm.Grid>

                                    <AtlasInfo type="success" title="Succès">
                                        { referenceBancaire.Message }
                                    </AtlasInfo>
                                </> }

                            </> }

                            { status409 == null && sollicitationId === null && <ErrorBanner title="Erreur lors de l'ajout du RIB" message={
                                referenceBancaireError ?? (sollicitationError != null ? "Une erreur est survenue durant l'envoi de votre demande" : undefined)
                            } /> }
                            
                            { status409 == null && (referenceBancaireError != null || referenceBancaire?.Error_Message != null) &&
                                <>
                                    <AtlasInfo title="Erreur">
                                        { referenceBancaire?.Error_Message ?? "La procédure d'enregistrement automatique a échoué, veuillez télécharger votre RIB." }
                                    </AtlasInfo>

                                    <FileField
                                        name="ibanpdf"
                                        label="Sélectionner un RIB"
                                        accept={ Object.keys(AcceptedImages) }
                                        tooltip={ `Formats acceptés : ${ Object.values(AcceptedImages).join(', ') } — 15 Mo maximum` }
                                        disabled={ (disableAll && referenceBancaire?.Error_Message === null) || sollicitationId !== null } />
                                
                                    { sollicitationId != null && <AtlasInfo type="success" title="Transmission effectuée" >
                                        Votre demande d'ajout de RIB a bien été transmise. Vous pouvez quitter cette page.
                                    </AtlasInfo> }
                                </>
                            }

                            <MyForm.Actions>
                                { status409 == null && referenceBancaire == null && sollicitationId == null ? <>
                                    <Button disabled={ disableAll } submit={ true } spinner={{spinning: form.submitting}}>
                                        Enregistrer l'IBAN
                                    </Button>
                                    <Button level={ 2 } onClick={ () => goBack() }>Annuler</Button>
                                </> : (status409 == null && (referenceBancaireError != null || referenceBancaire?.Error_Message != null) && sollicitationId === null ) ? <>
                                    <Button submit={ true } disabled={ !form.values?.ibanpdf } spinner={{spinning: form.submitting}}>
                                        Valider
                                    </Button>
                                    <Button level={ 2 } onClick={ () => goBack() }>Annuler</Button>
                                </> :
                                    <Button level={ 2 } onClick={ () => goBack() }>Retour</Button>
                                }
                            </MyForm.Actions>
                        </MyForm>
                    </Form>
                </AtlasFlex>
            </AtlasColumns>

            { form.submitting && <MyModal isLoader={true} label="Enregistrement en cours…">
                <p>Félicitations ! Votre RIB est en cours d'enregistrement. Cette opération peut prendre plusieurs minutes. Merci de patienter.</p>
            </MyModal> }
        </>
    )
}

/*
ALTRAN TECHNOLOGIES    702012956    70201295600653    FR111382500200LUCYDEMO00220
AMI APPLICATION MULTIMEDIA INFORMATIQU    418127866    41812786600063    FR111382500200LUCYDEMO00317
ATOS FRANCE    408024719    40802471900572    FR111382500200LUCYDEMO00414
SOPRA STERIA GROUPE    326820065    32682006500083    FR111382500200LUCYDEMO00511
ORSYS    482761160    48276116000019    FR111382500200LUCYDEMO00608
ADESA    393337738    39333773800040    FR111382500200LUCYDEMO00705
ALTERNANCE RHONE ALPES    480645860    48064586000069    FR111382500200LUCYDEMO00802


FR111382500200LUCYDEMO00415
*/