import { DossierCAPPFormation } from 'pages/dossier-capp/CAPPModel'
import RecapAtlasValue from './RecapAtlasValue'

interface RecapDiplomeProps {
    parent: DossierCAPPFormation | null
    initialValue?: any | null
    editMode?: boolean
}

export default function RecapDiplome(props: RecapDiplomeProps) {

    const valIntitulePrecis = props.parent?.diplome?.intitulePrecis
    const valInitialeIntitulePrecis = props.initialValue?.intitulePrecis

    const valcodeDiplome = props.parent?.diplome?.codeDiplome
    const valInitialecodeDiplome = props.initialValue?.codeDiplome

    const valcodeRNCP = props.parent?.diplome?.codeRNCP
    const valInitialecodeRNCP = props.initialValue?.codeRNCP


    return (<>
    <RecapAtlasValue label={"Intitulé"} initialValue={valInitialeIntitulePrecis} value={valIntitulePrecis} editMode={props.editMode || false} />
    <RecapAtlasValue label={"Code diplôme"} initialValue={valInitialecodeDiplome} value={valcodeDiplome} editMode={props.editMode || false} />
    <RecapAtlasValue label={"Code RNCP"} initialValue={valInitialecodeRNCP} value={valcodeRNCP} editMode={props.editMode || false} />
    </>)
}