import { deleteDossier } from "api/dossierAPI"
import { RootState } from "app/rootReducer"
import { AtlasButton, AtlasLink, MyModal } from "atlas-ds"
import { ToastService } from "components/BandeauAlert"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { DossierACN } from "./ACNFormMetadata"
import { getCampusUrl } from "./DossierACNCampusElements"

export interface DossierACNDatesModalProps {
    dossier: DossierACN
}

export default function DossierACNDatesModal(props: DossierACNDatesModalProps) {
    const [show, setShow] = useState(false)
    const [pending, setPending] = useState(false)

    const sessionStorageKey = `acn-dates-alert-dismissed-${props.dossier.NumeroDossier__c}`

    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const history = useHistory()

    useEffect(() => {
        setShow(sessionStorage.getItem(sessionStorageKey) !== "true")
    }, [])

    const handleDelete = () => {
        setPending(true)

        if (!props.dossier?.NumeroDossier__c || !entreprise) return;

        deleteDossier(props.dossier.NumeroDossier__c).then(_ => {
            ToastService.showToast({
                label: 'Suppression',
                type: 'success',
                content: "Le brouillon a été supprimé",
                link: <AtlasLink href={getCampusUrl(entreprise)}>
                    <span>Effectuer une nouvelle demande sur <i>campusAtlas</i></span>
                </AtlasLink>
            })
            history.push({
                pathname: '/mes-dossiers-contrats'
            })
        })
    }

    const handleShowDraft = () => {
        sessionStorage.setItem(sessionStorageKey, "true")
        setShow(false)
    }

    return <>
        <MyModal
            isError
            isPending={ pending }
            show={ show }
            label="Date de formation incorrecte"
            okButton={ <AtlasButton destructive onClick={ () => handleDelete() }>Supprimer le brouillon</AtlasButton> }
            cancelButton={ <AtlasButton onClick={ () => handleShowDraft() }>Voir le brouillon</AtlasButton> }
        >
            La date de début de formation doit être postérieure à la date du jour.
            Ce dossier ne pourra donc pas être finalisé.
            Vous devez le supprimer et déposer une nouvelle demande de prise en charge.
        </MyModal>
    </>
}
