/* eslint-disable @typescript-eslint/no-unused-expressions */
/*import { htmlFormat } from 'date-fns';*/
import { getClimateSchoolLink } from 'api/climateSchoolAPI'
import useApiState from 'api/useApiState'
import { RootState } from 'app/rootReducer'
import { AtlasColumns, AtlasInfo, AtlasValue, MyQuestions } from 'atlas-ds'
import { AtlasHeading } from 'atlas-ds/atlas/components/heading/AtlasHeading/AtlasHeading'
import { AtlasLogo } from 'atlas-ds/atlas/components/image/AtlasLogo/AtlasLogo'
import { AtlasFlex } from 'atlas-ds/atlas/components/layout/AtlasFlex/AtlasFlex'
import { AtlasSpacer } from 'atlas-ds/atlas/components/layout/AtlasSpacer/AtlasSpacer'
import { AtlasLoading } from 'atlas-ds/atlas/components/loading/AtlasLoading/AtlasLoading'
import { AtlasLink } from 'atlas-ds/atlas/components/nav/AtlasLink/AtlasLink'
import { AtlasHtml } from 'atlas-ds/atlas/components/value/AtlasHtml/AtlasHtml'
import { MyContainer } from 'atlas-ds/my/components/layout/MyContainer/MyContainer'
import { ToastService } from 'components/BandeauAlert'
import PageHeader from "components/PageHeader"
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { isEligible } from './index'

export default function MonClimateSchool() {

    const { entreprise } = useSelector((state: RootState) => state.contextState)
    
    // Aucune idée de pourquoi on a besoin de ce useRef, mais sinon le withGetClimateSchoolLink est appelé deux fois (strict mode ?)
    // https://stackoverflow.com/a/75126229/378129
    const initialized = useRef('')
    const [linkState, withGetClimateSchoolLink] = useApiState(getClimateSchoolLink)
    useEffect(() => {
        if (isEligible(entreprise) && entreprise?.SIREN__c && entreprise?.SIREN__c !== initialized.current) {
            initialized.current = entreprise?.SIREN__c
            withGetClimateSchoolLink(entreprise!.SIREN__c!)
        }
    }, [entreprise?.SIREN__c])

    const htmlToText = (html: string) => html
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<li>/g, `\n• `)
        .replace(/<\/ul>/g, `\n`)
        .replace(/<[^>]+>/g, '');

    const subject1 = `Engageons-nous ensemble pour la transition écologique ! 🌿`

    const message1 = "Bonjour,<br/><br/>"
        + "Dans le cadre de notre engagement pour un avenir plus durable, nous avons le plaisir de t’inviter à te créer un compte sur la plateforme de sensibilisation à la transition écologique climateschoolAtlas.<br/><br/>"
        + "climateschoolAtlas, c’est une expérience de formation ludique et interactive, 100% en ligne, pour mieux saisir ces enjeux complexes et se préparer à agir.<br/><br/>"
        + "<strong>🎯 Pourquoi s’inscrire ?</strong>"
        + "<ul>"
        + "<li>Contenus ludiques : Apprends en quelques minutes par jour grâce à des vidéos dynamiques.</li>"
        + "<li>Impact concret : Découvre des actions simples à mettre en place dans ta vie professionnelle et personnelle.</li>"
        + "<li>Engagement collectif : Fais partie d'une communauté engagée et partage tes connaissances avec tes collègues.</li>"
        + "</ul><br/>"
        + `🔗 Pour créer ton compte et commencer dès maintenant, clique sur ce lien : <a href="${linkState.value?.link}">${linkState.value?.link}</a><br/><br/>`
        + "Une fois que tu as créé ton compte, assure-toi d'ajouter climateschoolAtlas à tes favoris pour pouvoir y accéder rapidement.<br/><br/>"
        + "Notre planète a besoin de nous tous. Ensemble, faisons la différence !<br/><br/>"
        + "Merci pour ton engagement,<br/>"
        + "Cordialement,<br/>"

    const subject2 = `Nouveau rappel : Rejoins-nous pour la transition écologique ! 🌿`

    const message2 = "Bonjour,<br/><br/>"
        + "Si tu n’as pas créé ton compte sur la plateforme de <strong>sensibilisation à la transition écologique climateschoolAtlas</strong>, il est encore temps de nous rejoindre et de participer à cette démarche collective pour un avenir plus durable.<br/><br/>"
        + "📚 <strong>1 minute pour t'inscrire et commencer à explorer les modules interactifs !</strong><br/><br/>"
        + "climateschoolAtlas te permettra d'acquérir de nouvelles compétences et de découvrir des actions concrètes que tu pourras appliquer, aussi bien au travail que dans ta vie quotidienne.<br/><br/>"
        + "🎯 <strong>Pourquoi s'inscrire ?</strong>"
        + "<ul>"
        + "<li>Un contenu ludique et accessible, à ton rythme.</li>"
        + "<li>Des connaissances utiles pour contribuer à la protection de l’environnement.</li>"
        + "<li>Un engagement simple mais important pour un avenir plus vert.</li>"
        + "</ul><br/>"
        + `🔗 <strong>Crée ton compte ici</strong> : <a href="${linkState.value?.link}">${linkState.value?.link}</a><br/><br/>`
        + "Une fois que tu as créé ton compte, assure-toi d'ajouter climateschoolAtlas à tes favoris pour pouvoir y accéder rapidement.<br/><br/>"
        + "Nous comptons sur ta participation pour faire la différence !<br/><br/>"
        + "Merci pour ton engagement,<br/><br/>"
        + "Cordialement,<br/>"

    const onCopy = (content: string) => {
        ToastService.showToast({
            type: "success",
            label: "Copie effectuée",
            content
        })
    }

    return (
        <>
            {/* La largeur du logo permet d'occuper la même hauteur que les PageHeader classiques */}
            {/* Solution basique, qui pourra être intégrée au DS si ce cas se produit de nouveau */}
            <PageHeader logo={<AtlasLogo logo="climate-school" width="18.75rem" />}>
                climateschoolAtlas
            </PageHeader>

            <AtlasHeading tag="h2" size="m">
                Transition écologique : un défi, une opportunité pour tous
            </AtlasHeading>

            { isEligible(entreprise)
                ? <>
                    <AtlasHtml>
                        <p>climateschoolAtlas est une expérience de sensibilisation en ligne pour engager et sensibiliser vos salariés sur la transition écologique.</p>
                        <p>Le parcours est composé de 5 temps en microlearning pédagogiques et interactifs.</p>
                    </AtlasHtml>

                    <AtlasSpacer size="l" />

                    <AtlasColumns>
                        <div>
                            <AtlasHeading tag="h3" size="m">Les étapes à suivre</AtlasHeading>
                            <AtlasHeading tag="h4" size="s">1. Je copie/colle le message d'invitation et j'envoie l'e-mail à tous les salariés pour qu'ils se créent leur compte sur climateschoolAtlas (moi y compris).</AtlasHeading>

                            { linkState.error
                                ? <AtlasInfo title="Erreur de récupération du lien d'invitation" type="error">
                                    { linkState.error }
                                </AtlasInfo> : <AtlasLoading loading={linkState.pending}>
                                    <AtlasFlex column gap="m">
                                        <MyContainer colored>
                                            <AtlasLoading.Loaders>
                                                <AtlasValue label="Sujet du mail"> </AtlasValue>
                                            </AtlasLoading.Loaders>

                                            <AtlasValue
                                                label="Sujet du mail"
                                                copyable={{text: subject1}}
                                                onCopy={() => onCopy("Le sujet du mail a bien été copié dans votre presse-papier")}
                                            >{subject1}</AtlasValue>
                                        </MyContainer>

                                        <MyContainer colored>
                                            <AtlasLoading.Loaders>
                                                <AtlasValue label="Contenu du mail"> </AtlasValue>
                                            </AtlasLoading.Loaders>

                                            <AtlasValue
                                                label="Contenu du mail"
                                                copyable={{html: message1, text: htmlToText(message1)}}
                                                onCopy={() => onCopy("Le contenu du mail a bien été copié dans votre presse-papier")}
                                            >
                                                <AtlasHtml>{message1}</AtlasHtml>
                                            </AtlasValue>
                                        </MyContainer>
                                    </AtlasFlex>
                                </AtlasLoading>
                            }

                            <AtlasSpacer size="xl" />

                            <AtlasHeading tag="h4" size="s">2. Je fais une relance à J+10 en copiant le message ci-dessous:</AtlasHeading>

                            { linkState.error
                                ? <AtlasInfo title="Erreur de récupération du lien d'invitation" type="error">
                                    { linkState.error }
                                </AtlasInfo> : <AtlasLoading loading={linkState.pending}>
                                    <AtlasFlex column gap="m">
                                        <MyContainer colored>
                                            <AtlasLoading.Loaders>
                                                <AtlasValue label="Sujet du mail"> </AtlasValue>
                                            </AtlasLoading.Loaders>

                                            <AtlasValue
                                                label="Sujet du mail"
                                                copyable={{text: subject2}}
                                                onCopy={() => onCopy("Le sujet du mail a bien été copié dans votre presse-papier")}
                                            >{subject2}</AtlasValue>
                                        </MyContainer>

                                        <MyContainer colored>
                                            <AtlasLoading.Loaders>
                                                <AtlasValue label="Contenu du mail"> </AtlasValue>
                                            </AtlasLoading.Loaders>

                                            <AtlasValue
                                                label="Contenu du mail"
                                                copyable={{html: message2, text: htmlToText(message2)}}
                                                onCopy={() => onCopy("Le contenu du mail a bien été copié dans votre presse-papier")}
                                            >
                                                <AtlasHtml>{message2}</AtlasHtml>
                                            </AtlasValue>
                                        </MyContainer>
                                    </AtlasFlex>
                                </AtlasLoading>
                            }
                        </div>

                        <div>
                            <AtlasHeading tag="h3" size="m">Liens et documents</AtlasHeading>
                            <MyContainer colored>
                                <AtlasFlex gap="m" column>
                                    <AtlasLink
                                        level={2}
                                        external
                                        href="https://climateschoolatlas.opco-atlas.fr/fr/opco-atlas"
                                    >Voir la présentation du service</AtlasLink>
                                    <AtlasLink
                                        level={2}
                                        external
                                        href="https://www.opco-atlas.fr/sites/default/files/medias/files/2024/11/Guide%20utilisateur%20Salarie%CC%81_0.pdf"
                                    >Télécharger le guide utilisateur Salarié</AtlasLink>
                                </AtlasFlex>
                            </MyContainer>

                            <AtlasSpacer size="xxl" />

                            <AtlasHeading tag="h3" size="m">F.A.Q.</AtlasHeading>
                            <MyQuestions questions={[
                                {
                                    id: "quoi",
                                    label: <>climateschoolAtlas, de quoi s’agit-il&nbsp;?</>,
                                    answer: <>
                                        <p>climateschoolAtlas est une plateforme de sensibilisation en micro-learning vidéo sur les enjeux environnementaux, conçue pour accompagner la stratégie RSE des entreprises des secteurs financiers et du conseil.</p>
                                        <p>Comprendre les enjeux environnementaux est un préalable indispensable à l’action. D’où le défi que nous voulons relever : rendre cet apprentissage simple, ludique, et… passionnant ! C’est pour cette raison qu’Atlas s’est appuyé sur l’expertise de Climate School, notre partenaire pour ce service.</p>
                                        <p>Climate School suit une même méthode pour tous ses cours : des arguments scientifiques éprouvés, des cas d’usage réels, une réalisation soignée et peaufinée par des experts de l’audiovisuel. Tous les contenus sont originaux, conçus par son équipe, qui travaille en collaboration avec des experts, des chercheurs, des scientifiques, des professeurs etc.</p>
                                        <p>Le contenu de formations a été spécifiquement créé pour les entreprises Atlas sur la base du catalogue Climate School (focus métiers de nos branches, parcours spécifiques).</p>
                                    </>
                                },
                                {
                                    id: "difference",
                                    label: <>Quelle est la différence entre sensibilisation et formation&nbsp;?</>,
                                    answer: <>
                                        <p>La sensibilisation et la formation sont deux approches distinctes pour informer et préparer un public sur un sujet particulier, mais elles diffèrent dans leurs objectifs, leur profondeur et leur méthode.</p>
                                        <h4>Sensibilisation</h4>
                                        <p><strong>Objectif</strong> : Faire prendre conscience d'un problème, d'un enjeu ou d'un sujet. Il s'agit d'attirer l'attention sur une thématique pour encourager les personnes à y réfléchir ou à y réagir.</p>
                                        <p><strong>Contenu</strong> : Généralement moins approfondi, il présente les concepts de base, les faits saillants ou les risques.</p>
                                        <h4>Formation</h4>
                                        <p><strong>Objectif</strong> : Transmettre des connaissances et développer des compétences concrètes. La formation vise à préparer activement les participants à appliquer les connaissances acquises dans des situations pratiques.</p>
                                        <p><strong>Contenu</strong> : Plus approfondi et détaillé. La formation inclut souvent des instructions précises, des méthodes, des exercices pratiques et des évaluations pour s'assurer que les participants maîtrisent bien les compétences enseignées.</p>
                                    </>
                                },
                                {
                                    id: "qui",
                                    label: <>À qui s'adresse climateschoolAtlas&nbsp;?</>,
                                    answer: <p>Le contenu climateschoolAtlas est accessible et s'adresse à tous les collaborateurs qui exercent une activité dans les secteurs du Conseil, Banque / Finance, Expertise comptable / Commissariat, Assurances, et dont l'entreprise est adhérente à <a target="_blank" href="https://www.opco-atlas.fr/">l'Opco Atlas</a>.</p>
                                },
                                {
                                    id: "accessible",
                                    label: <>Le contenu de la plateforme est-il accessible&nbsp;?</>,
                                    answer: <p>Toutes les vidéos sont sous-titrées et nous veillons aux contrastes pour accroître la visibilité.</p>
                                },
                                {
                                    id: "langues",
                                    label: <>Quelles sont les langues disponibles&nbsp;?</>,
                                    answer: <p>L’ensemble des vidéos sont tournées en français et anglais. Des sous-titres sont disponibles en espagnol, italien, chinois, polonais et portugais.</p>
                                },
                                {
                                    id: "temps",
                                    label: <>Combien de temps dure le parcours de sensibilisation&nbsp;?</>,
                                    answer: <>
                                        <p>Le parcours est composé de 5 Temps, avec à l’intérieur plusieurs cours. Le Temps 3 est optionnel, il est réservé aux collaborateurs du secteur Banque/Finance.</p>
                                        <p>Il faut prévoir environ 7 heures de sensibilisation, à raison d’1 heure par jour par exemple.</p>
                                    </>
                                },
                                {
                                    id: "gratuit",
                                    label: <>L’accès à climateschoolAtlas est-il gratuit&nbsp;?</>,
                                    answer: <p>L’accès est gratuit pour les entreprises de 1 à 49 salariés inclus, dans la limite de 49 licences (une pour chaque collaborateur).</p>
                                },
                                {
                                    id: "lien",
                                    label: <>Je n’arrive pas à récupérer mon lien, pouvez-vous m’aider&nbsp;?</>,
                                    answer: <p>Pour nous contacter, <a target="_blank" href="https://www.opco-atlas.fr/contact.html">cliquez ici</a>.</p>
                                },
                                {
                                    id: "complement",
                                    label: <>En complément de la sensibilisation, je souhaite former mes collaborateurs à la transition écologique.</>,
                                    answer: <p>Atlas propose une offre d’accompagnement spécifique à la transition écologique. Pour plus d’information, rendez-vous sur notre site <a target="_blank" href="https://www.opco-atlas.fr/entreprise/transition-ecologique-nouvelle-mission-nouvel-accompagnement.html">Opco Atlas</a>.</p>
                                }
                            ]}></MyQuestions>
                        </div>
                    </AtlasColumns>
                </>
                : <AtlasInfo
                    type="error"
                    title="Désolé, climateschoolAtlas n'est pas disponible pour votre entreprise"
                    link={<Link to="/">Retour à l'accueil</Link>}
                >
                    Ce service n'est disponible que pour les entreprises de moins de 50 salariés.
                </AtlasInfo>
            }
        </>
    )
}
