(Symbol as any).metadata ??= Symbol.for("Symbol.metadata")

import { ComponentType, ReactNode } from "react"
import { SchemaForType } from "@dsid-opcoatlas/yop"
import { ObserverMetadata } from "./observers"
import { ValidationContextProperty } from "./validation"
import { FormContextFunctionProperty, FormContextProperty } from "./FormFields"
import { DTOProperty } from "./mapping"
import { Path } from "./Path"

export type ClassConstructor<T = any> = new (...args: any[]) => T

export type AnyFieldMetadata<ParentClass, FieldType> = {
    schema?: SchemaForType<FieldType> | (() => SchemaForType<FieldType>)
    required?: ValidationContextProperty<ParentClass, boolean, FieldType>
    ignored?: ValidationContextProperty<ParentClass, boolean, FieldType>
  
    clear?: boolean
}

export type InputFieldMetadata<ParentClass, FieldType> = {
    input?: FormContextFunctionProperty<ParentClass, FieldType, ComponentType<any> | undefined>
    
    label?: FormContextProperty<ParentClass, FieldType, string | JSX.Element | undefined>
    tooltip?: FormContextProperty<ParentClass, FieldType, string | JSX.Element | undefined>
    disabled?: FormContextProperty<ParentClass, FieldType, boolean | undefined>
    visible?: FormContextProperty<ParentClass, FieldType, boolean | undefined>
    suffix?: FormContextProperty<ParentClass, FieldType, string | JSX.Element | undefined>
    size?: FormContextProperty<ParentClass, FieldType, "full" | "short" | "long" | undefined>
    alone?: FormContextProperty<ParentClass, FieldType, boolean | undefined>
    message?: FormContextProperty<ParentClass, FieldType, { type: "error" | "warning" | "info", content: ReactNode } | undefined>

    dto?: DTOProperty<FieldType>
}

export type MinMaxFieldMetadata<ParentClass, FieldType, MinMaxtype> = {
    min?: ValidationContextProperty<ParentClass, MinMaxtype, FieldType>
    max?: ValidationContextProperty<ParentClass, MinMaxtype, FieldType>
}

export type StringFieldMetadata<ParentClass> =
    AnyFieldMetadata<ParentClass, string | null> &
    InputFieldMetadata<ParentClass, string | null> &
    MinMaxFieldMetadata<ParentClass, string | null, number | null | undefined>

export type NumberFieldMetadata<ParentClass> =
    AnyFieldMetadata<ParentClass, number | null> &
    InputFieldMetadata<ParentClass, number | null> &
    MinMaxFieldMetadata<ParentClass, number | null, number | null | undefined>

export type BooleanFieldMetadata<ParentClass> =
    AnyFieldMetadata<ParentClass, boolean | null> &
    InputFieldMetadata<ParentClass, boolean | null>

export type DateFieldMetadata<ParentClass> =
    AnyFieldMetadata<ParentClass, Date | null> &
    InputFieldMetadata<ParentClass, Date | null> &
    MinMaxFieldMetadata<ParentClass, Date | null, Date | null | undefined>


export type FileFieldMetadata<ParentClass> =
    AnyFieldMetadata<ParentClass, File | null> &
    InputFieldMetadata<ParentClass, File | null> &
    MinMaxFieldMetadata<ParentClass, File | null, number | null | undefined>

export type ArrayFieldMetadata<ParentClass, ElementType> =
    AnyFieldMetadata<ParentClass, ElementType[] | null> &
    MinMaxFieldMetadata<ParentClass, ElementType[] | null, number | null | undefined> &
    {
        type: ClassConstructor<ElementType>
        elements?: AnyFieldMetadata<ParentClass, ElementType>
        dto?: DTOProperty<ElementType[] | null>
        overrides?: ClassOverride<ClassConstructor<ElementType>>
    }


export type ObjectFieldMetadata<ParentClass, ObjectType extends object> =
    AnyFieldMetadata<ParentClass, ObjectType | null> &
    {
        type: ClassConstructor<ObjectType>
        dto?: DTOProperty<ObjectType | null>
        overrides?: ClassOverride<ClassConstructor<ObjectType>>    
    }


export type ArrayItemType<ArrayType> = ArrayType extends Array<infer ItemType> ? ItemType : never

export type PropertyType = "string" | "number" | "boolean" | "date" | "file" | "array" | "object" | undefined

export type FieldMetadata<ParentClass, FieldType> =
    AnyFieldMetadata<ParentClass, FieldType> &
    InputFieldMetadata<ParentClass, FieldType> &
    MinMaxFieldMetadata<ParentClass, FieldType, any> &
    {
        kind: PropertyType
        type?: ClassConstructor

        overrides?: ClassOverride<ClassConstructor<FieldType>>
        observers?: Map<string, ObserverMetadata<FieldType>>
        contextConsumer?: (context: any) => any
    }

export type ClassFieldsMetadata<ParentClass, T> = Record<keyof T, FieldMetadata<ParentClass, T>>

export const elementsSymbol = Symbol('elements')

export function getElementsMetadataForField<ParentClass, T>(context: ClassFieldDecoratorContext<ParentClass, T>, clear?: boolean): FieldMetadata<ParentClass, T> {
    if (typeof context.name === 'symbol')
        throw new Error('Symbol fields are not supported by Elements decorators')

    const metadata = context.metadata
    if (!Object.hasOwnProperty.bind(metadata)(elementsSymbol))
        metadata[elementsSymbol] = Object.create(metadata[elementsSymbol] ?? null)
    
    const elementsMetadata = metadata[elementsSymbol] as ClassFieldsMetadata<ParentClass, T>
    const fieldName = context.name as keyof T
    if (!Object.hasOwnProperty.bind(elementsMetadata)(fieldName))
        elementsMetadata[fieldName] = clear === true ? { kind: undefined } : { ...elementsMetadata[fieldName] }
    return elementsMetadata[fieldName]
}

export function assignDefined(dst: object, src: object) {
    return Object.assign(dst, Object.fromEntries(Object.entries(src).filter(([_, v]) => v !== undefined)))
}

export function fieldMetadataDecorator<ParentClass, FieldType>(value: FieldMetadata<ParentClass, FieldType>) {
    return function _(_: any, context: ClassFieldDecoratorContext<ParentClass, FieldType>) {
        const fieldMetadata = getElementsMetadataForField<ParentClass, FieldType>(context, value.clear)
        assignDefined(fieldMetadata, value)
    }
}

export type ClassConstructorType<ClassType> = ClassType extends ClassConstructor<infer Class> ? Class : never
export type ClassOverride<T extends ClassConstructor> = Partial<{
    [P in keyof ClassConstructorType<T>]: Omit<FieldMetadata<ClassConstructorType<T>, ClassConstructorType<T>[P]>, "kind">
}>

export function classFieldsMetadataDecorator<T extends ClassConstructor>(fieldValues: ClassOverride<T>, merge?: (dst: any, src: any) => any) {
    return function _(_: any, context: ClassDecoratorContext<T>) {
        const metadata = context.metadata
        if (!Object.hasOwnProperty.bind(metadata)(elementsSymbol))
            metadata[elementsSymbol] = Object.create(metadata[elementsSymbol] ?? null)
        
        const elementsMetadata = metadata[elementsSymbol] as ClassFieldsMetadata<any, ClassConstructorType<T>>
        for (const fieldName in fieldValues) {
            const value = fieldValues[fieldName]!
            if (!Object.hasOwnProperty.bind(elementsMetadata)(fieldName))
                elementsMetadata[fieldName] = { ...elementsMetadata[fieldName] }
            merge?.(value, elementsMetadata[fieldName])
            assignDefined(elementsMetadata[fieldName], value)
        }
    }
}

const metadataCache = new WeakMap<ClassConstructor, ClassFieldsMetadata<any, any>>()
const ObjectPrototype = Object.getPrototypeOf(Object)

export function getClassConstructor<T = unknown>(model: ClassConstructor, path: Path) {
    let currentModel = model
    for (const pathElement of path) {
        const metadata = getMetadata(currentModel)
        const field = metadata[pathElement.property]
        if (field.kind === "array")
            currentModel = field.type!
        else if (field.kind === "object")
            currentModel = field.type!
        else
            return undefined
    }
    return currentModel as ClassConstructor<T>
}

export function getMetadata<T>(model: ClassConstructor<T>, path?: string[]): ClassFieldsMetadata<any, any> {
    const key = model
    
    let metadata = metadataCache.get(key)
    if (metadata == null) {
        while (model != null && model !== ObjectPrototype) {
            metadata = { ...(model as any)[Symbol.metadata]?.[elementsSymbol], ...(metadata ?? {}) }
            model = Object.getPrototypeOf(model)
        }
        metadataCache.set(key, metadata!)
    }

    if (path != null && path.length > 0) {
        const field = metadata![path[0]]
        if (field?.kind === "object")
            metadata = getMetadata(field.type!, path.slice(1))
        else if (field?.kind === "array")
            metadata = getMetadata(field.type!, path.slice(1))
        else
            metadata = {}
    }

    return metadata as ClassFieldsMetadata<any, T>
}

