import { PieceTypeDTO } from 'api/documentAPI'
import { ETAT_DOSSIER_ACCORDE, ETAT_DOSSIER_CLOTURE, ETAT_DOSSIER_RUPTURE } from 'api/references'
import { AtlasInfo } from 'atlas-ds'
import { parseDate } from 'components/format/Format'
import { isPast } from 'date-fns'
import { DetailDossierExtension } from 'pages/detail-dossier/DetailDossierContrat'
import { DetailDossierNumeroDepot } from 'pages/detail-dossier/DetailDossierNumeroDepot'
import DossierCertificatRealisation from 'pages/detail-dossier/DossierCertificatRealisation'
import DossierInformationPoursuitePostRupturePrestataire from 'pages/detail-dossier/DossierInformationPoursuitePostRupturePrestataire'
import { AppDossierModule } from 'pages/detail-dossier/modules'
import { actionsEntrepriseAnnulerDossier, actionsEntrepriseCorrectionAvenant, actionsEntrepriseDeposerFacture, actionsEntrepriseRompreDossier } from 'pages/detail-dossier/modules/entreprise'
import { actionsPrestataireDeposerProforma, actionsPrestataireRompreDossier, actionsPrestataireRuptureDeposerFacture } from 'pages/detail-dossier/modules/prestataire'
import { MetadataEditCAPP } from 'pages/dossier-capp/CAPPEditFormMetadata'
import { MetadataCAPP } from 'pages/dossier-capp/CAPPFormMetadata'
import { CAPPMAPRuptureFormMetadata, CAPPRuptureFormMetadata } from 'pages/dossier-capp/CAPPRuptureFormMetadata'
import { MetadataCAPPMAP } from './CAPPMAPFormMetadata'


const dossierCAPPModule: AppDossierModule = {
    name: 'CAPP',
    dispositifId: MetadataCAPP.dispositif,
    metadata: { 
        entreprise: { create: MetadataCAPP, edit: MetadataEditCAPP },
        prestataire: { create: MetadataCAPPMAP },
    },
    extensions: {
        DetailDossierContrat: {
            infos: <DetailDossierNumeroDepot />,
        },
        entreprise: {
            DetailDossierContrat: {
                actions: (dossier, actions) => {
                    actionsEntrepriseCorrectionAvenant(dossier, actions)
                    
                    actionsEntrepriseRompreDossier(dossier, CAPPRuptureFormMetadata, actions)
                    actionsEntrepriseAnnulerDossier(dossier, actions)
                    actionsEntrepriseDeposerFacture(dossier, actions)
                },
            },
        } as DetailDossierExtension,
        prestataire: {
            DetailDossierContrat: {
                actions: (dossier, actions) => {
                    actionsPrestataireDeposerProforma(dossier, actions)
                    actionsPrestataireRuptureDeposerFacture(dossier, actions, true)
                    if (dossier.EtatDossierFO__c !== ETAT_DOSSIER_RUPTURE && !dossier.RuptureCA__c) {
                        actionsPrestataireRompreDossier(dossier, CAPPMAPRuptureFormMetadata, actions)
                    }

                    if (dossier.RuptureCA__c) {
                        actions.push(<DossierInformationPoursuitePostRupturePrestataire key="deposerPoursuite" detail={ dossier } />)
                    }
                    
                    if (dossier.DateFinContrat__c || dossier.DateRuptureCA__c) {
                        const dateFinContrat = dossier.DateFinContrat__c ? parseDate(dossier.DateFinContrat__c) : null;
                        const dateRupture = dossier.DateRuptureCA__c ? parseDate(dossier.DateRuptureCA__c) : null;
                        
                        // On vérifie si l'une des deux dates est antérieure ou égale à aujourd'hui
                        if ((dateFinContrat && isPast(dateFinContrat)) || (dateRupture && isPast(dateRupture) && dossier.RuptureCA__c)) {
                            if (dossier.EtatDossierFO__c === ETAT_DOSSIER_ACCORDE || 
                                dossier.EtatDossierFO__c === ETAT_DOSSIER_CLOTURE || 
                                dossier.EtatDossierFO__c === ETAT_DOSSIER_RUPTURE) {
                                
                                actions.push(<DossierCertificatRealisation key="certificatRealisation" detail={dossier} />)
                            }
                        }
                    }
                },
            },
        } as DetailDossierExtension,
    } as DetailDossierExtension,
    documentTypeFilter: (docType: PieceTypeDTO) => {
        return docType.nom !== 'CERFA' && docType.nom !== 'AVENANT AU CONTRAT' && docType.nom !== 'COPIE LETTRE RUPTURE CONTRAT'
    },
    documentWarningMessage: <AtlasInfo type="warning" title="Le CERFA a été automatiquement généré et joint à votre demande">
        <p>Merci de ne pas ajouter le CERFA original signé dans les pièces. En effet, les informations transmises sur myAtlas sont réputées conformes au contrat conclu avec l'apprenti(e).</p>
        <details>
            <summary>Une modification à transmettre ?</summary>
            <p>Pour toute modification de la demande à transmettre à l’Opco Atlas, merci d’utiliser le formulaire dédié, accessible dans la partie « Actions » en haut à droite de cette page :</p>
            <ul>
                <li>Avenant/correction : formulaire « Transmettre avenant / Corriger contrat »</li>
                <li>Rupture du contrat : formulaire « Informer la rupture du contrat »</li>
                <li>Annulation du contrat : formulaire « Annuler le dossier »</li>
            </ul>
        </details>
    </AtlasInfo>,
}

export default dossierCAPPModule