import {
  AtlasButtonProps,
  AtlasLinkProps,
  AtlasLoading,
  MyAction,
  MyActionProps,
} from "atlas-ds";
import classNames from "classnames";
import { Dispatch, SetStateAction, useState } from "react";

/**
 * Événement de déplacement d'une carte vers une nouvelle colonne et/ou position
 */
export interface MyKanbanCardMoveEvent {
  /**
   * L'id de la carte déplacée
   */
  cardId: string;
  /**
   * La colonne d'où vient la carte déplacée
   */
  fromColumnId: string;
  /**
   * La colonne dans laquelle la carte est déplacée
   */
  toColumnId: string;
  /**
   * L'id de la carte avant laquelle la carte est déplacée. Si cette valeur
   * n'est pas définie, la carte est placée en dernier.
   */
  beforeCardId?: string;
}

/**
 * Une carte
 */
export interface MyKanbanCard {
  /**
   * L'identifiant unique de la carte
   */
  id: string;
  /**
   * L'élément carte lui-même
   */
  card: React.ReactElement<MyActionProps>;
}

/**
 * Une colonne de carte
 */
export interface MyKanbanColumn {
  /**
   * L'identifiant unique de la colonne
   */
  id: string;
  /**
   * Le label de la colonne
   */
  label: string;
  /**
   * Le label du bouton qui permet de déplacer une carte vers cette colonne
   */
  moveLabel: string;
  /**
   * Un emoji d'ornement pour le label de la colonne
   */
  emoji: string;
  /**
   * Les cartes de la colonne
   */
  cards: {
    id: string;
    card: React.ReactElement<MyActionProps>;
  }[];
}

export interface MyKanbanCardPosition {
  /**
   * L'identifiant de la carte
   */
  id: string;
  /**
   * L'identifiant de la colonne dans laquelle se trouve la carte
   */
  columnId: string;
}

export interface MyKanbanProps {
  /**
   * Les colonnes de cartes
   */
  columns: MyKanbanColumn[];
  /**
   * Une action à éxécuter lorsqu'une carte est déplacée.
   * Le contexte appelant doit réorganiser la donnée pour que le déplacement ait
   * un effet visible (autrement dit, il ne se passe rien dans Storybook, c'est
   * normal !).
   */
  onCardMove: (moveEvent: MyKanbanCardMoveEvent) => void;
  /**
   * Une action à éxécuter lorsqu'une carte est retirée.
   * Le contexte appelant doit réorganiser la donnée pour que la suppression ait
   * un effet visible (autrement dit, il ne se passe rien dans Storybook, c'est
   * normal !).
   */
  onCardRemove: (cardId: string) => void;
  /**
   * Un lien ou bouton permettant d'ajouter de nouvelles actions
   */
  addCta: React.ReactElement<AtlasLinkProps | AtlasButtonProps>;
  /**
   * Un lien vers le kanban complet. Si ce paramètre est passé, le kanban est
   * présenté dans une version non interactive avec un lien vers le tableau
   * complet.
   */
  previewLink?: React.ReactElement<AtlasLinkProps>;
}

/**
 * Un tableau Kanban ré-organisable
 */
export function MyKanban(props: MyKanbanProps) {
  const hasCards = !!props.columns.find((column) => column.cards.length > 0);
  const isInteractive = !props.previewLink;

  let [draggedCard, setDraggedCard] = useState<MyKanbanCardPosition | null>();

  const addCta = {
    ...props.addCta,
    props: { ...props.addCta.props, level: 2, icon: "plus" },
  } as React.ReactElement<AtlasLinkProps | AtlasButtonProps>;

  return (
    <div
      className={classNames("my-kanban", {
        "my-kanban--dragging": !!draggedCard,
        "my-kanban--preview": !!props.previewLink,
      })}
    >
      <div className="my-kanban__columns">
        {props.columns.map((column, index) => (
          <MyKanban.InternalColumn
            {...props}
            key={`column-${column.id}`}
            index={index}
            column={column}
            hasCards={hasCards}
            isInteractive={isInteractive}
            setDraggedCard={setDraggedCard}
            draggedCard={draggedCard}
            addCta={index === 0 ? addCta : undefined}
          />
        ))}
      </div>
      {props.previewLink && (
        <div className="my-kanban__previewLink">{props.previewLink}</div>
      )}
    </div>
  );
}

interface MyKanbanInternalColumnProps extends Omit<MyKanbanProps, "addCta"> {
  /**
   * La colonne
   */
  column: MyKanbanColumn;
  /**
   * L'index de la colonne
   */
  index: number;
  /**
   * Le Kanban possède contient-il des cartes ?
   */
  hasCards: boolean;
  /**
   * Le Kanban est-il interactif ?
   */
  isInteractive: boolean;
  /**
   * Action de mise à jour de la carte actuellement déplacée
   */
  setDraggedCard: Dispatch<
    SetStateAction<MyKanbanCardPosition | null | undefined>
  >;
  /**
   * La carte actuellement déplacée
   */
  draggedCard?: MyKanbanCardPosition | null;
  /**
   * Un lien ou bouton permettant d'ajouter de nouvelles actions
   */
  addCta?: React.ReactElement<AtlasLinkProps | AtlasButtonProps>;
}

/**
 * Une colonne du Kanban. Usage interne uniquement.
 */
MyKanban.InternalColumn = (props: MyKanbanInternalColumnProps) => {
  const ColumnInteractiveElements = () => {
    let [isDropTarget, setIsDropTarget] = useState(false);

    const onDragEnter = () => setIsDropTarget(true);
    const onDragLeave = () => setIsDropTarget(false);

    const onDrop = () => {
      if (props.draggedCard) {
        props.onCardMove({
          cardId: props.draggedCard.id,
          fromColumnId: props.draggedCard.columnId,
          toColumnId: props.column.id,
        });
      }

      setIsDropTarget(false);
      props.setDraggedCard(null);
    };

    return (
      <>
        <div
          className={classNames("my-kanban__columnDrop", {
            "my-kanban__columnDrop--dropTarget": isDropTarget,
          })}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={onDrop}
        >
          {props.index > 0 && props.hasCards && (
            <div className="my-kanban__columnDropInstruction">
              <p>Glisser et déposer une carte action</p>
            </div>
          )}
          <div className="my-kanban__columnDropInner"></div>
        </div>

        {props.addCta && (
          <div className="my-kanban__addCta">{props.addCta}</div>
        )}
      </>
    );
  };

  return (
    <div className="my-kanban__column" key={props.column.id}>
      <div className="my-kanban__columnLabel">
        <span aria-hidden="true">{props.column.emoji}</span>{" "}
        {props.column.label}
      </div>
      <div className="my-kanban__columnCards">
        <AtlasLoading.Loaders>
          <div className="my-kanban__columnLoaders">
            {props.index == 0 && <AtlasLoading.Loader />}
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
          </div>
        </AtlasLoading.Loaders>
        {props.column.cards.map((card) => (
          <MyKanban.InternalCard
            {...props}
            key={`card-${card.id}`}
            card={card}
          />
        ))}
        {props.isInteractive && <ColumnInteractiveElements />}
      </div>
    </div>
  );
};

interface MyKanbanInternalCardProps extends MyKanbanInternalColumnProps {
  /**
   * La carte
   */
  card: MyKanbanCard;
}

/**
 * Une carte du Kanban. Usage interne uniquement.
 */
MyKanban.InternalCard = (props: MyKanbanInternalCardProps) => {
  const [isDropTarget, setIsDropTarget] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);

  if (props.isInteractive) {
    const onDragStart = () =>
      props.setDraggedCard({ id: props.card.id, columnId: props.column.id });

    const onDragEnd = () => props.setDraggedCard(null);

    const onDragEnter = () =>
      setIsDropTarget(props.draggedCard?.id !== props.card.id);

    const onDragLeave = () => setIsDropTarget(false);

    const onDrop = () => {
      setIsDropTarget(false);

      if (props.draggedCard) {
        props.onCardMove({
          cardId: props.draggedCard.id,
          fromColumnId: props.draggedCard.columnId,
          beforeCardId: props.card.id,
          toColumnId: props.column.id,
        });
      }

      setIsDropTarget(false);
      props.setDraggedCard(null);
    };

    const onCardMenuRemove = () => {
      props.onCardRemove(props.card.id);
      setIsLeaving(true);
    };

    const onCardMenuMove = (toColumnId: string) => {
      props.onCardMove({
        cardId: props.card.id,
        fromColumnId: props.column.id,
        toColumnId,
      });
    };

    return (
      <div
        key={props.card.id}
        className={classNames("my-kanban__card", {
          "my-kanban__card--dropTarget": isDropTarget,
          "my-kanban__card--isDragged": props.draggedCard?.id === props.card.id,
        })}
        draggable={true}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={(e) => e.preventDefault()}
        onDrop={onDrop}
      >
        <MyAction
          {...props.card.card.props}
          isLeaving={isLeaving}
          menuActions={[
            {
              label: "Retirer cette action",
              ariaLabel: `Retirer ${props.card.card.props.children}`,
              iconName: "trash",
              onClick: onCardMenuRemove,
            },
            ...props.columns
              .filter((globalColumn) => globalColumn.id !== props.column.id)
              .map((globalColumn) => ({
                label: (
                  <>
                    <span aria-hidden="true">{globalColumn.emoji}</span>{" "}
                    {globalColumn.moveLabel}
                  </>
                ),
                ariaLabel: `Déplacer ${props.card.card.props.children} vers la colonne ${globalColumn.moveLabel}`,
                onClick: () => onCardMenuMove(globalColumn.id),
              })),
          ]}
        />
      </div>
    );
  } else {
    return (
      <div key={props.card.id} className="my-kanban__card">
        <MyAction {...props.card.card.props} infoHref={undefined} />
      </div>
    );
  }
};
