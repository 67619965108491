import { PieceDTO, PieceTypeDTO } from 'api/documentAPI'
import { DossierSendStatus } from 'api/dossierAPI'
import { dispatchAppEvent } from 'app/events'
import { AtlasButton } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import log from 'loglevel'
import SendModal from 'pages/dossier/SendModal'
import { useEffect, useState } from 'react'


interface DepotFichierProps {
    idParent: string
    sendDocuments: (parentId: string) => Promise<void>
    checkSendDocumentsStatus: (parentId: string) => Promise<DossierSendStatus>
    transmissionEnCours?: boolean
    refreshEvent: string
    sendSuccessMessage: string
    DepotFichierModal: any
    warningMessage?: JSX.Element
    buttonLevel?: 1 | 2 | 3 | 4;
    buttonIcon?: string;
}

export interface DepotFichierModalProps {
    idParent: string
    nom: string
    withType?: boolean
    withObligatoires?: boolean
    documentTypeFilter?: (docType: PieceTypeDTO) => boolean
    warningMessage?: JSX.Element
    isUpdating?: boolean
    uploaded?: (doc: any) => void
    close: () => void
}

export default function DepotFichier(props: DepotFichierProps) {

    const [depotFichierModal, setDepotFichierModal] = useState(false)
    const [sendPending, setSendPending] = useState(false)

    const checkSendDocumentsStatus = (retry: number = 0) => {
        setTimeout(() => {
            props.checkSendDocumentsStatus(props.idParent).then(status => {
                if (status.EnCoursTransmission__c) {
                    if (retry < 10)
                        checkSendDocumentsStatus(retry+1)
                    else {
                        log.error('Erreur dépôt fichier ' + props.idParent)
                        ToastService.showToast({
                            label: 'Documents',
                            icon: 'file',
                            type: 'error',
                            content: 'Une erreur est survenue durant l\'envoi',
                        })
                        setSendPending(false)
                    }
                } else if (status.ErreurTransmission__c) {
                    log.error('Erreur dépôt fichier ' + props.idParent + ' => ' + status.ErreurTransmission__c)
                    ToastService.showToast({
                        label: 'Documents',
                        icon: 'file',
                        type: 'error',
                        content: status.ErreurTransmission__c,
                    })
                    setSendPending(false)
                } else {
                    ToastService.showToast({
                        label: 'Documents',
                        icon: 'file',
                        type: 'success',
                        content: props.sendSuccessMessage
                    })
                    dispatchAppEvent(props.refreshEvent)
                    setSendPending(false)
                }
            })
        }, 5000)
    }

    useEffect(() => {
        if (props.transmissionEnCours)
            setSendPending(true)
    }, [props.transmissionEnCours])

    useEffect(() => {
        if (sendPending && props.idParent)
            checkSendDocumentsStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendPending])

    const openDepotFichierModal = () => {
        setDepotFichierModal(true)
    }

    const closeDepotFichierModal = (ok: boolean) => {
        setDepotFichierModal(false)
        if (ok) {
            setSendPending(true)
            props.sendDocuments(props.idParent)
        }
    }

    const documentUploaded = (_: PieceDTO) => {
        dispatchAppEvent(props.refreshEvent)
    }

    return <>
        <AtlasButton
            id="documentsBoutonAjout"
            icon={ props.buttonIcon ?? "plus" }
            onClick={openDepotFichierModal}
            level={ props.buttonLevel ?? 3 }
        >Ajouter des documents</AtlasButton>

        { depotFichierModal &&
        <props.DepotFichierModal
            idParent={props.idParent}
            isUpdating={true}
            nom=""
            withType={true}
            warningMessage={ props.warningMessage }
            uploaded={ documentUploaded }
            close={ closeDepotFichierModal }
        /> }

        <SendModal pending={sendPending} />
    </>
}
