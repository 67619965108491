import { AtlasHeading, AtlasLinkConstructor, MyPageHeader, MyPageHeaderAnchorActiveClass, MyPageHeaderAnchorLink, MyPageHeaderProps } from 'atlas-ds'
import { Helmet, HelmetProvider } from "react-helmet-async"
import FirstScrollLink from './scroll/FirstScrollLink'
import ScrollLink from './scroll/ScrollLink'

export interface PageHeaderProps extends MyPageHeaderProps {
    children: string // Cannot be node because it's needed to generate string meta data
    anchorLinks?: MyPageHeaderAnchorLink[]
    helmet?: JSX.Element[]
    logo?: JSX.Element
}

export default function PageHeader(props: PageHeaderProps) {
    const removeTags = (text: string) => text.replace(/<(.|\n)*?>/g, '')

    const linkConstructor: AtlasLinkConstructor = (props: any, content: React.ReactNode, index?: number) => {
        const constructedLinkProps = {
            ...props,
            href: '#',
            to: props.href.replace('#', ''),
            spy: true,
            smooth: true,
            offset: -136,
            duration: 500,
            activeClass: MyPageHeaderAnchorActiveClass
        }
  
        return index === 0
            ? <FirstScrollLink {...constructedLinkProps}>{ content }</FirstScrollLink>
            : <ScrollLink {...constructedLinkProps}>{ content }</ScrollLink>
    }

    return <>
        <HelmetProvider>
            <Helmet>
                <title>{ removeTags(props.children) }</title>
                { props.helmet }
            </Helmet>
        </HelmetProvider>
        { props.children && <MyPageHeader
            {...props}
            anchors={props.anchorLinks ? {
                ariaLabel: "Accès rapides",
                links: props.anchorLinks,
                linkConstructor
            }: undefined}
        >
            { props.logo ? <h1>
                { props.logo }
            </h1> : <AtlasHeading tag="h1">
                <span dangerouslySetInnerHTML={{__html: props.children }} />
            </AtlasHeading> }
        </MyPageHeader> }
    </>
}
