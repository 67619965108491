import { DATE_FORMAT, SIREN_ADH } from 'app/constants'
import store from 'app/store'
import axios, { AxiosRequestConfig } from 'axios'
import { format, startOfToday, subYears } from 'date-fns'
import { getRequestConfig } from './baseAPI'
import { findDispositifFO, findDispositifsEligiblesParBranche, findEtatDossierFO, getAllDispositifFO } from './references'
import { Entreprise } from './referencesAPI'

export interface Statistique {
    EtatDossierFO__c: string
    EtatFactureFO__c: string
    EtatLibelle__c?: string
    EtatLibelleReduit__c?: string
    Nb: number
}

export interface Statistiques {
    Statistiques: Statistique[]
}

export interface StatsDispositifValeurs {
    [index: string]: number
    Brouillon: number
    Accorde: number
    EnCours: number
    Incomplet: number
    Annule: number
    Cloture: number
    Rupture: number
}

export interface StatsDispositif {
    DispositifFO__c: string
    DispositifLibelleReduit__c?: string
    DispositifLibelle__c?: string
    NbAlertes?: number
    Statistiques: Statistique[]
}

export interface StatsDispositifs {
    Stats: StatsDispositif[]
}

export interface StatsFinances {
    MontantTTCEngagementsOF: string
    MontantFactures : string
    MontantRegles : string
}

function initStatistiquesRequest(requestConfig: AxiosRequestConfig, entreprise: Entreprise) {
    const sirenAdh = store.getState().contextState.entreprise?.SIREN__c
    if (!sirenAdh) return null

    const parBranche = findDispositifsEligiblesParBranche(entreprise)
    const dispositifIds = (getAllDispositifFO() ?? []).filter(d => parBranche.includes(d.Id)).map(d => d.Id)
    const today = startOfToday()
    const params = new URLSearchParams([
        [SIREN_ADH, sirenAdh],
        ['date-debut', format(subYears(today, 5), DATE_FORMAT)],
        ['date-fin', format(today, DATE_FORMAT)],
    ])
    dispositifIds.forEach((dispositifId) => params.append('dispositif-fo', dispositifId))
    requestConfig.params = params
    return requestConfig
}

export async function getDossiersStatistiques(entreprise: Entreprise): Promise<StatsDispositif[]> {
    const requestConfig = await getRequestConfig()
    if (!initStatistiquesRequest(requestConfig, entreprise))
        return []
    
    const { data } = await axios.get<StatsDispositif[]>('/dossiers/stats', requestConfig)
    data.forEach(dispositif => {
        const ref = findDispositifFO(dispositif.DispositifFO__c)
        dispositif.DispositifLibelle__c = ref?.Libelle__c ?? ''
        dispositif.DispositifLibelleReduit__c = ref?.LibelleReduit__c ?? ''
        dispositif.Statistiques.forEach(stat => {
            const ref = findEtatDossierFO(stat.EtatDossierFO__c)
            stat.EtatLibelle__c = ref?.Libelle__c ?? ''
            stat.EtatLibelleReduit__c = ref?.LibelleReduit__c ?? ''
        })
    })
    return data
}

export async function getFacturesStatistiques(entreprise: Entreprise): Promise<StatsDispositif[]> {
    const requestConfig = await getRequestConfig()
    if (!initStatistiquesRequest(requestConfig, entreprise))
        return []

    const { data } = await axios.get<StatsDispositif[]>('/factures/stats', requestConfig)
    data.forEach(dispositif => {
        const ref = findDispositifFO(dispositif.DispositifFO__c)
        dispositif.DispositifLibelle__c = ref?.Libelle__c ?? ''
        dispositif.DispositifLibelleReduit__c = ref?.LibelleReduit__c ?? ''
    })
    return data
}


export async function getFinancesStatistiques(entreprise: Entreprise): Promise<StatsFinances> {


    const requestConfig = await getRequestConfig()
    const today = startOfToday()
    requestConfig.params = new URLSearchParams([
        ['date-debut', format(subYears(today, 5), DATE_FORMAT)],
        ['date-fin', format(today, DATE_FORMAT)],
    ])
    const { data } = await axios.get<StatsFinances>('entreprises/of/siren/'+entreprise.SIREN__c+'/factures/metriques', requestConfig)
    
    return data
}