import { MyState, MyStateType } from "atlas-ds"

export type StateType =
    | "bill"
    | "document"
    | "file"
    | "formation"
    | "paiement"
    | "rib"
    | "simulation"
    | "versement";

const states: { [type in StateType]: { [id: string]: MyStateType } } = {
    bill: {
        "1": "pending", // En cours
        "2": "actionNeeded", // Incomplète
        "3": "positive", // Payée
        "4": "negative", // Refusée
        "6": "pending", // Soumise
        "7": "pending", // En cours de règlement
        "8": "actionNeeded" // À transmettre
    },
    document: {
        "Ajout": "positive",
        "Suppression": "negative"
    },
    file: {
        "1": "draft", // Brouillon
        "2": "pending", // En cours
        "3": "actionNeeded", // Incomplet
        "4": "positive", // Accordé
        "5": "negative", // Refusé
        "6": "done", // Clôturé
        "7": "negative", // Rupture
        "8": "inactive", // Annulé
    },
    formation: {
        "1": "actionNeeded", // À compléter
        "2": "pending", // Session à créer
        "3": "positive", // Publié
        "4": "inactive" // Expiré
    },
    paiement: {
        "1": "inactive",
        "2": "negative",
    },
    rib: {
        "1": "positive",
        "2": "inactive",
    },
    simulation: {
        "sauvegardée": "positive",
        "convertie": "done"
    },
    versement: {
        "0": "draft",
        "3": "pending",
        "4": "positive",
    },
};

interface StateProps {
    children: string;
    type: StateType;
    id: string;
    small?: boolean;
}

export default function State(props: StateProps) {
    return <MyState type={states[props.type][props.id]} small={props.small}>
        {props.children}
    </MyState>;
}
