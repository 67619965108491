import { UseFormReturn } from '@dsid-opcoatlas/reform'
import { StringSchema } from '@dsid-opcoatlas/yop'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE, DISPOSITIF_CONTRAT_PROFESSIONNALISATION } from 'api/references'
import RadioField from 'components/reform/inputs/RadioField'
import CerfaBooleanField from 'pages/dossier/cerfa/CerfaBooleanField'
import ExportBooleanField from 'pages/dossier/export/ExportBooleanField'
import { ElementContext, SettingsFieldMetadata, createMetadata } from 'pages/dossier/metadata/FormMetadata'
import RecapBooleanField from 'pages/dossier/recap/RecapBooleanField'
import { BaseDossier, TYPE_HANDICAP, TYPE_HANDICAP_VALUES } from 'slices/dossierCommon'

const handicapSchema = new StringSchema<TYPE_HANDICAP>().oneOf(TYPE_HANDICAP_VALUES).defined()

const infoMessage = (ctx: ElementContext<BaseDossier>, form?: UseFormReturn<BaseDossier>) => {
    if (form?.getValue(ctx.path) === "1") {
        if (ctx.root.DispositifFO__c === DISPOSITIF_CONTRAT_APPRENTISSAGE) {
            return <>
                Pour le recrutement d’une personne en situation de handicap en contrat d’apprentissage, vous êtes éligible à une <a
                    href="https://www.agefiph.fr/aides-handicap/aide-lembauche-en-contrat-dapprentissage" target="blank" rel="noopener, noreferrer"
                >aide de l’Agefiph</a>.
            </>
        }
        else if (ctx.root.DispositifFO__c === DISPOSITIF_CONTRAT_PROFESSIONNALISATION) {
            return <>
                Pour le recrutement d’une personne en situation de handicap en contrat de professionnalisation, vous êtes éligible à une <a
                    href="https://www.agefiph.fr/aides-handicap/aide-lembauche-en-contrat-de-professionnalisation" target="blank" rel="noopener, noreferrer"
                >aide de l’Agefiph</a>.
            </>
        }
        else {
            return <>
                Pour la formation d’une personne en situation de handicap, l’Agefiph peut vous accompagner. Consultez les <a
                    href="https://www.agefiph.fr/sites/default/files/medias/fichiers/2023-02/Agefiph_Metodia_Janvier-2023.pdf" target="blank" rel="noopener, noreferrer"
                >aides mobilisables</a>.
            </>
        }
    }
    return undefined
}


export function HandicapFieldMetadata(settings: SettingsFieldMetadata<TYPE_HANDICAP | null, {
    options?: {
        value: string;
        label: string;
    }[]
}> = {}) {
    return createMetadata(
        {
            props: {
                options: [
                    { value: '1', label: 'Oui' },
                    { value: '2', label: 'Non' }
                ],
                infoMessage,
            },
            yop: handicapSchema,
            input: RadioField,
            recap: (props: any) => <RecapBooleanField {...props} label={ props.label } value={ props.value === '1' } />,
            export: (props: any) => <ExportBooleanField {...props}  value={ props.value === '1' } />,
            cerfa: (props: any) => <CerfaBooleanField value={ props.value === '1' } />,
        },
        settings
    )
}
