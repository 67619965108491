import { getParentPath } from '@dsid-opcoatlas/yop'
import { DISPOSITIF_CONTRAT_APPRENTISSAGE } from 'api/references'
import { DossierEmployeurElements } from 'pages/dossier/elements/DossierEmployeurElements'
import { DossierSalarieElements } from 'pages/dossier/elements/DossierSalariesElements'
import { DossierMaitresElements } from 'pages/dossier/elements/DossierMaitresElements'
import { DossierCentreFormationElements } from 'pages/dossier/elements/DossierCentreFormationElements'
import { DossierFormationElements } from 'pages/dossier/elements/DossierFormationElements'
import { DossierCAPPContratElements } from 'pages/dossier-capp/DossierCAPPContratElements'
import DossierCAPPConfirmation from './DossierCAPPConfirmation'
import { CAPPMetadata, MetadataCAPP, apiDossierCAPP } from './CAPPFormMetadata'
import { CAPPContratMetadata } from './CAPPContratMetadata'
import { FormMetadata, FormStep, IgnoredFieldMetadata, ObjectMetadata, ignored, registerMetadata } from 'pages/dossier/metadata/FormMetadata'
import { getEtablissements } from 'api/commonAPI'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { ObjectFieldMetadata, OptionalObjectFieldMetadata } from 'components/metadata/HolderFieldMetadata'
import { Ignored } from 'components/yop/constraints'
import { CheckboxFieldMetadata } from 'components/metadata/BooleanFieldMetadata'
import { DossierCAPPMAPMandat } from 'pages/dossier-capp/DossierCAPPMAPMandat'
import { simpleMapping } from 'pages/dossier/metadata/DossierMapping'
import store from 'app/store'
import { nil } from 'slices/dossierCommon'
import { AppType } from 'slices/contextSlice'
import { CAPPFormationMetadataBuilder } from './CAPPFormationMetadata'
import { DossierCAPPMaitre, createDossierCAPP, DossierCAPPContrat, DossierCAPP, createDossierCAPPStagiaire, createDossierCAPPMaitre, DossierCAPPStagiaire } from './CAPPModel'
import { CAPPMaitre1Metadata, CAPPMaitre2Metadata } from './CAPPMaitresMetadata'
import { CAPPStagiaireMetadata } from './CAPPStagiaireMetadata'
import { NameFieldMetadata } from 'components/metadata/TextFieldMetadata'


type DossierCAPPMAPMaitre = Pick<DossierCAPPMaitre, 'nomNaissance' | 'nom' | 'prenom' | 'sexe' | 'dateNaissance' | 'employeurDirigeant' | 'email' | 'plusHautDiplome' | 'niveauPlusHautDiplome' | 'emploiOccupe'>

export function createDossierCAPPMAP(initial?: any, storedState?: any | null) { return {
    entrepriseOFId: nil as string,
    ...createDossierCAPP(),
    attestationMandat: ((storedState?.NumeroDossier__c ? nil : storedState?.attestationMandat) ?? nil) as boolean,
} }
export type DossierCAPPMAP = ReturnType<typeof createDossierCAPPMAP>

export const CAPPMAPStagiaireMetadata: ObjectMetadata<DossierCAPPStagiaire> = {
    ...CAPPStagiaireMetadata,
    nomNaissance: NameFieldMetadata({
        props: {
            label: 'Nom de naissance',
            disabled: (context, form) => {
                return form?.isAsyncResultPending(`${ getParentPath(context.path) }.nir`) === true
            },
        },
        mapping: simpleMapping('NomNaissance__c'),
    }).required().mutate(yop => yop.max(50)),
}

export const CAPPMAPMaitre1Metadata: ObjectMetadata<DossierCAPPMAPMaitre> = {
    nomNaissance: NameFieldMetadata({
        props: {
            label: 'Nom de naissance',
            disabled: (context, form) => {
                return form?.isAsyncResultPending(`${ getParentPath(context.path) }.nir`) === true
            },
        },
        mapping: simpleMapping('NomNaissanceTuteur__c'),
    }).required().mutate(yop => yop.max(50)),
    nom: CAPPMaitre1Metadata.nom,
    prenom: CAPPMaitre1Metadata.prenom,
    sexe: CAPPMaitre1Metadata.sexe,
    dateNaissance: CAPPMaitre1Metadata.dateNaissance,
    employeurDirigeant: CAPPMaitre1Metadata.employeurDirigeant,
    email: CAPPMaitre1Metadata.email,
    plusHautDiplome: CAPPMaitre1Metadata.plusHautDiplome,
    niveauPlusHautDiplome: CAPPMaitre1Metadata.niveauPlusHautDiplome,
    emploiOccupe: CAPPMaitre1Metadata.emploiOccupe,
}

export const CAPPMAPMaitre2Metadata: ObjectMetadata<DossierCAPPMAPMaitre> = {
    nomNaissance: NameFieldMetadata({
        props: {
            label: 'Nom de naissance',
            disabled: (context, form) => {
                return form?.isAsyncResultPending(`${ getParentPath(context.path) }.nir`) === true
            },
        },
        mapping: simpleMapping('NomNaissanceTuteur2__c'),
    }).required().mutate(yop => yop.max(50)),
    nom: CAPPMaitre2Metadata.nom,
    prenom: CAPPMaitre2Metadata.prenom,
    sexe: CAPPMaitre2Metadata.sexe,
    dateNaissance: CAPPMaitre2Metadata.dateNaissance,
    employeurDirigeant: Ignored,
    email: CAPPMaitre2Metadata.email,
    plusHautDiplome: CAPPMaitre2Metadata.plusHautDiplome,
    niveauPlusHautDiplome: CAPPMaitre2Metadata.niveauPlusHautDiplome,
    emploiOccupe: CAPPMaitre2Metadata.emploiOccupe,
}

const CAPPMAPContratMetadata: ObjectMetadata<DossierCAPPContrat> = {
    ...CAPPContratMetadata,
    niveauOuEchelon: ignored(CAPPContratMetadata.niveauOuEchelon),
    coefficientHierarchique: ignored(CAPPContratMetadata.coefficientHierarchique),    
}

type DossierCAPPMAPLight = Omit<DossierCAPP, 'pieces' | 'pointsReserve' | 'indicateurConformite' | 'maitre1' | 'maitre2'> & { 
    entrepriseOFId: string | null,
    attestationMandat: boolean,
    maitre1: DossierCAPPMAPMaitre, 
    maitre2: DossierCAPPMAPMaitre | null, 
}

const CAPPMAPMetadataLight: ObjectMetadata<DossierCAPPMAPLight> = {
    entrepriseOFId: IgnoredFieldMetadata(
        simpleMapping<string | null>('EntrepriseOFParent__c'),
        ({ value }: { value: string | null }) => value ?? store.getState().contextState.entreprise?.Id ?? null,
    ),
    attestationMandat: CheckboxFieldMetadata({
        mapping: simpleMapping('AttesteHonneurMandatEntreprise__c'),
    }).required().mutate(s => s.oneOf([true], "J'atteste sur l'honneur avoir été mandaté par l'entreprise signataire du CERFA et m'engage")),
    employeur: CAPPMetadata.employeur,
    stagiaire: ObjectFieldMetadata(CAPPMAPStagiaireMetadata),
    maitre1: ObjectFieldMetadata(CAPPMAPMaitre1Metadata),
    maitre2: OptionalObjectFieldMetadata(CAPPMAPMaitre2Metadata),
    eligibilite: CAPPMetadata.eligibilite,
    formation: ObjectFieldMetadata(CAPPFormationMetadataBuilder({ 
        noCreateOF: true, 
        disableAdresseOF: true, 
        searchOptions: { certificationCa: true } 
    })),
    contrat: ObjectFieldMetadata(CAPPMAPContratMetadata),
    representant: CAPPMetadata.representant,
    attestationPieces: CAPPMetadata.attestationPieces,
    signature: CAPPMetadata.signature,
}
export const CAPPMAPMetadata = CAPPMAPMetadataLight as unknown as ObjectMetadata<DossierCAPPMAP>


export const MetadataCAPPMAP: FormMetadata<DossierCAPPMAP> = registerMetadata(AppType.Prestataire, {
    dispositif: DISPOSITIF_CONTRAT_APPRENTISSAGE,
    title: 'Transmission d\'un contrat d\'apprentissage',
    pathname: '/nouveau-dossier-apprentissage-map',
    steps: [
        {
            title: "Substitution de l'entreprise",
            yopPath: 'attestationMandat',
            formContent: DossierCAPPMAPMandat,
            submitDisabled: (dossier, pending, dossierInitial, entreprise) => !entreprise?.CertificationCA__c,
            options: {
                step0: true,
                localSave: true,
            },
        },
        { 
            ...MetadataCAPP.steps[0],
            preFormContent: undefined,
            formContent: props => <DossierEmployeurElements {...props} 
                label="Sélectionner l'établissement d'exécution du contrat" 
                labelContact='Contact entreprise'
                tooltipContact={ TOOLTIPS.employeur.contactEntreprise }
                getEtablissements={ getEtablissements } 
                requireSearch
            />,
        }, {
            ...MetadataCAPP.steps[1],
            formContent: DossierSalarieElements,
            initializer: (dossier: DossierCAPPMAP) => {
                if (!dossier.stagiaire)
                    dossier.stagiaire = { ...createDossierCAPPStagiaire(), IdContact: null }
                return dossier
            },
        }, {
            ...MetadataCAPP.steps[2],
            formContent: DossierMaitresElements,
            initializer: (dossier: DossierCAPPMAP) => {
                if (!dossier.maitre1)
                    dossier.maitre1 = { ...createDossierCAPPMaitre(), IdContact: null }
                return dossier
            },
        }, {
            ...MetadataCAPP.steps[3],
            formContent: DossierCentreFormationElements,
        }, {
            ...MetadataCAPP.steps[4],
            formContent: DossierFormationElements,
        }, {
            ...MetadataCAPP.steps[5],
            formContent: DossierCAPPContratElements,
        }, {
            ...MetadataCAPP.steps[6],
        }, {
            ...MetadataCAPP.steps[7],
        },
    ] as FormStep<DossierCAPPMAP>[],
    options: {
        noToolbox: true,
    },
    confirmationContent: props => <DossierCAPPConfirmation { ...props } masquerConformite={ true } masquerFMA={ true } />,
    api: apiDossierCAPP(
        () => MetadataCAPPMAP, 
        createDossierCAPPMAP, 
    ),
    fields: CAPPMAPMetadata,
    exportProps: [ ...MetadataCAPP.exportProps ] as any,
})
