import { Entreprise } from 'api/referencesAPI'
import { AppModule, AppState } from 'app/appInfo'
import { AppRoute } from 'app/AppModuleProvider'
import MonClimateSchool from './MonClimateSchool'

export const isEligible = (entreprise: Entreprise | null) => 
    (entreprise?.NumberOfEmployees != null && entreprise?.NumberOfEmployees < 50)
    && (
        !import.meta.env.REACT_APP_CLIMATE_SCHOOL_TRIAL_SIRENS
        || import.meta.env.REACT_APP_CLIMATE_SCHOOL_TRIAL_SIRENS.includes(entreprise?.SIREN__c)
    )

const climateSchoolAtlas = () => {
    return {
        id: 'climate-school',
        isEnabled: (_) => import.meta.env.REACT_APP_MODULES?.includes('climateschoolatlas'),
        routes: (_, appState: AppState) => [
            <AppRoute
                key="climate-school"
                path="/climate-school"

                // Garder la page active en cas d'accès hors-éligibilité (affichage d'une erreur),
                // mais afficher dans le sitemap + menu uniquement si éligible
                label={isEligible(appState.entreprise) ? "Climate School" : undefined}
                menuIcon={isEligible(appState.entreprise) ? "climate-school" : undefined}
            >
                <MonClimateSchool />
            </AppRoute>,
        ],
    } as AppModule
}

export default climateSchoolAtlas
