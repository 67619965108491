
import DurationMnField from 'components/reform/inputs/DurationMnField'
import { OptionalNumber, RequiredNumber } from 'components/yop/constraints'
import { Yop } from '@dsid-opcoatlas/yop'
import { createMetadata, SettingsFieldMetadata } from 'pages/dossier/metadata/FormMetadata'
import { FieldMapping } from 'pages/dossier/metadata/DossierMapping'
import CerfaDurationMnField from 'pages/dossier/cerfa/CertaDurationMnField'
import ExportDurationMnField from 'pages/dossier/export/ExportDurationMnField'
import RecapDurationMnField from 'pages/dossier/recap/RecapDurationMnField'

type DurationMn = { hours: number, minutes: number | null }

export const durationMnSchema = (maxHours?: number, maxMessage?: string) => Yop.object<DurationMn>({
    hours: RequiredNumber.min(1, 'Minimum 1 heure').max(maxHours ?? 168, maxMessage),
    minutes: OptionalNumber.min(0, 'Minimum 0 minute').test(ctx => {
        if (ctx.value! > 59)
            return ctx.createError('Maximum 59 minutes')
        if (ctx.parent?.hours != null && ctx.parent.hours >= (maxHours ?? 168) && ctx.value! > 0)
            return ctx.createError(`Maximum 0 minutes pour ${ maxHours ?? 168 } heure(s)`)
        return true
    }),
}).required()

export function durationMnMapping(pathHeures: string, pathMinutes: string): FieldMapping<DurationMn> {
    return {
        mapModelToDTO: (value: DurationMn, dto: any) => {
            dto[pathHeures] = value.hours
            dto[pathMinutes] = value.minutes
        },
        mapDTOToModel: (dto: any) => ({ 
            hours: dto[pathHeures], 
            minutes: dto[pathMinutes],
        } as DurationMn),
    }
}

export function RequiredDurationMnFieldMetadata(settings: SettingsFieldMetadata<DurationMn> = {}) {
    return createMetadata(
        {
            yop: durationMnSchema(),
            equals: (d1, d2) => d1 === d2 || (d1?.hours === d2?.hours && (d1?.minutes ?? 0) === (d2?.minutes ?? 0)),
            diff: (d1, d2) => d1?.hours !== d2?.hours || (d1?.minutes ?? 0) !== (d2?.minutes ?? 0) ? [d1, d2] : null,
            input: DurationMnField,
            recap: RecapDurationMnField,
            export: ExportDurationMnField,
            cerfa: CerfaDurationMnField,
        },
        settings,
    )
}
