import { Form, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { EntrepriseDetail, getDetailEntreprise } from "api/monCompteAPI"
import useApiState from "api/useApiState"
import { AtlasHeading, AtlasLoading, AtlasSpacer, MyContainer, MyForm, MyTable } from "atlas-ds"
import Link from "components/Link"
import PageHeader from "components/PageHeader"
import TextField from "components/reform/inputs/TextField"
import { Ignored } from "components/yop/constraints"
import { useEffect } from "react"
import { useParams } from "react-router-dom"



const prestataireValues = (entrprise?: EntrepriseDetail | null) => {
    return {
        name: entrprise?.AccountDTO?.Name,
        siren: entrprise?.AccountDTO?.SIREN__c,
        codeAdherent: entrprise?.AccountDTO?.CodeAdherent__c,
    }
}

type PrestataireValues = ReturnType<typeof prestataireValues>

export default function DetailPrestataire() {

    const [{ pending: DetailEntreprisePending, value: DetailEntreprise }, withgetDetailEntreprise, setDetailEntreprise] = useApiState(getDetailEntreprise)
    const { idEntreprise } = useParams<{ idEntreprise: string }>()

    useEffect(() => {

        if (idEntreprise) {
            withgetDetailEntreprise(idEntreprise, 'prestataire');
        }

    }, [idEntreprise, withgetDetailEntreprise]);

    const initialValues = prestataireValues(DetailEntreprise)
    const validationSchema = Yop.object<PrestataireValues>({
        name: Ignored,
        siren: Ignored,
        codeAdherent: Ignored,
    })

    const form = useForm<PrestataireValues>({
        initialValues,
        validationSchema,
        dispatchEvent: false,
    })

    return <>
        <PageHeader
            // actions={<DetailDetacherEntreprise key="detacher" entreprise={DetailEntreprise} type={TYPE_ORGANISME} />}
        >Mes prestataires</PageHeader>

        <AtlasHeading tag="h2" size="m">{ DetailEntreprise?.AccountDTO.Name }</AtlasHeading>
        <Form context={form} autoComplete="off" noValidate >
            <MyContainer>
                <MyForm>
                    <MyForm.Grid>


                        <TextField disabled name="name" label="Raison Sociale" />

                        <TextField disabled name="siren" label="Siren" />
                        <MyForm.Field size="short" alone>
                            <TextField disabled name="codeAdherent" label="Code OF" />
                        </MyForm.Field>



                    </MyForm.Grid>
                </MyForm>
            </MyContainer>
        </Form>

        <AtlasSpacer size="xl" />

        <AtlasHeading tag="h3" size="m">Comptes myAtlas associés</AtlasHeading>

        <AtlasLoading loading={DetailEntreprisePending}>
            <MyTable
                caption={"Mes entreprises"}
                rows={DetailEntreprise?.AccountDTO.ComptesSEL ?? []}
                emptyText={"Aucun Compte myAtlas associé"}
                columns={[
                    {
                        id: "FirstName",
                        label: "Prénom"
                    },
                    {
                        id: "LastName",
                        label: "Nom"
                    },
                    {
                        id: "Email",
                        label: "Mail"
                    },

                ]}
            />
        </AtlasLoading>

        <AtlasSpacer size="xl" />

        <p>Vous avez identifié des anomalies ? <Link to="/mes-prestataires" level={2}>Contactez-nous</Link></p>
    </>


}