import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { InseeDetail, convertirEnWebAdhesionDTO, postWebAdhesion } from "api/monCompteAPI"
import { TYPE_ENTREPRISE_PROSPCT } from "api/references"
import { Reference, ReferenceNAF, getReferencesNAF } from "api/referencesAPI"
import { RootState } from "app/rootReducer"
import { AtlasInfo, MyForm } from "atlas-ds"
import Link from "components/Link"
import CodeIdccField from 'components/fields/CodeIdccField'
import { RequiredSiren, RequiredSiret } from 'components/metadata/SiretFieldMetadata'
import Button from "components/reform/Button"
import CheckboxField from "components/reform/inputs/CheckboxField"
import CodePostalCommuneField from "components/reform/inputs/CodePostalCommuneField"
import DateField from "components/reform/inputs/DateField"
import FileField from "components/reform/inputs/FileField"
import PhoneField from "components/reform/inputs/PhoneField"
import RadioField from "components/reform/inputs/RadioField"
import SelectRefField from "components/reform/inputs/SelectRefField"
import TextField from "components/reform/inputs/TextField"
import { AcceptedDocuments, AcceptedImages, OptionalBoolean, OptionalDate, OptionalFileAccept, RequiredDate, RequiredEmail, RequiredFileAccept, RequiredPhone, RequiredString } from "components/yop/constraints"
import log from 'loglevel'
import { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { nil } from "slices/dossierCommon"

const codeIdccTooltip = <>
    Ce code peut être obtenu sur le site du Ministère du Travail <a href="https://travail-emploi.gouv.fr/dialogue-social/negociation-collective/article/conventions-collectives-nomenclatures" target="_blank" rel="noopener noreferrer">
        travail-emploi.gouv.fr</a>. S’il n’y a pas de convention collective veuillez indiquer le code 9999, si la convention collective est en cours de négociation,
    veuillez indiquer le code 9998.
</>

interface FormWebAdhesionProps {
    siren?: string
    dataInsee: InseeDetail
}

function createWebAdhesion() {
    return {
        raisonSocial: nil as string,
        siren: nil as string,
        siret: nil as string,
        codeNaf: nil as string,
        typeEntreprise: nil as string,
        codeIdcc: nil as string,
        adresse: nil as string,
        codePostal: nil as string,
        ville: nil as string,
        firstname: nil as string,
        lastname: nil as string,
        email: nil as string,
        phone: nil as string,
        civilite: nil as string,
        file: null as (File | null),
        dateDebutActivite: nil as Date | null,
        contactPrincipal: nil as boolean | null,
    }
}

export type WebAdhesionEntreprise = ReturnType<typeof createWebAdhesion>

export function WebAdhesionForm(props: FormWebAdhesionProps) {

    const { profil } = useSelector((state: RootState) => state.profilState)
    const history = useHistory()

    const [backendError, setBackendError] = useState<string | null>(null)
    const [referenceNAFData, setReferenceNAFData] = useState<ReferenceNAF[] | null>(null);
    
    // TODO Faire correspondre le DTO back et Front
    const account = props.dataInsee?.AccountDTO
    const initialValues = createWebAdhesion()
    initialValues.raisonSocial = account?.Name
    initialValues.siren = props.siren ?? ''
    initialValues.siret = account?.SIRET__c ?? props.siren
    initialValues.codeNaf = account?.CodeNAF__c
    initialValues.typeEntreprise = account?.Type_Entreprise
    initialValues.dateDebutActivite = account?.DateDebutActiviteEntreprise__c ? new Date(account?.DateDebutActiviteEntreprise__c) : null
    initialValues.codeIdcc = ''
    initialValues.adresse = account?.BillingStreet
    initialValues.codePostal = account?.BillingPostalCode
    initialValues.ville = account?.BillingCity
    initialValues.contactPrincipal = false

    const validationSchema = Yop.object<WebAdhesionEntreprise>({
        raisonSocial: RequiredString,
        siren: RequiredSiren,
        siret: RequiredSiret,
        codeNaf: RequiredString,
        typeEntreprise: RequiredString,
        codeIdcc: RequiredString,
        adresse: RequiredString,
        codePostal: RequiredString,
        ville: RequiredString,
        contactPrincipal: OptionalBoolean,
        firstname: RequiredString.ignoredIf<WebAdhesionEntreprise>(contexte => contexte.parent?.contactPrincipal === true),
        lastname: RequiredString.ignoredIf<WebAdhesionEntreprise>(contexte => contexte.parent?.contactPrincipal === true),
        email: RequiredEmail.ignoredIf<WebAdhesionEntreprise>(contexte => contexte.parent?.contactPrincipal === true)
            .test(contexte => {
                if (contexte.value === profil?.Contact.Email)
                    return contexte.createError("Si l'email saisi est le vôtre, merci de cocher la case 'Je suis le contact de l'entreprise'")
                return true
            }),
        phone: RequiredPhone.ignoredIf<WebAdhesionEntreprise>(contexte => contexte.parent?.contactPrincipal === true),
        civilite: RequiredString.ignoredIf<WebAdhesionEntreprise>(contexte => contexte.parent?.contactPrincipal === true),
        file: props.dataInsee.AccountDTO 
            ? OptionalFileAccept(Object.keys(AcceptedDocuments)) 
            : RequiredFileAccept("Document obligatoire", Object.keys(AcceptedDocuments)),
        dateDebutActivite: props.dataInsee.AccountDTO ? OptionalDate : RequiredDate,
    })

    const form = useForm<WebAdhesionEntreprise>({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<WebAdhesionEntreprise>) => {
            const entrepriseMamc = context.values!

            const params = {
                'raisonSocial': entrepriseMamc?.raisonSocial,
                'siren': entrepriseMamc?.siren,
                'siret': entrepriseMamc?.siret,
                'codeNaf': entrepriseMamc?.codeNaf,
                'codeIdcc': entrepriseMamc?.codeIdcc,
                'type': TYPE_ENTREPRISE_PROSPCT,
                'typeEntreprise': entrepriseMamc?.typeEntreprise,
                'ville': entrepriseMamc?.ville,
                'codePostal':  entrepriseMamc?.codePostal,
                'adresse': entrepriseMamc?.adresse,
                'contact': {
                    'civilite': entrepriseMamc?.civilite,
                    'firstname': entrepriseMamc?.firstname,
                    'lastname': entrepriseMamc?.lastname,
                    'email': entrepriseMamc?.email,
                    'phone': entrepriseMamc?.phone
                },
                'insee': !!account,
                'dateDebutActivite': entrepriseMamc?.dateDebutActivite,
            }
            const webadhesionDTO = convertirEnWebAdhesionDTO(params);
            if (entrepriseMamc?.file != null) {
                postWebAdhesion(JSON.stringify(webadhesionDTO), entrepriseMamc?.file, entrepriseMamc?.file.name)
                    .then(_ => {
                        history.replace({
                            pathname: "/mes-entreprises/web-adhesion-succes",
                        })
                    })
                    .catch(error => {
                        log.error(error)
                        const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite."
                        setBackendError(message)
                        context.setSubmitting(false)
                    })
            } else {
                postWebAdhesion(JSON.stringify(webadhesionDTO))
                    .then(_ => {
                        history.replace({
                            pathname: "/mes-entreprises/web-adhesion-succes",
                        })
                    })
                    .catch(error => {
                        log.error(error)
                        const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite."
                        setBackendError(message)
                        context.setSubmitting(false)
                    })
            }
        }
    })

    useEffect(() => {
        const fetchReferenceNAFData = async () => {
            try {
                const referencesNAF = await getReferencesNAF();
                setReferenceNAFData(referencesNAF);
            } catch (error) {
                log.error('Erreur lors de la récupération des données de référence NAF', error);
            }
        };
        fetchReferenceNAFData();
    }, []);

    const options = () =>
        referenceNAFData
            ? referenceNAFData.map((item) => ({
                Categorie__c: item.Categorie__c,
                CodeType__c: item.CodeType__c,
                IsActive__c: item.IsActive__c,
                IsActiveFO__c: item.IsActiveFO__c,
                Id: item.Id,
                Libelle__c: item.Libelle__c,
                id: item.Id,
                value: item.LibelleReduit__c,
                LibelleReduit__c: item.LibelleReduit__c,
                Priorite__c: item.Priorite__c,
            }))
            : []


    const onClickContactPrincipal = (checked: boolean | null, form: UseFormReturn<object>) => {
        form.setValue('civilite', checked ? profil?.Contact.Salutation : '')
        form.setValue('firstname', checked ? profil?.Contact.FirstName : '')
        form.setValue('lastname', checked ? profil?.Contact.LastName : '')
        form.setValue('email', checked ? profil?.Contact.Email : '')
        form.setValue('phone', checked ? profil?.Contact.Phone : '')
    }

    const onSiretChange = (value: string | null) => {
        if (value && value.length < 9)
            form.setValue('siret', form.values!.siren, true)
    }

    return <Form context={form} autoComplete="off" noValidate>
        <MyForm>
            <TextField disabled={!!account?.Name} name="raisonSocial"  label="Raison Sociale" />

            <MyForm.Grid>
                <TextField disabled name="siren" maxLength={9} label="Siren" />
                <TextField disabled={!!account?.SIRET__c} name="siret" onChange={onSiretChange} maxLength={14} label="Siret de l'établissement principal" />
                <SelectRefField
                    disabled={!!account?.CodeNAF__c}
                    name="codeNaf"
                    label="Code NAF"
                    options={() => options() as Reference[]}
                    sorted
                    isMAMC={true}
                />

                <SelectRefField disabled={!!account?.Type_Entreprise} name="typeEntreprise" label="Type d'entreprise"
                    options={refs => refs.REF_TYPE_ENTREPRISE.filter(ref => ref.IsActive__c)} sorted />

                <CodeIdccField
                    parent={undefined}
                    parentPath={""}
                    name="codeIdcc"
                    label="IDCC appliqué au sein de l'entreprise"
                    tooltip={codeIdccTooltip}
                ></CodeIdccField>

                <DateField disabled={!!account?.DateDebutActiviteEntreprise__c} name="dateDebutActivite" label="Date début d'activité de l'entreprise" />

                <MyForm.Field size="full">
                    <TextField disabled={!!account?.DateDebutActiviteEntreprise__c} name="adresse" maxLength={38} label="Adresse" />
                </MyForm.Field>

                {!account ?
                    <CodePostalCommuneField codePostalName="codePostal" communeName="ville" name="" label="Code postal et commune" />
                :
                    <>
                        <TextField disabled={!!account?.BillingPostalCode} name="codePostal" maxLength={5} label="Code postal" />
                        <TextField disabled={!!account?.BillingCity} name="ville" maxLength={50} label="Commune" />
                    </>
                }
                {!account &&
                    <MyForm.Fieldset legend="Fiche INSEE">
                        <FileField
                            name="file"
                            label="Sélectionner une fiche INSEE"
                            accept={Object.keys(AcceptedImages)}
                            tooltip={`Formats acceptés : ${Object.values(AcceptedImages).join(', ')} — 15 Mo maximum`}
                        />
                    </MyForm.Fieldset>
                }
            </MyForm.Grid>

            <MyForm.Fieldset legend="Contact au sein de l'entreprise">
                <CheckboxField name="contactPrincipal" label="Je suis le contact de l'entreprise" onChange={onClickContactPrincipal} />
                <RadioField name="civilite" label="Civilité" options={[{
                    value: "1",
                    label: "M."
                }, {
                    value: "2",
                    label: "Mme"
                }]} disabled={form.values?.contactPrincipal ?? undefined} />
                <MyForm.Grid>
                    <TextField name="firstname" maxLength={32} label="Prénom" disabled={form.values?.contactPrincipal ?? undefined} />
                    <TextField name="lastname" maxLength={50} label="Nom" disabled={form.values?.contactPrincipal ?? undefined} />
                    <TextField type="email" name="email" label="Email" disabled={form.values?.contactPrincipal ?? undefined} />
                    <PhoneField name="phone" label="Téléphone" disabled={form.values?.contactPrincipal ?? undefined} />
                </MyForm.Grid>

            </MyForm.Fieldset>

            {backendError &&
                <AtlasInfo type="error" title="Erreur" >{ backendError }</AtlasInfo>
            }

            <MyForm.Actions>
                <Link level={2} to="/mes-entreprises/attacher" disabled={form.submitting}>Annuler</Link>
                <Button submit={true} spinner={{ spinning: form.submitting }}>Valider</Button>
            </MyForm.Actions>
        </MyForm>
    </Form>
}



