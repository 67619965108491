import { SetValueOptions, UseFormReturn } from "@dsid-opcoatlas/reform"
import { OFSearchOptions } from "api/commonAPI"
import CurrencyField from "components/reform/inputs/CurrencyField"
import { CappOFAdresseValidationFieldsParams } from "components/yop/constraints"
import { MappingContext, dateMapping, simpleMapping } from "pages/dossier/metadata/DossierMapping"
import { ObjectMetadata, override, ElementContext } from "pages/dossier/metadata/FormMetadata"
import { SECTION_CFA, SECTION_MODULE_AGORA, moduleFields } from "pages/dossier/metadata/ModulesMetadata"
import { BooleanFieldMetadata } from "components/metadata/BooleanFieldMetadata"
import { CFAFieldMetadata } from "components/metadata/CFAFieldMetadata"
import { DiplomeFieldMetadata } from "components/metadata/DiplomeFieldMetadata"
import { NumberFieldMetadata } from "components/metadata/NumberFieldMetadata"
import { SelectRefFieldMetadata } from "components/metadata/RefFieldMetadata"
import { TextFieldMetadata } from "components/metadata/TextFieldMetadata"
import { TOOLTIPS } from "pages/dossier/Tooltips"
import { BaseDossier, BaseDossierModule, DossierCFA, createDossierCFA } from "slices/dossierCommon"
import { DossierCAPP, DossierCAPPFormation } from "./CAPPModel"
import { compareDates } from "api/util"
import { formatDate } from "components/format/Format"
import { ValidationContext } from '@dsid-opcoatlas/yop';
import { RECONNAISSANCE_HANDICAP, SITUATION_HANDICAP } from "api/references"
import { differenceInMonths } from "date-fns"
import { DateFieldMetadata } from "components/metadata/DateFieldMetadata"
import { CAPPStagiaireMetadata } from "./CAPPStagiaireMetadata"

export const SECTION_FORMATION = 'formation'
export const SECTION_FORMATION_ORGA = 'formation-orga'
export const SECTION_FORMATION_FRAIS = 'formation-frais'


export const CAPPFormationBaseMetadata = {
    modalitePedagogiqueAgora: SelectRefFieldMetadata({
        section: SECTION_MODULE_AGORA,
        props: {
            label: 'Modalités pédagogiques',
            options: refs => refs.REF_MODALITE_PEDAGOGIQUE.filter(ref => ref.Categorie__c?.split(',').includes('5')),
            sorted: true,
        },
        mapping: simpleMapping('ModalitePedagogiqueAgora__c'),
    }),
    adresseRealisationIdentiqueAgora: BooleanFieldMetadata({
        section: SECTION_MODULE_AGORA,
        props: {
            label: "L'adresse de réalisation de la formation est la même que celle principale",
            onChange: (value: boolean | null, form: UseFormReturn<DossierCAPP>, parentPath?: string) => {
                form.setValue(parentPath + '.organismePrincipalFormationAgora', null)
            }
        },
        mapping: {
            source: 'dossier',
            mapModelToDTO: (value: boolean | null, dto: any) => {
                dto.LieuPrincipalFormationIdentiqueOF__c = value ?? null
            },
            mapDTOToModel: (dto: any, context: MappingContext) => {
                return dto.LieuPrincipalFormationIdentiqueOF__c ?? null
            },
        },
    }),
    organismePrincipalFormationAgora: (adresseRequired: boolean, optional?: boolean) => CFAFieldMetadata({ 
        section: SECTION_MODULE_AGORA,
        props: {
            label: 'Lieu de formation principal',
            selectLabel: 'Choix du lieu de formation principal',
            nomLabel: "Dénomination du lieu de formation principal",
            searchOptions: { tous: true },
            da: false,
            uai: true,
            uaiLabel: 'N° UAI du lieu de formation principal',
            uaiOptional: false,
            siretLabel: "N° Siret du lieu de formation principal",
            siretOptional: false,
            adresseRequired: true,
            communeEnabled: true,
            requiredIf: (ctx: ValidationContext<DossierCFA | null>) => optional ? false : (ctx.parent?.adresseRealisationIdentiqueAgora === false),
        } as any,
        visibleIf: (ctx: ElementContext<DossierCAPPFormation>) => ctx.parent?.adresseRealisationIdentiqueAgora === false,
        mapping: {
            source: 'dossier',
            mapModelToDTO: (value: DossierCFA | null, dto: any) => {
                dto.SIRETOFLieuPrincipalFormation__c = value?.siret ?? null
                dto.RaisonSocOFLieuPrincipalFormation__c = value?.nom ?? null
                dto.UAIOFLieuPrincipalFormation__c = value?.numeroUAI ?? null
                dto.LieuFormationPrincipalLibelle1__c = value?.appartement ?? null
                dto.LieuFormationPrincipalLibelle2__c = value?.batiment ?? null
                dto.LieuFormationPrincipalLibelle3__c = value?.adresse ?? null
                dto.LieuFormationPrincipalLibelle4__c = value?.complement ?? null
                dto.LieuFormationPrincipalCodePostal__c = value?.codePostal ?? null
                dto.LieuFormationPrincipalVille__c = value?.commune ?? null
            },
            mapDTOToModel: (dto: any, context: MappingContext) => {
                if (!dto.SIRETOFLieuPrincipalFormation__c)
                    return null
                const of = createDossierCFA()
                of.EtablissementOFId__c = dto.SIRETOFLieuPrincipalFormation__c ?? null
                of.nom = dto.RaisonSocOFLieuPrincipalFormation__c ?? null
                of.siret = dto.SIRETOFLieuPrincipalFormation__c ?? null
                of.numeroUAI = dto.UAIOFLieuPrincipalFormation__c ?? null
                of.appartement = dto.LieuFormationPrincipalLibelle1__c ?? null
                of.batiment = dto.LieuFormationPrincipalLibelle2__c ?? null
                of.adresse = dto.LieuFormationPrincipalLibelle3__c ?? null
                of.complement = dto.LieuFormationPrincipalLibelle4__c ?? null
                of.codePostal = dto.LieuFormationPrincipalCodePostal__c ?? null
                of.commune = dto.LieuFormationPrincipalVille__c ?? null
                return of
            },
        },
    }),
}

export const CAPPFormationMetadataBuilder = ({ noCreateOF, disableAdresseOF, searchOptions }: { noCreateOF?: boolean, disableAdresseOF?: boolean, searchOptions?: OFSearchOptions }): ObjectMetadata<DossierCAPPFormation> => {
    return {
        cfa: CFAFieldMetadata({ 
            section: SECTION_CFA,
            props: {
                label: 'Organisme de formation',
                noCreate: noCreateOF,
                da: true,
                entreprise: true, 
                uai: true, 
                uaiLabel: 'N° UAI du CFA',
                forceDisabled: disableAdresseOF,
                adresseRequired: true,
                adresseValidationFieldParams: CappOFAdresseValidationFieldsParams,
                searchOptions,
            },
        }),
        diplome: DiplomeFieldMetadata({ 
            props: {
                label: 'Diplôme',
            },
            section: SECTION_FORMATION,
            dependencies: [
                { stepName: "Apprenti(e)", path: "stagiaire.diplomeVise", field: CAPPStagiaireMetadata.diplomeVise },
            ]
        }),
        organismeFormationCout: NumberFieldMetadata({
            props: {
                label: 'Coût pédagogique',
                suffix: '€',
            },
            section: SECTION_FORMATION,
            input: CurrencyField,
            mapping: simpleMapping('CoutPedagogique__c', 'dossier'),
        }).required().mutate(yop => yop.min(1000, 'Minimum 1000 €').max(60000, 'Maximum 60000 €')),
        majorationHandicapCout: (
            NumberFieldMetadata({
                props: {
                    label: 'Montant de la majoration handicap',
                    tooltip: TOOLTIPS.capp.formation.majorationHandicapCout,
                    suffix: '€',
                },
                section: SECTION_FORMATION,
                input: CurrencyField,
                visibleIf: (ctx: ElementContext<any, DossierCAPP>) =>
                    ctx.root.stagiaire?.situationHandicap === SITUATION_HANDICAP &&
                    ctx.root.stagiaire?.handicap === RECONNAISSANCE_HANDICAP,
                mapping: simpleMapping('MontantMajorationHandicap__c'),
            })
            .required()
            .mutate(yop => yop
                // .min(50, 'La majoration handicap doit être supérieure ou égale à 50 €')
                .max(12000, 'La majoration handicap doit être inférieure ou égale à 12 000 €')
                .test(context => {
                    const dateDebut = context.root?.contrat?.dateDebut
                    const dateFin = context.root?.contrat?.dateFin
                    if (dateDebut != null && dateFin != null) {
                        const nbMois = differenceInMonths(dateFin, dateDebut)
                        if (nbMois < 13 && context.value > 4000)
                            return context.createError('La majoration handicap doit être inférieure ou égale à 4 000 € lorsque la durée du contrat est inférieure à 13 mois')
                        if (nbMois <= 24 && context.value > 8000)
                            return context.createError('La majoration handicap doit être inférieure ou égale à 8 000 € lorsque la durée du contrat est inférieure ou égale à 24 mois')
                    }
                    return true
                })
                .ignoredIf((ctx: ValidationContext<any, DossierCAPP>) =>
                    ctx.root.stagiaire?.situationHandicap !== SITUATION_HANDICAP ||
                    ctx.root.stagiaire?.handicap !== RECONNAISSANCE_HANDICAP
                )
            )
        ),
        dateDebutFormation: override(moduleFields.dateDebutFormation, { 
            props: {
                label: 'Date de début de formation en CFA',
                tooltip: TOOLTIPS.capp.formation.dateEntree,
            },
            section: SECTION_FORMATION_ORGA,
        }).mutate(yop => yop
            .max<BaseDossierModule>(context => context.parent!.dateFinFormation, 'La date de début de formation en CFA est postérieure à la date de fin des épreuves')
        ),
        dateFinFormation: (
            DateFieldMetadata({
                props: {
                    label: 'Date prévue de fin des épreuves ou examens',
                    tooltip: TOOLTIPS.capp.formation.dateSortie,
                    disabled: false,
                },
                section: SECTION_FORMATION_ORGA,
                mapping: dateMapping('DateFinFormation__c'),
                visibleIf: (ctx: ElementContext<DossierCAPPFormation, DossierCAPP>) => ctx.parent?.formationInterne != null
            })
            .required()
            .mutate(yop => yop
                .min<BaseDossierModule>(context => context.parent!.dateDebutFormation, 'La date de fin des épreuves est antérieure à la date de début de formation en CFA')
                .test(ctx => {
                    const dateFinFormation = ctx.value
                    const dateFinContrat = ctx.root?.contrat?.dateFin
                    if (dateFinFormation != null && dateFinContrat != null && compareDates(dateFinFormation, dateFinContrat) > 0)
                        return ctx.createError(`La date de fin de contrat ${formatDate(dateFinContrat)} doit être fixée au plus tôt au dernier jour de la dernière épreuve nécessaire à l’obtention du titre ou diplôme préparé.`)
                    return true
                })
            )
        ),
        dureeTotale: override(moduleFields.dureeTotale, { 
            section: SECTION_FORMATION_ORGA,
            props: {
                label: 'Durée de la formation',
                tooltip: TOOLTIPS.capp.formation.duree,
            },
        }).required().mutate(yop => yop.max(9999.99, "Doit être inférieure à 10 000 heures")),
        fraisAnnexesInternational: BooleanFieldMetadata({
            section: SECTION_FORMATION_ORGA,
            props: {
                label: 'Mobilité internationale',
                tooltip: TOOLTIPS.capp.formation.fraisAnnexesInternational,
                onChange: (value, form) => {
                    if (!value)
                        form.setValue('formation.conditionRealisation', null, SetValueOptions.Untouch | SetValueOptions.Validate)
                },
            },
            mapping: simpleMapping('MobiliteInternationale__c', 'dossier'),
        }).required(),
        conditionRealisation: SelectRefFieldMetadata({
            section: SECTION_FORMATION_ORGA,
            props: {
                label: 'Condition de réalisation',
                options: refs => refs.REF_CONDITION_REALISATION.filter(ref => ref.IsActiveFO__c),
                sorted: true,
            },
            mapping: simpleMapping('ConditionRealisation__c', 'dossier'),
            visibleIf: (ctx: ElementContext<DossierCAPPFormation, BaseDossier>) => !!ctx.parent?.fraisAnnexesInternational,
        }).mutate(yop => yop.requiredIf(ctx => !!ctx.parent?.fraisAnnexesInternational)),
        fraisAnnexesHebergement: NumberFieldMetadata({
            section: SECTION_FORMATION_FRAIS,
            props: {
                label: 'Hébergement',
                tooltip: TOOLTIPS.capp.formation.fraisAnnexesHebergement,
                suffix: 'nuit(s)',
            },
            mapping: simpleMapping('NbNuite__c', 'dossier'),
        }).mutate(yop => yop.min(0).max(9999)),
        fraisAnnexesRestauration: NumberFieldMetadata({
            section: SECTION_FORMATION_FRAIS,
            props: {
                label: 'Restauration',
                tooltip: TOOLTIPS.capp.formation.fraisAnnexesRestauration,
                suffix: 'repas',
            },
            mapping: simpleMapping('NbRepas__c', 'dossier'),
        }).mutate(yop => yop.min(0).max(999)),
        // fraisAnnexesEquipementNature: TextFieldMetadata({
        //     section: SECTION_FORMATION_FRAIS,
        //     props: {
        //         label: <span>1<sup>er</sup> équipement nature</span>,
        //         exportLabel: '1er équipement nature',
        //         tooltip: TOOLTIPS.capp.formation.fraisAnnexesEquipementNature,
        //     },
        //     mapping: simpleMapping('NatureEquipement__c', 'dossier'),
        // }).mutate(yop => yop.max(200, 'Maximum 200 caractères')),
        fraisAnnexesEquipementCout: NumberFieldMetadata({
            section: SECTION_FORMATION_FRAIS,
            props: {
                label: <span>Coût 1<sup>er</sup> équipement</span>,
                exportLabel: 'Coût 1er équipement',
                tooltip: TOOLTIPS.capp.formation.fraisAnnexesEquipementCout,
                suffix: '€',
            },
            input: CurrencyField,
            mapping: simpleMapping('CoutEquipement__c', 'dossier'),
        }).mutate(yop => yop.min(0).max(9999)),
        adresseRealisationIdentiqueAgora: override(CAPPFormationBaseMetadata.adresseRealisationIdentiqueAgora, {
            section: SECTION_FORMATION_FRAIS,
        }).required(),
        organismePrincipalFormationAgora: override(CAPPFormationBaseMetadata.organismePrincipalFormationAgora(true), {
            section: SECTION_FORMATION_FRAIS,
        }),
        modalitePedagogiqueAgora: override(CAPPFormationBaseMetadata.modalitePedagogiqueAgora, {}).required(),
    } as any 
}

export const CAPPFormationMetadata = CAPPFormationMetadataBuilder({ noCreateOF: false })
