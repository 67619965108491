
import { BaseTextField, getFieldState, useFormContext, UseFormReturn, useRender } from '@dsid-opcoatlas/reform'
import { AtlasFieldDuration, AtlasFieldDurationInputProps } from 'atlas-ds'
import { BaseFieldProps } from './utils/BaseFieldProps'
import { useEffect } from 'react'

export function durationToString(hours: number | null | undefined) {
    if (hours == null)
        return ''
    const intHours = Math.floor(hours)
    const intMinutes = Math.round((hours - intHours) * 60)
    return `${intHours} h ${intMinutes.toString().padStart(2, '0')} min`
}

export function durationHours(hours: number | null | undefined) {
    if (hours == null)
        return hours
    return Math.floor(hours)
}

export function durationMinutes(hours: number | null | undefined) {
    if (hours == null)
        return hours
    return Math.round((hours - Math.floor(hours)) * 60)
}

export interface DurationFloatingFieldProps<T extends object> extends BaseFieldProps<{ hours: number, minutes: number }, T> {}

function getNumberValue(form: UseFormReturn<any>, name: string) {
    const value = form.getValue(name) ?? 0
    return Number.isNaN(value) ? 0 : value
}

export default function DurationFloatingField<T extends object>(props: DurationFloatingFieldProps<T>) {
    const nameDuration = props.name + "_duration"
    const nameHours = nameDuration + ".hours"
    const nameMinutes = nameDuration + ".minutes"

    const render = useRender()
    const context = useFormContext<T>()
    const fieldState = getFieldState<number>(context, props.name)
    
    if (context.getValue(nameDuration) == null) {
        const hours = fieldState.value ?? null
        context.setValue(nameDuration, { hours: durationHours(hours), minutes: durationMinutes(hours) })
    }

    useEffect(() => {
        const duration = context.getValue(nameDuration) as { hours: number | null, minutes: number | null }
        const hours = durationHours(fieldState.value ?? null)
        const minutes = durationMinutes(fieldState.value ?? null)
        if (duration.hours !== hours || duration.minutes !== minutes)
            context.setValue(nameDuration, { hours, minutes }, true)
    }, [fieldState.value])

    const onBlurHoursOrMinutes = (_: number | null, form: UseFormReturn<any>, parentPath?: string) => {
        const hours = getNumberValue(form, nameHours)
        const minutes = getNumberValue(form, nameMinutes)
        context.setValue(nameHours, hours)
        context.setValue(nameMinutes, minutes)
        context.setValue(props.name, hours + (minutes / 60), true)
    }

    const numberRegex = /^[\d]*$/

    return <AtlasFieldDuration
        name={ props.name }
        nameHours={ nameHours }
        nameMinutes={ nameMinutes }
        label={ props.label }
        error={ fieldState.error }
        disabled={ props.disabled }
        required={ fieldState.constraints.required }
        tooltipContent={ props.tooltip }
        inputConstructor={ (inputProps: AtlasFieldDurationInputProps) => <BaseTextField<any, number>
            { ...inputProps }
            render={ render }
            onBlur={ onBlurHoursOrMinutes }
            toModelValue={ value => numberRegex.test(value) ? parseInt(value) : null }
            toTextValue={ value => value != null ? String(value) : ''}
            acceptInputValue={ value => numberRegex.test(value) }
        /> }
    />
}
