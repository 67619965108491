import { Form, SetValueOptions, UseFormReturn, getFieldState, useForm } from '@dsid-opcoatlas/reform'
import { Yop } from '@dsid-opcoatlas/yop'
import { unwrapResult } from '@reduxjs/toolkit'
import { CATEGORIES_TYPES_ACTIONS, DiagActionDTO, DiagDiagnosticActionDTO, getActions, getTypesAction } from 'api/diagatlasAPI'
import { RootState } from 'app/rootReducer'
import store, { useAppDispatch } from 'app/store'
import { AtlasButton, AtlasCheckbox, AtlasColumns, AtlasFieldCheckboxDropdown, AtlasFieldRadioGroup, AtlasFlex, AtlasGrid, AtlasInfo, AtlasInfoType, AtlasLoading, AtlasResultsHeader, AtlasSpacer, AtlasTags, AtlasTooltip, MyAction, MyActionList, MyActionTag, MyContainer, MyForm, MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import Link from 'components/Link'
import PageHeader from 'components/PageHeader'
import Button from 'components/reform/Button'
import AutocompleteBaseField from 'components/reform/inputs/AutocompleteBaseField'
import SelectField from 'components/reform/inputs/SelectField'
import TextField from 'components/reform/inputs/TextField'
import { useScrollToTop } from 'components/scroll/useScrollToTop'
import { OptionalString, RequiredString } from 'components/yop/constraints'
import { addDays, addSeconds, isAfter, isBefore, parseISO, startOfDay, subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Diagnostic, diagnosticSliceActions } from 'slices/diagatlas/diagnosticSlice'
import { useDiagnostic } from 'slices/diagatlas/useDiagnostic'
import { nil } from 'slices/dossierCommon'
import { setQuery } from 'slices/recherchesSlice'
import { DiagDetails } from './DiagDetails'
import DiagPageHeader from './DiagPageHeader'
import DiagPlanActionOperations from './DiagPlanActionOperations'
import { useDiagnosticNavigation } from './useDiagnoticNavigation'

interface DiagnosticActionsPreconiseesProps {
    diagnostic: Diagnostic | null
    types?: string[]
    source?: string
    changeTypes?: (value: string, checked: boolean) => void
    changeSource?: (value: string) => void
    resetFilters?: () => void
    setShowAddAction?: React.Dispatch<React.SetStateAction<boolean>>
    maxElements?: number
    ajouterLabel?: string
    ajouterAction: (action: DiagDiagnosticActionDTO) => Promise<DiagDiagnosticActionDTO>
    desactiverAction?: (action: DiagDiagnosticActionDTO) => Promise<void>
    resultsHeader?: JSX.Element
    loading?: boolean
}

export const getActionBanner = (da: DiagDiagnosticActionDTO, diagnostic?: Diagnostic | null): { label: string, type: AtlasInfoType} | undefined => {
    if (getActionError(da)) return undefined;

    const dateRealisation = diagnostic?.dateRealisation ? parseISO(diagnostic?.dateRealisation) : null
    const dateModification = diagnostic?.dateModification ? parseISO(diagnostic?.dateModification) : null

    const dateAjout = da.DateAjout ? parseISO(da.DateAjout) : null
    const isNew = !!dateModification && !!dateAjout && isAfter(dateAjout!, addSeconds(dateRealisation!, 3)) && ( // Pas à la création
        !isBefore(dateAjout!, dateModification!) // Modifiés depuis la dernière maj du diag
        || isAfter(dateAjout!, subDays(Date.now(), 1))       // Modifiés depuis - de 24h
    )

    if (isNew) {
        return { label: "Nouveau", type: "success" }
    }

    const dateFin = da.Action.DateFin ? parseISO(da.Action.DateFin) : null
    const isLastDays = dateFin && isBefore(dateFin, addDays(Date.now(), 60))

    if (isLastDays) {
        return { label: "Derniers jours", type: "warning" }
    }

    return undefined
}

export const getActionError = (da: DiagDiagnosticActionDTO) : string | undefined => {
    const dateFin = da.Action.DateFin ? parseISO(da.Action.DateFin) : null

    return (dateFin && isBefore(dateFin, startOfDay(Date.now())))
        ? "Cette action n'est plus disponible"
        : undefined;
}

export const getActionTags = (da: DiagDiagnosticActionDTO) => {
    const tags = [{ label: da.Action.Type.Libelle, category: da.Action.Type.CategorieLibelle }] as MyActionTag[]
    if (da.Action.Source !== 'Atlas') {
        tags.push({ label: 'Action personnalisée' } as MyActionTag)
    }
    return tags
}

export function DiagnosticActionsPreconisees(props: DiagnosticActionsPreconiseesProps) {
    const [updatingAction, setUpdatingAction] = useState('')

    const types = props.types ?? []
    const source = props.source ?? "all"

    const planAction = props.maxElements ? props.diagnostic?.planAction?.slice(0, props.maxElements) : props.diagnostic?.planAction

    const deleteAction = (da: DiagDiagnosticActionDTO) => {
        if (props.desactiverAction) {
            setUpdatingAction(da.Action.Id)
            setTimeout(() => {
                props.desactiverAction!(da)
                    .finally(() => setUpdatingAction(''))
            }, 100)
        }
    }

    const ajouterAction = (da: DiagDiagnosticActionDTO) => {
        setUpdatingAction(da.Action.Id)
        setTimeout(() => {
            props.ajouterAction(da)
                .finally(() => setUpdatingAction(''))
        }, 100)
    }

    const actions = planAction
        ?.filter(da => !getActionError(da))
        ?.filter(da => types.length === 0 || types.includes(da.Action.Type.CategorieLibelle))
        ?.filter(da => source === "all" || (source === 'Atlas' && !da.AjoutEntreprise) || source === 'Entreprise' && da.AjoutEntreprise)

    return <AtlasLoading loading={ !!props.loading }>
        { props.resultsHeader ?? <AtlasResultsHeader
            count={actions?.length ?? 0}
            resultsLabel="actions préconisée"
            noResultsLabel="Aucune action préconisée"
            actions={props.setShowAddAction
                ? <AtlasButton onClick={() => props.setShowAddAction!(true) } icon="plus" level={ 3 }>Ajouter une action</AtlasButton>
                : undefined
            }
            onResetFilters={props.resetFilters}
            filters={props.changeTypes && props.changeSource && (types.length > 0 || source !== "all") ? <AtlasTags>{[
                ...types
                    .filter(type => type !== "all")
                    .map(type => <AtlasTags.Item
                        key={ type }
                        label="Type d'actions"
                        delete={{
                            onDelete: () => props.changeTypes!(type, false),
                            ariaLabel: `Retirer le filtre type ${type}`
                        }}
                    >{ type }</AtlasTags.Item>),
                ...source !== "all" ? [<AtlasTags.Item
                    key={ source }
                    label="Préconisées par"
                    delete={{
                        onDelete: () => props.changeSource!('all'),
                        ariaLabel: `Retirer le filtre préconisées par ${source}`
                    }}
                >{ source }</AtlasTags.Item>] : []
            ]}</AtlasTags> : undefined}
        /> }

        { (props.loading || actions?.length) ? <MyActionList>
            { actions
                ?.map((da, index) => <MyAction
                        key={ `action-${da.ExternalIdHeroku}` }
                        tags={ getActionTags(da) } 
                        banner={ getActionBanner(da, props.diagnostic) }
                        error={ getActionError(da) }
                        isLeaving={ updatingAction === da.Action.Id }
                        onDelete={ props.desactiverAction ? () => deleteAction(da) : undefined }
                        infoHref={ da.Action.URL } 
                        button={ getActionError(da) ? undefined : <AtlasButton icon="plus" onClick={ () => ajouterAction(da) }>
                            { props.ajouterLabel ?? "Ajouter au plan d'action" }
                        </AtlasButton> }
                    >{ da.Action.Libelle }</MyAction>
                ) ?? []
            }
        </MyActionList> : <AtlasInfo title="Aucun résultat" type={ planAction?.length === 0 ? "error" : "warning"}>
            { planAction?.length === 0
                ? "Aucune action préconisée n'est disponible."
                : "Votre recherche n'a remonté aucun résultat. Nous vous invitons à modifier les critères de recherche."
            }
        </AtlasInfo> }
    </AtlasLoading>
}


export default function MonDiagnosticActions() {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const navigation = useDiagnosticNavigation()
    useScrollToTop()

    const storedFilters = JSON.parse(sessionStorage.getItem('diagnostic-filtres-actions') ?? 'null')

    const [showAddAction, setShowAddAction] = useState(false)
    const [showCreateAction, setShowCreateAction] = useState(false)
    const [disableAskDesactiverAction, setDisableAskDesactiverAction] = useState(false)
    const [showAskDesactiverAction, setShowAskDesactiverAction] = useState(null as (DiagDiagnosticActionDTO | null))
    const [types, setTypes] = useState(storedFilters?.types ?? [])
    const [source, setSource] = useState(storedFilters?.source ?? 'all')

    const [actionUpdating, setActionUpdating] = useState('')

    const { diagnostic, loading } = useDiagnostic(null, ['plan-action', 'has-plan-action'], { statuts: 'preconisee' })

    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        setDisableAskDesactiverAction(localStorage.getItem('diagatlas.askDesactiverCheckbox') === 'true')

        const diagInfoSeen = localStorage.getItem('diagatlas.infoSeen')
        if (diagInfoSeen !== "true") {
            localStorage.setItem('diagatlas.infoSeen', 'true')
            setShowInfo(true)
        }
    }, []) 

    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        setSource(params.source)
        setTypes(params.types ? params.types.split(',') : [])
    }, [document.location.search])

    useEffect(() => {
        const params = new URLSearchParams(document.location.search)

        if (!params.get("types") && !params.get("source")) {
            const storedQuery = store.getState().recherchesState.queries.diagnostic;

            if (storedQuery) {
                updateFilters(new URLSearchParams(storedQuery))
            }
        }
    }, [])

    const changeTypes = (value: string, checked: boolean) => {
        const params = new URLSearchParams(location.search)

        if (value === 'all') {
            params.delete("types")
        } else {
            let newTypes = [...types]

            if (checked) {
                newTypes.push(value)
            } else {
                newTypes = newTypes.filter(type => type !== value)
            }

            // No types or all types selected
            if (newTypes.length === 0 || newTypes.length === 3) {
                params.delete("types")
            } else {
                params.set("types", newTypes.join(','))
            }
        }

        updateFilters(params)
    }

    const changeSource = (value: string) => {
        const params = new URLSearchParams(location.search)
        if (value === "all") {
            params.delete("source")
        } else {
            params.set("source", value);
        }
        updateFilters(params)
    }

    const resetFilters = () => {
        const params = new URLSearchParams(location.search)
        params.delete("types")
        params.delete("source")
        updateFilters(params)
    }

    const updateFilters = (params: URLSearchParams) => {
        const storedParams = new URLSearchParams()
        if (params.get("types")) {
            storedParams.set("types", params.get("types")!)
        }
        if (params.get("source")) {
            storedParams.set("source", params.get("source")!)
        }
        dispatch(setQuery({ name: "diagnostic", query: storedParams.toString() }))

        history.push({
            pathname: location.pathname,
            search: params.toString(),
        })
    }


    const ajouterAction = (action: DiagDiagnosticActionDTO) => {
        if (actionUpdating !== '') {
            return Promise.resolve(action)
        }
        setActionUpdating(action.Id)
        return dispatch(diagnosticSliceActions.addAction({ action: action.Action.Id, preconisee: false }))
            .then(unwrapResult)
            .then(res => {
                ToastService.showToast({
                    type: "success",
                    label: "Action ajoutée",
                    content: `L'action « ${res.Action.Libelle} » a bien été ajoutée à votre plan d'action`,
                    icon: "diagnostic",
                    link: <Link to="/mon-diagnostic-plan">Voir mon plan d'action</Link>,
                })
                return res
            })
            .finally(() => setActionUpdating(''))
    }

    const doDesactiverAction = (action: DiagDiagnosticActionDTO) => {
        if (actionUpdating !== '') {
            return Promise.resolve()
        }
        setActionUpdating(action.Id)
        return dispatch(diagnosticSliceActions.desactiverAction({ action: action.Action.Id }))
            .then(unwrapResult)
            .finally(() => setActionUpdating(''))
}

    const desactiverAction = (action: DiagDiagnosticActionDTO) => {
        if (disableAskDesactiverAction)
            return doDesactiverAction(action)
        else
            setShowAskDesactiverAction(action)
        return Promise.resolve()
    }

    const creerAction = () => {
        setShowAddAction(false)
        setShowCreateAction(true)
    }

    return <>
        <DiagPageHeader>
            <AtlasLoading loading={ loading }>
                <PageHeader
                    actions={[
                        <DiagPlanActionOperations diagnostic={diagnostic} key="operations" />,
                        diagnostic?.hasPlanAction
                            ? <AtlasButton
                                key="plan"
                                onClick={ () => navigation.navigateToPath('plan') }
                                icon="diagnostic"
                            >Plan d'action</AtlasButton>
                            : loading
                            ? <AtlasButton key="plandisabled" icon="diagnostic" disabled>Plan d'action</AtlasButton>
                            : <AtlasTooltip
                                key="plantooltip"
                                opener={ <AtlasButton disabled icon="diagnostic">Plan d'action</AtlasButton> }
                            >Pour accéder à votre plan d'action, commencez par ajouter des actions préconisées ci-dessous.</AtlasTooltip>
                    ]}
                >Actions préconisées</PageHeader>
            </AtlasLoading>
        </DiagPageHeader>

        { showInfo && <>
            <AtlasInfo title="Votre diagnostic est terminé !">
                <p>Découvrez les actions recommandées par l'Opco Atlas.</p>
                <p><strong>Survolez les actions et sélectionnez celles que vous souhaitez ajouter à votre Plan d'action. Vous pouvez ajuster ces recommandations en modifiant le diagnostic ou en ajoutant une action.</strong></p>
            </AtlasInfo>
            <AtlasSpacer size="xl" />
        </> }

        <AtlasColumns alignItems='end'>
            <MyContainer>
                <AtlasGrid columnsCount={3} gap="xl">
                    <AtlasFieldCheckboxDropdown
                        name="type"
                        label="Type d'actions"
                        separateFirstOption
                        onChange={event => changeTypes(event.target.value, event.target.checked)}
                        options={[
                            { label: "Tous", value: "all", checked: types.length === 0 },
                            { label: "Formation", value: "Formation", checked: types.includes("Formation") },
                            { label: "Recrutement", value: "Recrutement", checked: types.includes("Recrutement") },
                            { label: "Pratiques RH", value: "Pratiques RH", checked: types.includes("Pratiques RH") }
                        ]}
                    />
                    <AtlasFieldRadioGroup
                        name="ajout"
                        label="Préconisées par"
                        onChange={event => changeSource(event.target.value)}
                        options={[
                            { label: "Atlas", value: "Atlas", checked: source === "Atlas" },
                            { label: "Entreprise", value: "Entreprise", checked: source === "Entreprise" },
                            { label: "Tous", value: "all", checked: !source }
                        ]}
                    />
                </AtlasGrid>
            </MyContainer>

            <AtlasLoading loading={loading}>
                { <DiagDetails diagnostic={diagnostic ?? undefined} /> }
            </AtlasLoading>
        </AtlasColumns>

        <AtlasSpacer size="xl" />

        <DiagnosticActionsPreconisees
            loading={ loading }
            diagnostic={ diagnostic }
            resetFilters={ resetFilters }
            changeTypes={ changeTypes }
            changeSource={ changeSource }
            setShowAddAction={ setShowAddAction }
            types={ types }
            source={ source }
            ajouterAction={ ajouterAction }
            desactiverAction={ desactiverAction }
        />

        { showAddAction && <AjouterActionModal show={showAddAction} close={ () => setShowAddAction(false) } 
            next={ () => setShowAddAction(false)} create={ creerAction } /> }

        { showCreateAction && <CreerActionModal show={showCreateAction} close={ () => setShowCreateAction(false) } 
            next={ () => setShowCreateAction(false)} /> }

        { showAskDesactiverAction && <AskDesactiverActionModal action={ showAskDesactiverAction } close={ () => setShowAskDesactiverAction(null) } 
            next={ () => {
                setDisableAskDesactiverAction(localStorage.getItem('diagatlas.askDesactiverCheckbox') === 'true')
                doDesactiverAction(showAskDesactiverAction)
                setShowAskDesactiverAction(null)
            } }
        /> }
    </>
}

export function AjouterActionModal(props: { show: boolean, close: () => void, next: () => void, create: () => void }) {

    const dispatch = useAppDispatch()
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const { saving, error } = useSelector((state: RootState) => state.diagnosticState)

    const clearError = () => {
        dispatch(diagnosticSliceActions.clear())
    }

    const close = () => {
        clearError()
        props.close()
    }

    const create = () => {
        clearError()
        props.create()
    }

    const [typesAction, setTypesAction] = useState([] as any[])

    useEffect(() => {
        if (entreprise) {
            getTypesAction(entreprise!.Id)
                .then(typesAction => setTypesAction(typesAction.map(ta => ({
                    value: ta.Id,
                    label: ta.Libelle,
                }))))
        }
    }, [dispatch, entreprise])

    const initialValues = { type: nil as string, action: nil as { Id: string, Libelle: string } }

    const validationSchema = Yop.object<typeof initialValues>({
        type: RequiredString,
        action: Yop.object<{ Id: string, Libelle: string }>({ Id: RequiredString, Libelle: RequiredString }).defined().required(),
    })

    const onSubmit = (context: UseFormReturn<typeof initialValues>) => {
        dispatch(diagnosticSliceActions.addAction({ action: context.values!.action.Id, preconisee: true }))
            .then(unwrapResult)
            .then(() => {
                props.next()
                ToastService.showToast({
                    type: "success",
                    label: "Action ajoutée",
                    content: `L'action « ${context.values!.action.Libelle} » a été ajoutée à la liste des actions préconisées`
                })
            })
            .finally(() => context.setSubmitting(false))
    }

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit, 
    })
    const fieldState = getFieldState<DiagActionDTO | null>(form, 'action')

    const searchActions = async (recherche: string) => {
        if (!entreprise || !form.values?.type || recherche == null)
            return []

        const actions = await getActions(entreprise.Id, form.values.type, recherche)
        return actions
    }

    return (
        <MyModal show={ props.show } label="Ajouter une action">
            <Form context={form} noValidate>
                <MyForm>
                    <SelectField
                        selectionner
                        name="type"
                        label="Type d'action"
                        options={ typesAction }
                        onChange={ () => {
                            form.setValue("action", null, SetValueOptions.Untouch)
                            clearError()
                        } }
                    />

                    <AutocompleteBaseField<DiagActionDTO>
                        label="Action"
                        defaultValue={ fieldState.value?.Libelle ?? "" }
                        error={ fieldState.error }
                        required={ fieldState.constraints.required }
                        disabled={ form.values?.type == null }
                        placeholder="Rechercher une action"
                    
                        search={ searchActions }
                        searchDebounceOptions={{ immediate: form.values?.type != null, empty: true, minChars: 0 }}
                        
                        optionFor={ action => ({ id: action.Code, content: action.Libelle }) }
                        onSelect={ action => { clearError(); form.setValue("action", action, true) } }
                        hideNoResultsMessage
                    />

                    { !saving && error && (error.startsWith('action already exists in plan') ? 
                        <AtlasInfo type="warning" title="Erreur d'ajout">
                            Cette action est déjà présente dans le plan
                        </AtlasInfo> :
                        error.startsWith('action already exists in preco') ? 
                        <AtlasInfo type="warning" title="Erreur d'ajout">
                            Cette action est déjà présente dans la liste des actions préconisées
                        </AtlasInfo> :
                        <AtlasInfo type="error" title="Erreur d'ajout">{ error }</AtlasInfo> )
                    }

                    <MyForm.Actions>
                        <AtlasButton type="submit" level={ 1 } disabled={ !form.values?.action } spinner={{ spinning: saving }} >
                            Ajouter
                        </AtlasButton>
                        <AtlasButton level={ 2 } onClick={ close }>
                            Retour
                        </AtlasButton>
                    </MyForm.Actions>

                    <AtlasFlex column gap="s">
                        <p>Vous ne trouvez pas l'action recherchée ?</p>
                        <AtlasButton type="button" level={3} onClick={ create } icon="plus">
                            Créez votre action personnalisée
                        </AtlasButton>
                    </AtlasFlex>
                </MyForm>
            </Form>
        </MyModal>
    )
}

export function CreerActionModal(props: { show: boolean, close: () => void, next: () => void }) {

    const dispatch = useAppDispatch()
    const { saving, error } = useSelector((state: RootState) => state.diagnosticState)

    const clearError = () => {
        dispatch(diagnosticSliceActions.clear())
    }

    const close = () => {
        clearError()
        props.close()
    }

    const initialValues = { categorie: nil as string, libelle: nil as string, lien: nil as string | null }

    const validationSchema = Yop.object<typeof initialValues>({
        categorie: RequiredString,
        libelle: RequiredString,
        lien: OptionalString.matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9()@:%_*.~#?&/=]*)/),
    })

    const onSubmit = (context: UseFormReturn<typeof initialValues>) => {
        dispatch(diagnosticSliceActions.createAction({ categorie: context.values!.categorie, libelle: context.values!.libelle, lien: context.values!.lien ?? undefined }))
            .then(unwrapResult)
            .then(() => {
                props.next()
                ToastService.showToast({
                    type: "success",
                    label: "Action ajoutée",
                    content: `L'action « ${context.values!.libelle} » a été ajoutée à la liste des actions préconisées`
                })
            })
            .finally(() => context.setSubmitting(false))
    }

    const form = useForm({ 
        initialValues, 
        validationSchema, 
        dispatchEvent: false,
        onSubmit,
    })

    return (
        <MyModal show={ props.show } label="Créer une action personnalisée">
            <Form context={form} noValidate>
                <MyForm>
                    <SelectField
                        selectionner
                        name="categorie"
                        label="Type d'action"
                        options={ Object.entries(CATEGORIES_TYPES_ACTIONS).map(([id, libelle]) => ({
                            value: id,
                            label: libelle,
                        })) }
                        onChange={ clearError }
                    />

                    <TextField 
                        name="libelle"
                        label="Intitulé de l'action"
                        trim={ true }
                        onChange={ clearError }
                    />

                    <TextField 
                        name="lien"
                        label="Lien URL"
                        onChange={ clearError }
                    />

                    { !saving && error && (error.startsWith('action already exists in plan') ? 
                        <AtlasInfo type="warning" title="Erreur de création">
                            Cette action est déjà présente dans le plan
                        </AtlasInfo>
                        : error.startsWith('action already exists in preco') ? 
                        <AtlasInfo type="warning" title="Erreur de création">
                            Cette action est déjà présente dans la liste d'actions préconisées
                        </AtlasInfo>
                        : error.startsWith('action already exists') ?
                        <AtlasInfo type="warning" title="Erreur de création">
                            Cette action a déjà été créée par votre entreprise. Retrouvez-là dans la liste des actions personnalisées.
                        </AtlasInfo>
                        : <AtlasInfo type="error" title="Erreur de création">{ error }</AtlasInfo> )
                    }

                    <MyForm.Actions>
                        <AtlasButton type="submit" level={ 1 } disabled={ !form.values?.categorie } spinner={{ spinning: saving }}>
                            Enregistrer et ajouter
                        </AtlasButton>
                        <AtlasButton level={ 2 } onClick={ close }>Retour</AtlasButton>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}

export function AskCreerPlanActionModal(props: { close: () => void, next: () => void }) {
    return (
        <MyModal
            label="Validation du diagnostic"
            okButton={ <Button onClick={ props.next }>Continuer</Button> }
            cancelButton={ <Button onClick={ props.close }>Retour</Button> }
        >
            <AtlasFlex column gap="l">
                <p>La validation du diagnostic créera une liste d'actions préconisées par Atlas en fonction de vos choix.</p>
            </AtlasFlex>
        </MyModal>
    )
}

export function AskDesactiverActionModal(props: { action: DiagDiagnosticActionDTO, close: () => void, next: () => void }) {
    const [ask, setAsk] = useState(false)

    useEffect(() => {
        const ask = localStorage.getItem('diagatlas.askDesactiverCheckbox') === 'true'
        setAsk(ask)
    }, [])

    const confirm = () => {
        if (ask)
            localStorage.setItem('diagatlas.askDesactiverCheckbox', 'true')
        else 
            localStorage.removeItem('diagatlas.askDesactiverCheckbox')
        props.next()
    }

    return (
        <MyModal
            label="Désactiver une action"
            okButton={ <Button onClick={ confirm }>Confirmer</Button> }
            cancelButton={ <Button onClick={ props.close }>Retour</Button> }
        >
            <AtlasFlex column gap="l">
                <p>Voulez-vous vraiment supprimer l'action « <strong>{ props.action.Action.Libelle }</strong> » ?</p>

                <AtlasCheckbox
                    name="askDesactiverCheckbox"
                    value="askDesactiverCheckbox"
                    label="Ne plus me demander de confirmer"
                    checked={ ask }
                    onChange={ event => setAsk(event.target.checked) }
                />
            </AtlasFlex>
        </MyModal>
    )
}
