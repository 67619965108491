import { findTypeReglement, getRefsBancaires, RefBancaireDTO, RefsBancairesSearchQuery } from 'api/refBancaireAPI'
import { AtlasButton, AtlasInfo, MyDropdownMenu, MyTableColumn } from 'atlas-ds'
import { formatApiDate } from 'components/format/Format'
import Link from 'components/Link'
import PageAlert from 'components/PageAlert'
import { BaseSearchQueryImpl } from 'components/recherche/BaseSearchQueryImpl'
import { Filtre } from 'components/recherche/Filtres'
import RechercheBase, { singleTab } from 'components/recherche/RechercheBase'
import State from 'components/State'
import RefBancaireDesactiverModal from 'pages/detail-ref-bancaire/RefBancaireDesactiverModal'
import { useState } from 'react'

const interruption = import.meta.env.REACT_APP_INTERRUPTION_REFS_BANCAIRES === 'true'


export const mesRefsBancairesRefreshEvent = "MesRefsBancaires:Refresh"

export class RefsBancairesSearchQueryImpl extends BaseSearchQueryImpl implements RefsBancairesSearchQuery {

    constructor(search: URLSearchParams) {
        super(search, 'dateCreation')
        this.applySearch(search)
    }

    clone() {
        return new RefsBancairesSearchQueryImpl(this.toSearchParams())
    }
}

export default function MesRefsBancaires(props: { labelAjout: string }) {

    const [showDesactiverModal, setShowDesactiverModal] = useState<RefBancaireDTO | null>(null)

    const filtres = [] as Filtre[]

    const colonnes = (_: RefsBancairesSearchQuery): MyTableColumn[] => [
        {
            id: "banque",
            label: "Banque",
            sortable: true
        },
        {
            id: "iban",
            label: "IBAN",
            wrap: true
        },
        {
            id: "dispositif",
            label: "Dispositif",
            value: (row: any) => findTypeReglement(row.dispositif)?.Libelle ?? '' },
        {
            id: "dateCreation",
            label: "Date de création",
            sortable: true,
            value: (row: any) => formatApiDate(row.dateCreation)
        },
        {
            id: "siret",
            label: "SIRET établissement"
        },
        {
            id: "statut",
            label: "statut",
            value: (row: any) => <State type="bill" id={ row.statut === "Actif" ? "3" : "5" }>
                { row.statut }
            </State>
        }
    ]

    const info = interruption ? <AtlasInfo type="warning" title="Interruption de service">
        Pour des raisons indépendantes d’Atlas, les services de dépôt de références bancaires sont temporairement interrompus. Veuillez nous excuser pour la gêne occasionnée.
    </AtlasInfo> : <PageAlert config="BandeauxRefBancaires" />
 
    const actions = (refBancaire: RefBancaireDTO) => {
        const actions = [
            <Link
                level={ 3 }
                key="detail"
                icon="details"
                to={ '/detail-ref-bancaire/' + refBancaire.Id }
            >Voir le détail</Link>,
        ]
        if (refBancaire.statut === 'Actif') {
            actions.push(<AtlasButton
                key="desactiver"
                icon="close"
                level={ 3 }
                onClick={() => setShowDesactiverModal(refBancaire)}
            >Désactiver</AtlasButton>)
        }
        return actions
    }

    const newQuery = (params: URLSearchParams) => new RefsBancairesSearchQueryImpl(params)

    const headerActions =
        <MyDropdownMenu
            disabled={interruption}
            items={[
                <Link to="/ajout-ref-bancaire-adherent" icon="reglements">
                    { props.labelAjout }
                </Link>,
                <Link to="/ajout-ref-bancaire-factor" icon="reglements">
                    Ajouter un RIB factor
                </Link>
            ]}
        >Ajouter un RIB</MyDropdownMenu>

    return (
        <RechercheBase<RefBancaireDTO, RefsBancairesSearchQueryImpl>
            name="MesRefsBancaires"
            title="Références bancaires"
            headerActions={ headerActions }
            headerInfo={ info }
            newQuery={ newQuery }
            filtres={ filtres }
            colonnes={ colonnes }
            tabs={ singleTab(getRefsBancaires) }
            tableActions={ actions }>
            <>
            { showDesactiverModal &&
            <RefBancaireDesactiverModal show={ true } refBancaire={ showDesactiverModal! } 
                close={ () => setShowDesactiverModal(null) } />
            }
            </>
        </RechercheBase>
    )
}